import { ButtonProps, IconButton } from "@mui/material";

interface SimpleIconButtonProps {
  children?: React.ReactNode;
}

function SimpleIconButton(props: SimpleIconButtonProps | ButtonProps) {
  const { children, ...rest } = props;

  const render = () => {
    return (
      <IconButton {...rest}>
        {children}
      </IconButton>
    );
  };

  return render();
}

export default SimpleIconButton;
