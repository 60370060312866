import { observable, action, makeObservable } from "mobx";
import {
  ClientProfileDetails,
  ClientProfileSettings,
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
  DataValidationStatus,
  ClientProfileAlertsData,
} from "./types";

export class ClientProfileStore {
  constructor() {
    makeObservable(this, {
      clientProfileDetails: observable,
      clientProfileSettings: observable,
      clientProfileAccessDetails: observable,
      clientProfileCameraSettings: observable,
      dataValidationStatus: observable,
      clientProfileAlerts: observable,

      setClientProfileDetails: action,
      setCameraSettings: action,
      setClientProfileAlerts: action,
      clear: action,
    });
  }

  public clientProfileDetails: ClientProfileDetails =
    new ClientProfileDetails();
  public clientProfileSettings: ClientProfileSettings =
    new ClientProfileSettings();
  public clientProfileAccessDetails: ClientProfileAccessSettings =
    new ClientProfileAccessSettings();
  public clientProfileCameraSettings: ClientProfileCameraSettings =
    new ClientProfileCameraSettings();
  public dataValidationStatus: DataValidationStatus =
    new DataValidationStatus();
  public clientProfileAlerts: ClientProfileAlertsData =
    new ClientProfileAlertsData();

  clientProfileAssignmentAreas = {
    clientProfileAccessLocations: [],
    clientProfileAssignmentAreas: [],
    hasChanges: false,
  };

  setClientProfileDetails = (id: any, data: ClientProfileDetails) => {
    const {
      areaName,
      clientName,
      isAccessProfileEnabled,
      isAlarmProfileEnabled,
      isStatusEnabled,
      locationName,
      activeAlertPeriodId,
      activeAccessPeriodId,
      activeSensors,
      availableStatuses,
      sensorIssues,
    } = data;

    const { clientProfileDetails } = this;

    clientProfileDetails.clientId = id;
    clientProfileDetails.areaName = areaName;
    clientProfileDetails.clientName = clientName;
    clientProfileDetails.isAccessProfileEnabled = isAccessProfileEnabled;
    clientProfileDetails.isAlarmProfileEnabled = isAlarmProfileEnabled;
    clientProfileDetails.isStatusEnabled = isStatusEnabled;
    clientProfileDetails.locationName = locationName;
    clientProfileDetails.activeAlertPeriodId = activeAlertPeriodId;
    clientProfileDetails.activeAccessPeriodId = activeAccessPeriodId;
    clientProfileDetails.activeSensors = activeSensors;
    clientProfileDetails.availableStatuses = availableStatuses;
    clientProfileDetails.sensorIssues = sensorIssues;
  };

  setCameraSettings = (data: ClientProfileCameraSettings) => {
    this.clientProfileCameraSettings.optionTypeId = data.optionTypeId;
    this.clientProfileCameraSettings.isActive = data.isActive;
  };

  setValidationDetails = (status: boolean) => {
    this.dataValidationStatus.acousticSettingsValid = status;
    this.dataValidationStatus.buildingAccessValid = status;
  };

  setClientProfileAlerts = (data: ClientProfileAlertsData) => {
    this.clientProfileAlerts = data;
  };

  clear() {
    const { clientProfileDetails } = this;
    clientProfileDetails.locationName = "";
    clientProfileDetails.clientName = "";
    clientProfileDetails.areaName = "";
    clientProfileDetails.activeSensors = [];
    clientProfileDetails.availableStatuses = {};
    clientProfileDetails.isStatusEnabled = false;
    clientProfileDetails.isAlarmProfileEnabled = false;
    clientProfileDetails.isAccessProfileEnabled = false;
    clientProfileDetails.sensorIssues = [];

    const { clientProfileSettings } = this;
    clientProfileSettings.alerts = {};
    clientProfileSettings.intervals = [
      {
        intervalId: 0,
        startTime: "",
        isDaytimeInterval: true,
      },
      {
        intervalId: 0,
        startTime: "",
        isDaytimeInterval: false,
      },
    ];

    this.clientProfileAlerts.alerts = [];
    this.clientProfileAccessDetails.clientProfileAssignmentAreas = [];

    const { clientProfileCameraSettings } = this;
    clientProfileCameraSettings.isActive = false;
    clientProfileCameraSettings.optionTypeId = 0;
  }
}
