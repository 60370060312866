import * as React from "react";
import "./time-picker.css";

import moment from "moment";
import classnames from "classnames";

interface TimeColumnProps {
  upDisabled?: boolean;
  downDisabled?: boolean;
  value: string;
  upClicked(): any;
  downClicked(): any;
}
const TimeColumn = (props: TimeColumnProps) => {
  return (
    <div className="col">
      <div
        className={classnames("button", {
          disabled: props.upDisabled,
          active: !props.upDisabled,
        })}
        onClick={!props.upDisabled ? props.upClicked : undefined}
      >
        +
      </div>
      <div className="input">{props.value}</div>
      <div
        className={classnames("button", {
          disabled: props.downDisabled,
          active: !props.downDisabled,
        })}
        onClick={!props.downDisabled ? props.downClicked : undefined}
      >
        -
      </div>
    </div>
  );
};

export { TimeColumn };

export interface ITimePickerProps {
  value: string;
  limitAt: string;
  onChange?(value: string): void;
}
export class TimePicker extends React.Component<ITimePickerProps, any> {
  constructor(props:ITimePickerProps) {
    super(props);
    this.state = { value: this.props.value };
  }

  public render() {
    const isStartValueBeforeLimit = this.isStartValueBeforeLimit();
    const time = this.time();
    const limitAt = this.limitAt();

    const hourUp = this.increase({ hours: 1 });
    const hourDown = this.increase({ hours: -1 });

    const minutesUp = this.increase({ minutes: 15 });
    const minutesDown = this.increase({ minutes: -15 });

    const nextHour = this.time().add({ hours: 1 });
    const prevHour = this.time().subtract({ hours: 1 });

    const nextMinutes = this.time().add({
      minutes: 15,
    });
    const prevMinutes = this.time().subtract({
      minutes: 15,
    });

    const upHourDisabled =
      (isStartValueBeforeLimit && nextHour.isSameOrAfter(limitAt)) ||
      nextHour.hour() === 0;
    const downHourDisabled =
      (!isStartValueBeforeLimit && prevHour.isSameOrBefore(limitAt)) ||
      time.hour() === 0;

    const upMinutesDisabled =
      nextMinutes.isSame(limitAt) || nextMinutes.minutes() === 0;
    const downMinutesDisabled =
      prevMinutes.isSame(limitAt) || time.minutes() === 0;

    return (
      <div className="amstel-time-picker">
        <div className="columns">
          <TimeColumn
            value={time.format("HH")}
            upDisabled={upHourDisabled}
            downDisabled={downHourDisabled}
            upClicked={hourUp}
            downClicked={hourDown}
          />
          <div className="col margins">:</div>
          <TimeColumn
            value={time.format("mm")}
            downDisabled={downMinutesDisabled}
            upDisabled={upMinutesDisabled}
            upClicked={minutesUp}
            downClicked={minutesDown}
          />
        </div>
      </div>
    );
  }

  private increase = (increment: { hours?: number; minutes?: number }) => {
    const time = this.time();
    const { onChange } = this.props;
    return () => {
      const newTime = time.add(increment);
      const value = newTime.format("HH:mm");

      this.setState({
        value,
      });

      if (onChange === undefined) {
        return;
      }
      onChange(value);
    };
  };

  private time() {
    const time = moment(this.state.value, "HH:mm");
    return time;
  }

  private limitAt() {
    const time = moment(this.props.limitAt, "HH:mm");
    return time;
  }

  private isStartValueBeforeLimit() {
    const startValue = moment(this.props.value, "HH:mm");
    const limitAt = this.limitAt();

    return startValue.isBefore(limitAt);
  }
}
