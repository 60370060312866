import { PeripheralData } from "./types";
import { PeripheralDataIn } from "./typesIn";
import { PeripheralUpdateCallOut } from "./typesOut";

export interface PeripheralDataConverter {
  toStore(inData: PeripheralDataIn): PeripheralData;
  toOut(storeData: PeripheralData): PeripheralUpdateCallOut;
}

export const PeripheralDataConverter: PeripheralDataConverter = {
  toStore(inData: PeripheralDataIn): PeripheralData {
    return {
      address: inData.address,
      areaName: inData.areaName,
      controllerMAC: inData.controllerMAC,
      ipAddress: inData.ipAddress,
      isUsed: inData.isUsed,
      locationName: inData.locationName,
      mariName: inData.mariName,
      service: inData.service,
      bus: inData.bus,
      locationId: inData.locationId,
      peripheralId: inData.peripheralId,
      subAddress: inData.subAddress,
    };
  },
  toOut(storeData: PeripheralData): PeripheralUpdateCallOut {
    return {
      address: storeData.address,
      areaName: storeData.areaName,
      bus: storeData.bus || 0,
      controllerMAC: storeData.controllerMAC,
      ipAddress: storeData.ipAddress,
      isUsed: storeData.isUsed,
      locationId: storeData.locationId || 0,
      locationName: storeData.locationName,
      mariName: storeData.mariName,
      peripheralId: storeData.peripheralId || 0,
      service: storeData.service,
      subAddress: storeData.subAddress || 0,
    };
  },
};
