import { uploadFileRequest, postRequest, deleteRequest } from "../../web";
import { AlertListCallIn } from "./store/typesIn";
import { AlertListCallOut } from "./store/typesOut";

const resourceUrl = "/api/careplan/manage-alerts";

const webCalls = {
  readAll(sorted: any) {
    return postRequest<AlertListCallOut, AlertListCallIn>(
      `${resourceUrl}/list`,
      sorted
    );
  },

  update(alertData: FormData) {
    return uploadFileRequest<null>(resourceUrl, alertData);
  },

  remove(alertId: number) {
    return deleteRequest<
      {
        alertId: number;
      },
      null
    >(resourceUrl, {
      alertId,
    });
  },
};

export default webCalls;
