import "./sortheader.css";

import classNames from "classnames";
import { ArrowDown, ArrowUp } from "../assets";
import { ColumnAlign } from "./BasicHeader";
import OverflowLabel from "../../OverflowLabel";

export enum SORT_DIRECTION {
  ASCENDING,
  NONE,
  DESCENDING,
}

export interface SortHeaderProps {
  text: string;
  sortDirection: SORT_DIRECTION;
  selected: boolean;
  onClick(): void;
  locked?: boolean;
  align?: ColumnAlign;
}

function Icon(sortDirection: SORT_DIRECTION) {
  if (sortDirection === SORT_DIRECTION.ASCENDING) {
    return (
      <div className="sort-icons-container">
        <ArrowUp />
      </div>
    );
  }
  if (sortDirection === SORT_DIRECTION.DESCENDING) {
    return (
      <div className="sort-icons-container">
        <ArrowDown />
      </div>
    );
  }
  return (
    <div className="sort-icons-container">
      <ArrowUp />
      <ArrowDown />
    </div>
  );
}

export function SortHeader(props: SortHeaderProps) {
  const {
    onClick,
    selected,
    locked,
    sortDirection,
    text,
    align = ColumnAlign.Left,
  } = props;

  return (
    <div
      className={classNames("sort-header", {
        selected: selected,
        locked: locked,
        center: align === ColumnAlign.Center,
        left: align === ColumnAlign.Left,
        right: align === ColumnAlign.Right,
      })}
      onClick={() => {
        if (!locked) onClick();
      }}
    >
      <div className="header-container">
        <OverflowLabel text={text.toLocaleUpperCase()} />
      </div>
      <div className="sort-header-icon">{Icon(sortDirection)}</div>
    </div>
  );
}
