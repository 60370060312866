import { ClientProfileStore } from "./store/store";
import { inject, observer } from "mobx-react";
import { ClientProfileAccessSettingsPage } from "./components/clientProfileAccessSettings";
import webApi from "./web";
import { Lambda, observe } from "mobx";
import { returnToClientProfile } from "./return-to-client-profile";
import { useEffect } from "react";
import { ChangeClientProfileAccessSettingCallOut } from "./store/typesOut";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface ClientProfileEditAccessProps {
  clientProfileStore: ClientProfileStore;
}

function ClientProfileEditAccessComponent(props: ClientProfileEditAccessProps) {
  const _observationDisposers: Lambda[] = [];

  const { id } = useParams<{ id: string }>();

  const history = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    disposeOfObservations();
    recordObservations();

    return () => {
      disposeOfObservations();
    };
  }, []);

  const disposeOfObservations = () => {
    if (_observationDisposers.length === 0) {
      return;
    }
    _observationDisposers.forEach((disposeObservation) => disposeObservation());
  };

  const recordObservations = () => {
    const { clientProfileStore } = props;
    const { clientProfileAccessDetails } = clientProfileStore;

    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      return;
    }

    let observationDisposer = null;

    clientProfileAccessDetails.clientProfileAssignmentAreas.forEach(
      (accessLocation) => {
        observationDisposer = observe(accessLocation, (change) => {
          clientProfileAccessDetails.hasChanges = true;
        });
        _observationDisposers.push(observationDisposer);
      }
    );
  };

  const render = () => {
    const { clientProfileStore } = props;
    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      returnToClientProfile(history, Number(id));
      return <div />;
    }
    return (
      <ClientProfileAccessSettingsPage
        clientProfileStore={clientProfileStore}
        saveAccessSettings={saveAccessSettings}
        cancelAccessSettings={cancelAccessSettings}
      />
    );
  };

  const saveAccessSettings = () => {
    const clientDetails = props.clientProfileStore.clientProfileDetails;
    const clientAccess = props.clientProfileStore.clientProfileAccessDetails;

    const { isEditingDay } = state;
    const access = isEditingDay
      ? clientAccess.getDaytimeInterval()
      : clientAccess.getNighttimeInterval();

    const accessSettings: ChangeClientProfileAccessSettingCallOut = {
      clientId: clientDetails.clientId!,
      accessGroupId: access.accessGroupId,
      locationId: access.accessLocationId,
      startTime: access.accessAreaStartTime,
    };
    webApi
      .changeClientProfileAccessSetting(accessSettings)
      .then(() => {
        clientAccess.hasChanges = false;
        history(-1);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const cancelAccessSettings = () => {
    const { clientProfileStore } = props;
    const { clientProfileAccessDetails } = clientProfileStore;

    clientProfileAccessDetails.hasChanges = false;
    history(-1);
  };

  return render();
}

export const ClientProfileEditAccess = inject("clientProfileStore")(
  observer(ClientProfileEditAccessComponent)
);
