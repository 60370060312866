import { updateRequest, postRequest, deleteRequest } from "../../web";
import { PeripheralListCallIn } from "./store/typesIn";
import {
  PeripheralListCallOut,
  PeripheralUpdateCallOut,
} from "./store/typesOut";

const resourceUrl = "/api/inventory/manage-peripherals";

const webCalls = {
  readAll(peripheralData: PeripheralListCallOut) {
    return postRequest<PeripheralListCallOut, PeripheralListCallIn>(
      `${resourceUrl}/list`,
      peripheralData
    );
  },

  update(peripheralData: PeripheralUpdateCallOut) {
    return updateRequest<PeripheralUpdateCallOut, null>(
      resourceUrl,
      peripheralData
    );
  },

  remove(peripheralId: number) {
    return deleteRequest<{ peripheralId: number }, null>(resourceUrl, {
      peripheralId,
    });
  },
};

export default webCalls;
