import styles from "./virtual-devices.module.css";

import { ReactComponent as Check } from "../../svg/check.svg";
import { ReactComponent as Dash } from "../../svg/dash.svg";

export interface PeripheralUsedProps {
  value: boolean;
}

export function VirtualDevicesCell(props: PeripheralUsedProps) {
  if (props.value) {
    return (
      <div className={styles["is-used-icon"]}>
        <Check />
      </div>
    );
  }

  return (
    <div className={styles["is-used-icon"]}>
      <Dash />
    </div>
  );
}
