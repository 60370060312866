import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { SensorDataStore } from "../../store/store";
import { SensorStateItem, SensorStatesRespones } from "../../store/types";
import { TransitionStore } from "../../../../store";
import { HistoryIcon } from "../../../../components/svg";
import styles from "./icon-tooltip-cell.module.css";
import Web from "../../web";
import moment from "moment";
import SimpleIconButton from "../../../../components/buttons/SimpleIconButton";
import { useTranslation } from "react-i18next";

export interface IconTooltipCellProps {
  sensorDataStore?: SensorDataStore;
  transitionStore?: TransitionStore;
  sensorId: number;
}

const IconTooltipCell = (props: IconTooltipCellProps) => {
  const [showTooltip, setShowToolTip] = useState(false);
  const sensorDataStore = props.sensorDataStore!;
  const transitionStore = props.transitionStore!;
  const SENSOR_STATES_REQUEST_COUNT = 10;
  const { sensorId } = props;
  const { t } = useTranslation("sensor-grid");

  const isStatesLoading = () => {
    return (
      sensorDataStore?.sensorStatesList.sensorId === sensorId &&
      sensorDataStore?.sensorStatesList.loading
    );
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleMouseLeave);
    };
  }, []);

  const values = () => {
    if (
      !sensorDataStore?.sensorStatesList.sensorStates ||
      sensorDataStore?.sensorStatesList.sensorStates.length === 0
    ) {
      return (
        <div className={styles["empty-list-text"]}>
          {t("No sensor state changes")}
        </div>
      );
    }
    return sensorDataStore?.sensorStatesList.sensorStates.map(
      (value: SensorStateItem) => {
        return (
          <div className={styles["sensor-state-item"]}>
            {t(value.sensorState)}
            <div className={styles["sensor-state-separator"]}>{" - "}</div>
            <div className={styles["sensor-state-timestamp"]}>
              {moment(value.timestamp).locale(navigator.language.substring(0, 2)).format("D MMM YYYY HH:mm:ss")}
            </div>
          </div>
        );
      }
    );
  };

  const handleMouseEnter = () => {
    setShowToolTip(true);
    document.addEventListener("mousedown", handleMouseLeave);
    sensorDataStore?.setSensorStatesList({
      sensorId: sensorId,
      sensorStates: [],
      loading: true,
    });
    transitionStore.disableLoadingFeedback = true;

    Web.readSensorStates({
      sensorId: sensorId,
      readingsCount: SENSOR_STATES_REQUEST_COUNT,
    })
      .then((response: any) => {
        const value: SensorStatesRespones = response.data;
        if (value.sensorId === sensorId) {
          sensorDataStore?.setSensorStatesList({
            sensorId: sensorId,
            sensorStates: value.sensorStates,
            loading: false,
          });
        }
      })
      .finally(() => {
        transitionStore.disableLoadingFeedback = false;
      });
  };

  const handleMouseLeave = () => {
    setShowToolTip(false);

    document.removeEventListener("mousedown", handleMouseLeave);
    sensorDataStore?.setSensorStatesList({
      sensorId: -1,
      sensorStates: [],
      loading: true,
    });
  };

  const render = () => {
    return (
      <div className={styles["icon-tooltip-container"]}>
        <SimpleIconButton
          onClick={() => {
            if (!showTooltip) {
              handleMouseEnter();
            }
            setShowToolTip(!showTooltip);
          }}
        >
          <HistoryIcon className={styles["icon-tooltip-cell"]} />
        </SimpleIconButton>
        {showTooltip && (
          <div className={styles["tooltip"]}>
            {isStatesLoading() && (
              <div className={styles["loader-sensor-id"]} />
            )}
            {!isStatesLoading() && values()}
          </div>
        )}
      </div>
    );
  };

  return render();
};

export default inject(
  "sensorDataStore",
  "transitionStore"
)(observer(IconTooltipCell));
