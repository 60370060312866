import { AvailableRoomData, ClientStoreData } from "./store";
import { IClientDataInactiveClients } from "./types";
import { AvailableRoomDataIn, ResidentDataIn, InactiveResidentIn } from "./typesIn";
import { ResidentDataOut } from "./typesOut";

export interface ClientDataConverter {
  toStore(inData: ResidentDataIn): ClientStoreData;
  toOut(storeData: ClientStoreData): ResidentDataOut;
}

export interface AvailableRoomDataConverter {
  toStore(inData: AvailableRoomDataIn): AvailableRoomData;
}

export interface InactiveClientDataConverter {
  toStore(inData: InactiveResidentIn): IClientDataInactiveClients;
}

export const ClientDataConverter: ClientDataConverter = {
  toStore(inData: ResidentDataIn) {
    return {
      areaName: inData.areaName,
      clientId: inData.clientId,
      clientName: inData.clientName,
      locationId: inData.locationId,
      locationName: inData.locationName,
    };
  },
  toOut(storeData: ClientStoreData): ResidentDataOut {
    return {
      clientId: storeData.clientId,
      areaName: storeData.areaName || undefined,
      clientName: storeData.clientName,
      locationId: storeData.locationId || undefined,
      locationName: storeData.locationName || undefined,
    };
  },
};

export const AvailableRoomDataConverter: AvailableRoomDataConverter = {
  toStore(inData: AvailableRoomDataIn) {
    return {
      areaName: inData.areaName,
      locationId: inData.locationId,
      locationName: inData.locationName,
    };
  },
};

export const InactiveClientDataConverter: InactiveClientDataConverter = {
  toStore(inData: InactiveResidentIn) {
    return {
      clientId: inData.clientId,
      clientName: inData.clientName,
      dateInactivated: inData.dateInactivated,
    };
  },
};
