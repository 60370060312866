import classNames from "classnames";
import styles from "../daily.module.css";

export interface ArrowButtonProps {
  isEnabled: boolean;
  onArrowClick(): void;

  styleName: string;
  data: any;
}

export function ArrowButton(props: ArrowButtonProps) {
  const availableStyleName = "available";
  const { isEnabled, data, styleName } = props;

  return (
    <svg
      className={classNames(styles[styleName], {
        [styles[availableStyleName]]: isEnabled,
      })}
      onClick={() => {
        if (isEnabled) props.onArrowClick();
      }}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 12 12"
    >
      {data}
    </svg>
  );
}

export function GetArrow() {
  return (
    <path
      fillRule="evenodd"
      d="M6.243 6.828L2.707 3.293a1 1 0 0 0-1.414 1.414L5.536 8.95a.997.997 0 0 0 1.414 0l4.242-4.243a1 1 0 0 0-1.414-1.414L6.243 6.828z"
    />
  );
}
