import moment from "moment";
import styles from "./datecell.module.css";

export function DateCell(props: any) {
  const { date } = props;

  const localTime = moment(date).locale(navigator.language.substring(0, 2)).format("D MMM YYYY");

  const render = () => {
    return <div className={styles.container}>{localTime}</div>;
  };

  return render();
}
