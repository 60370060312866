import styles from "./statuses.module.css";

import { toJS } from "mobx";
import { StatusItem } from "./status-item";

interface StatusPanelProps {
  showStatuses?: boolean;
  data: any;
}

export function StatusesPanel(props: StatusPanelProps) {
  const render = () => {
    const { data } = props;

    const statusPanelContent = (
      <ul className={styles["status-panel-content"]}>
        {data.map((item: any, index: any) => (
          <li key={`status-panel-item-${index}`}>
            <StatusItem original={toJS(item)} />
          </li>
        ))}
      </ul>
    );
    return <div className={styles["statuses-panel"]}>{statusPanelContent}</div>;
  };

  return render();
}
