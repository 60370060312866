import "./select-icon.css";
import classnames from "classnames";

import { ReactComponent as InBed } from "../modules/clients-overview/svg/client-in-bed.svg";
import { ReactComponent as InRoom } from "../modules/clients-overview/svg/client-in-room.svg";
import { ReactComponent as InBathroom } from "../modules/clients-overview/svg/client-in-bathroom.svg";
import { ReactComponent as Inactive } from "../modules/clients-overview/svg/client-inactive.svg";
import { ReactComponent as NotAvailable } from "../modules/clients-overview/svg/client-not-available.svg";
import { ReactComponent as OutOfBed } from "../modules/clients-overview/svg/client-out-of-bed.svg";
import { ReactComponent as OutOfRoom } from "../modules/clients-overview/svg/client-out-of-room.svg";
import { ReactComponent as StatusOff } from "../modules/clients-overview/svg/client-status-off.svg";
import { ReactComponent as InChair } from "../modules/clients-overview/svg/client-in-chair.svg";
import { ReactComponent as VisitorDetected } from "../modules/clients-overview/svg/client-visitor-detected.svg";
import { ReactComponent as ClientAlarm } from "../modules/clients-overview/svg/client-alarm.svg";
import { ReactComponent as VoiceAlarm } from "../modules/clients-overview/svg/client-voice-alarm.svg";
import { ReactComponent as NursePresent } from "../modules/clients-overview/svg/client-nurse-present.svg";

export function StatusIcon(props: any) {
  return (
    <div className={classnames("status-icon", props.className)}>
      {props.children}
    </div>
  );
}

export function IconElement(icon: {
  data: any;
  isCustom: boolean;
  className?: any;
  width: string;
  height: string;
  alertId?: any;
}) {
  if (!icon.data || icon.data == undefined) return <img />;

  if (icon.isCustom)
    return (
      <img
        alt="icon"
        src={icon.data}
        className={icon.className}
        width={icon.width}
        height={icon.height}
      />
    );
  else
    return (
      <icon.data
        className={icon.className}
        width={icon.width}
        height={icon.height}
      />
    );
}

export function SelectIcon(iconProps: {
  alertId?: number;
  alertIcon?: string;
  statusId?: number;
  statusIcon?: string;
  parentStatusId?: number;
  alertDelayed?: boolean;
  alertActive?: boolean;
  alertDisabled?: boolean;
  isNursePresent?: boolean;
}) {
  const {
    alertId,
    alertIcon,
    alertDelayed,
    alertActive,
    statusId,
    statusIcon,
    parentStatusId,
    isNursePresent,
  } = iconProps;

  const alertColorProps = {
    alertId,
    alertDelayed,
    alertActive,
  };

  const Icon = {
    element: NotAvailable,
    className: classnames({
      disabled: iconProps.alertDisabled,
    }),
    isCustomIcon: false,
  };

  let IconColor = "";

  if (isNursePresent) {
    IconColor = "nursePresent";
    Icon.element = NursePresent;
  } else if (!!alertId) {
    IconColor = chooseStatusColor(alertColorProps);
    const icon = getAlertIcon(alertId, alertIcon!);
    Icon.element = icon.data;
    Icon.isCustomIcon = icon.isCustomIcon;
  } else {
    const icon = getStatusIcon(statusIcon!, statusId!, parentStatusId);
    Icon.element = icon.data;
    Icon.isCustomIcon = icon.isCustomIcon;
  }

  Icon.className += ` ${IconColor}`;
  return Icon;
}

function getAlertIcon(alertId: number, alertIcon: string): any {
  const icon = {
    data: getDefaultAlertIcon(),
    isCustomIcon: false,
  };

  if (alertId >= 1 && alertId < 9) {
    icon.data = chooseAlertIcon(alertId);
    return icon;
  }

  if (!!alertIcon && alertIcon.length > 0) {
    icon.data = alertIcon;
    icon.isCustomIcon = true;
  }

  return icon;
}

function chooseAlertIcon(alertId: number): any {
  switch (alertId) {
    case 1:
      return OutOfBed;
    case 2:
      return InBathroom;
    case 3:
      return OutOfRoom;
    case 4:
      return Inactive;
    case 5:
      return VisitorDetected;
    case 6:
      return ClientAlarm;
    case 7:
      return VoiceAlarm;
    case 8:
      return InBed;
    default:
      return ClientAlarm;
  }
}

function getDefaultAlertIcon() : any{
  return ClientAlarm;
}

function chooseStatusIcon(statusId?: number, parentStatusId?: number): any {
  if (statusId === undefined) return NotAvailable;

  switch (statusId) {
    case 1:
      return chooseStatusIcon(parentStatusId);
    case 2:
      return InBed;
    case 3:
      return VisitorDetected;
    case 4:
      return InRoom;
    case 5:
      return OutOfRoom;
    case 6:
      return InBathroom;
    case 8:
      return StatusOff;
    case 9:
      return OutOfBed;
    case 10:
      return InChair;
    default:
      return NotAvailable;
  }
}
function chooseStatusColor(alert: {
  alertId?: number;
  alertDelayed?: boolean;
  alertActive?: boolean;
}): string {
  const inactiveAlert = "inactive";
  const delayedAlert = "delayed";
  const instantAlert = "instant";
  const { alertId, alertDelayed, alertActive } = alert;
  
  const alertOverride = alertId === 6;
  if (typeof alertActive !== "undefined" && !alertActive) {
    return inactiveAlert;
  } else if (alertOverride || (alertId && !alertDelayed)) {
    return instantAlert;
  } else if (!alertOverride && alertId && alertDelayed) {
    return delayedAlert;
  }

  return "";
}

function getStatusIcon(
  iconData: string,
  statusId: number,
  parentStatusId?: number
): any {
  const statusIcon = {
    data: iconData,
    isCustomIcon: false,
  };

  if (isStatusIconValid(iconData)) {
    statusIcon.data = iconData;
    statusIcon.isCustomIcon = true;
  } else if (statusId >= 1 && statusId <= 10)
    statusIcon.data = chooseStatusIcon(statusId, parentStatusId);
  else statusIcon.data = chooseStatusIcon(parentStatusId);

  return statusIcon;
}

function isStatusIconValid(iconData: string) {
  // length("data:image/svg+xml;base64,") == 27
  return !!iconData && iconData.length > 50;
}
