import { observable, action, makeObservable } from "mobx";

export interface IClientData {
  id?: number | null;
  name?: string;

  statusId?: number;
  parentStatusId?: number;
  parentStatus?: string;
  status?: string;
  statusIcon?: string;

  alertId?: number;
  alert?: string;
  alertDelayed?: boolean;
  areaId?: number;
  areaName?: string;
  alertIcon?: string;

  locationId?: number | null;
  locationName?: string;

  statusTimestamp?: string;
  alertTimestamp?: string;
  extraTimestamp?: string;

  nursePresent?: boolean;
  hasTemporaryProfileChange?: boolean;
  sensorIssues: SensorIssues[] | null;
}

export interface SensorIssues {
  id: string;
  status: number;
  template: string;
  type: number;
}

export interface AlertSettingsData {
  id?: number;
  clientName: string;
  locationName: string;
  areaName: string;
  alertSettings: SettingsList;
}
export interface Alert {
  alertId: number;
  alertName: string;
  status: number;
  delayUnitValue: string;
  delay: number | null;
}
export interface SettingsList {
  isActive: boolean;
  daytimeAlerts: Alert[];
  nighttimeAlerts: Alert[];
}

export interface ISortOption {
  id: string;
  desc: boolean;
}

export interface ISortOptions {
  sortOptions: ISortOption[];
}

export const EmptyClientData: IClientData = {
  id: null,
  name: "",
  locationId: null,
  locationName: "",
  sensorIssues: null,
};

export class ClientOverviewStore {
  constructor() {
    makeObservable(this, {
      selectedTab: observable,
      clients: observable,
      alertSettingsList: observable,
      selectedClient: observable,
      isDataStale: observable,
      nightTimeAlerts: observable,
      assignedClients: observable,
      alarmOverviewSorting: observable,
      lastLocations: observable,
      lastLocatonsTimers: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      viewType: observable,

      mapAlertSettingsData: action,
      setSelectedTab: action,
      setSelectedClient: action,
      setNightTimeAlerts: action,
      setAlarmOverviewSortOptions: action,
      setAssignedClientsSortOptions: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
    });
  }

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public selectedTab: string = "client-status";
  public clients: IClientData[] = [];
  public alertSettingsList: AlertSettingsData[] = [];
  public selectedClient: any = EmptyClientData;
  public isDataStale: boolean = false;
  public nightTimeAlerts: boolean = false;
  public lastLocations: any = {};
  public lastLocatonsTimers: any[] = [];
  public viewType: string = "grid";
  public assignedClients = {
    sortOptions: [
      {
        id: "status",
        desc: true,
      },
    ],
  };

  public alarmOverviewSorting = {
    sortOptions: [
      {
        id: "clientName",
        desc: false,
      },
    ],
  };
  executeOnReload = () => {};

  public mapAlertSettingsData(data: any) {
    this.alertSettingsList = [];

    data.forEach((alert: any) => {
      const newAlert: AlertSettingsData = {
        id: alert.clientId,
        clientName: alert.clientName,

        areaName: alert.areaName,
        locationName: alert.locationName,

        alertSettings: {
          isActive: alert.areProfileAlertsActive,
          daytimeAlerts: alert.daytimeAlertsSettings,
          nighttimeAlerts: alert.nighttimeAlertsSettings,
        },
      };

      this.alertSettingsList.push(newAlert);
    });
  }

  public setSelectedTab(value: string) {
    this.selectedTab = value;
  }

  public setSelectedClient(value: any) {
    this.selectedClient = value;
  }

  public setNightTimeAlerts(value: boolean) {
    this.nightTimeAlerts = value;
  }

  public setAlarmOverviewSortOptions(value: any) {
    this.alarmOverviewSorting.sortOptions = value;
  }

  public setAssignedClientsSortOptions(value: any) {
    this.assignedClients.sortOptions = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }
}
