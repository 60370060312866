import { Button, ButtonProps } from "@mui/material";
import styles from "./cancel-button.module.css";
import { EditCancel } from "../svg";

interface CancelButtonProps {
  children?: React.ReactNode;
}

function CancelButton(props: CancelButtonProps | ButtonProps) {
  const { children, ...rest } = props;

  const render = () => {
    return (
      <div className={styles["cancel-button"]}>
        <Button startIcon={<EditCancel />} {...rest}>
          {children}
        </Button>
      </div>
    );
  };

  return render();
}

export default CancelButton;
