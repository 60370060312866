import styles from "../search-clients.module.css";

import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BaseTable, SortHeader } from "../../../../components/table";
import { LocationCell } from "../../../../components/table/cells/LocationCell";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import AlertSettingsCell from "./cells/alert-settings-cell";
import webResource from "../../web";
import AlertSettings from "./headers/AlertSettings";
import { ResidentLink } from "../cells/ResidentLink";
import { AlertDataIn } from "../../store/typesIn";
import { AlertDataConverter } from "../../store/convertor";
import { ClientOverviewStore } from "../../store/store";
import { TransitionStore } from "../../../../store";

interface AlertOverviewGridProps {
  clientOverviewStore?: ClientOverviewStore;
  transitionStore?: TransitionStore;
}

function AlertOverviewGrid(props: AlertOverviewGridProps) {
  const clientOverviewStore = props.clientOverviewStore!;
  const { t } = useTranslation(["client-grid"]);
  const { alarmOverviewSorting, nightTimeAlerts } = clientOverviewStore;
  const history = useNavigate();

  const getSortDirection = (id: string) => {
    if (alarmOverviewSorting.sortOptions[0].id === id) {
      if (alarmOverviewSorting.sortOptions[0].desc) {
        return SORT_DIRECTION.DESCENDING;
      } else return SORT_DIRECTION.ASCENDING;
    }
    return SORT_DIRECTION.NONE;
  };

  const columnData: any[] = [
    {
      header: t("Client"),
      cellComponent: (value: any) => (
        <ResidentLink
          value={value.clientName}
          click={() => {
            accessClientProfile(value.id);
          }}
        />
      ),
      width: "25%",
      HeaderComponent: (
        <SortHeader
          text={t("Client")}
          selected={alarmOverviewSorting.sortOptions[0].id === "clientName"}
          sortDirection={getSortDirection("clientName")}
          locked={false}
          onClick={() => {
            if (alarmOverviewSorting.sortOptions[0].id === "clientName") {
              clientOverviewStore.setAlarmOverviewSortOptions([
                {
                  id: "clientName",
                  desc: !alarmOverviewSorting.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAlarmOverviewSortOptions([
                { id: "clientName", desc: false },
              ]);
            }
          }}
        />
      ),
    },
    {
      header: t("Room"),
      cellComponent: LocationCell,
      width: "40%",
      HeaderComponent: (
        <SortHeader
          text={t("Room")}
          selected={alarmOverviewSorting.sortOptions[0].id === "locationName"}
          sortDirection={getSortDirection("locationName")}
          locked={false}
          onClick={() => {
            if (alarmOverviewSorting.sortOptions[0].id === "locationName") {
              clientOverviewStore.setAlarmOverviewSortOptions([
                {
                  id: "locationName",
                  desc: !alarmOverviewSorting.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAlarmOverviewSortOptions([
                { id: "locationName", desc: false },
              ]);
            }
          }}
        />
      ),
    },
    {
      cellComponent: (value: any) => {
        return (
          <AlertSettingsCell
            data={value.alertSettings}
            nightTime={nightTimeAlerts}
            showTooltip
          />
        );
      },
      HeaderComponent: <AlertSettings store={clientOverviewStore} />,
    },
  ];

  const accessClientProfile = (clientId: number) => {
    history(`/client-profile/${clientId}`);
  };

  const render = () => {
    const { selectedClient } = clientOverviewStore;
    return (
      <BaseTable
        minWidth={1286}
        data={clientOverviewStore!.alertSettingsList}
        columns={columnData}
        customRowId={(_index, rowData) => {
          if (rowData.id === selectedClient?.id) return "selected-client";
          return "";
        }}
        customRowClass={(_index, rowData) => {
          if (rowData.id === selectedClient?.id)
            return styles["selected-client"];
          return "";
        }}
        expandedRowComponent={<div />}
      ></BaseTable>
    );
  };

  return render();
}
export default inject(
  "clientOverviewStore",
  "transitionStore"
)(observer(AlertOverviewGrid));
