import { useEffect, useState } from "react";
import * as Constants from "./canvas-chart-constants";

export interface CanvasChartProps {
  canvasId: string;
  categories: any[];
  barData: any[];
  parts: number;
  yLabels?: Map<number, string>;
  drawBarConnectionLines?: boolean;
}

export function CanvasChart(props: CanvasChartProps) {
  const [categoryImages, setCategoryImages] = useState(new Map());

  useEffect(() => {
    render();
    const interval = setInterval(() => {
      render();
    }, Constants.RENDER_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  });

  const render = () => {
    const canvas: HTMLCanvasElement = document.getElementById(
      `canvas-daily-chart-${props.canvasId}`
    ) as HTMLCanvasElement;

    if (!canvas) return;

    canvas.style.width = Constants.CHART_WIDTH;
    canvas.style.height = Constants.CHART_HEIGHT;

    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const ctx: CanvasRenderingContext2D = canvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;

    clearCanvas(canvas, ctx);
    drawBars(canvas, ctx);
    drawYLabels(canvas, ctx);
    drawCategoriesBars(ctx);
    drawCategoryIcons(ctx);
    drawCategorySeparationLines(canvas, ctx);
    drawCategoryLabels(ctx);
    drawGreyZone(canvas, ctx);
  };

  const drawGreyZone = (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    let leftLimit = props.parts;

    props.barData.forEach((bar) => {
      if (bar.end - bar.start > 0)
        if (bar.start < leftLimit) leftLimit = bar.start;
    });

    if (leftLimit > 0) {
      const contentWidth =
        canvas.width - Constants.CATEGORY.WIDTH - Constants.BARS.LEFT_PADDING;

      const left =
        (leftLimit / props.parts) * contentWidth + Constants.CATEGORY.WIDTH;

      ctx.fillStyle = Constants.BARS.NO_ACTIVITY_ZONE_COLOR;
      ctx.fillRect(
        Constants.CATEGORY.WIDTH,
        0,
        left - Constants.CATEGORY.WIDTH,
        Constants.CATEGORY.HEIGHT
      );
    }
  };

  const clearCanvas = (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.fillStyle = Constants.BARS.BACKGROUND_COLOR;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  };

  const drawCategoryLabels = (ctx: CanvasRenderingContext2D) => {
    const lineHeight = 15;
    const categoryHeight = Constants.CATEGORY.HEIGHT / props.categories.length;

    props.categories.forEach((category, index) => {
      const textZoneHeight =
        (categoryHeight - category.labels.length * lineHeight) / 2;

      category.labels.forEach((label: string, labelIndex: number) => {
        const textTop =
          categoryHeight * index +
          textZoneHeight +
          labelIndex * lineHeight +
          lineHeight / 2;
        const textLeft =
          Constants.CATEGORY.ICON.PADDING_LEFT +
          Constants.CATEGORY.ICON.PADDING_RIGHT +
          Constants.CATEGORY.ICON.WIDTH;

        ctx.fillStyle = category.color;
        ctx.font = `${Constants.CATEGORY.TEXT.FONT_SIZE} ${Constants.CATEGORY.TEXT.FONT}`;
        ctx.textAlign = Constants.CATEGORY.TEXT.ALIGN as CanvasTextAlign;
        ctx.textBaseline = Constants.CATEGORY.TEXT
          .BASE_LINE as CanvasTextBaseline;
        ctx.fillText(label, Math.floor(textLeft), Math.floor(textTop));
      });
    });
  };

  const drawCategorySeparationLines = (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    const categoryHeight = Constants.CATEGORY.HEIGHT / props.categories.length;

    props.categories.forEach((_category, index) => {
      const topPoint = index * categoryHeight + categoryHeight;

      ctx.lineWidth = Constants.CATEGORY.SEPARATION_LINE.SIZE;
      ctx.fillStyle = Constants.CATEGORY.SEPARATION_LINE.COLOR;
      ctx.beginPath();
      ctx.moveTo(0, topPoint);
      ctx.lineTo(canvas.width, topPoint);
      ctx.stroke();
    });
  };

  const drawCategoryIcons = (ctx: CanvasRenderingContext2D) => {
    const categoryHeight = Constants.CATEGORY.HEIGHT / props.categories.length;

    props.categories.forEach((category, index) => {
      const imageTop = index * categoryHeight;

      if (categoryImages.has(index)) {
        ctx.drawImage(
          categoryImages.get(index),
          Constants.CATEGORY.ICON.PADDING_LEFT,
          imageTop,
          Constants.CATEGORY.ICON.WIDTH,
          Constants.CATEGORY.ICON.HEIGHT
        );
      } else {
        const image = new Image();

        image.onload = () => {
          ctx.drawImage(
            image,
            Constants.CATEGORY.ICON.PADDING_LEFT,
            imageTop,
            Constants.CATEGORY.ICON.WIDTH,
            Constants.CATEGORY.ICON.HEIGHT
          );

          setCategoryImages(categoryImages.set(index, image));
        };

        image.src = category.icon;
      }
    });
  };

  const drawCategoriesBars = (ctx: CanvasRenderingContext2D) => {
    const categoryHeight = Constants.CATEGORY.HEIGHT / props.categories.length;

    props.categories.forEach((category, index) => {
      ctx.fillStyle = category.color;
      ctx.fillRect(
        0,
        index * categoryHeight,
        Constants.CATEGORY.SIDE_BAR.SIZE,
        categoryHeight
      );
    });
  };

  const drawYLabels = (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    const labelMap = props.yLabels;

    const labelTop = canvas.height - Constants.BOTTOM_LABEL.TEXT.BOTTOM_PADDING;
    const contentWidth =
      canvas.width - Constants.CATEGORY.WIDTH - Constants.BARS.LEFT_PADDING;

    labelMap?.forEach((val, key) => {
      const labelLeft =
        (key / props.parts) * contentWidth + Constants.CATEGORY.WIDTH;

      ctx.fillStyle = Constants.BOTTOM_LABEL.TEXT.COLOR;
      ctx.font = `${Constants.BOTTOM_LABEL.TEXT.FONT_SIZE} ${Constants.BOTTOM_LABEL.TEXT.FONT}`;
      ctx.textAlign = Constants.BOTTOM_LABEL.TEXT.ALIGN as CanvasTextAlign;
      ctx.textBaseline = Constants.BOTTOM_LABEL.TEXT
        .BASE_LINE as CanvasTextBaseline;
      ctx.fillText(val, labelLeft, labelTop);
    });
  };

  const drawBars = (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    props.barData.forEach((barData) => {
      const contentWidth =
        canvas.width - Constants.CATEGORY.WIDTH - Constants.BARS.LEFT_PADDING;
      const categoryHeight = Constants.BARS.HEIGHT / props.categories.length;

      const barHeight = Math.round(
        categoryHeight * Constants.BARS.BAR_HEIGHT_PERCENTAGE
      );
      const barWidth =
        ((barData.end - barData.start) / props.parts) * contentWidth;

      const barLeft =
        (barData.start / props.parts) * contentWidth + Constants.CATEGORY.WIDTH;
      const barTop =
        Math.round(barData.category * categoryHeight) +
        categoryHeight / 2 -
        barHeight / 2;

      ctx.fillStyle = props.categories[barData.category].color;
      ctx.fillRect(barLeft, barTop, barWidth, barHeight);

      ctx.fillStyle = Constants.BARS.TEXT.COLOR;
      ctx.font = `${Constants.BARS.TEXT.FONT_SIZE} ${Constants.BARS.TEXT.FONT}`;
      ctx.textAlign = Constants.BARS.TEXT.ALIGN as CanvasTextAlign;
      ctx.textBaseline = Constants.BARS.TEXT.BASE_LINE as CanvasTextBaseline;
      if (
        ctx.measureText(barData.label).width <
        barWidth -
          Constants.BARS.TEXT.PADDING_LEFT -
          Constants.BARS.TEXT.PADDING_RIGHT
      )
        ctx.fillText(
          barData.label,
          barLeft + barWidth / 2,
          barTop + barHeight / 2
        );
    });
  };

  return (
    <div>
      <canvas id={`canvas-daily-chart-${props.canvasId}`} />
    </div>
  );
}
