import {
  createRequest,
  updateRequest,
  postRequest,
  deleteRequest,
} from "../../web";
import {
  InactiveResidentsListCallIn,
  ResidentListCallIn,
  ResidentUpdateCallIn,
} from "./store/typesIn";
import { ResidentDataOut } from "./store/typesOut";

const resourceUrl = "/api/careplan/manage-clients";

const webCalls = {
  readAll(sorted: any) {
    return postRequest<any[], ResidentListCallIn>(
      `${resourceUrl}/list`,
      sorted
    );
  },

  save(clientDefinition: ResidentDataOut) {
    return createRequest<ResidentDataOut, ResidentUpdateCallIn>(
      resourceUrl,
      clientDefinition
    );
  },

  update(clientDefinition: ResidentDataOut) {
    return updateRequest<ResidentDataOut, ResidentUpdateCallIn>(
      resourceUrl,
      clientDefinition
    );
  },

  remove(clientId: number) {
    return deleteRequest<
      {
        clientId: number;
      },
      null
    >(resourceUrl, {
      clientId,
    });
  },

  readAllInactive(sorted: any) {
    return postRequest<any[], InactiveResidentsListCallIn>(
      `${resourceUrl}/inactive-clients`,
      sorted
    );
  },

  removeInactive(clientId: number) {
    return deleteRequest<
      {
        clientId: number;
      },
      null
    >(`${resourceUrl}/inactive-clients`, {
      clientId,
    });
  },
};

export default webCalls;
