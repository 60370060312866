import { Button, ButtonProps } from "@mui/material";
import styles from "./back-button.module.css";
import { ArrowLeft } from "../svg";

interface BackButtonProps {
    children?: React.ReactNode;
}

function BackButton(props: BackButtonProps | ButtonProps) {
    const { children, ...rest } = props;

    const render = () => {
        return (
            <div className={styles["back-button"]}>
                <Button fullWidth variant="contained" startIcon={<ArrowLeft />} {...rest}>
                    {children}
                </Button>
            </div>
        );
    };

    return render();
}

export default BackButton;
