import styles from "./search-sensors.module.css";

import * as React from "react";
import { FilterField, ValueData } from "../../../components/form/filter-field";
import { observer, inject } from "mobx-react";
import { EmptySensorData, SensorDataStore } from "../store/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "../../../components/svg";
import { SearchStore } from "../../../store";
import { SensorData } from "../store/types";

export interface SearchInputsProps {
  onSearchChange(): void;
  sensorDataStore?: SensorDataStore;
  searchStore?: SearchStore;
}

function SearchInputs(props: SearchInputsProps) {
  const [searchValue, setSearchValue] = useState(
    props.searchStore!.sensorSearch
  );
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(true);
  const { sensorDataStore, onSearchChange } = props;
  const { t } = useTranslation("search-sensor");

  const searchInputChange = (value: any) => {
    setIsSearchItemSelected(false);
    setSearchValue(value);

    if (value === "") {
      clearSearchSelection();
    }
  };

  const clearSearchSelection = () => {
    const { sensorDataStore, searchStore } = props;

    if (!sensorDataStore) return;

    searchStore!.sensorSearch = "";
    sensorDataStore.setHiglight(EmptySensorData);
    onSearchChange();
  };

  const getSearchValues = () => {
    if (!searchValue || !sensorDataStore) return [];

    return sensorDataStore.sensors
      .filter((value: SensorData) => {
        if (!value.sensorId) return false;
        return value.sensorId.toLowerCase().includes(searchValue.toLowerCase());
      })
      .sort((a: SensorData, b: SensorData) => {
        return a.sensorId.localeCompare(b.sensorId, undefined, {
          numeric: true,
        });
      })
      .map((val, index) => {
        return {
          key: index,
          object: val,
          displayValue: val.sensorId,
        };
      });
  };

  const setSearchSelection = (item: any) => {
    sensorDataStore!.setHiglight(item);
    setSearchValue(item.sensorId?.toUpperCase());
    setIsSearchItemSelected(true);
    onSearchChange();
  };

  const searchContentRender = (value: ValueData) => {
    const cameraLocation = value.object.locationName;
    let displalyLocation = cameraLocation;

    if (cameraLocation === "") {
      displalyLocation = (
        <div className={styles["no-location-field"]}>
          {t("NoLocationField")}
        </div>
      );
    }

    return (
      <div>
        <div className={styles["sensor-name"]}>
          {value.object.sensorId!.toUpperCase()}
        </div>
        <div className={styles["sensor-room"]}>
          <span className={styles["area"]}>{value.object.areaName}</span>
          <span className={styles["room"]}>{displalyLocation}</span>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    const searchValues = getSearchValues();
    if (isSearchItemSelected && searchValues.length === 1) {
      sensorDataStore!.setHiglight(searchValues[0].object);
    }
    return function cleanup() {
      clearSearchSelection();
    };
  }, []);

  const searchValues = getSearchValues();

  return (
    <FilterField
      icon={Search}
      contentRenderer={searchContentRender}
      placeholder={t("Search sensor")}
      inputValue={searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChange(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    />
  );
}

export default inject("sensorDataStore", "searchStore")(observer(SearchInputs));
