import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface UniteIdInputProps {
  mariDataStore?: MariDataStore;
}

const InputStringMaximumLength = 99;

function UniteIdInput(props: UniteIdInputProps) {
  const mariDataStore = props.mariDataStore!;
  const { t } = useTranslation("mari-grid");
  const uniteId = mariDataStore.selectedMari.uniteId;

  return (
    <TextFieldInput
      value={uniteId}
      disabled={mariDataStore.isValidationInProgress}
      label={t("UniteId").toUpperCase()}
      placeholder={t("UniteId")}
      onChange={(event: any) => {
        const uniteIdValue = event.target.value;

        if (uniteIdValue.length > InputStringMaximumLength) return;

        mariDataStore.setSelectedMareUniteId(uniteIdValue);
        mariDataStore.validateSelectedMari();
      }}
    />
  );
}

export default inject("mariDataStore")(observer(UniteIdInput));
