import styles from "./password-field.module.css";

import { useTranslation } from "react-i18next";
import { KeyboardEvent } from "react";
import { InputField } from "../../../../components/form/input-field";

export interface PasswordFieldProps {
  value: string;
  onChange?(value: string, target?: any): void;
  onKeyUpEvent?(e: KeyboardEvent<HTMLInputElement>): void;
}

export function PasswordField(props: PasswordFieldProps) {
  const { t } = useTranslation("login");
  const { value, onChange } = props;

  return (
    <div className={styles["login-password-field"]}>
      <InputField
        placeholder={t("password")}
        passwordField
        onChange={onChange}
        value={value}
        onKeyUpEvent={props.onKeyUpEvent}
      />
    </div>
  );
}
