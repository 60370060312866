import styles from "./profile-button.module.css";

import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ProfileIcon } from "../../../../svg/profile-button.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export interface ProfileButtonProps {
  id: number;
}

function ProfileButton(props: ProfileButtonProps) {
  const { t } = useTranslation("client-grid");
  const { id } = props;
  const history = useNavigate();

  const accessClientProfile = (clientId: number) => {
    history(`/client-profile/${clientId}`);
  };

  const render = () => {
    return (
      <Button
        onClick={() => {
          accessClientProfile(id);
        }}
        startIcon={<ProfileIcon />}
      >
        <div className={styles["text"]}>{t("Profile")}</div>
      </Button>
    );
  };

  return render();
}

export default inject("clientOverviewStore")(observer(ProfileButton));
