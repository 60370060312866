import { postRequest, readRequest } from "../../web";
import { ILoginModel } from "./types";

const loginUrl = "/api/auth/logon";
const currentUrl = "/api/auth/current";
const logoutUrl = "/api/auth/logout";
const resourceUrl = "/api/feature/";

const webCalls = {
  requestAuthentication(model: ILoginModel) {
    return postRequest(loginUrl, model);
  },

  requestCurrent() {
    return postRequest(currentUrl);
  },

  requestLogout() {
    return postRequest(logoutUrl);
  },

  readFeatureList() {
    return readRequest(resourceUrl).then((value) => {

      if(value.data.featureList.ofeliaIntegrationEnabled){
        value.data.featureList.showLastLocation = false;
        value.data.featureList.isVoiceAlertAvailable = false;
        value.data.featureList.manageMaris = false;
        value.data.featureList.managePeripherals = false;
        value.data.featureList.manageAccessSettings = false
      }
      
      return value;
    });
  },
};

export default webCalls;
