import { ClientProfileStore } from "./store/store";
import { inject, observer } from "mobx-react";
import webApi from "./web";
import { ClientProfileCameraSettingsPage } from "./components/clientProfileCameraSettings";
import { Lambda, observe } from "mobx";
import { returnToClientProfile } from "./return-to-client-profile";
import { FeatureStore } from "../../store";
import { useEffect } from "react";
import { ChangeClientProfileCameraStatusCallOut } from "./store/typesOut";
import { useNavigate, useParams } from "react-router-dom";

interface ClientProfileEditCameraProps {
  clientProfileStore: ClientProfileStore;
  featureStore?: FeatureStore;
}

function ClientProfileEditCamera(props: ClientProfileEditCameraProps) {
  const _observationDisposers: Lambda[] = [];

  const { id } = useParams<{ id: string }>();
  const history = useNavigate();

  useEffect(() => {
    disposeOfObservations();
    recordObservations();
    return () => {
      disposeOfObservations();
    };
  }, []);

  const disposeOfObservations = () => {
    if (_observationDisposers.length === 0) {
      return;
    }
    _observationDisposers.forEach((disposeObservation) => disposeObservation());
  };

  const recordObservations = () => {
    const { clientProfileStore } = props;
    const { clientProfileCameraSettings } = clientProfileStore;

    let observationDisposer = null;

    observationDisposer = observe(
      clientProfileCameraSettings,
      "optionTypeId",
      (change) => {
        clientProfileCameraSettings.hasChanges = true;
      }
    );
    _observationDisposers.push(observationDisposer);
  };

  const render = () => {
    const { clientProfileStore, featureStore } = props;

    if (
      featureStore!.features.manageCameraViewSettings === false ||
      clientProfileStore.clientProfileDetails.clientId === -1
    ) {
      returnToClientProfile(history, Number(id));
      return <div />;
    }
    return (
      <ClientProfileCameraSettingsPage
        clientProfileStore={clientProfileStore}
        saveCameraSettings={saveCameraSettings}
        cancelCameraSettings={cancelCameraSettings}
      />
    );
  };

  const saveCameraSettings = () => {
    const { clientProfileStore } = props;
    const cameraSettings = clientProfileStore.clientProfileCameraSettings;
    const clientDetails = clientProfileStore.clientProfileDetails;

    const data: ChangeClientProfileCameraStatusCallOut = {
      clientId: clientDetails.clientId!,
      cameraOptionTypeId: cameraSettings.optionTypeId,
      isActive: cameraSettings.isActive,
    };
    webApi
      .changeClientProfileCameraStatus(data)
      .then(() => {
        cameraSettings.hasChanges = false;
        history(-1);
      })
      .catch((error: any) => {
        console.log(error);
        history(-1);
      });
  };

  const cancelCameraSettings = () => {
    const { clientProfileStore } = props;
    const cameraSettings = clientProfileStore.clientProfileCameraSettings;

    cameraSettings.hasChanges = false;
    history(-1);
  };

  return render();
}

export default inject(
  "clientProfileStore",
  "featureStore"
)(observer(ClientProfileEditCamera));
