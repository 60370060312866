import "./table.css";
import classNames from "classnames";
import { Fragment } from "react";
import { BasicHeader } from "./headers/BasicHeader";

export enum ExpandedState {
  COLLAPSED = -2,
  TOP,
}

export interface BaseTableProps {
  data: any[];
  columns: ColumnData[];
  expandedRows?: ExpandedState;
  expandedRowComponent?: any;
  customRowClass?(rowId: number, rowData: any): string;
  customRowId?(rowId: number, rowData: any): string;
  minWidth?: number;
}

export interface ColumnData {
  accesor?: string;
  header: string;
  cellComponent?(value: any, index: number): any;
  HeaderComponent?: any;
  width?: string;
}

export function BaseTable(props: BaseTableProps) {
  const { columns, data, minWidth } = props;

  const TableCell = (colData: string[], cellComponents: any[]) => {
    return colData.map((value, index) => {
      if (cellComponents[index]) {
        return (
          <td key={index}>
            <div
              className={classNames("table-cell-container", {
                firstColumn: index === 0,
              })}
            >
              {cellComponents[index]}
            </div>
          </td>
        );
      } else {
        return (
          <td key={index}>
            <div
              className={classNames("table-cell-container", {
                firstColumn: index === 0,
              })}
            >
              {value}
            </div>
          </td>
        );
      }
    });
  };

  const TableRow = (
    index: number,
    colData: string[],
    cellComponents: any[]
  ) => {
    let customRowClasses = "";

    const {
      data,
      customRowId,
      customRowClass,
      expandedRows,
      expandedRowComponent,
    } = props;

    if (customRowClass)
      if (customRowClass(index, data[index]) !== "") {
        customRowClasses = " " + customRowClass(index, data[index]);
      }

    let customRowIds = "";

    if (customRowId)
      if (customRowId(index, data[index]) !== "") {
        customRowIds = customRowId(index, data[index]);
      }

    let dataVisible = index !== expandedRows;

    return (
      <Fragment key={index}>
        {dataVisible && (
          <tr
            className={
              classNames({
                odd: (index + 1) % 2 === 1,
                even: (index + 1) % 2 === 0,
              }) + customRowClasses
            }
            id={customRowIds}
            key={index + 1}
          >
            {TableCell(colData, cellComponents)}
          </tr>
        )}
        {expandedRows === index && expandedRowComponent}
      </Fragment>
    );
  };

  const TableHeader = () => {
    return (
      <thead>
        <tr className="tab-header">
          {columns.map((value: any, index: number) => {
            if (value.HeaderComponent) {
              return (
                <th
                  className={classNames({
                    firstColumn: index === 0,
                  })}
                  style={{ width: value.width }}
                  key={index}
                >
                  <div className="header-cell-container">
                    {value.HeaderComponent}
                  </div>
                </th>
              );
            }
            return (
              <th
                className={classNames({
                  firstColumn: index === 0,
                })}
                style={{ width: value.width }}
                key={index}
              >
                <div className="header-cell-container">
                  <BasicHeader text={value.header.toUpperCase()} />
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    const { expandedRows, expandedRowComponent } = props;

    return (
      <tbody>
        {expandedRows === ExpandedState.TOP && expandedRowComponent}
        {data.map((value: any, index: number) => {
          const values = columns.map((columnData: any) => {
            return value[columnData.accesor];
          });

          const cellComponents = columns.map((columnData: any) => {
            if (columnData.cellComponent) {
              return columnData.cellComponent(value, index);
            }
            return undefined;
          });

          return TableRow(index, values, cellComponents);
        })}
      </tbody>
    );
  };

  const getTableStyles = () => {
    const styles: any = {};

    if (minWidth) {
      styles["minWidth"] = minWidth;
    }

    return styles;
  };

  return (
    <table style={getTableStyles()}>
      {TableHeader()}
      {TableBody()}
    </table>
  );
}
