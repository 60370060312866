import styles from "./alert-overview-toggle.module.css";
import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import ToggleButtonsComponent from "../../../../components/toggle-button/ToggleButtonGroup";
import { ReactComponent as ArrowUp } from "../../svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../svg/arrow-down.svg";
import { useTranslation } from "react-i18next";

export interface AlertOverviewToggleProps {
  clientOverviewStore?: ClientOverviewStore;
}

function AlertOverviewToggle(props: AlertOverviewToggleProps) {
  const clientOverviewStore = props.clientOverviewStore!;
  const { alarmOverviewSorting } = clientOverviewStore!;
  const { t } = useTranslation("client-grid");

  const getIcon = (id: string) => {
    if (alarmOverviewSorting.sortOptions[0].id === id) {
      if (alarmOverviewSorting.sortOptions[0].desc) {
        return <ArrowDown />;
      } else {
        return <ArrowUp />;
      }
    }

    return (
      <div className={styles["inactive"]}>
        <ArrowUp /> <ArrowDown />
      </div>
    );
  };

  const buttons = [
    {
      id: "clientName",
      icon: getIcon("clientName"),
      text: t("Client"),
    },
    { id: "locationName", icon: getIcon("locationName"), text: t("Room") },
  ];

  const render = () => {
    return (
      <ToggleButtonsComponent
        buttons={buttons}
        selectedID={alarmOverviewSorting.sortOptions[0].id}
        onChange={(ID: string) => {
          if (ID === null) {
            clientOverviewStore.setAlarmOverviewSortOptions([
              {
                id: alarmOverviewSorting.sortOptions[0].id,
                desc: !alarmOverviewSorting.sortOptions[0].desc,
              },
            ]);
          } else {
            clientOverviewStore.setAlarmOverviewSortOptions([
              {
                id: ID,
                desc: false,
              },
            ]);
          }
        }}
      />
    );
  };

  return render();
}

export default inject("clientOverviewStore")(observer(AlertOverviewToggle));
