import {
  ActionButton,
  ButtonType,
  GlobalDialogData,
} from "../store/transition-store";

export function NotificationDialog(
  dialogTitle: string,
  dialogContent: string,
  okButtonText: string
): GlobalDialogData {
  const actionButtonData: ActionButton[] = [
    {
      text: okButtonText,
      closeDialog: true,
      type: ButtonType.OK_BUTTON,
      callback: () => {},
    },
  ];

  return {
    actionButtonData: actionButtonData,
    title: dialogTitle,
    message: dialogContent,
  };
}
