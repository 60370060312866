import styles from "./alert-settings.module.css";

import classnames from "classnames";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SunIcon } from "../../../svg/icon-sun.svg";
import { ReactComponent as MoonIcon } from "../../../svg/moon-icon.svg";

function AlertSettings(props: any) {
  const { store } = props;
  const { t } = useTranslation("client-grid");

  return (
    <div className={styles["alert-settings-header-container"]}>
      {t("AlarmSettings").toLocaleUpperCase()}
      {!store?.nightTimeAlerts && (
        <SunIcon className={styles["alarm-settings-header-icon"]} />
      )}
      {store?.nightTimeAlerts && (
        <MoonIcon className={styles["alarm-settings-header-icon"]} />
      )}
      <label className={styles["switch"]}>
        <input
          type="checkbox"
          checked={!store?.nightTimeAlerts}
          onChange={(e: any) => {
            store!.setNightTimeAlerts(!store?.nightTimeAlerts);
          }}
        />
        <span
          className={classnames(`${styles["slider"]} ${styles["round"]}`, {
            [styles.day]: !store?.nightTimeAlerts,
            [styles.night]: store?.nightTimeAlerts,
          })}
        ></span>
      </label>
    </div>
  );
}

export default observer(AlertSettings);
