import { inject, observer } from "mobx-react";
import { SwitchInput } from "../../../../../components/form-inputs";
import { ChangeEvent } from "react";
import { MariDataStore } from "../../../store/store";

export interface VirtualDevicesProps {
  mariDataStore?: MariDataStore;
}

function VirtualDevices(props: VirtualDevicesProps) {
  const mariDataStore = props.mariDataStore!;

  const isUsed = mariDataStore.selectedMari.virtualDevices;

  return (
    <SwitchInput
      checked={isUsed}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        mariDataStore.setSelectedVirtualDevices(event.target.checked);
        mariDataStore.validateSelectedMari();
      }}
    ></SwitchInput>
  );
}

export default inject("mariDataStore")(observer(VirtualDevices));
