import styles from "./camera-location.module.css";

import { CameraLocation, LocationPath } from "../camera-locations";
import { ReactComponent as Dash } from "../../svg/dash.svg";

export default function CameraLocationCell(props: any) {
  const { locations, filteringLocationId, t } = props;

  const path = LocationPath(locations, filteringLocationId);
  const location = CameraLocation(locations, t, filteringLocationId);

  return (
    <div className={styles["camera-location"]}>
      {location.length !== 0 && (
        <div className={styles["location-path"]}>{path}</div>
      )}
      {location.length !== 0 ? (
        <div className={styles["location-name"]}>{location}</div>
      ) : (
        <div className={styles["dash-icon"]}>
          <Dash />
        </div>
      )}
    </div>
  );
}
