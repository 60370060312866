import { observable, action } from "mobx";

export default class UnexpectedErrorStore {
    @observable public message: string | null = null;
    @observable public status: number | null = null;

    public setError = action(function setMessage(this: any, error: any) {
        this.message = error.message;
        this.status = error.status;
    });

    public reset = action(function resetMessage(this: any) {
        this.message = null;
        this.status = null;
    });
}
