import styles from "../../page.module.css";

import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import { ViewItem, ViewSwitch } from "../../../../components/view-switch";
import CardViewGrid from "../card-view-grid/CardViewGrid";
import { CARD_TYPES } from "../card-view-grid/card-item/card-item";
import webResource from "../../web";
import { AlertDataConverter } from "../../store/convertor";
import { AlertDataIn } from "../../store/typesIn";
import AlertOverviewGrid from "../alert-overview-grid/AlertOverviewGrid";
import { useEffect } from "react";

export interface ResidentStatusTabProps {
  clientOverviewStore?: ClientOverviewStore;
}

function ResidentStatusTab(props: ResidentStatusTabProps) {
  const clientOverviewStore = props.clientOverviewStore!;

  useEffect(() => {
    fetchData();
  }, [clientOverviewStore.alarmOverviewSorting.sortOptions]);

  const fetchData = () => {
    const { sortOptions } = clientOverviewStore.alarmOverviewSorting;

    clientOverviewStore.setIsDataLoading(true);

    return webResource.readAlertSettings(sortOptions).then((response) => {
      const alertList = response.data?.data.map((alert: AlertDataIn) => {
        return AlertDataConverter.toStore(alert);
      });

      clientOverviewStore.alertSettingsList = alertList!;

      clientOverviewStore.setIsDataLoading(false);

      setTimeout(() => {
        const selectedClientElement =
          document.querySelector("#selected-client");
        if (selectedClientElement) {
          selectedClientElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
        }
      });
    });
  };

  const render = () => {
    return (
      <ViewSwitch activeKey={clientOverviewStore.viewType}>
        <ViewItem itemKey="grid">
          <div className={styles["overflow-content"]}>
            <AlertOverviewGrid />
          </div>
        </ViewItem>
        <ViewItem itemKey="card">
          <div className={styles["overflow-content"]}>
            <CardViewGrid cardType={CARD_TYPES.Alert} />
          </div>
        </ViewItem>
      </ViewSwitch>
    );
  };

  return render();
}

export default inject("clientOverviewStore")(observer(ResidentStatusTab));
