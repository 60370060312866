import styles from "./client-profile-page.module.css";

import ClientProfileContent from "./client-profile-content";
import ClientProfileHeader from "./client-profile-header";
import { observer } from "mobx-react";
import {
  ClientProfileDetails,
  ClientProfileSettings,
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
  DataValidationStatus,
} from "../../store/types";

interface ClientPageProps {
  clientDetails: ClientProfileDetails;
  clientSettings: ClientProfileSettings;
  clientAccess: ClientProfileAccessSettings;
  clientCameraSettings: ClientProfileCameraSettings;
  dataValidationStatus: DataValidationStatus;
  toggleClientStatus(): void;
  toggleAlertStatus(): void;
  toggleAccessStatus(): void;
  toggleCameraStatus(): void;
  switchAlertTimeStatus(): void;
  switchAccessTimeStatus(): void;
  infoLoaded: boolean;
}

export function ClientProfilePage(props: ClientPageProps) {
  const {
    clientDetails,
    clientSettings,
    clientAccess,
    clientCameraSettings,
    toggleClientStatus,
    toggleAlertStatus,
    toggleAccessStatus,
    toggleCameraStatus,
    switchAlertTimeStatus,
    switchAccessTimeStatus,
    infoLoaded,
    dataValidationStatus,
  } = props;

  const render = () => {
    return (
      <div>
        <ClientProfileHeader clientDetails={clientDetails} />
        <div className={styles["client-profile-content"]}>
          <ClientProfileContent
            clientAccess={clientAccess}
            clientSettings={clientSettings}
            clientDetails={clientDetails}
            clientCameraSettings={clientCameraSettings}
            toggleClientStatus={toggleClientStatus}
            toggleAlertStatus={toggleAlertStatus}
            toggleAccessStatus={toggleAccessStatus}
            toggleCameraStatus={toggleCameraStatus}
            switchAlertTimeStatus={switchAlertTimeStatus}
            switchAccessTimeStatus={switchAccessTimeStatus}
            infoLoaded={infoLoaded}
            dataValidationStatus={dataValidationStatus}
          />
        </div>
      </div>
    );
  };

  return render();
}
export default observer(ClientProfilePage);
