import styles from "./client-profile-camera-tab.module.css";

import classNames from "classnames";
import { observer } from "mobx-react";
import {
  ClientProfileCameraSettings,
  ClientProfileDetails,
} from "../../store/types";
import { useTranslation } from "react-i18next";
import { Edit } from "../../../../components/svg";
import { AuthStore, FeatureStore, TransitionStore } from "../../../../store";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { ChangeEvent } from "react";
import { SwitchInput } from "../../../../components/form-inputs";
import { useNavigate } from "react-router-dom";

interface ClientProfileCameraTabProps {
  authStore: AuthStore;
  clientCameraSettings: ClientProfileCameraSettings;
  clientDetails: ClientProfileDetails;
  toggleCameraStatus(): void;
  featuresStore: FeatureStore;
  transitionStore: TransitionStore;
}

export function ClientProfileCameraTab(props: ClientProfileCameraTabProps) {
  const { featuresStore } = props;
  const { t } = useTranslation("client-profile");
  const {
    clientCameraSettings,
    toggleCameraStatus,
    authStore,
    transitionStore,
    clientDetails,
  } = props;

  const history = useNavigate();

  const render = () => {
    if (featuresStore.features.manageCameraViewSettings === false)
      return <div />;

    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {" "}
            {t("Video camera views on smartphone")}{" "}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          {renderCameraStatusToggleButton()}
          {renderCameraStatusDescription()}
          <div className={styles["col-3"]}></div>
          {renderCameraSettingsEditButton()}
        </li>
      </div>
    );
  };

  const renderCameraStatusToggleButton = () => {
    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      prepareToggleDialogInformation();

    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        clientCameraSettings.isActive = !clientCameraSettings.isActive;
        toggleCameraStatus();
      }
    );

    return (
      <div className={`${styles["toggle-button"]} ${styles["col-1"]}`}>
        <div className={styles["switch-container"]}>
          <SwitchInput
            checked={clientCameraSettings.isActive}
            disabled={!authStore.hasAdminRights()}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              transitionStore.showGlobalDialog(confirmationDialogData);
            }}
          ></SwitchInput>
        </div>
      </div>
    );
  };

  const prepareToggleDialogInformation = () => {
    const toggleStatus = !clientCameraSettings.isActive
      ? t("common:On")
      : t("common:Off");
    const action = !clientCameraSettings.isActive
      ? t("common:Start")
      : t("common:Stop");

    const dialogTitle = t("CameraToggleDialogTitle", {
      toggleStatus: toggleStatus,
    });
    const okButtonText = t("CameraToggleDialogConfirmation", {
      toggleStatus: toggleStatus,
    });
    const cancelButtonText = t("common:Cancel");
    const dialogContent = `${t("CameraToggleDialogAction", {
      action: action,
    })} ${t("CameraToggleDialogQuestion", {
      toggleStatus: toggleStatus,
      clientName: clientDetails.clientName,
    })}`;

    return { dialogTitle, okButtonText, cancelButtonText, dialogContent };
  };

  const renderCameraStatusDescription = () => {
    const description = cameraDescriptionById(
      clientCameraSettings.optionTypeId
    );
    return (
      <div
        className={classNames({
          [`${styles["col-2"]} ${styles["text"]}`]:
            clientCameraSettings.isActive,
          [`${styles["col-2"]} ${styles["text"]} ${styles["disabled"]}`]:
            !clientCameraSettings.isActive,
        })}
      >
        {description}
      </div>
    );
  };

  const cameraDescriptionById = (typeId: number) => {
    switch (typeId) {
      case 1:
        return t("Allow camera views only on alerts");
      case 2:
        return t("Allow camera views anytime");
      default:
        return "";
    }
  };

  const renderCameraSettingsEditButton = () => {
    return (
      <div className={styles["col-4"]}>
        {authStore.hasAdminRights() && (
          <div className={`${styles["row-1"]} ${styles["edit-text"]}`}>
            <Edit className={styles["edit-day-time-icon"]} />
            <div
              className={styles["edit-day-time-text"]}
              onClick={() => {
                history(
                  "/client-profile/camera-settings/" + clientDetails.clientId
                );
              }}
            >
              {t("Edit camera views")}
            </div>
          </div>
        )}
      </div>
    );
  };

  return render();
}

export default observer(ClientProfileCameraTab);
