import { postRequest, readRequest } from "../../web";
import { ISortOption } from "./store/store";
import {
  AlertDataListCallIn,
  ClientListCallIn,
  ShowLocationCallIn,
} from "./store/typesIn";

const resourceUrl = "/api/clients-overview";

const webCalls = {
  readMyClients(sorted: ISortOption[]) {
    return postRequest<ISortOption[], ClientListCallIn>(
      `${resourceUrl}/overview`,
      sorted
    );
  },
  showLastLocation(clientId: number) {
    return readRequest<ShowLocationCallIn>(
      `${resourceUrl}/location?clientId=${clientId}`
    );
  },
  readAlertSettings(sorted: ISortOption[]) {
    return postRequest<ISortOption[], AlertDataListCallIn>(
      `${resourceUrl}/list-alert-settings`,
      sorted
    );
  },
};

export default webCalls;
