import { GlobalDialogData } from "../store/transition-store";

export const NO_CONNECTION_MODAL_ID: string = "NO_CONNECTION_MODAL";

export function NoConnectionModal(translationContext: any) {
  const dialog: GlobalDialogData = {
    message: `${translationContext("common:ErrorContent")}${translationContext(
      "common:ContactSupport"
    )}`,
    title: translationContext("common:NoConnection"),
    actionButtonData: [],
    canBeClosed: false,
    id: NO_CONNECTION_MODAL_ID,
  };

  return dialog;
}
