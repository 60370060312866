import styles from "./client-dashboard.module.css";

import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { runInAction } from "mobx";
import { ClientDashboardStore } from "./store/store";
import { HistoricalView } from "./components/historical-view/historical.view";
import { LogsView } from "./components/logs-view/logs.view";
import DailyView from "./components/daily-view/daily.view";
import web from "./web";
import Header from "./components/header/header";
import { useEffect } from "react";
import { ReadClientOverviewCallIn } from "./store/typesIn";
import { ClientOverviewConverter } from "./store/convertor";
import { TransitionStore } from "../../store";
import { useParams } from "react-router-dom";

export interface IClientDashboardProps {
  match: any;
  clientDashboardStore?: ClientDashboardStore;
  transitionStore?: TransitionStore;
}

function ClientDashboard(props: IClientDashboardProps) {
  const { t, ready: readyDashboard } = useTranslation("dashboard");

  const clientDashboardStore = props.clientDashboardStore!;
  const { isTranslationLoading, isDataLoading } = clientDashboardStore;

  const languagesLoaded = readyDashboard;
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    clientDashboardStore.isDataLoading = true;

    web
      .readClientOverview(Number(id))
      .then((result: any) => {
        const response: ReadClientOverviewCallIn = result.data;
        runInAction(() => {
          clientDashboardStore.clientDetails =
            ClientOverviewConverter.toStore(response);
        });
      })
      .finally(() => {
        clientDashboardStore.isDataLoading = false;
      });

    return () => {
      clientDashboardStore.clientDetails = {
        clientName: "",
        locationName: "",
        areaName: "",

        timestamp: "",
      };
      clientDashboardStore.statusesAndAlerts = [];
      clientDashboardStore.clientStatuses = [];
    };
  }, []);

  useEffect(() => {
    const transitionStore = props.transitionStore!;

    if (!languagesLoaded) {
      clientDashboardStore.setIsTranslationLoading(true);
    } else {
      clientDashboardStore.setIsTranslationLoading(false);
    }

    if (isTranslationLoading || isDataLoading) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            {layout()}
          </>
        )}
      </>
    );
  };

  const layout = () => {
    return (
      <div className={styles["client-dashboard-content"]}>
        <div className={styles["dashboard-layout"]}>
          <div className={styles["content"]}>
            {dailyView()}
            {historicalView()}
          </div>
          {logsView()}
        </div>
      </div>
    );
  };

  const dailyView = () => {
    return (
      <div className={styles["daily-view"]}>
        <DailyView clientId={Number(id)} />
      </div>
    );
  };

  const logsView = () => {
    return <LogsView clientId={Number(id)} t={t} />;
  };

  const historicalView = () => {
    return <HistoricalView clientId={Number(id)} t={t} />;
  };

  return render();
}

export default inject(
  "clientDashboardStore",
  "transitionStore"
)(observer(ClientDashboard));
