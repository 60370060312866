import styles from "./footer.module.css";

import { FooterLogo } from "../../../../components/svg";

export function Footer() {
  return (
    <div className={styles["login-layout-footer"]}>
      <div className={styles["login-footer-logo"]}>
        <FooterLogo />
      </div>
    </div>
  );
}
