import styles from "./edit-alert.module.css";

import { inject, observer } from "mobx-react";
import AlertNameInput from "./components/alert-name-input";
import TriggerPortInput from "./components/trigger-port-input";
import DelayTypeDropDown from "./components/delay-option-input";
import BrowseForIcon from "./components/icon-input";
import BrowseForDelayIcon from "./components/delay-icon-input";
import { useEffect } from "react";
import { FeatureStore } from "../../../../store";
import { AlertDataStore } from "../../store/store";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import { useTranslation } from "react-i18next";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";
import { runInAction } from "mobx";
import AlternateTextInput from "./components/alternate-text-input";

interface EditAlertProps {
  featureStore?: FeatureStore;
  alertDataStore?: AlertDataStore;
  saveLocked?: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
}

function EditAlert(props: EditAlertProps) {
  let editComponentElement: HTMLDivElement;
  const alertDataStore = props.alertDataStore!;
  const featureStore = props.featureStore!;
  const { selectedAlert } = alertDataStore;
  const { saveLocked, onSaveClick, onCancelClick } = props;
  const { t } = useTranslation("common");

  useEffect(() => {
    scrollIntoViewAndAlign(editComponentElement);

    return function unmount() {
      runInAction(() => {
        alertDataStore.errors = null;
        alertDataStore.errorMessages = null;
        alertDataStore.setValidated(true);
      });
    };
  }, []);

  const render = () => {
    return (
      <tr
        className={styles["edit-alert"]}
        ref={(control) => (editComponentElement = control!)}
      >
        <td className={styles["first"]}>
          <div className={styles["column"]}>
            <AlertNameInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <AlternateTextInput />
          </div>
        </td>

        {featureStore.features.showTriggerPort && (
          <td>
            <div className={styles["column"]}>
              <TriggerPortInput />
            </div>
          </td>
        )}

        <td>
          <div className={styles["column"]}>
            <DelayTypeDropDown />
          </div>
        </td>
        <td>
          <div className={styles["column"]}>
            <BrowseForIcon />
          </div>
        </td>
        <td>
          <div className={styles["column"]}>
            <BrowseForDelayIcon />
          </div>
        </td>
        <td className={styles["action-column"]}>
          <div className={styles["action-buttons-container"]}>
            <div className={styles["action-buttons"]}>
              <CancelButton
                onClick={() => {
                  onCancelClick();
                }}
              >
                {t("Cancel")}
              </CancelButton>

              <SaveButton
                onClick={() => {
                  onSaveClick();
                }}
                disabled={saveLocked}
              >
                {t("Save")}
              </SaveButton>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return render();
}

export default inject("alertDataStore", "featureStore")(observer(EditAlert));
