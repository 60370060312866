import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface IpAddressInputProps {
  peripheralDataStore?: PeripheralDataStore;
}

function IpAddressInput(props: IpAddressInputProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const { t } = useTranslation("peripheral-grid");
  const ip = peripheralDataStore.selectedPeripheral.ipAddress;

  return (
    <TextFieldInput
      value={ip}
      label={t("IpAddress").toUpperCase()}
      inputProps={{ pattern: "^[-0-9A-Fa-f.:]{0,}$" }}
      placeholder={t("IpAddress")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;

        peripheralDataStore.setSelectedPeripheralIpAddress(event.target.value);
        peripheralDataStore.validateSelectedPeripheral();
      }}
    />
  );
}

export default inject("peripheralDataStore")(observer(IpAddressInput));
