import styles from "./alert-settings-cell.module.css";

import classnames from "classnames";
import { inject, observer } from "mobx-react";
import { ReactComponent as ClientVoiceAlarm } from "../../../svg/client-voice-alarm.svg";
import { ReactComponent as ClientInactiveAlarm } from "../../../svg/client-inactive.svg";
import { ReactComponent as ClientOutOfBedAlarm } from "../../../svg/client-out-of-bed.svg";
import { ReactComponent as ClientInBathroomAlarm } from "../../../svg/client-in-bathroom.svg";
import { ReactComponent as ClientInBedAlarm } from "../../../svg/client-in-bed.svg";
import { ReactComponent as ClientOutOfRoomAlarm } from "../../../svg/client-out-of-room.svg";
import { ReactComponent as ClientVisitorDetectedAlarm } from "../../../svg/client-visitor-detected.svg";
import { FeatureStore } from "../../../../../store";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface AlarmSettingCellProps {
  featureStore?: FeatureStore;
  data: any;
  nightTime?: boolean;
  showTooltip?: boolean;
}

export enum AlarmStatus {
  ACTIVE = 1,
  DELAYED,
  INACTIVE,
}

export enum AlarmId {
  OUTOFBEDALARM = 1,
  INBATHROOMALARM = 2,
  OUTOFROOMALARM = 3,
  INACTIVEALARM = 4,
  VISITORDETECTEDALARM = 5,
  VOICEALARM = 7,
  INBEDALARM = 8,
}

function AlarmSettingsCell(props: AlarmSettingCellProps) {
  const statuses = [
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
    { status: 0, delay: 0, delayType: "" },
  ];
  const { data, nightTime, featureStore, showTooltip } = props;
  const { features } = featureStore!;
  const { t } = useTranslation("client-grid");

  if (!data) return null;

  const alertData = nightTime ? data.nighttimeAlerts : data.daytimeAlerts;

  alertData.forEach((alert: any) => {
    statuses[alert.alertId].status = alert.status;
    statuses[alert.alertId].delay = alert.delay;
    statuses[alert.alertId].delayType = alert.delayUnitValue;
  });

  const getClasses = (alarmId: number) => {
    if (!data.isActive) {
      return styles.inactive;
    }
    return classnames({
      [styles.inactive]: statuses[alarmId].status === AlarmStatus.INACTIVE,
      [styles.active]: statuses[alarmId].status === AlarmStatus.ACTIVE,
      [styles.delayed]: statuses[alarmId].status === AlarmStatus.DELAYED,
    });
  };

  const getTooltip = (id: number) => {
    let delayTime = statuses[id].delay + " " + t(statuses[id].delayType);

    if (!data.isActive || statuses[id].status === AlarmStatus.INACTIVE)
      return t("AlertDisabled");

    if (statuses[id].delay === 0 || statuses[id].delay === null)
      delayTime = t("Instant");

    return t("DelayTime") + ": " + delayTime;
  };

  return (
    <div className={styles.alarmSettingsCell}>
      {features.isVoiceAlertAvailable && (
        <Tooltip
          title={showTooltip === true ? getTooltip(AlarmId.VOICEALARM) : ""}
        >
          <ClientVoiceAlarm className={getClasses(AlarmId.VOICEALARM)} />
        </Tooltip>
      )}

      {!!statuses[AlarmId.INACTIVEALARM].status && (
        <Tooltip
          title={showTooltip === true ? getTooltip(AlarmId.INACTIVEALARM) : ""}
        >
          <ClientInactiveAlarm className={getClasses(AlarmId.INACTIVEALARM)} />
        </Tooltip>
      )}

      <Tooltip
        title={showTooltip === true ? getTooltip(AlarmId.OUTOFBEDALARM) : ""}
      >
        <ClientOutOfBedAlarm className={getClasses(AlarmId.OUTOFBEDALARM)} />
      </Tooltip>
      <Tooltip
        title={showTooltip === true ? getTooltip(AlarmId.INBATHROOMALARM) : ""}
      >
        <ClientInBathroomAlarm
          className={getClasses(AlarmId.INBATHROOMALARM)}
        />
      </Tooltip>
      <Tooltip
        title={showTooltip === true ? getTooltip(AlarmId.INBEDALARM) : ""}
      >
        <ClientInBedAlarm className={getClasses(AlarmId.INBEDALARM)} />
      </Tooltip>
      <Tooltip
        title={showTooltip === true ? getTooltip(AlarmId.OUTOFROOMALARM) : ""}
      >
        <ClientOutOfRoomAlarm className={getClasses(AlarmId.OUTOFROOMALARM)} />
      </Tooltip>
      <Tooltip
        title={
          showTooltip === true ? getTooltip(AlarmId.VISITORDETECTEDALARM) : ""
        }
      >
        <ClientVisitorDetectedAlarm
          className={getClasses(AlarmId.VISITORDETECTEDALARM)}
        />
      </Tooltip>
    </div>
  );
}
export default inject("featureStore")(observer(AlarmSettingsCell));
