import UnexpectedErrorStore from "./unexpected-error-store";
import AuthStore from "./auth-store";
import LoginStore from "./login-store";
import TransitionStore from "./transition-store";
import FeatureStore from "./feature-store";
import SearchStore from "./search-store";

import { ClientOverviewStore } from "../modules/clients-overview/store/store";
import { ClientDataStore } from "../modules/manage-clients/store/store";
import { SensorDataStore } from "../modules/manage-sensors/store/store";
import { CameraDataStore } from "../modules/manage-cameras/store/store";
import { MariDataStore } from "../modules/manage-maris/store/store";
import { PeripheralDataStore } from "../modules/manage-peripherals/store/store";
import { ClientDashboardStore } from "../modules/client-dashboard/store/store";
import { ClientProfileStore } from "../modules/client-profile/store/store";
import { SubstatusDataStore } from "../modules/manage-substatuses/store/store";
import { AlertDataStore } from "../modules/manage-alerts/store/store";

export { UnexpectedErrorStore };
export { AuthStore };
export { LoginStore };
export { TransitionStore };
export { FeatureStore };
export { SearchStore };

const unexpectedErrorStore = new UnexpectedErrorStore();
const authStore = new AuthStore();
const loginStore = new LoginStore();
const transitionStore = new TransitionStore();
const featureStore = new FeatureStore();
const searchStore = new SearchStore();

const clientOverviewStore = new ClientOverviewStore();
const clientDataStore = new ClientDataStore();
const sensorDataStore = new SensorDataStore();
const cameraDataStore = new CameraDataStore();
const mariDataStore = new MariDataStore();
const peripheralDataStore = new PeripheralDataStore();
const substatusDataStore = new SubstatusDataStore();
const alertDataStore = new AlertDataStore();
const clientDashboardStore = new ClientDashboardStore();
const clientProfileStore = new ClientProfileStore();

const stores = {
  unexpectedErrorStore,
  authStore,
  loginStore,
  transitionStore,
  featureStore,
  searchStore,

  clientOverviewStore,
  clientDashboardStore,
  clientProfileStore,
  clientDataStore,

  sensorDataStore,
  cameraDataStore,
  mariDataStore,
  peripheralDataStore,
  substatusDataStore,
  alertDataStore,
};

export default stores;
