import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { AlertDataStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import SearchAlert from "../search-alert";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import AddButton from "../../../../components/buttons/AddButton";
import { TransitionStore } from "../../../../store";

export interface IHeaderProps {
  alertDataStore?: AlertDataStore;
  transitionStore?: TransitionStore;
}

function Header(props: IHeaderProps) {
  const { t } = useTranslation("alert-grid");
  const alertDataStore = props.alertDataStore!;
  const transitionStore = props.transitionStore!;

  const render = () => {
    return (
      <div className={styles["manage-alerts-page-header"]}>
        <div className={styles["manage-alerts-tabs"]}>
          <TabPanel
            onItemChange={(key: string) => {
              alertDataStore.setSelectedTab(key);
            }}
            activeKey={alertDataStore.selectedTab}
          >
            <TabItem itemKey={"manage-alerts"} label={t("manageAlerts")} />
            <TabItem itemKey={"default-alerts"} label={t("Default Alerts")} />
          </TabPanel>
        </div>

        <div className={styles["manage-alerts-filter-inputs"]}>
          <div className={styles["manage-alerts-input-container"]}>
            <SearchAlert onSearchChange={searchChange} />
          </div>

          {alertDataStore.selectedTab === "manage-alerts" && (
            <div className={styles["manage-alerts-add-button"]}>
              <AddButton
                disabled={
                  transitionStore.isLoading || alertDataStore.hasSelectedAlert
                }
                onClick={() => {
                  if (alertDataStore.loading) return;

                  alertDataStore.addNewEmptyAlert();
                }}
              >
                {t("addAlert")}
              </AddButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  const searchChange = () => {
    setTimeout(() => {
      selectAlertOnPage();
    });
  };

  const selectAlertOnPage = () => {
    const selectedElement = document.querySelector("#selected-alert");
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  return render();
}

export default inject("alertDataStore", "transitionStore")(observer(Header));
