import styles from "./edit-client.module.css";

import { inject, observer } from "mobx-react";
import { WarningSign } from "../../../../components/svg";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useEffect } from "react";
import { ClientDataStore } from "../../store/store";
import LocationField from "./components/location-dropdown";
import ResidentNameField from "./components/client-name-input";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import { runInAction } from "mobx";

interface EditClientProps {
  clientDataStore?: ClientDataStore;
  saveLocked?: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
}

function EditClient(props: EditClientProps) {
  let editComponentElement: HTMLDivElement;
  const clientDataStore = props.clientDataStore!;
  const showAddNewTitle = !clientDataStore.isNewClient;
  const { saveLocked, onSaveClick, onCancelClick } = props;
  const { t } = useTranslation("client-grid");
  const { t: commonT } = useTranslation("common");

  useEffect(() => {
    scrollIntoViewAndAlign(editComponentElement);

    return function unmount() {
      runInAction(() => {
        clientDataStore.errors = null;
        clientDataStore.errorMessages = null;
        clientDataStore.setValidated(true);
      });
    };
  }, []);

  const render = () => {
    return (
      <>
        <tr className={styles["info-row-edit-client"]}>
          {showAddNewTitle && (
            <td colSpan={3}>
              <div className={styles["edit-client-warning"]}>
                <div className={styles["warning-icon"]}>
                  <WarningSign />
                </div>
                <div className={styles["label"]}>{t("ClientEditWarning")}</div>
              </div>
            </td>
          )}
        </tr>
        <tr
          className={classNames(styles["edit-client"], {
            [styles["top-border"]]: !showAddNewTitle,
            [styles["no-top-border"]]: showAddNewTitle,
          })}
          ref={(control) => (editComponentElement = control!)}
        >
          <td className={styles["first"]}>
            <div className={styles["column"]}>
              <ResidentNameField />
            </div>
          </td>

          <td>
            <div className={styles["column"]}>
              <LocationField />
            </div>
          </td>

          <td className={styles["action-column"]}>
            <div className={styles["action-buttons-container"]}>
              <div className={styles["action-buttons"]}>
                <CancelButton
                  onClick={() => {
                    onCancelClick();
                  }}
                >
                  {commonT("Cancel")}
                </CancelButton>

                <SaveButton
                  disabled={saveLocked}
                  onClick={() => {
                    onSaveClick();
                  }}
                >
                  {commonT("Save")}
                </SaveButton>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };

  return render();
}

export default inject("clientDataStore")(observer(EditClient));
