import "./view-switch.css";
import * as React from "react";

import { ViewItem, ViewItemProps } from "./view-item/view-item";

export interface ViewSwitchProps {
  activeKey: string;
  children?: React.ReactNode;
}

export function ViewSwitch(props: ViewSwitchProps) {
  const children = () => {
    const { children, activeKey } = props;
    const elements: React.ReactElement[] = [];

    React.Children.forEach(children, (element: any) => {
      if (
        !React.isValidElement(element) ||
        element.type !== (<ViewItem itemKey="" />).type
      )
        return;

      const elementProps: ViewItemProps = element.props as ViewItemProps;

      if (elementProps.itemKey === activeKey) {
        elements.push(element);
      }
    });

    return elements;
  };

  const render = () => {
    return <div className="view-switch-container">{children()}</div>;
  };

  return render();
}
