import { ReactNode } from "react";
import { ActionButton, ButtonType } from "../store/transition-store";

export function CreateConfirmationDialog(
  dialogTitle: string,
  dialogContent: ReactNode,
  okButtonText: string,
  cancelButtonText: string,
  canBeClosed: boolean,
  callbackOnOk: () => void
) {
  const actionButtonData: ActionButton[] = [
    {
      text: okButtonText,
      closeDialog: true,
      callback: callbackOnOk,
      type: ButtonType.OK_BUTTON,
    },
    {
      text: cancelButtonText,
      closeDialog: true,
      callback: () => { },
    },
  ];

  return {
    actionButtonData: actionButtonData,
    title: dialogTitle,
    message: dialogContent,
    canBeClosed
  };
}
