import { FormControlLabel, Switch } from "@mui/material";

import styles from "./switch.module.css";
import formInputStyles from "../form-input.module.css";
import { withStyles } from "@material-ui/styles";

interface SwitchInputProps {
  label?: string;
  errorMessage?: string;
}

const CustomSwitch = withStyles({
  colorPrimary: {
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#22B3B3",
      opacity: 1
    },
    "&.Mui-checked .MuiSwitch-thumb": {
      backgroundColor: "#173544"
    },
    "&.Mui-checked.Mui-disabled .MuiSwitch-thumb": {
      backgroundColor: "#FFFFFF",
      opacity: 1
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      backgroundColor: "#9E9E9E",
      opacity: 1
    }
  },
})(Switch);

function SwitchInput(props: SwitchInputProps | any) {
  const { errorMessage, label, ...rest } = props;

  const render = () => {
    return (
      <div className={styles["switch-input"]}>
        <FormControlLabel
          value="top"
          control={<CustomSwitch {...rest} />}
          label={label}
          labelPlacement="top"
        />
        {errorMessage && (
          <div className={formInputStyles["intent-danger"]}>{errorMessage}</div>
        )}
      </div>
    );
  };

  return render();
}

export default SwitchInput;
