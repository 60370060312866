import styles from "./calendar-button.module.css";

import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { UseDateFieldProps } from "@mui/x-date-pickers/DateField";
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from "@mui/x-date-pickers/models";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";
import { Moment } from "moment";
import { Calendar } from "../../../../../components/svg";

interface ButtonFieldProps
  extends UseDateFieldProps<Moment, false>,
    BaseSingleInputFieldProps<
      Moment | null,
      Moment,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface CalendarButtonProps {
  value: Moment | null;
  label: string;
  onChange(timestamp: Moment | null): void;
  shouldDisableDate(date: Moment): boolean;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      startIcon={<Calendar className={styles["calendar-icon"]} />}
      className={styles["calendar-button"]}
    >
      <div className={styles["label"]}>{label ? `${label}` : null}</div>
    </Button>
  );
}

export function ButtonDatePicker(
  props: Omit<DatePickerProps<Moment>, "open" | "onOpen" | "onClose">
) {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}

export default function CalendarButton(props: CalendarButtonProps) {
  const { onChange, value, shouldDisableDate, label } = props;

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={navigator.language.substring(0, 2)}
    >
      <ButtonDatePicker
        label={label}
        value={value}
        onChange={(value) => onChange(value)}
        shouldDisableDate={shouldDisableDate}
      />
    </LocalizationProvider>
  );
}
