import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface SubaddressInputProps {
  peripheralDataStore?: PeripheralDataStore;
}

function SubddressInput(props: SubaddressInputProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const { t } = useTranslation("peripheral-grid");
  const { errorMessages } = peripheralDataStore;
  const subAddress =
    peripheralDataStore.selectedPeripheral.subAddress?.toString() || "";

  return (
    <TextFieldInput
      value={subAddress}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["subAddress"] &&
          t("RequiredInformation")) ||
        ""
      }
      label={t("Subaddress").toUpperCase()}
      inputProps={{ pattern: "^[0-9]{1,3}" }}
      placeholder={t("Subaddress")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;
        const subaddress = parseInt(event.target.value);

        if (isNaN(subaddress))
          peripheralDataStore.setSelectedPeripheralSubAdress(undefined);
        else peripheralDataStore.setSelectedPeripheralSubAdress(subaddress);
        peripheralDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        peripheralDataStore.validateSelectedPeripheral("subAddress");
        peripheralDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("peripheralDataStore")(observer(SubddressInput));
