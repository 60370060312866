import * as React from "react";
import classnames from "classnames";

import "./side-menu-button.css";

export interface SideMenuButtonProps {
  children?: React.ReactNode;
  onButtonClick(): void;
  text?: string;
  isActive: boolean;
  buttonId: string;
  collapsed?: boolean;
  isDisabled?: boolean;
}

function SideMenuButton(props: SideMenuButtonProps) {
  const { children, isActive, text, onButtonClick, buttonId, collapsed, isDisabled } = props;

  return (
    <div
      id={buttonId}
      className={classnames(`menu-button ${isActive ? "menu-button-active" : ""} + ${isDisabled ? "menu-button-disabled" : ""}`)}
      onClick={() => {
        onButtonClick();
      }}
    >
      <div className="button-content">
        <div className="side-button-icon-container">{children}</div>
        {!collapsed &&
          <div className="side-menu-text">{text}</div>
        }
      </div>
    </div>
  );
}

export default SideMenuButton;
