import { IRoomData, SensorData } from "./types";
import { RoomDataIn, SensorDataIn } from "./typesIn";
import { SensorDataOut } from "./typesOut";

export interface SensorDataConverter {
  toStore(inData: SensorDataIn): SensorData;
  toOut(storeData: SensorData): SensorDataOut;
}

export interface RoomDataConverter {
  toStore(inData: RoomDataIn): IRoomData;
}

export const SensorDataConverter: SensorDataConverter = {
  toStore(inData: SensorDataIn) {
    return {
      id:inData.id,
      sensorId: inData.sensorId,
      inactivityPeriod: inData.inactivityPeriod,
      availableSensorTypes: inData.availableSensorTypes,
      locationId: inData.locationId,
      locationName: inData.locationName,
      sensorType: inData.sensorType,
      status: inData.status,
      areaName: inData.areaName,
      storage: inData.storage,
    };
  },
  toOut(storeData: SensorData): SensorDataOut {
    return {
      id:storeData.id || 0,
      areaName: storeData.areaName || "",
      sensorId: storeData.sensorId,
      availableSensorTypes: storeData.availableSensorTypes,
      inactivityPeriod: storeData.inactivityPeriod,
      isNewSensor: storeData.isNewSensor || false,
      locationId: storeData.locationId || 0,
      locationName: storeData.locationName,
      sensorType: storeData.sensorType,
      status: storeData.status || 0,
      storage: storeData.storage || false,
    };
  },
};

export const RoomDataConverter: RoomDataConverter = {
  toStore(inData: RoomDataIn) {
    return {
      locationId: inData.locationId,
      locationName: inData.locationName,
      areaName: inData.areaName,
    };
  },
};
