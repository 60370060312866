import styles from "./status-toggle.module.css";

import { inject, observer } from "mobx-react";
import { SensorDataStore } from "../../../store/store";
import { AuthStore } from "../../../../../store";
import { SwitchInput } from "../../../../../components/form-inputs";
import { ChangeEvent } from "react";

export interface StatusToggleProps {
  sensorDataStore?: SensorDataStore;
  authStore?: AuthStore;
}

function StatusToggle(props: StatusToggleProps) {
  const sensorDataStore = props.sensorDataStore!;
  const authStore = props.authStore!;

  const storage = sensorDataStore.selectedSensor.storage;

  return (
    <div className={styles["status-toggle"]}>
      <SwitchInput
        disabled={!authStore.isAdmin()}
        checked={!storage}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          sensorDataStore.setSelectedSensorStorage(!event.target.checked);
          sensorDataStore.setSelectedSensorHasChanges(true);
          sensorDataStore.validateSelectedSensor();
        }}
      ></SwitchInput>
    </div>
  );
}

export default inject("sensorDataStore", "authStore")(observer(StatusToggle));
