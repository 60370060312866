import MediaQuery from "react-responsive";

export function PortraitMode(props: any) {
  return (
    <MediaQuery
      min-device-width={768}
      max-device-width={1224}
      orientation={"portrait"}
    >
      {props.children}
    </MediaQuery>
  );
}

export function LandscapeMode(props: any) {
  return (
    <MediaQuery
      min-device-width={1024}
      max-device-width={1224}
      orientation={"landscape"}
    >
      {props.children}
    </MediaQuery>
  );
}
