import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../../../../components/form-inputs";
import { PeripheralDataStore } from "../../../store/store";
import { LocationData } from "../../../store/types";

export interface LocationDropDownInputProps {
  peripheralDataStore?: PeripheralDataStore;
}

function LocationDropDown(props: LocationDropDownInputProps) {
  const peripheralDataStore = props.peripheralDataStore!;

  const locationId = peripheralDataStore.selectedPeripheral.locationId;
  const { t } = useTranslation("peripheral-grid");

  const formatLocationDisplay = (location: LocationData) => {
    return `${location.areaName} - ${location.locationName}`;
  };

  const buildLocationDropdownData = () => {
    const { availableLocations, selectedPeripheral } = peripheralDataStore;

    let locationsList: any = availableLocations;

    if (
      selectedPeripheral.locationId &&
      availableLocations.findIndex(
        (loc: any) => loc.locationId === selectedPeripheral.locationId
      ) === -1
    ) {
      locationsList = locationsList.concat(selectedPeripheral);
    }

    const dropdownData = locationsList
      .map((value: any) => ({
        id: value.locationId,
        areaName: value.areaName,
        locationId: value.locationId,
        locationName: value.locationName,
        value: formatLocationDisplay(value),
      }))
      .sort((a: any, b: any) => {
        return a.value.localeCompare(b.value, undefined, {
          numeric: true,
        });
      });
    dropdownData.unshift({ id: null, value: t("NoRoomAssigned") });
    return dropdownData;
  };

  const locationDropdownData = buildLocationDropdownData();
  const emptyLocation = { id: null, value: t("NoRoomAssigned") };
  const selectedLocation =
    locationDropdownData.find((value: any) => value.id === locationId) ||
    emptyLocation;
  const { errorMessages } = peripheralDataStore;

  return (
    <SelectInput
      showToolTip
      options={locationDropdownData}
      label={t("LocationName").toUpperCase()}
      placeholder={t("LocationName")}
      value={selectedLocation}
      onChange={(_event: any, value: any) => {
        if (value === null) {
          peripheralDataStore!.setSelectedPeripheralLocationData(0, "", "");
          peripheralDataStore!.validateSelectedPeripheral();
          return;
        }

        peripheralDataStore.setSelectedPeripheralLocationData(
          value.locationId,
          value.locationName,
          value.areaName
        );

        peripheralDataStore.validateSelectedPeripheral("locationId");
      }}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["locationId"] &&
          t("RequiredInformation")) ||
        ""
      }
    ></SelectInput>
  );
}

export default inject("peripheralDataStore")(observer(LocationDropDown));
