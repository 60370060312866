import styles from "./card-item.module.css";

import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import ProfileButton from "./buttons/profile-button";
import DashboardButton from "./buttons/dashboard-button";
import { ReactComponent as WarningIcon } from "../../../svg/warning.svg";
import { ReactComponent as NurseIcon } from "../../../svg/nurse-presence.svg";

import classNames from "classnames";
import {
  StatusIcon,
  StatusIconProps,
} from "../../../../../components/status-icon/status-icon";
import { useNavigate } from "react-router-dom";
import CardAlertIcons from "./alert-icons/AlertIcons";
import CurrentLocation from "./curent-location/CurrentLocation";
import OverflowLabel from "../../../../../components/OverflowLabel";
import { Tooltip } from "@mui/material";
import { WARNING_TYPE } from "../../../../../components/sensor-issues-mapper";

export enum CARD_TYPES {
  Status,
  Alert,
}

export interface CardItemProps {
  initialsText: string;
  name: string;
  location: string;
  status?: string;
  timestamp?: string;
  secondStatus?: string;
  secondTimestamp?: string;
  id: number;
  warning?: boolean;
  warningTooltip?: string;
  nurseIsPresent?: boolean;
  warningType?: WARNING_TYPE;
  alert?: boolean;
  delayed?: boolean;
  clientOverviewStore?: ClientOverviewStore;
  StatusProps?: StatusIconProps;
  alertIconData?: any;
  type: CARD_TYPES;
  highlight?: boolean;
}

function CardItem(props: CardItemProps) {
  const { t } = useTranslation("client-grid");
  const {
    initialsText,
    name,
    location,
    status,
    id,
    timestamp,
    secondStatus,
    secondTimestamp,
    warning,
    warningTooltip,
    warningType,
    nurseIsPresent,
    alert,
    delayed,
    StatusProps,
    alertIconData,
    type,
    highlight,
  } = props;
  const history = useNavigate();

  const getCardId = () => {
    if (highlight) return "selected-client";
    return "";
  };

  const render = () => {
    return (
      <div
        id={getCardId()}
        className={classNames(styles["card-item-container"], {
          [styles["highlight"]]: highlight,
        })}
      >
        <div className={styles["header"]}>
          <div className={styles["icon-container"]}>{initialsText}</div>
          <div className={styles["name-container"]}>
            <div className={styles["location"]}>
              <OverflowLabel text={location} />
            </div>
            <div className={styles["name"]}>
              <OverflowLabel text={name} />
            </div>
          </div>
          {warning && (
            <div
              onClick={() => {
                accessClientProfile(id);
              }}
              className={classNames(styles["warning-icon"], {
                [styles["red"]]:
                  warningType === undefined || warningType === WARNING_TYPE.Red,
                [styles["yellow"]]: warningType === WARNING_TYPE.Yellow,
              })}
            >
              <Tooltip title={warningTooltip}>
                <WarningIcon />
              </Tooltip>
            </div>
          )}
        </div>

        <div className={styles["content"]}>
          {type === CARD_TYPES.Status && (
            <>
              <CurrentLocation clientID={id} />
              <div className={styles["content-header"]}>
                <div className={classNames(styles["status-icon-container"])}>
                  <StatusIcon {...StatusProps} />
                </div>
                <div className={styles["status-container"]}>
                  <div className={styles["main-status"]}>
                    <div
                      className={classNames(styles["status"], {
                        [styles["alert"]]: alert,
                        [styles["delayed"]]: delayed,
                      })}
                    >
                      <OverflowLabel text={status!} />
                    </div>
                    {timestamp && timestamp != "" && (
                      <div className={styles["status-timestamp"]}>
                        {timestamp}
                      </div>
                    )}
                  </div>

                  {secondStatus && (
                    <div className={styles["second-status-container"]}>
                      <div className={styles["second-status"]}>
                        <OverflowLabel text={secondStatus!} />
                      </div>
                      <div className={styles["second-status-timestamp"]}>
                        {secondTimestamp}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles["content-footer"]}>
                {nurseIsPresent && (
                  <div className={styles["nurse-container"]}>
                    <div className={styles["nurse-icon"]}>
                      <NurseIcon />
                    </div>
                    <div className={styles["nurse-status"]}>
                      {t("NurseInRoom").toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {type === CARD_TYPES.Alert && (
            <div className={styles["content-alert-icons"]}>
              <CardAlertIcons iconData={alertIconData} />
            </div>
          )}
        </div>

        <div className={styles["footer"]}>
          <ProfileButton id={id} />
          <DashboardButton id={id} />
        </div>
      </div>
    );
  };

  const accessClientProfile = (clientId: number) => {
    history(`/client-profile/${clientId}`);
  };

  return render();
}

export default inject("clientOverviewStore")(observer(CardItem));
