import {
  ObservableMap,
  observable,
  computed,
  action,
  makeObservable,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { sensorDataSchema } from "../validation";
import { SensorData, IRoomData, SensorStateItem } from "./types";
import { ExpandedState } from "../../../components/table/table";

export const EmptySensorData: any = observable({
  sensorType: "BathroomSensor",
  status: null,
  inactivityPeriod: "",
  availableSensorTypes: [],
  storage: false,
  locationId: null,
  locationName: "",
  isNewSensor: false,
});

export interface ISensorsSort {
  id: string;
  desc: boolean;
}

export class SensorsSort implements ISensorsSort {
  public id: string;
  public desc: boolean;

  constructor(id: string, desc: boolean) {
    this.id = id;
    this.desc = desc;
  }
}
export interface SensorStatesList {
  sensorId: number;
  sensorStates: SensorStateItem[];
  loading: boolean;
}

export class SensorDataStore {
  constructor() {
    makeObservable(this, {
      validationErrors: observable,
      sensors: observable,
      rooms: observable,
      types: observable,
      filteringLocationId: observable,
      selectedSensor: observable,
      oldSensorData: observable,
      highlightSensor: observable,
      selectedSensorHasChanges: observable,
      sensorsSort: observable,
      expandedSensorIndex: observable,
      isLoading: observable,
      sensorStatesList: observable,
      errors: observable,
      errorMessages: observable,
      validated: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      hasSelectedSensor: computed,
      hasSelectedFilterId: computed,

      setSensorStatesList: action,
      setSensorsSort: action,
      setHiglight: action,
      setSensors: action,
      closeExpanded: action,
      resetSelectedData: action,
      setSelectedSensor: action,
      setAvailableRooms: action,
      setSensorTypes: action,
      setSelectedSensorHasChanges: action,
      setSelectedSensorType: action,
      clearSelectedSensorLocationId: action,
      setSelectedSensorLocationData: action,
      setIsNewSensor: action,
      setSelectedSensorSensorId: action,
      setSelectedSensorStorage: action,
      setExpandedSensorIndex: action,
      setIsLoading: action,
      validateSelectedSensor: action,
      setValidated: action,
      setNewSensor: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
    });
  }

  public validationErrors: ObservableMap<{}> = observable.map();
  public validated: boolean = true;
  public sensors: SensorData[] = [];
  public rooms: IRoomData[] = [];
  public types: string[] = [];
  public sensorsSort: ISensorsSort[] = [new SensorsSort("status", true)];
  public filteringLocationId: number | null = null;
  public selectedSensor: SensorData = EmptySensorData;
  public oldSensorData: SensorData = EmptySensorData;
  public highlightSensor: SensorData | null = EmptySensorData;
  public selectedSensorHasChanges: boolean = false;
  public expandedSensorIndex: number = ExpandedState.COLLAPSED;
  public isLoading: boolean = false;
  public errors: FlatErrorValidationResult | null = null;
  public errorMessages: FlatErrorValidationResult | null = null;

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;

  public bringHighlightedSensorIntoView: boolean = true;
  public sensorStatesList: SensorStatesList = {
    sensorId: -1,
    sensorStates: [],
    loading: true,
  };

  public get hasSelectedFilterId() {
    return this.filteringLocationId !== null;
  }

  public setHiglight(item: SensorData | null) {
    this.highlightSensor = item;
  }

  public setSensors(value: SensorData[]) {
    this.sensors = value;
  }

  public resetSelectedData() {
    this.selectedSensor = EmptySensorData;
    this.highlightSensor = null;
    this.filteringLocationId = null;
  }

  public setSensorStatesList(value: SensorStatesList) {
    this.sensorStatesList = value;
  }

  public setSensorsSort(value: ISensorsSort[]) {
    this.sensorsSort = value;
  }

  public setSelectedSensor(value: SensorData) {
    this.selectedSensor = value;
    this.oldSensorData = value;
  }

  public setNewSensor() {
    this.selectedSensor = {
      id: 0,
      sensorId: "",
      locationId: null,
      locationName: "",
      areaName: "",
      storage: false,
      sensorType: "BathroomSensor",
      status: null,
      inactivityPeriod: "",
      availableSensorTypes: this.types,
      isNewSensor: true,
    };

    this.oldSensorData = this.selectedSensor;
    this.selectedSensorHasChanges = false;
    this.expandedSensorIndex = ExpandedState.TOP;
  }

  public setAvailableRooms(value: IRoomData[]) {
    this.rooms = value;
  }

  public setSensorTypes(value: string[]) {
    this.types = value;
  }

  public setSelectedSensorHasChanges(value: boolean) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensorHasChanges = value;
  }

  public setSelectedSensorType(value: string) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.sensorType = value;
  }

  public clearSelectedSensorLocationId() {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.locationId = null;
  }

  public setSelectedSensorLocationData(
    locationId: number,
    locationName: string,
    areaName: string
  ) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.locationId = locationId;
    this.selectedSensor.locationName = locationName;
    this.selectedSensor.areaName = areaName;
  }

  public setIsNewSensor(value: boolean) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.isNewSensor = value;
  }

  public setSelectedSensorSensorId(value: string) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.sensorId = value;
  }

  public setSelectedSensorId(value: number) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.id = value;
  }

  public setSelectedSensorStorage(value: boolean) {
    this.selectedSensor = { ...this.selectedSensor };
    this.selectedSensor.storage = value;
  }

  public get hasSelectedSensor() {
    return this.expandedSensorIndex !== ExpandedState.COLLAPSED;
  }

  public closeExpanded() {
    this.expandedSensorIndex = ExpandedState.COLLAPSED;
  }

  public setExpandedSensorIndex(index: number) {
    this.expandedSensorIndex = index;
  }

  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedSensor(value: string = "") {
    const validationResult = validateData<SensorData>(
      this.selectedSensor,
      sensorDataSchema
    );

    var type = this.selectedSensor.sensorType;
    var id = this.selectedSensor.sensorId;
    var sensorTemplate = new RegExp(/^[A-Fa-f0-9]*$/);
    var vsensorTemplate = new RegExp(/^[A-Za-z0-9]*$/);

    const acceptedSensorType = [...this.types];
    const virtualSensorIndex = acceptedSensorType.findIndex(
      (type) => type === "VirtualSensor"
    );
    if (virtualSensorIndex) acceptedSensorType.splice(virtualSensorIndex, 1);

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = {};

    if (acceptedSensorType.includes(type)) {
      if (
        ~["BedSensor", "ChairSensor"].indexOf(type) &&
        !id.toUpperCase().startsWith("B")
      ) {
        if (!this.errors!["sensorId"]) {
          this.errors!["sensorId"] = [];
        }
        this.errors!["sensorId"].push("IDB");
      } else if (
        ~["RoomSensor", "DoorSensor", "BathroomSensor"].indexOf(type) &&
        !id.toUpperCase().startsWith("C")
      ) {
        if (!this.errors!["sensorId"]) {
          this.errors!["sensorId"] = [];
        }
        this.errors!["sensorId"].push("IDC");
      }
      if (id.length !== 6) {
        if (!this.errors!["sensorId"]) {
          this.errors!["sensorId"] = [];
        }
        this.errors!["sensorId"].push("ID6");
      }
      if (!sensorTemplate.test(id)) {
        if (!this.errors!["sensorId"]) {
          this.errors!["sensorId"] = [];
        }
        this.errors!["sensorId"].push("IDA-F");
      }
    } else if (!vsensorTemplate.test(id)) {
      if (!this.errors!["sensorId"]) {
        this.errors!["sensorId"] = [];
      }
      this.errors!["sensorId"].push("IDA-Z");
    }

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}
