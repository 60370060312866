import styles from "./resident-link.module.css";
import { ClientAlarm, ClientAlert, RoomIcon } from "./room-icon";

export function ResidentLink(props: any) {
  return (
    <div className={styles["client-name-cell"]}>
      {roomIcon(props.alertData)}
      <div onClick={props.click} className={styles["client-link"]}>
        <span className={styles["client-link-content"]}>{props.value}</span>
      </div>
    </div>
  );
}

function roomIcon(alertData: any) {
  let alarmColor = "alert";
  if (!alertData) {
    return "";
  }

  if (!alertData.alertId) {
    return <RoomIcon modifier={alarmColor} />;
  }

  let Icon = ClientAlarm;

  var alertOverride = alertData.alertId === 4 || alertData.alertId === 5;
  if (!alertOverride && alertData.alertDelayed) {
    alarmColor = "warning";
    Icon = ClientAlert;
  }

  return (
    <RoomIcon modifier={alarmColor}>
      <Icon width="30px" height="30px" />
    </RoomIcon>
  );
}
