import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";
import { inject } from "mobx-react";

import Clients from "./page.highorder";
import { FeatureStore } from "../../store";

export interface ProtectedManageClientsProps {
  featureStore?: FeatureStore;
}

function ProtectedManageClients(props: ProtectedManageClientsProps) {
  const render = () => {
    const { manageClients } = props.featureStore!.features;

    return (
      <AuthorizedRoute
        requiredRoles={[ROLES.NURSEADMIN]}
        path="/"
        component={Clients}
        shouldRedirect={manageClients === false}
        {...props}
      />
    );
  };

  return render();
}

const InjectedPage = inject("featureStore")(ProtectedManageClients);

const clientsRoute = (
  <Route path="/manage-residents" element={<InjectedPage />} />
);

export default clientsRoute;
