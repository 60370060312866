import * as joi from "joi";

export const cameraDataSchema = joi.object().keys({
  cameraId: joi.number().required(),
  cameraName: joi.string().required(),
  cameraRtsp: joi.string().required(),
  locations: joi
    .array()
    .allow(null)
    .optional()
    .items({
      locationId: joi.number().required(),
      locationName: joi.string().required(),
      locationPath: joi.string().allow("").optional(),
      distance: joi
        .number()
        .positive()
        .allow(0)
        .max(214748365)
        .integer()
        .required(),
    }),
});
