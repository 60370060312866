import {
  ActionsCell,
  ActionsCellProps,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../components/table/cells/ActionsCell";
import { MariDataStore } from "../store/store";
import { FeatureStore, TransitionStore } from "../../../store";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BrowseForFile from "./upload-file.actions";
import { ExpandedState } from "../../../components/table/table";

export interface ButtonDetails {
  isVisible: boolean;
  component: any;
}

export interface ManageMariActionsCellProps {
  mariDataStore?: MariDataStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;

  selectedMariIndex: number;
  onActionButtonClicked(buttonType: BUTTON_TYPE): void;
  onSendFile(file: FormData): void;
}

function ManageMariActionsCell(props: ManageMariActionsCellProps) {
  const featureStore = props.featureStore!;
  const mariDataStore = props.mariDataStore!;
  const transitionStore = props.transitionStore!;
  const { isLoading } = transitionStore;

  const actionsCellButtons = () => {
    const cellProps: ActionsCellProps = {
      buttonVisibility: actionButtonsVisibility(
        props.selectedMariIndex,
        mariDataStore.expandedMariIndex
      ),
      buttonAvailability: actionButtonsAvailability(
        props.selectedMariIndex,
        mariDataStore.expandedMariIndex
      ),
      onButtonClick: (type) => props.onActionButtonClicked(type),
    };

    return (
      <ActionsCell
        buttonVisibility={actionButtonsVisibility(
          props.selectedMariIndex,
          mariDataStore.expandedMariIndex
        )}
        buttonAvailability={actionButtonsAvailability(
          props.selectedMariIndex,
          mariDataStore.expandedMariIndex
        )}
        customButtons={[
          {
            node: UploadButtonComponent(cellProps),
            typeId: 4,
            availability: isUploadAvailable(
              props.selectedMariIndex,
              mariDataStore.expandedMariIndex
            ),
          },
        ]}
        onButtonClick={(type) => props.onActionButtonClicked(type)}
      ></ActionsCell>
    );
  };

  const UploadButtonComponent = (props: ActionsCellProps) => {
    return (
      props.buttonVisibility.edit && (
        <UploadButton
          {...getUploadButtonProps(props.buttonAvailability!.edit)}
        />
      )
    );
  };

  const getUploadButtonProps = (isEditAvailable: boolean) => {
    const buttonProps: UploadButtonProps = {
      isAvailable: isEditAvailable,
      onUploadFile: (file) => props.onSendFile(file),
    };

    return buttonProps;
  };

  const isUploadAvailable = (index: number, selectedIndex: number) => {
    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      return true;
    }
    return false;
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      editAvailability = true;
      deleteAvailability = featureStore.features.uniteIntegrationEnabled;
    }

    if (transitionStore.isLoading) {
      deleteAvailability = false;
    }

    if (mariDataStore.maris.length <= 1) {
      deleteAvailability = false;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = featureStore.features.uniteIntegrationEnabled;
    }

    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  return (
    <div className="actions-cell">
      <div className="actions-cell-button-container">
        {actionsCellButtons()}
      </div>
    </div>
  );
}

export default inject(
  "mariDataStore",
  "featureStore",
  "transitionStore"
)(observer(ManageMariActionsCell));

interface UploadButtonProps {
  isAvailable?: boolean;
  onUploadFile(file: FormData): void;
}

function UploadButton(props: UploadButtonProps) {
  const { t } = useTranslation();

  return (
    <BrowseForFile
      isAvailable={props.isAvailable}
      onFileSelected={(file: FormData) => {
        if (file === null) {
          return;
        }

        props.onUploadFile(file!);
      }}
      t={t}
    />
  );
}
