import { Button, ButtonProps } from "@mui/material";
import styles from "./add-button.module.css";
import { Plus } from "../svg";

interface AddButtonProps {
  children?: React.ReactNode;
}

function AddButton(props: AddButtonProps | ButtonProps) {
  const { children, ...rest } = props;

  const render = () => {
    return (
      <div className={styles["add-button"]}>
        <Button
          sx={{ height: "36px" }}
          fullWidth
          variant="contained"
          startIcon={<Plus />}
          {...rest}
        >
          {children}
        </Button>
      </div>
    );
  };

  return render();
}

export default AddButton;
