import styles from "./upload-file.actions.module.css";

import { ChangeEvent, createRef } from "react";
import { FileUpload } from "../../../components/svg";
import classNames from "classnames";

export interface BrowseForFileProps {
  isAvailable?: boolean;
  onFileSelected(file: FormData): void;
  t: any;
}

function BrowseForFile(props: BrowseForFileProps) {
  const browseRef = createRef<HTMLInputElement>();
  const { isAvailable } = props;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return;

    if (event.target.files.length === 0) return;

    const data = new FormData();
    data.append("file", event.target.files[0]);
    props.onFileSelected(data);
    browseRef.current!.value = "";
  };

  const browseForFile = () => {
    if (browseRef!.current! === null) return;
    browseRef!.current!.click();
  };

  return (
    <div
      className={styles["manage-mari-browse-action-style"]}
      onClick={browseForFile}
    >
      <FileUpload
        className={classNames(styles["upload-icon"], {
          [styles["locked-upload-icon"]]: !isAvailable,
        })}
      />

      <input
        ref={browseRef}
        className={styles["manage-mari-upload-backup-browse-button"]}
        type="file"
        name="file"
        accept=".nib"
        onChange={onChange}
      />
    </div>
  );
}

export default BrowseForFile;
