import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CameraDataStore } from "../../../store/store";
import { AuthStore } from "../../../../../store";
import styles from "./../edit-camera.module.css";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface RTSPInputProps {
  cameraDataStore?: CameraDataStore;
  authStore?: AuthStore;
}

const InputStringMaximumLength = 249;

function RTSPInput(props: RTSPInputProps) {
  const cameraDataStore = props.cameraDataStore!;
  const authStore = props.authStore!;

  const { t } = useTranslation("camera-grid");
  const cameraRtsp = cameraDataStore!.selectedCamera.cameraRtsp;
  const { errorMessages } = cameraDataStore;

  return (
    <>
      {authStore.isAdmin() && (
        <td>
          <div className={styles["edit-column-content"]}>
            <TextFieldInput
              value={cameraRtsp}
              errorMessage={
                (errorMessages &&
                  errorMessages["cameraRtsp"] &&
                  t("Required information")) ||
                ""
              }
              mandatory
              disabled={!authStore!.isAdmin()}
              label={t("RTSP").toUpperCase()}
              placeholder={t("RTSP")}
              onChange={(event: any) => {
                const cameraNameValue = event.target.value;
                if (cameraNameValue.length > InputStringMaximumLength) return;

                cameraDataStore.setSelectedCameraRtsp(cameraNameValue);
                cameraDataStore.setValidated(false);
              }}
              onValueChangeDebounced={() => {
                cameraDataStore.validateSelectedCamera("cameraRtsp");
                cameraDataStore.setValidated(true);
              }}
            />
          </div>
        </td>
      )}
    </>
  );
}

export default inject("authStore", "cameraDataStore")(observer(RTSPInput));
