import "./modal-dialog.css";

import { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { TransitionStore } from "../store";
import { ActionButton } from "../store/transition-store";
import { DialogClose } from "./svg";
import SimpleIconButton from "./buttons/SimpleIconButton";
import { Button } from "@mui/material";

interface DialogProps {
  transitionStore?: TransitionStore;
}

const Dialog = (props: DialogProps): any => {
  const { transitionStore } = props;
  const isOpen = transitionStore!.isGlobalDialogVisible();

  useEffect(() => {
    if (isOpen) {
      document.documentElement.className = "no-scroll";
    } else {
      document.documentElement.className = "";
    }

    return function cleanup() {
      document.documentElement.className = "";
    };
  }, [isOpen]);

  const mapDialogActionButtons = (actionButtonData: ActionButton[]) => {
    return actionButtonData.map((data, index) => (
      <Button
        key={index}
        color="inherit"
        onClick={() => {
          if (data.callback) data.callback();
          if (data.closeDialog) {
            transitionStore!.hideGlobalDialog();
          }
        }}
        variant="text"
      >
        {data.text}
      </Button>
    ));
  };

  return (
    isOpen && (
      <div className="modal-dialog-component">
        <div className="overlay" />
        <div className="confirmation-dialog">
          <div className="title-container">
            <div className="title">{transitionStore!.title}</div>
            {transitionStore!.canBeClosed === true && (
              <SimpleIconButton
                onClick={() => {
                  transitionStore!.hideGlobalDialog();
                }}
              >
                <DialogClose />
              </SimpleIconButton>
            )}
          </div>

          <div className="body-container">
            <div className="content">
              <div className="content-message">{transitionStore!.message}</div>
            </div>
          </div>
          {transitionStore!.actionButtonData.length > 0 && (
            <>
              <div className="separator-line" />
              <div className="button-container">
                {mapDialogActionButtons(transitionStore!.actionButtonData)}
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default inject("transitionStore")(observer(Dialog));
