import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface UsernameInputProps {
  mariDataStore?: MariDataStore;
}

const InputStringMaximumLength = 50;

function UsernameInput(props: UsernameInputProps) {
  const mariDataStore = props.mariDataStore!;
  const { t } = useTranslation("mari-grid");
  const username = mariDataStore.selectedMari.username;
  const { errorMessages } = mariDataStore;

  let errorMessageText = "";
  let helperText = null;

  if (errorMessages) {
    if (errorMessages["username"]) {
      errorMessageText = t("RequiredInformation");
    } else if (errorMessages["baseAddress"]) {
      helperText = " ";
    }
  }

  return (
    <TextFieldInput
      value={username}
      disabled={mariDataStore.isValidationInProgress}
      mandatory
      errorMessage={errorMessageText}
      helperMessage={helperText}
      label={t("Username").toUpperCase()}
      placeholder={t("Username")}
      onChange={(event: any) => {
        const usernameValue = event.target.value;
        if (usernameValue.length > InputStringMaximumLength) return;

        mariDataStore.setSelectedMareUsername(usernameValue);
        mariDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        mariDataStore.validateSelectedMari("username");
        mariDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("mariDataStore")(observer(UsernameInput));
