import { ObjectSchema, ValidationOptions, ValidationResult } from "joi";

export function validateData<DataType>(
  value: DataType,
  schema: ObjectSchema
): ValidationResult {
  const validationOptions: ValidationOptions = {
    abortEarly: false,
    allowUnknown: true,
  };

  return schema.validate(value, validationOptions);
}
