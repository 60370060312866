import Layout from "./components/layout";
import ClientsOverview from "./modules/clients-overview";
import ManageClients from "./modules/manage-clients";
import ManageSensors from "./modules/manage-sensors";
import ManageCameras from "./modules/manage-cameras";
import ManageMaris from "./modules/manage-maris";
import ManagePeripherals from "./modules/manage-peripherals";
import ManageSubstatuses from "./modules/manage-substatuses";
import ManageAlerts from "./modules/manage-alerts";

import ClientProfile from "./modules/client-profile";
import ClientDashboard from "./modules/client-dashboard";
import { Routes } from "react-router";
import AuthenticationLayer from "./components/authentication/AuthenticationLayer";
import ModalDialog from "./components/modal-dialog";
import Login from "./modules/login";
import { BrowserRouter, Navigate, Route } from "react-router-dom";

const routes = () => (
  <AuthenticationLayer>
    <ModalDialog />
    <BrowserRouter>
      <Routes>
        {Login}
        <Route element={<Layout />}>
          <Route
            index
            element={<Navigate to={{ pathname: "/residents-overview" }} />}
          />

          {ClientsOverview}
          {ManageClients}
          {ManageSensors}
          {ManageCameras}
          {ManageMaris}
          {ManagePeripherals}
          {ManageSubstatuses}
          {ManageAlerts}
          {ClientProfile}
          {ClientDashboard}
        </Route>
      </Routes>
    </BrowserRouter>
  </AuthenticationLayer>
);

export default routes;
