import styles from "./client-profile-access-settings-header.module.css";

import { observer } from "mobx-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ClientProfileDetails } from "../../store/types";
import { PageHeader } from "../../../../components/page";
import { IconButton } from "../../../../components/icon-button";
import { CrossX, Save } from "../../../../components/svg";
import { Component } from "react";
import SaveButton from "../../../../components/buttons/SaveButton";
import CancelButton from "../../../../components/buttons/CancelButton";

interface IClientAccessHeaderProps extends WithTranslation {
  clientDetails: ClientProfileDetails;
  t: any;
  title: string;
  saveAccessSettings(): void;
  cancelAccessSettings(): void;
}

@observer
export class ClientProfileAccessSettingsHeader extends Component<
  IClientAccessHeaderProps,
  any
> {
  public render() {
    const {
      title,
      t,
      clientDetails,
      cancelAccessSettings,
      saveAccessSettings,
    } = this.props;

    return (
      <PageHeader>
        <div className={styles["title"]}>{t(title)}</div>
        <div className={styles["client-name"]}>{clientDetails.clientName}</div>
        <div className={styles["client-area"]}>
          <span>{clientDetails.areaName}</span>
          <span> - </span>
          <span className={styles["client-room"]}>
            {clientDetails.locationName}
          </span>
        </div>

        <div className={styles["container"]}>
          <div
            className={`${styles["header-button"]} ${styles["save-button"]}`}
          >
            <SaveButton
              variant="contained"
              onClick={() => saveAccessSettings()}
            >
              {t("common:Save")}
            </SaveButton>
          </div>
          <div className={styles["header-button"]}>
            <CancelButton
              onClick={() => cancelAccessSettings()}
              variant="contained"
            >
              {t("common:Cancel")}
            </CancelButton>
          </div>
        </div>
      </PageHeader>
    );
  }
}
export default withTranslation(["client-profile"])(
  ClientProfileAccessSettingsHeader
);
