import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { mariDataSchema } from "../validation";
import { MariData } from "./types";
import { ExpandedState } from "../../../components/table/table";

export const EmptyMari: MariData = observable({
  mariId: undefined,
  mariName: "",
  username: "",
  password: "",
  baseAddress: "",
  uniteId: "",
  nismIp: "",
  virtualDevices: false,
});

export interface IMariSort {
  id: string;
  desc: boolean;
}

export class MariSort implements IMariSort {
  public id: string;
  public desc: boolean;

  constructor(id: string, desc: boolean) {
    this.id = id;
    this.desc = desc;
  }
}

export class MariDataStore {
  constructor() {
    makeObservable(this, {
      validationErrors: observable,
      maris: observable,
      selectedMariHasChanges: observable,
      selectedMari: observable,
      oldMariData: observable,
      highlightMari: observable,
      mariSort: observable,
      expandedMariIndex: observable,
      isValidationInProgress: observable,
      errors: observable,
      errorMessages: observable,
      validated: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      hasSelectedMari: computed,

      setNewMari: action,
      setMaris: action,
      resetSelectedData: action,
      setSelectedMari: action,
      setSelectedMariName: action,
      setSelectedMareBaseAdress: action,
      setSelectedMarePassword: action,
      setSelectedMareUsername: action,
      setSelectedMareUniteId: action,
      setHilightMari: action,
      setMariSort: action,
      closeExpanded: action,
      setExpandedMariIndex: action,
      setSelectedMariHasChanges: action,
      setIsValidationInProgress: action,
      validateSelectedMari: action,
      setValidated: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
      setSelectedVirtualDevices: action,
    });
  }

  public validated: boolean = true;
  public errorMessages: FlatErrorValidationResult | null = null;
  public validationErrors: ObservableMap<{}> = observable.map();
  public maris: MariData[] = [];
  public mariSort: IMariSort[] = [new MariSort("locationName", false)];
  public expandedMariIndex: number = ExpandedState.COLLAPSED;
  public isValidationInProgress: boolean = false;
  public errors: FlatErrorValidationResult | null = null;

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;

  public selectedMariHasChanges: boolean = false;
  public selectedMari: MariData = {
    mariId: undefined,
    mariName: "",
    username: "",
    password: "",
    baseAddress: "",
    uniteId: "",
    nismIp: "",
    virtualDevices: false,
  };
  public oldMariData: MariData = {
    mariId: undefined,
    mariName: "",
    username: "",
    password: "",
    baseAddress: "",
    uniteId: "",
    nismIp: "",
    virtualDevices: false,
  };

  public highlightMari: MariData | null = {
    mariId: undefined,
    mariName: "",
    username: "",
    password: "",
    baseAddress: "",
    uniteId: "",
    nismIp: "",
    virtualDevices: false,
  };

  public setMariSort(value: IMariSort[]) {
    this.mariSort = value;
  }

  public setNewMari() {
    const data = {
      mariId: 0,
      mariName: "",
      username: "",
      password: "",
      baseAddress: "",
      uniteId: "",
      nismIp: "",
      virtualDevices: false,
      isNewMari: true,
    };
    this.selectedMari = { ...data };
    this.oldMariData = { ...data };

    this.selectedMariHasChanges = false;
    this.expandedMariIndex = ExpandedState.TOP;
  }

  public setIsNewMari(value: boolean) {
    this.selectedMari.isNewMari = value;
  }

  public setMaris(value: MariData[]) {
    this.maris = value;
  }

  public resetSelectedData() {
    this.selectedMari = EmptyMari;
    this.highlightMari = null;
    this.isValidationInProgress = false;
  }

  public setSelectedMari(value: MariData) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari = value;
    this.oldMariData = { ...value };
  }

  public setSelectedMariHasChanges(value: boolean) {
    this.selectedMariHasChanges = value;
  }

  public setSelectedMariName(value: string) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.mariName = value;
    this.selectedMariHasChanges = true;
  }

  public setSelectedMareBaseAdress(value: string) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.baseAddress = value;
    this.selectedMariHasChanges = true;
  }

  public setSelectedMarePassword(value: string) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.password = value;
    this.selectedMariHasChanges = true;
  }

  public setSelectedMariNismIp(value: string) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.nismIp = value;
    this.selectedMariHasChanges = true;
  }

  public setSelectedMareUsername(value: string) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.username = value;
    this.selectedMariHasChanges = true;
  }

  public setSelectedMareUniteId(value: string) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.uniteId = value;
    this.selectedMariHasChanges = true;
  }

  public setSelectedVirtualDevices(value: boolean) {
    this.selectedMari = { ...this.selectedMari };
    this.selectedMari.virtualDevices = value;
    this.selectedMariHasChanges = true;
  }

  public setHilightMari(value: MariData | null) {
    this.highlightMari = value;
  }

  public get hasSelectedMari() {
    return this.expandedMariIndex !== ExpandedState.COLLAPSED;
  }

  public closeExpanded() {
    this.expandedMariIndex = ExpandedState.COLLAPSED;
  }

  public setExpandedMariIndex(index: number) {
    this.expandedMariIndex = index;
  }

  public setIsValidationInProgress(isInProgress: boolean) {
    this.isValidationInProgress = isInProgress;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedMari(value: string = "") {
    const validationResult = validateData<MariData>(
      this.selectedMari,
      mariDataSchema
    );

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = null;

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}
