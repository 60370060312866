import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import ToggleButtonsComponent from "../../../../components/toggle-button/ToggleButtonGroup";
import { ReactComponent as Day } from "../../svg/day.svg";
import { ReactComponent as Night } from "../../svg/night.svg";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface TimeToggleProps {
  clientOverviewStore?: ClientOverviewStore;
}

function TimeToggle(props: TimeToggleProps) {
  const clientOverviewStore = props.clientOverviewStore!;

  const { t } = useTranslation("client-grid");

  const buttons = [
    {
      id: "day",
      icon: <Day width={16} />,
      tooltip: t("DayToggleButton"),
    },
    {
      id: "night",
      icon: <Night width={16} />,
      tooltip: t("NightToggleButton"),
    },
  ];

  const getId = () => {
    if (clientOverviewStore.nightTimeAlerts) return "night";
    else return "day";
  };

  const render = () => {
    return (
      <ToggleButtonsComponent
        buttons={buttons}
        selectedID={getId()}
        onChange={(ID: string) => {
          if (ID !== null)
            if (ID === "day") {
              clientOverviewStore.nightTimeAlerts = false;
            } else {
              clientOverviewStore.nightTimeAlerts = true;
            }
        }}
      />
    );
  };
  return render();
}

export default inject("clientOverviewStore")(observer(TimeToggle));
