import classNames from "classnames";
import "./basicheader.css";
import OverflowLabel from "../../OverflowLabel";

export enum ColumnAlign {
  Left,
  Center,
  Right,
}
export interface BasicHeaderProps {
  text: string;
  align?: ColumnAlign;
}

export function BasicHeader(props: BasicHeaderProps) {
  const { align = ColumnAlign.Left, text } = props;
  return (
    <div
      className={classNames("basic-header", {
        center: align === ColumnAlign.Center,
        left: align === ColumnAlign.Left,
        right: align === ColumnAlign.Right,
      })}
    >
      <div className="header-container">
        <OverflowLabel text={text.toUpperCase()} />
      </div>
    </div>
  );
}
