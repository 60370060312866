import { inject, observer } from "mobx-react";
import { Tooltip } from "@mui/material";
import { ReactComponent as Info } from "../../../svg/used-info.svg";
import { useTranslation } from "react-i18next";

function UsedInfoIcon() {
  const { t } = useTranslation("peripheral-grid");

  return (
    <Tooltip title={t("UsedInfo")}>
      <Info width={"40px"} />
    </Tooltip>
  );
}

export default inject("peripheralDataStore")(observer(UsedInfoIcon));
