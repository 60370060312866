import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

import Alerts from "./page.highorder";

const ProtectedManageAlerts = (props: any) => (
  <AuthorizedRoute
    requiredRoles={[ROLES.ADMIN]}
    exact
    path="/"
    component={Alerts}
    {...props}
  />
);

const clientsRoute = (
  <Route path="/manage-alerts" element={<ProtectedManageAlerts />} />
);

export default clientsRoute;
