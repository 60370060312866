import { Button, ButtonProps } from "@mui/material";
import styles from "./save-button.module.css";
import { EditSave } from "../svg";

interface SaveButtonProps {
  children?: React.ReactNode;
}

function SaveButton(props: SaveButtonProps | ButtonProps) {
  const { children, ...rest } = props;

  const render = () => {
    return (
      <div className={styles["save-button"]}>
        <Button
          startIcon={<EditSave />}
          {...rest}
        >
          {children}
        </Button>
      </div>
    );
  };

  return render();
}

export default SaveButton;
