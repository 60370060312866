import styles from "./client-profile-client-status-tab.module.css";

import classNames from "classnames";
import { ClientProfileDetails } from "../../store/types";
import { ReactComponent as InBed } from "../../../clients-overview/svg/client-in-bed.svg";
import { ReactComponent as InRoom } from "../../../clients-overview/svg/client-in-room.svg";
import { ReactComponent as InBathroom } from "../../../clients-overview/svg/client-in-bathroom.svg";
import { ReactComponent as OutOfRoom } from "../../../clients-overview/svg/client-out-of-room.svg";
import { ReactComponent as InChair } from "../../../clients-overview/svg/client-in-chair.svg";
import { observer } from "mobx-react";
import { AuthStore, TransitionStore } from "../../../../store";
import { WithTranslation, withTranslation } from "react-i18next";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { ChangeEvent, Component } from "react";
import { SwitchInput } from "../../../../components/form-inputs";

interface IClientProfileClientStatusTabProps extends WithTranslation {
  t: any;
  authStore: AuthStore;
  transitionStore: TransitionStore;
  clientDetails: ClientProfileDetails;
  toggleClientStatus(): void;
}

interface IClientProfileClientStatusTabState {
  toggleValue: boolean;
}

@observer
export class ClientProfileClientStatusTab extends Component<
  IClientProfileClientStatusTabProps,
  IClientProfileClientStatusTabState
> {
  private _orderedStatuses;

  constructor(props: IClientProfileClientStatusTabProps) {
    super(props);
    this.state = {
      toggleValue: false,
    };

    this._orderedStatuses = this.createSortedStatuses();
  }

  private createSortedStatuses() {
    const statuses = [
      { name: "InBed", icon: InBed },
      { name: "InRoom", icon: InRoom },
      { name: "InBathroom", icon: InBathroom },
      { name: "OutOfRoom", icon: OutOfRoom },
      { name: "InChair", icon: InChair },
    ];

    return statuses;
  }

  public render() {
    const { clientDetails, t } = this.props;

    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}> {t("Client status")} </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          {this.renderClientStatusToggleButton()}
          <div
            className={classNames({
              [`${styles["col-2"]} ${styles["text"]}`]:
                clientDetails.isStatusEnabled,
              [`${styles["col-2"]} ${styles["text"]} ${styles["disabled"]}`]:
                !clientDetails.isStatusEnabled,
            })}
          >
            {t("Available statuses")}
          </div>
          {this.renderAvailableStatusIcons()}
          <div className={styles["col-4"]} />
        </li>
      </div>
    );
  }

  private renderClientStatusToggleButton = () => {
    const { clientDetails, toggleClientStatus, authStore, transitionStore } =
      this.props;
    const { dialogTitle, dialogContent, confirmButtonText, cancelButtonText } =
      this.preareToggleDialogInformation();
    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      confirmButtonText,
      cancelButtonText,
      false,
      () => {
        clientDetails.isStatusEnabled = !clientDetails.isStatusEnabled;
        toggleClientStatus();
      }
    );

    return (
      <div className={`${styles["toggle-button"]} ${styles["col-1"]}`}>
        <div className={styles["switch-container"]}>
          <SwitchInput
            checked={clientDetails.isStatusEnabled}
            disabled={!authStore.hasAdminRights()}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              transitionStore.showGlobalDialog(confirmationDialogData);
            }}
          ></SwitchInput>
        </div>
      </div>
    );
  };
  private preareToggleDialogInformation = () => {
    const { clientDetails, t } = this.props;
    const toggleStatus = !clientDetails.isStatusEnabled
      ? t("common:On")
      : t("common:Off");
    const action = !clientDetails.isStatusEnabled
      ? t("common:Start")
      : t("common:Stop");

    const dialogTitle = t("ClientToggleDialogTitle", {
      toggleStatus: toggleStatus,
    });

    const confirmButtonText = t("ClientToggleDialogConfirmation", {
      toggleStatus: toggleStatus,
    });
    const cancelButtonText = t("common:Cancel");
    const dialogContent = `${t("ClientToggleDialogAction", {
      action: action,
    })} ${t("ClientToggleDialogQuestion", {
      toggleStatus: toggleStatus,
      clientName: clientDetails.clientName,
    })}`;

    return { dialogTitle, confirmButtonText, cancelButtonText, dialogContent };
  };

  private renderAvailableStatusIcons = () => {
    const { clientDetails } = this.props;

    const icons = this.AvailableStatuses();
    if (icons.length <= 0) return <div />;

    const renderIcons: any[] = [];
    icons.forEach((status, index) => {
      renderIcons.push(
        <status.icon
          key={index}
          className={classNames(styles["default-icon"], {
            [styles["inactive-status-icon"]]:
              !clientDetails.isStatusEnabled || !status.isActive,
          })}
        />
      );
    });

    return (
      <div className={styles["col-3"]}>
        <div className={styles["row-1"]}>
          <div className={styles["status-group"]}> {renderIcons} </div>
        </div>
      </div>
    );
  };

  private AvailableStatuses = () => {
    const icons: IconInformation[] = [];
    const { availableStatuses } = this.props.clientDetails;
    if (availableStatuses === undefined || availableStatuses === null)
      return icons;

    this._orderedStatuses.forEach((status) => {
      if (availableStatuses[status.name] !== undefined) {
        icons.push({
          icon: status.icon,
          isActive: availableStatuses[status.name],
        });
      }
    });

    return icons;
  };
}

interface IconInformation {
  icon: any;
  isActive: boolean;
}

export default withTranslation(["client-profile"])(
  ClientProfileClientStatusTab
);
