import { inject, observer } from "mobx-react";
import { SubstatusDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface StatusNameInputProps {
  substatusDataStore?: SubstatusDataStore;
}

function StatusNameInput(props: StatusNameInputProps) {
  const { t } = useTranslation(["substatus-grid"]);
  const substatusDataStore = props.substatusDataStore!;
  const statusName = substatusDataStore!.selectedSubstatus.statusName;
  const { errorMessages } = substatusDataStore;

  return (
    <TextFieldInput
      autoFocus
      value={statusName}
      label={t("StatusName").toUpperCase()}
      inputProps={{ pattern: "^[A-Za-z0-9_ ]{0,100}$" }}
      placeholder={t("StatusName")}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["statusName"] &&
          t("RequiredInformation")) ||
        ""
      }
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;
        substatusDataStore.setSelectedSubstatusName(event.target.value);
        substatusDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        substatusDataStore.validateSelectedSubstatus("statusName");
        substatusDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("substatusDataStore")(observer(StatusNameInput));
