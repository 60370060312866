import styles from "./alert-sound.module.css";

import { observer } from "mobx-react";
import classnames from "classnames";
import Dropdown from "../../../components/dropdown-grid";
import { WithTranslation, withTranslation } from "react-i18next";
import { IAlertInfo } from "./client-settings-alert-status";
import { Component } from "react";

interface ISoundProps extends WithTranslation {
  alert: IAlertInfo;
  t: any;
  onChange?(): void;
}

@observer
class Sound extends Component<ISoundProps> {
  dbValues = [0, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  public render() {
    const { alert, t, onChange } = this.props;

    return (
      <div
        className={classnames(styles["sound-col"], {
          [styles["invisible"]]:
            alert.settings.sound === null || alert.settings.sound === undefined,
        })}
      >
        <div className={styles["label"]}>
          <div>{t("Sound")}</div>
          <div className={styles["disabled"]}>(db)</div>
        </div>
        {alert.enabled ? (
          <Dropdown
            defaultValue={alert.settings.sound}
            data={this.dbValues}
            onChange={(value) => {
              alert.settings.sound = value;
              if (onChange) {
                onChange();
              }
            }}
          />
        ) : (
          <div className={styles["alert-readonly-settings-value"]}>
            {alert.settings.sound}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation(["client-profile"])(Sound);
