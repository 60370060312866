import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BaseTable, SortHeader } from "../../../components/table";
import Web from "../web";
import {
  ActionsCell,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../components/table/cells/ActionsCell";
import { LocationCell } from "../../../components/table/cells/LocationCell";
import { TextCell } from "../../../components/table/cells/TextCell";
import { SORT_DIRECTION } from "../../../components/table/headers/SortHeader";
import { PeripheralData } from "../store/types";
import EditPeripheral from "./edit-peripheral/edit-peripheral";
import { EmptyPeripheral, PeripheralDataStore } from "../store/store";
import webResource from "../web";
import TransitionStore, {
  GlobalDialogData,
} from "../../../store/transition-store";
import { NoConnectionModal } from "../../../utils/NoConnectionDialog";
import { CreateConfirmationDialog } from "../../../utils/ConfirmationDialog";
import { ColumnData, ExpandedState } from "../../../components/table/table";
import { PeripheralUsed } from "./cells/peripheral-used";
import { PeripheralDataIn } from "../store/typesIn";
import { PeripheralDataConverter } from "../store/convertor";
import { PeripheralListCallOut } from "../store/typesOut";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../components/table/headers/BasicHeader";

interface ManagePeripheralsGridProps {
  peripheralDataStore?: PeripheralDataStore;
  transitionStore?: TransitionStore;
}

function ManagePeripheralsGrid(props: ManagePeripheralsGridProps) {
  const { t } = useTranslation(["peripheral-grid"]);
  const peripheralDataStore: PeripheralDataStore = props.peripheralDataStore!;
  const transitionStore: TransitionStore = props.transitionStore!;
  const {
    selectedPeripheralHasChanges,
    errors,
    selectedPeripheral,
    oldPeripheralData,
    validated,
  } = peripheralDataStore;
  const { isLoading } = transitionStore;

  useEffect(() => {
    readAllPeripheralData();
  }, []);

  const getSortDirection = (accessor: string) => {
    if (accessor !== peripheralDataStore.sortOptions[0].id)
      return SORT_DIRECTION.NONE;

    if (peripheralDataStore.sortOptions[0].desc)
      return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const columnData: ColumnData[] = [
    {
      header: t("MariName"),
      width: "12%",
      cellComponent: (value: PeripheralData) => (
        <TextCell text={value.mariName} />
      ),
      HeaderComponent: (
        <SortHeader
          text={t("MariName")}
          selected={peripheralDataStore.sortOptions[0].id === "mariName"}
          sortDirection={getSortDirection("mariName")}
          locked={peripheralDataStore.hasSelectedPeripheral}
          onClick={() => {
            sortColumn("mariName");
          }}
        />
      ),
    },
    {
      header: t("LocationName"),
      width: "16%",
      cellComponent: LocationCell,
      HeaderComponent: (
        <SortHeader
          text={t("LocationName")}
          selected={peripheralDataStore.sortOptions[0].id === "locationName"}
          sortDirection={getSortDirection("locationName")}
          locked={peripheralDataStore.hasSelectedPeripheral}
          onClick={() => {
            sortColumn("locationName");
          }}
        />
      ),
    },
    {
      header: t("Bus"),
      width: "60px",
      cellComponent: (value: PeripheralData) => <TextCell text={value.bus} />,
    },
    {
      header: t("Address"),
      width: "100px",
      cellComponent: (value: PeripheralData) => (
        <TextCell text={value.address} />
      ),
    },
    {
      header: t("Subaddress"),
      width: "150px",
      cellComponent: (value: PeripheralData) => (
        <TextCell text={value.subAddress} />
      ),
    },
    {
      header: t("ControllerMAC"),
      width: "145px",
      cellComponent: (value: PeripheralData) => (
        <TextCell text={value.controllerMAC} />
      ),
      HeaderComponent: (
        <SortHeader
          text={t("ControllerMAC")}
          selected={peripheralDataStore.sortOptions[0].id === "controllerMAC"}
          sortDirection={getSortDirection("controllerMAC")}
          locked={peripheralDataStore.hasSelectedPeripheral}
          onClick={() => {
            sortColumn("controllerMAC");
          }}
        />
      ),
    },
    {
      header: t("IpAddress"),
      cellComponent: (value: PeripheralData) => (
        <TextCell text={value.ipAddress} />
      ),
      HeaderComponent: (
        <SortHeader
          text={t("IpAddress")}
          selected={peripheralDataStore.sortOptions[0].id === "ipAddress"}
          sortDirection={getSortDirection("ipAddress")}
          locked={peripheralDataStore.hasSelectedPeripheral}
          onClick={() => {
            sortColumn("ipAddress");
          }}
        />
      ),
    },
    {
      header: t("IsUsed"),
      HeaderComponent: (
        <BasicHeader text={t("IsUsed")} align={ColumnAlign.Center} />
      ),
      width: "95px",
      cellComponent: (value: PeripheralData) => (
        <PeripheralUsed isUsed={value.isUsed} />
      ),
    },
    {
      header: t("Actions"),
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      width: "140px",
      cellComponent: (value: PeripheralData, index: number) => {
        return (
          <ActionsCell
            buttonVisibility={actionButtonsVisibility(
              index,
              peripheralDataStore.expandedRow
            )}
            buttonAvailability={actionButtonsAvailability(
              index,
              peripheralDataStore.expandedRow
            )}
            onButtonClick={(type: BUTTON_TYPE) => {
              switch (type) {
                case BUTTON_TYPE.EDIT: {
                  peripheralDataStore.setSelectedPeripheral({ ...value });
                  peripheralDataStore.setSelectedPeripheralHasChanges(false);
                  peripheralDataStore.setExpandedRow(index);
                  break;
                }

                case BUTTON_TYPE.DELETE: {
                  deletePeripheral(value.peripheralId!, value.controllerMAC);
                  break;
                }
              }
            }}
          />
        );
      },
    },
  ];

  const sortColumn = (id: string) => {
    if (peripheralDataStore.sortOptions[0].id === id) {
      peripheralDataStore.setSorting([
        {
          id: id,
          desc: !peripheralDataStore.sortOptions[0].desc,
        },
      ]);
    } else {
      peripheralDataStore.setSorting([{ id: id, desc: false }]);
    }
    readAllPeripheralData();
  };

  const deletePeripheral = (peripheralId: number, controllerMAC: string) => {
    if (
      peripheralDataStore.selectedPeripheral &&
      peripheralDataStore.selectedPeripheral.peripheralId === 0
    ) {
      return;
    }

    showDialog(peripheralId!, controllerMAC);
  };

  const showDialog = (peripheralId: number, peripheralMAC: string) => {
    const title = t("RemovePeripheral");
    const content = t("DeletePeripheralDialogQuestion", {
      peripheralMAC: peripheralMAC,
    });
    const data = CreateConfirmationDialog(
      title,
      content,
      t("Remove"),
      t("Cancel"),
      false,
      () => {
        peripheralDataStore.setIsDeleteLoading(true);
        Web.remove(peripheralId).then(() => {
          peripheralDataStore.setIsDeleteLoading(false);
          readAllPeripheralData();
        });
      }
    );

    transitionStore?.showGlobalDialog(data);
  };

  const save = () => {
    if (!selectedPeripheralHasChanges) {
      return;
    }

    const peripheralData = peripheralDataStore.selectedPeripheral;

    peripheralData.isNewPeripheral = false;

    let subscription = Web.update(
      PeripheralDataConverter.toOut(peripheralData)
    );

    peripheralDataStore.setIsSaveLoading(true);

    subscription
      .then(
        () => {
          clearSelectedPeripheral();
          readAllPeripheralData();
          peripheralDataStore.closeExpanded();
        },
        (err: any) => {
          err.message = t("Could not save or update peripheral");
          reportAjaxError();
        }
      )
      .finally(() => {
        peripheralDataStore.setIsSaveLoading(false);
      });
  };

  const reportAjaxError = () => {
    const dialog: GlobalDialogData = NoConnectionModal(t);

    transitionStore?.showGlobalDialog(dialog);
  };

  const readAllPeripheralData = () => {
    peripheralDataStore.setIsDataLoading(true);

    const peripheralData: PeripheralListCallOut = {
      SortOptions: peripheralDataStore.sortOptions,
    };

    return webResource
      .readAll(peripheralData)
      .then((ajaxResponse) => {
        const response = ajaxResponse.data!;

        const peripheralDataList: PeripheralData[] = response.data.map(
          (peripheral: PeripheralDataIn) => {
            return PeripheralDataConverter.toStore(peripheral);
          }
        );

        peripheralDataStore.setPeripherals(peripheralDataList);
        peripheralDataStore.availableLocations = response.availableLocations;
        peripheralDataStore.setIsDataLoading(false);
      })
      .catch(() => {
        const dialog: GlobalDialogData = NoConnectionModal(t);
        transitionStore?.showGlobalDialog(dialog);
        peripheralDataStore.setIsDataLoading(false);
      });
  };

  const clearSelectedPeripheral = () => {
    peripheralDataStore.setSelectedPeripheral(EmptyPeripheral);
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      editAvailability = true;
      deleteAvailability = true;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const saveDisabled = () => {
    let saveAvailability = false;

    if (peripheralDataStore.isSaveLoading) return true;

    if (
      JSON.stringify(selectedPeripheral) === JSON.stringify(oldPeripheralData)
    ) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = true;
    }

    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  const render = () => {
    return (
      <BaseTable
        minWidth={1286}
        expandedRows={peripheralDataStore.expandedRow}
        data={peripheralDataStore.peripherals}
        columns={columnData}
        expandedRowComponent={
          <EditPeripheral
            saveLocked={saveDisabled()}
            onSaveClick={() => {
              save();
            }}
            onCancelClick={() => {
              clearSelectedPeripheral();
              peripheralDataStore.closeExpanded();
              readAllPeripheralData();
            }}
          />
        }
      ></BaseTable>
    );
  };

  return render();
}

export default inject(
  "peripheralDataStore",
  "transitionStore"
)(observer(ManagePeripheralsGrid));
