import {
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
  ClientProfileDetails,
  ClientProfileSettings,
} from "./types";
import {
  BuildingAccessDelailIn,
  CameraSettingIn,
  ProfileDetailsIn,
  ProfileSettingIn,
} from "./typesIn";

export interface ProfileDetailsConverter {
  toStore(inData: ProfileDetailsIn): ClientProfileDetails;
}

export const ProfileDetailsConverter: ProfileDetailsConverter = {
  toStore(inData: ProfileDetailsIn) {
    return {
      activeAccessPeriodId: inData.activeAccessPeriodId,
      activeSensors: inData.activeSensors,
      activeAlertPeriodId: inData.activeAlertPeriodId,
      areaName: inData.areaName,
      availableStatuses: inData.availableStatuses,
      clientName: inData.clientName,
      isAccessProfileEnabled: inData.isAccessProfileEnabled,
      isAlarmProfileEnabled: inData.isAlarmProfileEnabled,
      isStatusEnabled: inData.isStatusEnabled,
      locationName: inData.locationName,
      sensorIssues: inData.sensorIssues,
    };
  },
};

export interface ProfileSettingsConverter {
  toStore(inData: ProfileSettingIn): ClientProfileSettings;
}

export const ProfileSettingsConverter: ProfileSettingsConverter = {
  toStore(inData: ProfileSettingIn) {
    const settings = new ClientProfileSettings();
    settings.alerts = inData.alerts;
    settings.intervals = inData.intervals;

    return settings;
  },
};

export interface BuildingAccessConverter {
  toStore(inData: BuildingAccessDelailIn): ClientProfileAccessSettings;
}

export const BuildingAccessConverter: BuildingAccessConverter = {
  toStore(inData: BuildingAccessDelailIn) {
    const settings = new ClientProfileAccessSettings();

    settings.clientProfileAccessLocations = inData.clientProfileAccessLocations;
    settings.clientProfileAssignmentAreas = inData.clientProfileAssignmentAreas;

    return settings;
  },
};

export interface CameraSettingsConverter {
  toStore(inData: CameraSettingIn): ClientProfileCameraSettings;
}

export const CameraSettingsConverter: CameraSettingsConverter = {
  toStore(inData: CameraSettingIn) {
    const settings = new ClientProfileCameraSettings();

    settings.isActive = inData.isActive;
    settings.optionTypeId = inData.optionTypeId;

    return settings;
  },
};
