import styles from "./manage-clients-grid.module.css";

import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { BaseTable, SortHeader } from "../../../../components/table";
import {
  ActionsCell,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../../components/table/cells/ActionsCell";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import TransitionStore, {
  GlobalDialogData,
} from "../../../../store/transition-store";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import {
  ClientDataStore,
  ClientStoreData,
  EmptyClientData,
} from "../../store/store";
import EditClient from "../edit-client/edit-client";
import webResource from "../../web";
import { TextCell } from "../../../../components/table/cells/TextCell";
import { LocationCell } from "../../../../components/table/cells/LocationCell";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ColumnData, ExpandedState } from "../../../../components/table/table";
import {
  AvailableRoomDataConverter,
  ClientDataConverter,
} from "../../store/convertor";
import {
  AvailableRoomDataIn,
  ResidentDataIn,
  ResidentsListCallIn,
} from "../../store/typesIn";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../../components/table/headers/BasicHeader";

interface IManageClientsGridProps {
  clientDataStore?: ClientDataStore;
  transitionStore?: TransitionStore;
}

function ManageClientsGrid(props: IManageClientsGridProps) {
  const clientDataStore = props.clientDataStore!;
  const transitionStore = props.transitionStore!;
  const { isLoading } = transitionStore;

  const { residentsSort, errors, selectedClient, oldClientData, validated } =
    clientDataStore;

  const { t } = useTranslation("client-grid");

  useEffect(() => {
    readAllClientData();

    return function unmount() {
      clientDataStore.closeExpanded();
    };
  }, []);

  const readAllClientData = () => {
    const { residentsSort } = clientDataStore;

    clientDataStore.setIsDataLoading(true);

    return webResource
      .readAll(residentsSort)
      .then((ajaxResponse: any) => {
        const response: ResidentsListCallIn = ajaxResponse.data;

        const clientList = response.data.map((client: ResidentDataIn) => {
          return ClientDataConverter.toStore(client);
        });

        clientDataStore.setClients(clientList);

        const roomsList = response.availableRooms.map(
          (room: AvailableRoomDataIn) => {
            return AvailableRoomDataConverter.toStore(room);
          }
        );

        clientDataStore.rooms = roomsList;
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore.showGlobalDialog(dialog);
      })
      .finally(() => {
        clientDataStore.setIsDataLoading(false);
      });
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== residentsSort[0].id) return SORT_DIRECTION.NONE;

    if (residentsSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      editAvailability = true;
      deleteAvailability = true;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const saveDisabled = () => {
    let saveAvailability = false;

    if (clientDataStore.isSaveLoading) return true;

    if (JSON.stringify(selectedClient) === JSON.stringify(oldClientData)) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = true;
    }
    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  const deleteClient = (value: any) => {
    const content = (
      <div>
        <span>
          {t("client-grid:ArchiveClientDialogQuestion1")}
          <span className={styles["manage-clients-grid-popup-client-name"]}>
            {value.clientName}
          </span>
          {t("client-grid:ArchiveClientDialogQuestion2")}
        </span>
      </div>
    );

    const data = CreateConfirmationDialog(
      t("ArchivingClient"),
      content,
      t("Archive"),
      t("Cancel"),
      false,
      () => {
        clientDataStore.setIsDeleteLoading(true);
        webResource.remove(value.clientId).then(() => {
          clientDataStore.setIsDeleteLoading(false);
          readAllClientData();
        });
      }
    );

    transitionStore.showGlobalDialog(data);
  };

  const save = () => {
    if (errors && Object.keys(errors).length !== 0) return;

    const clientDefinition: ClientStoreData = {
      areaName: clientDataStore.selectedClient.areaName || "",
      clientId: clientDataStore.selectedClient.clientId || 0,
      clientName: clientDataStore.selectedClient.clientName,
      locationId: clientDataStore.selectedClient.locationId,
      locationName: clientDataStore.selectedClient.locationName,
    };

    if (clientDefinition.locationId === 0) {
      clientDefinition.locationId = null;
    }

    const clientData = ClientDataConverter.toOut(clientDefinition);

    let subscription = null;
    const clientIdExists = !!clientData.clientId;
    if (clientIdExists) {
      subscription = webResource.update(clientData);
    } else {
      clientData.clientId = undefined;
      subscription = webResource.save(clientData);
    }
    clientDataStore.setIsSaveLoading(true);
    subscription
      .then(
        (result: any) => {
          clearSelectedClient();

          readAllClientData();
        },
        (err: any) => {
          err.message = t("Could not save or update client");
          reportAjaxError(err);
        }
      )
      .finally(() => {
        clientDataStore.setIsSaveLoading(false);
      });
  };

  const clearSelectedClient = () => {
    clientDataStore.setSelectedClient(EmptyClientData);
    clientDataStore.closeExpanded();
  };

  const reportAjaxError = (ajaxError: any) => {
    const dialog: GlobalDialogData = NoConnectionModal(t);
    transitionStore.showGlobalDialog(dialog);
  };

  const columnData: ColumnData[] = [
    {
      header: t("Client name"),
      accesor: "clientName",
      width: "45%",
      HeaderComponent: (
        <SortHeader
          text={t("Client name")}
          selected={residentsSort[0].id === "clientName"}
          sortDirection={getSortDirection("clientName")}
          locked={clientDataStore.hasSelectedClient}
          onClick={() => {
            if (residentsSort[0].id === "clientName") {
              clientDataStore.setResidentsSort([
                { id: "clientName", desc: !residentsSort[0].desc },
              ]);
            } else {
              clientDataStore.setResidentsSort([
                { id: "clientName", desc: false },
              ]);
            }
            readAllClientData();
          }}
        />
      ),
      cellComponent: (value: any) => <TextCell text={value.clientName} />,
    },
    {
      header: t("Room"),
      cellComponent: LocationCell,
      HeaderComponent: (
        <SortHeader
          text={t("Room")}
          selected={residentsSort[0].id === "locationName"}
          sortDirection={getSortDirection("locationName")}
          locked={clientDataStore.hasSelectedClient}
          onClick={() => {
            if (residentsSort[0].id === "locationName") {
              clientDataStore.setResidentsSort([
                { id: "locationName", desc: !residentsSort[0].desc },
              ]);
            } else {
              clientDataStore.setResidentsSort([
                { id: "locationName", desc: false },
              ]);
            }
            readAllClientData();
          }}
        />
      ),
    },
    {
      header: t("Actions"),
      width: "188px",
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: any, index: number) => (
        <ActionsCell
          buttonVisibility={actionButtonsVisibility(
            index,
            clientDataStore.expandedResident
          )}
          buttonAvailability={actionButtonsAvailability(
            index,
            clientDataStore.expandedResident
          )}
          onButtonClick={(type: BUTTON_TYPE) => {
            switch (type) {
              case BUTTON_TYPE.EDIT: {
                const data: ClientStoreData = {
                  areaName: value.areaName,
                  clientId: value.clientId,
                  clientName: value.clientName,
                  locationId: value.locationId,
                  locationName: value.locationName,
                };

                clientDataStore.setSelectedClient(data);
                clientDataStore.setOldClientData(data);
                clientDataStore.modifiedSelectedClient = false;

                clientDataStore.setExpandedResident(index);
                break;
              }

              case BUTTON_TYPE.DELETE: {
                deleteClient(value);
                break;
              }
            }
          }}
        />
      ),
    },
  ];

  return (
    <BaseTable
      minWidth={1286}
      expandedRows={clientDataStore.expandedResident}
      data={clientDataStore.clients.slice()}
      columns={columnData}
      expandedRowComponent={
        <EditClient
          saveLocked={saveDisabled()}
          onSaveClick={() => {
            save();
          }}
          onCancelClick={() => {
            clientDataStore.closeExpanded();
          }}
        />
      }
    />
  );
}

export default inject(
  "clientDataStore",
  "transitionStore"
)(observer(ManageClientsGrid));
