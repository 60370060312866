import { ClientProfileStore } from "./store/store";
import { inject, observer } from "mobx-react";
import webApi from "./web";
import { ClientProfileAlertSettingsPage } from "./components/clientProfileAlertSettings";
import { observe, Lambda } from "mobx";
import { returnToClientProfile } from "./return-to-client-profile";
import { AlertData } from "./store/types";
import { useEffect } from "react";
import { UpdateClientProfileAlertSettingsCallOut } from "./store/typesOut";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface ClientProfileEditAlertsProps {
  clientProfileStore: ClientProfileStore;
}

function ClientProfileEditAlertsComponent(props: ClientProfileEditAlertsProps) {
  let _observationDisposers: Lambda[] = [];

  const { id } = useParams<{ id: string }>();

  const {state} = useLocation();

  const history = useNavigate();

  useEffect(() => {
    disposeOfObservations();
    recordObservations();
    return () => {
      disposeOfObservations();
    };
  }, []);

  const disposeOfObservations = () => {
    if (_observationDisposers.length === 0) {
      return;
    }
    _observationDisposers.forEach((disposeObservation) => disposeObservation());
  };

  const recordObservations = () => {
    const { clientProfileStore } = props;
    if (state) return;

    const { editingPeriodId } = state!;
    const { clientProfileSettings } = clientProfileStore;

    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      return;
    }

    _observationDisposers = [];
    const currentEditInterval =
      clientProfileSettings.getInterval(editingPeriodId);

    const observationDisposer = observe(currentEditInterval, (change) => {
      clientProfileSettings.hasChanges = true;
    });

    _observationDisposers.push(observationDisposer);
  };

  const render = () => {
    const { clientProfileStore } = props;

    if (clientProfileStore.clientProfileDetails.clientId === -1) {
      returnToClientProfile(history, Number(id));
      return <div />;
    }
    return (
      <ClientProfileAlertSettingsPage
        clientProfileStore={clientProfileStore}
        saveAlertsSettings={saveAlertsSettings}
        cancelAlertChanges={cancelAlertChanges}
      />
    );
  };

  const saveAlertsSettings = () => {
    const { editingPeriodId } = state;
    const { clientProfileDetails, clientProfileSettings } =
      props.clientProfileStore;

    const currentEditInterval =
      clientProfileSettings.getInterval(editingPeriodId);

    const data: UpdateClientProfileAlertSettingsCallOut = {
      clientId: clientProfileDetails.clientId!,
      clientProfileIntervalId: currentEditInterval.intervalId,
      alertsData: getAlertsDataToUpdate(),
      startTime: currentEditInterval.startTime,
    };

    webApi
      .updateClientProfileAlertSettings(data)
      .then(() => {
        clientProfileSettings.hasChanges = false;
        history(-1);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getAlertsDataToUpdate = (): AlertData[] => {
    const { clientProfileAlerts } = props.clientProfileStore;

    const alerts: AlertData[] = [];

    clientProfileAlerts.alerts.forEach((alert) => {
      const entry: AlertData = {
        alertId: alert.alertId,
        delay: alert.delay,
        sound: alert.sound,
        isActive: alert.isActive,
      };
      alerts.push(entry);
    });

    return alerts;
  };

  const cancelAlertChanges = () => {
    const {  clientProfileStore } = props;
    const { clientProfileSettings } = clientProfileStore;

    clientProfileSettings.hasChanges = false;
    history(-1);
  };

  return render();
}

export const ClientProfileEditAlerts = inject("clientProfileStore")(
  observer(ClientProfileEditAlertsComponent)
);
