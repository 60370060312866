import styles from "./page.module.css";

import { observer, inject } from "mobx-react";
import { PeripheralDataStore } from "./store/store";
import ManagePeripheralsGrid from "./components/ManagePeripheralsGrid";
import { useEffect } from "react";
import Header from "./components/header/header";
import { useTranslation } from "react-i18next";
import { TransitionStore } from "../../store";

interface IPeripheralProps {
  peripheralDataStore: PeripheralDataStore;
  transitionStore: TransitionStore;
}

function Peripherals(props: IPeripheralProps) {
  const { ready: readyCameraGrid } = useTranslation("peripheral-grid");
  const { ready: readyCommon } = useTranslation("common");
  const { transitionStore, peripheralDataStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = peripheralDataStore;

  const languagesLoaded = readyCameraGrid && readyCommon;

  useEffect(() => {
    return () => {
      peripheralDataStore.resetSelectedData();
      peripheralDataStore.closeExpanded();
      transitionStore.isPromptShown = false;
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      peripheralDataStore.setIsTranslationLoading(true);
    } else {
      peripheralDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  useEffect(() => {
    transitionStore.isPromptShown = peripheralDataStore.hasSelectedPeripheral;
  }, [peripheralDataStore.hasSelectedPeripheral]);

  const render = () => {
    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div
              id="grid-container"
              className={styles["manage-peripherals-grid-container"]}
            >
              <div className={styles["overflow-content"]}>
                <ManagePeripheralsGrid />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return render();
}

export default inject(
  "peripheralDataStore",
  "transitionStore"
)(observer(Peripherals));
