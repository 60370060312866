import styles from "./deletebuttoncell.module.css";

import { useTranslation } from "react-i18next";
import { Delete } from "../../../../../components/svg";
import classnames from "classnames";

export interface DeleteButtonCellProps {
  locked?: boolean;
  onDeleteClick(): void;
}

export function DeleteButtonCell(props: DeleteButtonCellProps) {
  const { t } = useTranslation("client-grid");

  const render = () => {
    const { onDeleteClick } = props;

    return (
      <div
        className={classnames(styles["inactive-clients-delete-button"], {
          [styles["locked"]]: props.locked,
        })}
        onClick={() => {
          if (!props.locked) onDeleteClick();
        }}
      >
        <Delete />
        {t("DeletePermanently")}
      </div>
    );
  };

  return render();
}
