export interface CategoriesProps {
  t(input: string): string;
}

export interface Category {
  total: number;
  count: number;
  imageSrc: string;
  color: string;
  name: string;
}

export function GetCategories(props: CategoriesProps): Category[] {
  return [
    {
      color: "rgb(40, 192, 24)",
      imageSrc: "/images/ic-out-of-room.png",
      name: "Out of room",
      total: 0,
      count: 0,
    },
    {
      color: "rgb(31, 135, 210)",
      imageSrc: "/images/ic-in-bathroom.png",
      name: "In bathroom",
      total: 0,
      count: 0,
    },
    {
      color: "rgb(97, 51, 115)",
      imageSrc: "/images/ic-in-room.png",
      name: "In room",
      total: 0,
      count: 0,
    },
    {
      color: "rgb(11, 45, 70)",
      imageSrc: "/images/ic-in-bed.png",
      name: "In bed",
      total: 0,
      count: 0,
    },
  ];
}
