import searchStyles from "./search-camera.module.css";
import gridStyles from "./manage-cameras-grid.module.css";

import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BaseTable, SortHeader } from "../../../components/table";
import { SORT_DIRECTION } from "../../../components/table/headers/SortHeader";
import { TextCell } from "../../../components/table/cells/TextCell";
import { CameraLocation } from "./cells";
import { CameraDistance } from "./camera-locations";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../components/table/headers/BasicHeader";
import EditCameraComponent from "./edit-camera/edit-camera";
import { CreateConfirmationDialog } from "../../../utils/ConfirmationDialog";
import Web from "../web";
import TransitionStore, {
  GlobalDialogData,
} from "../../../store/transition-store";
import { NoConnectionModal } from "../../../utils/NoConnectionDialog";
import { CameraDataStore, EmptyCamera } from "../store/store";
import { CameraData } from "../store/types";
import { AuthStore } from "../../../store";
import {
  ActionsCell,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../components/table/cells/ActionsCell";
import { CameraDataConverter } from "../store/convertor";
import { CameraDataIn } from "../store/typesIn";
import { ExpandedState } from "../../../components/table/table";

interface IManageCamerasGridProps {
  cameraDataStore?: CameraDataStore;
  transitionStore?: TransitionStore;
  authStore?: AuthStore;
}

function ManageCamerasGrid(props: IManageCamerasGridProps) {
  const { t } = useTranslation(["camera-grid"]);
  const cameraDataStore = props.cameraDataStore!;
  const authStore = props.authStore!;
  const transitionStore = props.transitionStore!;
  const { errors, selectedCamera, oldCameraData, validated } = cameraDataStore;
  const { isLoading } = transitionStore;

  useEffect(() => {
    fetchData();
  }, []);

  const getSortDirection = (id: string) => {
    if (cameraDataStore.sorting[0].id === id) {
      if (cameraDataStore.sorting[0].desc) {
        return SORT_DIRECTION.DESCENDING;
      } else return SORT_DIRECTION.ASCENDING;
    }
    return SORT_DIRECTION.NONE;
  };

  const columnData: any[] = [
    {
      header: t("Camera"),
      width: "25%",
      cellComponent: (value: any) => <TextCell text={value.cameraName} />,
      HeaderComponent: (
        <SortHeader
          text={t("Camera")}
          selected={cameraDataStore.sorting[0].id === "cameraName"}
          sortDirection={getSortDirection("cameraName")}
          locked={cameraDataStore.hasSelectedCamera}
          onClick={() => {
            sortColumn("cameraName");
          }}
        />
      ),
    },
    {
      header: t("RTSP"),
      width: "15%",
      accesor: "cameraRtsp",
      cellComponent: (value: any) => <TextCell text={value.cameraRtsp} />,
      HeaderComponent: <BasicHeader text={t("RTSP")} />,
    },
    {
      header: t("Room"),
      cellComponent: (value: any) => {
        return (
          <CameraLocation
            locations={value.locations}
            filteringLocationId={cameraDataStore.filteringLocationId}
            t={t}
          />
        );
      },
      HeaderComponent: (
        <SortHeader
          text={t("Room")}
          selected={cameraDataStore.sorting[0].id === "locations"}
          sortDirection={getSortDirection("locations")}
          locked={cameraDataStore.hasSelectedCamera}
          onClick={() => {
            sortColumn("locations");
          }}
        />
      ),
    },
    {
      header: t("Distance"),
      width: "15%",
      cellComponent: (value: any) => (
        <div>
          {CameraDistance(
            value.locations,
            t,
            cameraDataStore.filteringLocationId
          )}
        </div>
      ),
      HeaderComponent: (
        <SortHeader
          text={t("Distance")}
          selected={cameraDataStore.sorting[0].id === "distance"}
          sortDirection={getSortDirection("distance")}
          locked={cameraDataStore.hasSelectedCamera}
          onClick={() => {
            sortColumn("distance");
          }}
        />
      ),
    },
    {
      header: t("Generic"),
      accesor: "cameraGeneric",
      width: "87px",
      cellComponent: (value: CameraData) =>
        value.generic ? t("Yes") : t("No"),
    },
    {
      header: t("Actions"),
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      width: "140px",
      cellComponent: (value: any, index: number) => {
        return (
          <ActionsCell
            buttonVisibility={actionButtonsVisibility(
              index,
              cameraDataStore.expandedCamera
            )}
            buttonAvailability={actionButtonsAvailability(
              index,
              cameraDataStore.expandedCamera
            )}
            onButtonClick={(type) => onActionButtonClicked(value, index, type)}
          />
        );
      },
    },
  ];

  const onActionButtonClicked = (
    cameraData: CameraData,
    index: number,
    buttonType: BUTTON_TYPE
  ) => {
    switch (buttonType) {
      case BUTTON_TYPE.EDIT: {
        cameraDataStore.setExpandedCamera(index);
        cameraDataStore.setSelectedCamera({
          cameraId: cameraData.cameraId,
          cameraName: cameraData.cameraName,
          cameraRtsp: cameraData.cameraRtsp,
          cameraUrl: cameraData.cameraUrl,
          locations: cameraData.locations.map((location) => {
            return { ...location };
          }),
          generic: cameraData.generic,
        });
        cameraDataStore.setSelectedCameraHasChanges(false);
        break;
      }

      case BUTTON_TYPE.DELETE: {
        cameraDataStore.setSelectedCamera({
          cameraId: cameraData.cameraId,
          cameraName: cameraData.cameraName,
          cameraRtsp: cameraData.cameraRtsp,
          cameraUrl: cameraData.cameraUrl,
          locations: cameraData.locations,
          generic: cameraData.generic,
        });
        openConfirmationDialog(cameraData.cameraName);
        break;
      }
    }
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      editAvailability = true;
      deleteAvailability = true;
    }

    if (!authStore.isAdmin()) {
      deleteAvailability = false;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const saveDisabled = () => {
    let saveAvailability: boolean = false;

    if (cameraDataStore.isSaveLoading) return true;

    if (JSON.stringify(selectedCamera) === JSON.stringify(oldCameraData)) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = true;
    }

    if (!authStore.isAdmin()) {
      deleteVisibility = false;
    }

    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  const sortColumn = (id: string) => {
    if (cameraDataStore.sorting[0].id === id) {
      cameraDataStore.setSorting([
        {
          id: id,
          desc: !cameraDataStore.sorting[0].desc,
        },
      ]);
    } else {
      cameraDataStore.setSorting([{ id: id, desc: false }]);
    }
    fetchData();
  };

  const openConfirmationDialog = (cameraName: string) => {
    const content = (
      <div>
        <span>
          {t("camera-grid:DeleteCameraDialogQuestion1")}
          <span className={gridStyles["manage-cameras-grid-popup-client-name"]}>
            {cameraName}
          </span>
          {t("camera-grid:DeleteCameraDialogQuestion2")}
        </span>
      </div>
    );

    const data = CreateConfirmationDialog(
      t("Remove camera"),
      content,
      t("Remove"),
      t("Cancel"),
      false,
      onDialogOkClick
    );

    transitionStore?.showGlobalDialog(data);
  };

  const onDialogOkClick = () => {
    removeCamera(cameraDataStore.selectedCamera.cameraId!);
  };

  const fetchData = () => {
    cameraDataStore.setIsLoading(true);
    readAllCameraData(cameraDataStore.sorting).then((_response: any) => {
      cameraDataStore.resetExpandedCamera();
      cameraDataStore.setIsLoading(false);
    });
  };

  const readAllCameraData = (sorted: any[]) => {
    const cameraData = {
      SortOptions: sorted,
      LocationId: cameraDataStore.filteringLocationId,
    };

    cameraDataStore.setIsDataLoading(true);

    return Web.readAll(cameraData)
      .then((ajaxResponse: any) => {
        const response = ajaxResponse.data;

        const newCameraList = response.data.map((camera: CameraDataIn) => {
          return CameraDataConverter.toStore(camera);
        });

        cameraDataStore.setCameras(newCameraList);
        cameraDataStore.setLocations(response.availableLocations);
        cameraDataStore.setIsDataLoading(false);
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore?.showGlobalDialog(dialog);
        cameraDataStore.setIsDataLoading(false);
      });
  };

  const saveCamera = () => {
    const { selectedCameraHasChanges } = cameraDataStore;

    if (!selectedCameraHasChanges) {
      return;
    }

    const cameraData: any = CameraDataConverter.toOut(
      cameraDataStore.selectedCamera
    );

    cameraData.filterLocationId = cameraDataStore.filteringLocationId;

    cameraDataStore.setIsSaveLoading(true);
    Web.update(cameraData).then(
      () => {
        cameraDataStore.resetExpandedCamera();
        clearSelectedCamera();
        fetchData();
        cameraDataStore.setIsSaveLoading(false);
      },
      (err: any) => {
        err.message = t("Could not save or update camera");
        reportAjaxError(err);
        cameraDataStore.setIsSaveLoading(false);
      }
    );
  };

  const removeCamera = (id: number) => {
    cameraDataStore.setIsDeleteLoading(true);
    Web.remove(id).then(() => {
      fetchData();
      cameraDataStore.setIsDeleteLoading(false);
    });
  };

  const clearSelectedCamera = () => {
    cameraDataStore.setSelectedCamera(EmptyCamera);
  };

  const checkRtspVisibility = () => {
    if (authStore.isAdmin()) return;

    const rtspColumnIndex = columnData.findIndex((column) => {
      return column.accesor === "cameraRtsp";
    });

    if (rtspColumnIndex !== -1) columnData.splice(rtspColumnIndex, 1);
  };

  const checkGenericVisibility = () => {
    if (authStore.isAdmin()) return;

    const genericColumnIndex = columnData.findIndex((column) => {
      return column.accesor === "cameraGeneric";
    });
    if (genericColumnIndex !== -1) columnData.splice(genericColumnIndex, 1);
  };

  const reportAjaxError = (ajaxError: any) => {
    const dialog: GlobalDialogData = NoConnectionModal(t);

    transitionStore?.showGlobalDialog(dialog);
  };

  const render = () => {
    checkRtspVisibility();
    checkGenericVisibility();

    const { highlightCamera } = cameraDataStore;
    return (
      <BaseTable
        minWidth={1286}
        expandedRows={cameraDataStore.expandedCamera}
        data={cameraDataStore.cameras}
        columns={columnData}
        customRowId={(_index, rowData) => {
          if (rowData.cameraId === highlightCamera?.cameraId)
            return "selected-search-camera";
          return "";
        }}
        customRowClass={(_index, rowData) => {
          if (rowData.cameraId === highlightCamera?.cameraId)
            return searchStyles["selected-search-camera"];
          return "";
        }}
        expandedRowComponent={
          <EditCameraComponent
            saveLocked={saveDisabled()}
            onSaveClick={() => {
              saveCamera();
            }}
            onCancelClick={() => {
              cameraDataStore.resetExpandedCamera();
              fetchData();
            }}
          />
        }
      ></BaseTable>
    );
  };

  return render();
}
export default inject(
  "cameraDataStore",
  "transitionStore",
  "authStore"
)(observer(ManageCamerasGrid));
