import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

import Peripherals from "./page.highorder";

const ProtectedPeripherals = (props: any) => (
  <AuthorizedRoute
    requiredRoles={[ROLES.ADMIN]}
    exact
    path="/"
    component={Peripherals}
    {...props}
  />
);

const peripheralsRoute = (
  <Route path="/manage-peripherals" element={<ProtectedPeripherals />} />
);

export default peripheralsRoute;
