import styles from "./sensor-status.module.css";

import { observer } from "mobx-react";
import classNames from "classnames";
import * as moment from "moment";
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../svg/check.svg";
import { ReactComponent as Undefined } from "../../svg/undefined.svg";
import { ReactComponent as BatteryLow } from "../../svg/lowbattery.svg";
import { ReactComponent as Storage } from "../../svg/storage.svg";
import { ReactComponent as Inactive } from "../../svg/inactive.svg";
import { ReactComponent as Disconnected } from "../../svg/disconnected.svg";
import { ReactComponent as Dash } from "../../svg/dash.svg";
import { ReactComponent as NewIcon } from "../../svg/new-icon.svg";
import { WarningSign } from "../../../../components/svg";
import OverflowLabel from "../../../../components/OverflowLabel";

interface SensorStatus {
  statusId: number;
  inactivityPeriod: string;
}

export enum SensorState {
  Undefined = 0xff,
  Active = 0x0001,
  Inactive = 0x0002,
  Storage = 0x0004,
  Disconnected = 0x0008,
  BatteryLow = 0x0010,
  NotAvailable = 0x0020,
  SetForTooLong = 0x0040,
  New = 0x0100,
}

export function SensorStatus(props: SensorStatus) {
  const { t } = useTranslation("sensor-grid");

  const render = () => {
    const { statusId, inactivityPeriod } = props;

    const statusIcon = getIcon(statusId);
    const statusText = getText(statusId, inactivityPeriod);
    const hasExtraStatus = isComposedState(statusId);

    const Icon = {
      Data: statusIcon,
    };

    return (
      <div
        className={classNames(styles["sensor-status"], {
          [styles["orange"]]: isOrangeStatus(statusId),
          [styles["red"]]: isRedStatus(statusId),
          [styles["blue"]]: isBlueStatus(statusId),
          [styles["with-extra-info"]]: hasExtraInfo(statusId),
        })}
      >
        <div
          className={classNames(
            styles["status-icon"],
            styles["sensor-status"],
            {
              [styles["orange"]]: isOrangeStatus(statusId),
              [styles["red"]]: isRedStatus(statusId),
              [styles["blue"]]: isBlueStatus(statusId),
            }
          )}
        >
          <Icon.Data />
        </div>
        <div className={styles["status-text-cell"]}>
          <div
            className={`${styles["status-text"]} ${styles["text-visibility"]}`}
          >
            <OverflowLabel text={statusText} />
          </div>
          {hasExtraStatus && (
            <div
              className={`${styles["substatus-text"]} ${styles["text-visibility"]}`}
            >
              <OverflowLabel text={getSubstatus(statusId)} />
            </div>
          )}
        </div>
      </div>
    );
  };

  const getIcon = (id: number) => {
    if (hasFlag(id, SensorState.Undefined)) return Undefined;
    if (hasFlag(id, SensorState.Disconnected)) return Disconnected;
    if (hasFlag(id, SensorState.Inactive)) return Inactive;
    if (hasFlag(id, SensorState.SetForTooLong)) return WarningSign;
    if (hasFlag(id, SensorState.BatteryLow)) return BatteryLow;
    if (hasFlag(id, SensorState.Active)) return Check;
    if (hasFlag(id, SensorState.Storage)) return Storage;
    if (hasFlag(id, SensorState.NotAvailable)) return Dash;
    if (hasFlag(id, SensorState.New)) return NewIcon;

    return Undefined;
  };

  const hasFlag = (state: number, expectedState: SensorState): boolean => {
    return (state & expectedState) === expectedState;
  };

  const getText = (id: number, inactivityPeriod: string) => {
    if (hasFlag(id, SensorState.Undefined)) return t("Unknown");
    if (hasFlag(id, SensorState.Disconnected)) return t("Disconnected");
    if (hasFlag(id, SensorState.Inactive))
      return t("Inactive") + ": " + formatStatusSince(inactivityPeriod, 0);
    if (hasFlag(id, SensorState.SetForTooLong)) return t("SetForTooLong");
    if (hasFlag(id, SensorState.BatteryLow)) return t("Battery low");
    if (hasFlag(id, SensorState.Active)) return t("Active");
    if (hasFlag(id, SensorState.Storage)) return t("Storage");
    if (hasFlag(id, SensorState.NotAvailable)) return "";
    if (hasFlag(id, SensorState.New)) return "New";

    return t("Unknown");
  };

  const isComposedState = (id: number): boolean => {
    return (
      id !== SensorState.Undefined &&
      hasFlag(id, SensorState.BatteryLow) &&
      (hasFlag(id, SensorState.Inactive) ||
        hasFlag(id, SensorState.SetForTooLong))
    );
  };

  const getSubstatus = (id: number) => {
    if (isComposedState(id)) return t("Battery low");
    return "";
  };

  const isRedStatus = (id: number) => {
    return getColor(id) === "red";
  };

  const isOrangeStatus = (id: number) => {
    return getColor(id) === "orange";
  };

  const isBlueStatus = (id: number) => {
    return getColor(id) === "blue";
  };

  const getColor = (id: number) => {
    if (id === SensorState.Undefined) return "blue";

    if (
      hasFlag(id, SensorState.Inactive) ||
      hasFlag(id, SensorState.Disconnected)
    )
      return "red";

    if (
      hasFlag(id, SensorState.BatteryLow) ||
      hasFlag(id, SensorState.SetForTooLong)
    )
      return "orange";

    return "blue";
  };

  const hasExtraInfo = (id: number): boolean => {
    return id !== SensorState.Undefined && hasFlag(id, SensorState.Inactive);
  };

  const formatStatusSince = (statusSince: string, defaultValue: any) => {
    const timeSpan = moment.duration(statusSince);

    if (timeSpan.asMinutes() < 1) {
      return defaultValue;
    }

    if (timeSpan.asHours() < 1) {
      return `${timeSpan.minutes()}m`;
    }

    if (timeSpan.asDays() < 1) {
      return `${timeSpan.hours()}h ${timeSpan.minutes()}m`;
    }

    return `${Math.trunc(timeSpan.asDays())}d ${timeSpan.hours()}h`;
  };

  return render();
}

export default observer(SensorStatus);
