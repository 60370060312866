import styles from "./client-profile-camera-settings-content.module.css";

import classNames from "classnames";
import { ClientProfileCameraSettings } from "../../store/types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

interface ClientProfileSettingsContentProps {
  cameraSettings: ClientProfileCameraSettings;
}

export enum CameraViewsOnSmartPhone {
  ALLOW_ON_ALARMS = 1,
  ALLOW_ANYTIME,
}

function ClientProfileCameraSettingsContent(
  props: ClientProfileSettingsContentProps
) {
  const { t } = useTranslation("client-profile");
  const { cameraSettings } = props;

  const render = () => {
    return (
      <div>
        <ul className={styles["flex-container"]}>
          {renderCamerasSettingsTitle()}
          {renderCameraRadioSettings()}
        </ul>
      </div>
    );
  };

  const renderCamerasSettingsTitle = () => {
    return (
      <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
        <div className={styles["title"]}>
          {t("Video camera views on smartphone")}
        </div>
      </li>
    );
  };

  const renderCameraRadioSettings = () => {
    return (
      <li className={`${styles["item"]} ${styles["even-tab"]}`}>
        <div className={styles["settings-col"]}>
          <div className={styles["settings-radio"]}>
            <input
              className={styles["radio-input"]}
              type="radio"
              checked={!isCameraViewsOnAnytime()}
              onChange={() =>
                changeCameraSettings(CameraViewsOnSmartPhone.ALLOW_ON_ALARMS)
              }
            ></input>
            <div
              onClick={() =>
                changeCameraSettings(CameraViewsOnSmartPhone.ALLOW_ON_ALARMS)
              }
              className={styles["time-alerts"]}
            >
              <span
                className={classNames({
                  [styles["radio-text"]]: isCameraViewsOnAnytime(),
                  [styles["selected-radio-text"]]: !isCameraViewsOnAnytime(),
                })}
              >
                {t("Allow camera views only on alerts")}
              </span>
            </div>
          </div>

          <div className={styles["settings-radio"]}>
            <input
              className={styles["radio-input"]}
              type="radio"
              checked={isCameraViewsOnAnytime()}
              onChange={() =>
                changeCameraSettings(CameraViewsOnSmartPhone.ALLOW_ANYTIME)
              }
            ></input>
            <div
              onClick={() =>
                changeCameraSettings(CameraViewsOnSmartPhone.ALLOW_ANYTIME)
              }
              className={styles["time-alerts"]}
            >
              <span
                className={classNames({
                  [styles["radio-text"]]: !isCameraViewsOnAnytime(),
                  [styles["selected-radio-text"]]: isCameraViewsOnAnytime(),
                })}
              >
                {t("Allow camera views anytime")}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  };

  const isCameraViewsOnAnytime = () => {
    return (
      cameraSettings.optionTypeId === CameraViewsOnSmartPhone.ALLOW_ANYTIME
    );
  };

  const changeCameraSettings = (type: CameraViewsOnSmartPhone) => {
    cameraSettings.optionTypeId = type;
  };

  return render();
}
export default observer(ClientProfileCameraSettingsContent);
