import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ClientDataStore } from "../../../store/store";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface ClientNameInputProps {
  clientDataStore?: ClientDataStore;
}

const CLIENT_NAME_MAXIMUM_LENGTH = 99;

function ClientNameInput(props: ClientNameInputProps) {
  const clientDataStore = props.clientDataStore!;
  const { t } = useTranslation("client-grid");
  const clientName = clientDataStore!.selectedClient.clientName;
  const { errorMessages } = clientDataStore;

  return (
    <TextFieldInput
      autoFocus
      value={clientName}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["clientName"] &&
          t("Required information")) ||
        ""
      }
      label={t("Client name").toUpperCase()}
      placeholder={t("Client name")}
      onChange={(event) => {
        const { value } = event.target;
        if (value.length > CLIENT_NAME_MAXIMUM_LENGTH) return;

        clientDataStore!.setSelectedClientName(value);
        clientDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        clientDataStore.validateSelectedClient("clientName");
        clientDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("clientDataStore")(observer(ClientNameInput));
