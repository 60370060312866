import { inject, observer } from "mobx-react";
import { IconUploadField } from "../../../../../components/form/edit-fields/icon-upload-field/IconUploadField";
import { AlertDataStore, DEFAULT_DELAY_ICON } from "../../../store/store";
import { alertDataSchema } from "../../../validation";

export interface IBrowseForDelayIconProps {
  alertDataStore?: AlertDataStore;
}

function BrowseForDelayIcon(props: IBrowseForDelayIconProps) {
  const alertDataStore = props.alertDataStore!;

  const validate = () => {
    let schema = alertDataSchema;

    alertDataStore.validateSelectedAlert(schema);
  };

  const onIconChanged = (data: FormData): void => {
    alertDataStore.setSelectedDelayIcon(data);
    validate();
  };

  const svgIcon = new Blob([DEFAULT_DELAY_ICON], { type: "image/svg+xml" });

  return (
    <IconUploadField
      currentIcon={alertDataStore.selectedAlert.delayIcon}
      iconMemberName="delayIcon"
      onIconChanged={onIconChanged}
      defaultIcon={svgIcon}
    />
  );
}

export default inject("alertDataStore")(observer(BrowseForDelayIcon));