import styles from "./login.module.css";

import { useState, KeyboardEvent, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { AuthStore, LoginStore } from "../../store";
import webResource from "./web";
import { NoConnectionModal } from "../../utils/NoConnectionDialog";
import { ILoginModel, LoginResponse } from "./types";
import TransitionStore, {
  GlobalDialogData,
} from "../../store/transition-store";
import { LogoIcon } from "./svg";
import { LoginButton } from "./components/login-button/login-button";
import { PasswordField } from "./components/input-fields/password-field";
import { UsernameField } from "./components/input-fields/username-field";
import { Footer } from "./components/footer/footer";
import FeatureStore from "../../store/feature-store";
import { runInAction } from "mobx";

interface LoginPageProps {
  authStore?: AuthStore;
  loginStore?: LoginStore;
  transitionStore?: TransitionStore;
  featureStore?: FeatureStore;
}

export function LoginPage(props: LoginPageProps) {
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(" ");
  const { t, ready } = useTranslation("login");

  useEffect(() => {
    const { transitionStore } = props;
    if (!ready) {
      transitionStore!.setLoading(true);
    } else {
      transitionStore!.setLoading(false);
    }
  });

  const render = () => {
    const { authStore } = props;
    const isAuthenticated = authStore!.isAuthenticated;

    if (isAuthenticated && isLoggingIn && authStore!.authentication.role) {
      return redirectOnLogin();
    }

    return (
      <>
        {ready && (
          <div className={styles["login-page"]}>
            <div className={styles["login-container"]}>
              <div className={styles["form"]}>
                <div className={styles["title-container"]}>
                  <LogoIcon />
                  <div className={styles["title"]}>{t("projectTile")}</div>
                </div>
                <div className={styles["sub-title-container"]}>
                  <div className={styles["sub-title"]}>{t("subtitle")}</div>
                </div>

                <div className={`${styles["input-box"]} ${styles["username"]}`}>
                  <div className={styles["label"]}>{t("username")}</div>
                  <UsernameField
                    onChange={handleUsernameChange}
                    value={props.loginStore!.credentials.username}
                    onKeyUpEvent={handleKeyUp}
                  />
                </div>
                <div className={`${styles["input-box"]} ${styles["password"]}`}>
                  <div className={styles["label"]}>{t("password")}</div>
                  <PasswordField
                    onChange={handlePasswordChange}
                    value={props.loginStore!.credentials.password}
                    onKeyUpEvent={handleKeyUp}
                  />
                </div>
                {renderMessage()}
                <LoginButton
                  disabled={isLoginDisabled()}
                  clickEvent={handleLogin}
                />
              </div>
            </div>
            <div className={styles["footer-container"]}>
              <Footer />
            </div>
          </div>
        )}
      </>
    );
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    if (isLoginDisabled()) return;

    const { loginStore } = props;
    const { username, password } = loginStore!.credentials;

    requestLogin({ username, password });
  };

  const renderMessage = () => {
    if (messageType === "error")
      return (
        <div className={styles["message-container"]}>
          <div className={`${styles["message"]} ${styles["error"]}`}>
            {message}
          </div>
        </div>
      );

    return (
      <div className={styles["message-container"]}>
        <div className={styles["message"]}>{message}</div>
      </div>
    );
  };

  const isLoginDisabled = () => {
    return (
      props.loginStore!.credentials.username === "" ||
      props.loginStore!.credentials.password === ""
    );
  };

  const redirectOnLogin = () => {
    const { authStore } = props;
    const { featureStore } = props;

    const isAdmin = authStore!.isAdmin();

    if (!featureStore!.features.initialConfigCompleted && isAdmin) {
      return <Navigate to={{ pathname: "/manage-maris" }} />;
    }

    if (featureStore!.features.initialConfigCompleted && isAdmin) {
      return <Navigate to={{ pathname: "/manage-sensors" }} />;
    }

    if (isAdmin) {
      return <Navigate to={{ pathname: "/manage-sensors" }} />;
    }

    return <Navigate to={{ pathname: "/residents-overview" }} />;
  };

  const handleUsernameChange = (value: any) => {
    props.loginStore!.setUsername(value);
    setMessage("");
  };

  const handlePasswordChange = (value: any) => {
    props.loginStore!.setPassword(value);
    setMessage("");
  };

  const handleLogin = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const { loginStore } = props;
    const username = loginStore!.credentials.username;
    const password = loginStore!.credentials.password;

    requestLogin({ username, password });
  };

  const requestLogin = (credentials: ILoginModel) => {
    props.loginStore!.validationErrors.clear();

    const { authStore, featureStore } = props;
    setLoggingIn(true);
    setMessage(t("loggingInMessage"));
    setMessageType("normal");

    webResource
      .requestAuthentication(credentials)
      .then((ajaxResponse: any) => {
        const data = ajaxResponse.data as LoginResponse;

        authStore!.authenticate(
          data.username,
          data.role,
          data.firstName,
          data.lastName
        );
        authStore!.setToken(data.token);
      })
      .then(() => {
        webResource.readFeatureList().then((data) => {
          runInAction(() => {
            featureStore!.features = data.data.featureList;
          });
        });
      })
      .then(() => {
        setLoggingIn(false);
      })
      .catch((ex: any) => {
        handleErrors(ex);
        authStore!.clearAuthentication();
      });
  };

  const handleErrors = (ex: any) => {
    const { transitionStore } = props;
    const callResponse = ex.response;
    const callResponseData = ex.data;

    if (callResponse.status === 401) {
      if (callResponseData && callResponseData.Authentication) {
        const { errorMessage } = callResponseData.Authentication.errors[0];

        setMessageType("error");
        switch (errorMessage) {
          case "No permissions":
            setMessage(t("noPermissions"));
            break;

          case "Invalid license":
            setMessage(t("invalidLicense"));
            break;

          case "Configuration not finalized":
            setMessage(t("configurationNotFinalized"));
            break;

          default:
            setMessage(t("wrongCredentials"));
            break;
        }
      }
    }

    if (callResponse.status === 500) {
      ex.message = t("TheServerEncounteredAnInternalErrorPleaseTryAgain");
      const dialog: GlobalDialogData = NoConnectionModal(t);

      transitionStore?.showGlobalDialog(dialog);
    }
  };

  return render();
}

export default inject("featureStore")(
  inject("authStore")(
    inject("loginStore")(inject("transitionStore")(observer(LoginPage)))
  )
);
