import { CameraData, ILocationData } from "./types";
import { CameraDataIn, LocationDataIn } from "./typesIn";
import { CameraDataOut, LocationDataOut } from "./typesOut";

export interface CameraDataConverter {
  toStore(inData: CameraDataIn): CameraData;
  toOut(storeData: CameraData): CameraDataOut;
}

export interface LocationDataConverter {
  toStore(inData: LocationDataIn): ILocationData;
  toOut(storeData: ILocationData): LocationDataOut;
}

export const CameraDataConverter: CameraDataConverter = {
  toStore(inData: CameraDataIn) {
    return {
      cameraId: inData.cameraId,
      cameraName: inData.cameraName,
      cameraRtsp: inData.cameraRtsp,
      cameraUrl: inData.cameraUrl,
      generic: inData.generic,
      locations: inData.locations.map((value) => {
        return LocationDataConverter.toStore(value);
      }),
    };
  },
  toOut(storeData: CameraData): CameraDataOut {
    return {
      cameraId: storeData.cameraId || 0,
      cameraName: storeData.cameraName,
      rtsp: storeData.cameraRtsp,
      generic: storeData.generic,
      url: storeData.cameraUrl,
      locations: storeData.locations.map((value) => {
        return LocationDataConverter.toOut(value);
      }),
    };
  },
};

export const LocationDataConverter: LocationDataConverter = {
  toStore(inData: LocationDataIn) {
    return {
      locationId: inData.locationId,
      locationName: inData.locationName,
      locationPath: inData.locationPath,
      distance: inData.distance,
    };
  },
  toOut(storeData: ILocationData): LocationDataOut {
    return {
      locationId: storeData.locationId,
      locationName: storeData.locationName,
      locationPath: storeData.locationPath,
      distance: storeData.distance,
    };
  },
};
