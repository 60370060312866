import styles from "./client-profile-alert-settings-content.module.css";

import ClientsSettingsAlertGroup from "../../cells/client-settings-alert-status";
import { TimePicker } from "../../../../components/time-picker";
import {
  ClientProfileAlertsData,
  ClientProfileSettings,
} from "../../store/types";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { TransitionStore } from "../../../../store";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom";

interface ClientProfileSettingsContentProps {
  clientSettings: ClientProfileSettings;
  clientProfileAlerts: ClientProfileAlertsData;
  canUpdateVoiceAlerts: boolean;
  transitionStore?: TransitionStore;
  clientId: number;
  isDaytimeInterval: boolean;
}

function ClientProfileAlertSettingsContent(
  props: ClientProfileSettingsContentProps
) {
  const { t } = useTranslation("client-profile");
  const { clientSettings, clientProfileAlerts, canUpdateVoiceAlerts } =
    props;
  const { state } = useLocation();
  const { editingPeriodId } = state;

  const render = () => {
    if (!props.clientSettings.alerts) {
      const linkArr = window.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Navigate to={link} />;
    }
    return (
      <div>
        <ul className={styles["flex-container"]}>
          {renderTimeIntervalTitle()}
          {renderTimeIntervalSettings()}

          {renderAlertsDetailsTitle()}
          {renderAlertsDetailsSettings()}
        </ul>
      </div>
    );
  };

  const renderTimeIntervalTitle = () => {
    let currentInterval = clientSettings.getInterval(editingPeriodId);

    const timePeriod = currentInterval.isDaytimeInterval
      ? "Day time interval"
      : "Night time interval";

    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(timePeriod)}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Start hour")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("End hour")}
        </div>
      </li>
    );
  };

  const renderTimeIntervalSettings = () => {
    const { getInterval } = clientSettings;
    const { editingPeriodId } = state;
    const interval = getInterval(editingPeriodId);
    const endHourAlertTimePrefix = interval.isDaytimeInterval ? "Night" : "Day";

    return (
      <li
        className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
      >
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-1"]}`}
        >
          {t("Active interval")}
        </div>
        <div className={styles["settings-col-2"]}>
          <TimePicker
            value={getInterval(editingPeriodId).startTime}
            limitAt={getEndHour(editingPeriodId)}
            onChange={(value) => {
              getInterval(editingPeriodId).startTime = value;
            }}
          />
        </div>
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-3"]}`}
        >
          {getEndHour(editingPeriodId) +
            " - " +
            t(endHourAlertTimePrefix + " time alerts start")}
        </div>
      </li>
    );
  };

  const renderAlertsDetailsTitle = () => {
    const { getInterval } = clientSettings;
    const { editingPeriodId } = state;
    const interval = getInterval(editingPeriodId);
    const timePeriod = interval.isDaytimeInterval
      ? "Day time alerts"
      : "Night time alerts";

    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(timePeriod)}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Active")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("Alert Settings")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-4"]}`}
        >
          {t("Sensors")}
        </div>
      </li>
    );
  };

  const renderAlertsDetailsSettings = () => {
    return (
      <li className={`${styles["item"]} ${styles["settings-even-tab"]}`}>
        <ClientsSettingsAlertGroup
          clientSettings={clientSettings}
          clientProfileAlerts={clientProfileAlerts}
          canUpdateVoiceAlerts={canUpdateVoiceAlerts}
        />
      </li>
    );
  };

  const getEndHour = (periodId: number) => {
    const interval = clientSettings.getInterval(periodId);

    const daytimeInterval = clientSettings.getDaytimeInterval();
    const nighttimeInterval = clientSettings.getNighttimeInterval();

    const endHour = interval.isDaytimeInterval
      ? nighttimeInterval.startTime
      : daytimeInterval.startTime;

    return endHour.length > 5 ? endHour.substring(0, 5) : endHour;
  };

  return render();
}

export default inject("transitionStore")(
  observer(ClientProfileAlertSettingsContent)
);
