import "./tab-panel.css";
import * as React from "react";
import { useEffect } from "react";
import { TabItem, TabItemProps } from "./tab-item/tab-item";
import classnames from "classnames";

export interface TabPanelProps {
  activeKey: string;
  children?: React.ReactNode;
  layout?: string;
  onItemChange?(key: string): void;
}

export function TabPanel(props: TabPanelProps) {
  useEffect(() => {}, []);

  const handleItemChange = (key: string) => {
    const { onItemChange, activeKey } = props;

    if (key !== activeKey) onItemChange!(key);
  };

  const buttons = () => {
    const items: any[] = [];
    const { children, activeKey } = props;

    React.Children.forEach(children, (element: any) => {
      if (
        !React.isValidElement(element) ||
        element.type !== (<TabItem itemKey="" />).type
      ) {
        items.push(element);
        return;
      }
      const newProps: TabItemProps & React.Attributes = {
        ...(element.props as TabItemProps),
      };

      newProps.onClick = handleItemChange;
      newProps.key = newProps.itemKey;
      if (newProps.itemKey !== activeKey) {
        newProps.isInactive = true;
      }
      const newChild = React.cloneElement(element, newProps);

      items.push(newChild);
    });

    return items;
  };

  const render = () => {
    const { layout } = props;
    return (
      <div
        className={classnames("tabs-panel", {
          vertical: layout === "vertical",
        })}
      >
        {buttons()}
      </div>
    );
  };

  return render();
}
