import styles from "./overflow-header.module.css";

import { useState } from "react";
import { Tooltip } from "@mui/material";
import { withStyles } from "@material-ui/styles";

interface OverflowLabelProps {
  text: string;
  tooltip?: boolean;
}

const OverflowLabelTooltip = withStyles({
  tooltip: {
    backgroundColor: "#173544",
  },
})(Tooltip);

const OverflowLabel = (props: OverflowLabelProps): any => {
  const { text } = props;
  const [headerRef, setHeaderRef] = useState<HTMLDivElement | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <OverflowLabelTooltip title={showTooltip ? text : ""}>
      <div
        ref={(ref) => {
          setHeaderRef(ref);
        }}
        onMouseMove={() => {
          let toolTipVisible = false;
          if (headerRef)
            if (headerRef.children.length)
              if (headerRef.children[0].scrollWidth > headerRef.clientWidth) {
                toolTipVisible = true;
              }
          setShowTooltip(toolTipVisible);
        }}
      >
        <div className={styles["overflow-label"]}>{text}</div>
      </div>
    </OverflowLabelTooltip>
  );
};

export default OverflowLabel;
