import { ReactComponent as LowBattery } from "../modules/manage-sensors/svg/lowbattery.svg";
import { ReactComponent as Inactive } from "../modules/manage-sensors/svg/inactive.svg";
import { ReactComponent as Disconnected } from "../modules/manage-sensors/svg/disconnected.svg";
import { ReactComponent as WarningIcon } from "../modules/manage-sensors/svg/warning.svg";

export enum SensorState {
  Undefined = 0xff,
  Active = 0x0001,
  Inactive = 0x0002,
  Storage = 0x0004,
  Disconnected = 0x0008,
  BatteryLow = 0x0010,
  NotAvailable = 0x0020,
  SetForTooLong = 0x0040,
}

export enum WARNING_TYPE {
  Red,
  Yellow,
}

export const getStatusText = (status: number) => {
  if (status === SensorState.Undefined) return "";

  if (hasFlag(status, SensorState.Inactive)) return "Inactive";

  if (hasFlag(status, SensorState.Disconnected)) return "Disconnected";

  if (hasFlag(status, SensorState.SetForTooLong)) return "SetForTooLong";

  if (hasFlag(status, SensorState.BatteryLow)) return "Battery low";

  return "";
};

export const getTypeText = (type: number, id: string, template: string) => {
  switch (type) {
    case 0:
      return "Unknown";
    case 1:
      return "Bed";
    case 2:
      return "Room";
    case 4:
      return "Door";
    case 8:
      return "Bathroom";
    case 16:
      return "Chair";
    case 32:
      return "Pendant";
    case 64:
      return "Acoustic";
    case 128:
      return "OtherSensor";
    case 16773120:
      if (template && template !== "") {
        return template;
      } else {
        return "VirtualSensor";
      }
    default:
      return id;
  }
};

export const getWarningType = (issues: any) => {
  let type = WARNING_TYPE.Yellow;

  if (issues && issues.length > 0)
    issues.forEach((issue: any) => {
      if (!isWarning(issue.status)) {
        type = WARNING_TYPE.Red;
      }
    });

  return type;
};

export const getIcon = (status: number): any => {
  if (status === SensorState.Undefined) return;

  if (hasFlag(status, SensorState.Inactive)) return Inactive;
  if (hasFlag(status, SensorState.Disconnected)) return Disconnected;
  if (hasFlag(status, SensorState.SetForTooLong)) return WarningIcon;
  if (hasFlag(status, SensorState.BatteryLow)) return LowBattery;
};

export const isWarning = (status: number) => {
  if (status === SensorState.Undefined) return false;

  return (
    hasFlag(status, SensorState.Inactive) === false &&
    hasFlag(status, SensorState.Disconnected) === false
  );
};

const hasFlag = (state: number, expectedState: SensorState): boolean => {
  return (state & expectedState) === expectedState;
};
