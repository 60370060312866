import "./locationcell.css";
import { ReactComponent as Dash } from "../../svg/dash.svg";
import OverflowLabel from "../../OverflowLabel";

export function LocationCell(props: any) {
  if (props.areaName === "") {
    return (
      <div className="location-cell">
        <div className="dash-icon">
          <Dash />
        </div>
      </div>
    );
  } else {
    return (
      <div className="location-cell">
        <div className="location-cell-areaName">
          <OverflowLabel text={props.areaName}></OverflowLabel>
        </div>
        <div className="location-cell-locationName">
          <OverflowLabel text={props.locationName}></OverflowLabel>
        </div>
      </div>
    );
  }
}
