import { ObjectSchema } from "joi";
import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { AlertData, DelayType, IAlertData } from "./types";
import { ExpandedState } from "../../../components/table/table";

export const EmptyAlert: AlertData = observable({
  alertId: -1,
  alertName: "",
  alternateText: "",
  triggerPort: undefined,
  delayType: 0,
  icon: "",
  delayIcon: "",
  iconChanged: false,
  newIcon: null,
  delayIconChanged: false,
  newDelayIcon: null,
});

export const DEFAULT_INSTANT_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path fill="rgb(247, 59, 15)" d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>';

export const DEFAULT_DELAY_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path fill="rgb(244, 174, 34)" d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>';
  
export const DEFAULT_BLACK_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path  d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>';

export interface IAlertsSort {
  id: string;
  desc: boolean;
}

export class AlertsSort implements IAlertsSort {
  public id: string;
  public desc: boolean;

  constructor(id: string, desc: boolean) {
    this.id = id;
    this.desc = desc;
  }
}

export class AlertDataStore {
  constructor() {
    makeObservable(this, {
      alerts: observable,
      delayTypes: observable,
      validationErrors: observable,
      selectedAlert: observable,
      highlightAlert: observable,
      alertsSort: observable,
      selectedAlertHasChanges: observable,
      loading: observable,
      expandedAlertIndex: observable,
      errors: observable,
      errorMessages: observable,
      validated: observable,
      selectedTab: observable,
      searchValue: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      isNewAlert: computed,

      setAlertsSort: action,
      setAlerts: action,
      setDelayTypes: action,
      closeExpanded: action,
      setSelectedAlert: action,
      setSelectedAlertName: action,
      setSelectedTriggerPort: action,
      setSelectedDelayType: action,
      setSelectedAlertHasChanged: action,
      resetSelectedData: action,
      setExpandedAlertIndex: action,
      addNewEmptyAlert: action,
      setHilightAlert: action,
      validateSelectedAlert: action,
      setValidated: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
      setSelectedTab: action,
    });
  }

  public selectedAlert: AlertData = {
    alertId: -1,
    alertName: "",
    alternateText: "",
    triggerPort: undefined,
    delayType: 0,
    icon: "",
    delayIcon: "",
    iconChanged: false,
    newIcon: null,
    delayIconChanged: false,
    newDelayIcon: null,
  };

  public oldAlertData: any = {
    alertId: -1,
    alertName: "",
    triggerPort: undefined,
    delayType: 0,
    icon: "",
    delayIcon: "",
    newIcon: null,
    newDelayIcon: null,
  };

  public highlightAlert: IAlertData | null = {
    alertId: -1,
    alertName: "",
    triggerPort: undefined,
    delayType: 0,
    icon: "",
    delayIcon: "",
  };

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;

  public validated: boolean = true;
  public errorMessages: FlatErrorValidationResult | null = null;
  public validationErrors: ObservableMap<{}> = observable.map();
  public selectedAlertHasChanges: boolean = false;
  public alerts: AlertData[] = [];
  public delayTypes: DelayType[] = [];
  public alertsSort: IAlertsSort[] = [new AlertsSort("alertName", false)];
  public expandedAlertIndex: number = ExpandedState.COLLAPSED;
  public loading: boolean = false;
  public errors: FlatErrorValidationResult | null = null;
  public selectedTab: string = "manage-alerts";
  public searchValue: string = "";

  public resetSelectedData() {
    this.selectedAlert = EmptyAlert;
    this.highlightAlert = null;
    this.oldAlertData = EmptyAlert;
  }

  public setSelectedAlert(value: AlertData) {
    this.selectedAlert = { ...value };
    const { iconChanged, delayIconChanged, ...rest } = this.selectedAlert;
    this.oldAlertData = { ...rest };
  }

  public setAlerts(value: AlertData[]) {
    this.alerts = value;
  }

  public setDelayTypes(value: DelayType[]) {
    this.delayTypes = value;
  }

  public get hasSelectedAlert() {
    return this.expandedAlertIndex !== ExpandedState.COLLAPSED;
  }

  public closeExpanded() {
    this.expandedAlertIndex = ExpandedState.COLLAPSED;
  }

  public setExpandedAlertIndex(index: number) {
    this.expandedAlertIndex = index;
  }

  public setAlertsSort(value: IAlertsSort[]) {
    this.alertsSort = value;
  }

  public get isNewAlert() {
    return this.selectedAlert.alertId === null;
  }

  public setSelectedAlertName(value: string) {
    this.selectedAlert = { ...this.selectedAlert };
    this.selectedAlert.alertName = value;
    this.selectedAlertHasChanges = true;
  }

  public setSelectedAlternateText(value: string) {
    this.selectedAlert = { ...this.selectedAlert };
    this.selectedAlert.alternateText = value;
    this.selectedAlertHasChanges = true;
  }

  public setSelectedTriggerPort(value: number | undefined) {
    this.selectedAlert = { ...this.selectedAlert };
    this.selectedAlert.triggerPort = value;
    this.selectedAlertHasChanges = true;
  }

  public setSelectedDelayType(value: number) {
    this.selectedAlert = { ...this.selectedAlert };
    this.selectedAlert.delayType = value;
    this.selectedAlertHasChanges = true;
  }

  public setSelectedIcon(value: FormData | null) {
    this.selectedAlert = { ...this.selectedAlert };
    this.selectedAlert.newIcon = value;
    this.selectedAlert.iconChanged = true;
    this.selectedAlertHasChanges = true;
  }

  public setSelectedDelayIcon(value: FormData | null) {
    this.selectedAlert = { ...this.selectedAlert };
    this.selectedAlert.newDelayIcon = value;
    this.selectedAlert.delayIconChanged = true;
    this.selectedAlertHasChanges = true;
  }

  public setSelectedAlertHasChanged(value: boolean) {
    this.selectedAlertHasChanges = value;
  }

  public setHilightAlert(value: AlertData | null) {
    this.highlightAlert = value;
  }

  public setSelectedTab(value: string) {
    this.selectedTab = value;
  }

  public addNewEmptyAlert() {
    const instantSvg = new Blob([DEFAULT_INSTANT_ICON], { type: "image/svg+xml" });
    const delaySvg = new Blob([DEFAULT_DELAY_ICON], { type: "image/svg+xml" });

    const delayIconForm = new FormData();
    const iconForm = new FormData();
    delayIconForm.append("delayIcon", delaySvg);
    iconForm.append("icon", instantSvg);

    const data = {
      alertId: null,
      alertName: "",
      alternateText: "",
      triggerPort: undefined,
      delayType: 0,
      icon: "",
      delayIcon: "",
      iconChanged: false,
      newIcon: iconForm,
      delayIconChanged: false,
      newDelayIcon: delayIconForm,
    };
    this.selectedAlert = { ...data };
    this.oldAlertData = { ...data };

    this.selectedAlertHasChanges = false;
    this.expandedAlertIndex = ExpandedState.TOP;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedAlert(schema: ObjectSchema, value: string = "") {
    const validationResult = validateData<AlertData>(
      this.selectedAlert,
      schema
    );

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = null;

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}