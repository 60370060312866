import styles from "./client-status-group.module.css";

import { ClientProfileSettings } from "../store/types";
import { inject, observer } from "mobx-react";
import { SelectIcon, StatusIcon } from "../../../components/select-icon";
import { Component } from "react";
import { FeatureStore } from "../../../store";

interface IClientAlertStatusProps {
  featureStore?: FeatureStore;
  clientSettings: ClientProfileSettings;
  disabled?: boolean;
  alertPeriodId: number;
}

@inject("featureStore")
@observer
export default class ClientAlertStatusIcons extends Component<IClientAlertStatusProps> {
  public render() {
    const icons = this.createAlertStatusIcons();
    return (
      <div className={styles["status-group"]}>
        {icons.map((Icon) => {
          return Icon;
        })}
      </div>
    );
  }

  private createAlertStatusIcons() {
    const { clientSettings, alertPeriodId, disabled } = this.props;
    const { alerts } = clientSettings;
    const iconTypes = this.createSortedAlertIcons();
    if (!clientSettings.alerts) {
      return [];
    }
    let icons = [];
    icons = iconTypes.map((iconType, index) => {
      const alert = alerts[alertPeriodId].find(
        (a) => a.typeId === iconType.alertId
      );
      if (alert === undefined) return <div />;

      const iconProps = {
        alertId: alert.typeId,
        alertDelayed: alert!.delay! > 0,
        alertActive: alert.isActive,
        alertDisabled: disabled,
      };
      const Icon = SelectIcon(iconProps);
      const IconElement = Icon.element;
      return (
        <StatusIcon key={alert.name + "_" + index}>
          <IconElement className={Icon.className} width="40px" height="40px" />
        </StatusIcon>
      );
    });
    return icons;
  }

  private createSortedAlertIcons() {
    const { features } = this.props.featureStore!;

    const alerts = [
      { alertId: 7 },
      { alertId: 4 },
      { alertId: 1 },
      { alertId: 2 },
      { alertId: 8 },
      { alertId: 3 },
      { alertId: 5 },
    ];

    if (features.isVoiceAlertAvailable === false) {
      const voiceAlertIndex = alerts.findIndex((alert) => {
        return alert.alertId === 7;
      });
      if (voiceAlertIndex >= 0) alerts.splice(voiceAlertIndex, 1);
    }

    return alerts;
  }
}
