import styles from "./client-profile-sensor-issues-tab.module.css";

import classNames from "classnames";
import { SensorIssuesInfo } from "../../store/types";
import { useTranslation } from "react-i18next";
import { SearchStore, AuthStore } from "../../../../store";
import { inject } from "mobx-react";
import { ReactComponent as WarningIcon } from "../../../manage-sensors/svg/warning.svg";

import { useNavigate } from "react-router-dom";
import {
  WARNING_TYPE,
  getIcon,
  getStatusText,
  getTypeText,
  getWarningType,
  isWarning,
} from "../../../../components/sensor-issues-mapper";

interface ClientProfileSensorIssuesTabProps {
  searchStore?: SearchStore;
  authStore?: AuthStore;
  sensorIssues: SensorIssuesInfo[];
}

function ClientProfileSensorIssuesTab(
  props: ClientProfileSensorIssuesTabProps
) {
  const { t } = useTranslation("client-profile");
  const { sensorIssues, authStore, searchStore } = props;
  const history = useNavigate();

  const render = () => {
    if (!sensorIssues) return null;

    const items = sensorIssues.map((issue) => {
      const StatusIcon = getIcon(issue.status);
      const status = t(getStatusText(issue.status));
      const type = t(getTypeText(issue.type, issue.id, issue.template));
      const onClickHandler = authStore!.isNurseAdmin()
        ? () => goToManageSensors(issue.id)
        : () => {};
      const isWarningStatus = isWarning(issue.status);

      return (
        <div
          className={classNames(styles["sensor-issue"], {
            [styles["clickable"]]: authStore!.isNurseAdmin(),
          })}
          key={issue.id}
          onClick={onClickHandler}
        >
          <div className={styles["sensor-issues-icon"]}>
            <StatusIcon
              className={classNames(styles["text"], {
                [styles["warning"]]: isWarningStatus,
                [styles["critical"]]: !isWarningStatus,
              })}
              width="40px"
              height="40px"
            />
          </div>

          <div className={styles["text"]}>
            {type} - {status}
          </div>
        </div>
      );
    });

    if (sensorIssues === undefined || !sensorIssues.length) {
      return null;
    }

    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}> {t("Sensor issues")} </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          <div
            className={classNames(styles["col-1"], {
              [styles["warning"]]:
                getWarningType(sensorIssues) === WARNING_TYPE.Yellow,
              [styles["critical"]]:
                getWarningType(sensorIssues) !== WARNING_TYPE.Yellow,
            })}
          >
            <WarningIcon width="40px" height="40px" />
          </div>
          <div className={styles["col-234"]}>{items}</div>
          <div className={styles["col-4"]} />
        </li>
      </div>
    );
  };

  const goToManageSensors = (sensorId: string) => {
    searchStore!.sensorSearch = sensorId;

    setTimeout(() => {
      history(`/manage-sensors`);
    });
  };

  return render();
}

export default inject("searchStore", "authStore")(ClientProfileSensorIssuesTab);
