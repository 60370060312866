import styles from "./edit-peripheral.module.css";
import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../store/store";
import LocationDropDown from "./components/location-dropdown";
import BusInput from "./components/bus-input";
import AddressInput from "./components/address-input";
import SubaddressInput from "./components/subaddress-input";
import MacAddressInput from "./components/mac-address-input";
import IpAddressInput from "./components/ip-address-input";
import UsedToggle from "./components/used-toggle";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import UsedInfoIcon from "./components/used-info-icon";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";
import { runInAction } from "mobx";
import { PeripheralData } from "../../store/types";

interface EditPeripheralProps {
  peripheralDataStore?: PeripheralDataStore;
  saveLocked?: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
}

function EditPeripheral(props: EditPeripheralProps) {
  let editComponentElement: HTMLTableRowElement;
  let lockedUsedToggle: boolean = false;
  const peripheralDataStore = props.peripheralDataStore!;
  const { saveLocked, onSaveClick, onCancelClick } = props;
  const { t } = useTranslation("common");
  const peripheralList = peripheralDataStore.peripherals;
  const selectedLocationName = peripheralDataStore.selectedPeripheral.locationName;
  const selectedAreaName = peripheralDataStore.selectedPeripheral.areaName;
  const selectedLocationId = peripheralDataStore.selectedPeripheral.locationId;
  const selectedPeripheralId = peripheralDataStore.selectedPeripheral.peripheralId;

  useEffect(() => {
    scrollIntoViewAndAlign(editComponentElement);
    return function unmount() {
      runInAction(() => {
        peripheralDataStore.errors = null;
        peripheralDataStore.errorMessages = null;
        peripheralDataStore.setValidated(true);
      });
    };
  }, []);

  const existingPeripheralRoom = peripheralList.find(
    (peripheral: PeripheralData) => {
      if (
        selectedLocationName === peripheral.locationName &&
        selectedAreaName === peripheral.areaName &&
        selectedPeripheralId !== peripheral.peripheralId
      ) {
        return true;
      }

      return false;
    }
  );

  if (selectedLocationId === undefined) {
    lockedUsedToggle = false;
  } else if (existingPeripheralRoom === undefined) {
    lockedUsedToggle = true;

    peripheralDataStore.selectedPeripheral.isUsed = true;
  } else {
    const existingActivePeripheral = peripheralList.find(
      (peripheral: PeripheralData) => {
        if (
          selectedLocationName === peripheral.locationName &&
          selectedAreaName === peripheral.areaName &&
          selectedPeripheralId !== peripheral.peripheralId &&
          peripheral.isUsed
        ) {
          return true;
        }

        return false;
      }
    );

    if (existingActivePeripheral) {
      lockedUsedToggle = false;
    } else lockedUsedToggle = true;
  }

  const render = () => {
    return (
      <tr
        className={styles["edit-peripheral"]}
        ref={(control: HTMLTableRowElement) => (editComponentElement = control)}
      >
        <td></td>

        <td>
          <div className={styles["column"]}>
            <LocationDropDown />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <BusInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <AddressInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <SubaddressInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <MacAddressInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <IpAddressInput />
          </div>
        </td>

        <td>
          <div className={styles["column"]}>
            <div className={styles["used-column-content"]}>
              <UsedToggle lockedToggle={lockedUsedToggle} />

              <UsedInfoIcon />
            </div>
          </div>
        </td>

        <td className={styles["action-column"]}>
          <div className={styles["action-buttons-container"]}>
            <div className={styles["action-buttons"]}>
              <CancelButton
                onClick={() => {
                  onCancelClick();
                }}
              >
                {t("Cancel")}
              </CancelButton>

              <SaveButton
                onClick={() => {
                  onSaveClick();
                }}
                disabled={saveLocked}
              >
                {t("Save")}
              </SaveButton>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return render();
}

export default inject("peripheralDataStore")(observer(EditPeripheral));
