import * as React from "react";

export interface ViewItemProps {
  itemKey: string;
  children?: React.ReactNode;
}

export function ViewItem(props: ViewItemProps) {
  const render = () => {
    const { children } = props;
    return <>{children}</>;
  };

  return render();
}
