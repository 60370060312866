import { ClientDetails } from "./store";
import { IClientStatus, IStatusAndAlert } from "./types";
import {
  ClientStatusIn,
  LastPeriodStatuseAndAlert,
  ReadClientOverviewCallIn,
} from "./typesIn";

export interface StatusesAndAlertsConverter {
  toStore(inData: LastPeriodStatuseAndAlert): IStatusAndAlert;
}

export const StatusesAndAlertsConverter: StatusesAndAlertsConverter = {
  toStore(inData: LastPeriodStatuseAndAlert): IStatusAndAlert {
    return {
      iconData: inData.iconData,
      name: inData.name,
      timestamp: inData.timestamp,
      type: inData.type,
      typeId: inData.typeId,
      wasDelayed: inData.wasDelayed,
      parentTypeId: inData.parentTypeId,
    };
  },
};

export interface ClientStatusesConverter {
  toStore(inData: ClientStatusIn): IClientStatus;
}

export const ClientStatusesConverter: ClientStatusesConverter = {
  toStore(inData: ClientStatusIn): IClientStatus {
    return {
      clientId: inData.clientId,
      hoursInBathroom: inData.hoursInBathroom,
      hoursInBed: inData.hoursInBed,
      hoursInRoom: inData.hoursInRoom,
      hoursOutOfRoom: inData.hoursOutOfRoom,
      id: inData.id,
      locationId: inData.locationId,
      timesInBathroom: inData.timesInBathroom,
      timesInBed: inData.timesInBed,
      timesInRoom: inData.timesInRoom,
      timesOutOfRoom: inData.timesOutOfRoom,
      timeStamp: inData.timeStamp,
    };
  },
};

export interface ClientOverviewConverter {
  toStore(inData: ReadClientOverviewCallIn): ClientDetails;
}

export const ClientOverviewConverter: ClientOverviewConverter = {
  toStore(inData: ReadClientOverviewCallIn): ClientDetails {
    return {
      areaName: inData.areaName,
      clientName: inData.clientName,
      locationName: inData.locationName,
      timestamp: inData.timestamp,
    };
  },
};
