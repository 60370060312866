import styles from "./tab-container.module.css";

import classnames from "classnames";
import { Fragment, useEffect, useState } from "react";

export interface TabData {
  component: any;
  title: string;
}

export interface ITabContainerProps {
  tabList: TabData[];
  defaultTab: number;
}

export function TabContainer(props: ITabContainerProps) {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const { defaultTab } = props;
    setTabIndex(defaultTab);
  }, []);

  const handleClickTab = (index: number) => {
    setTabIndex(index);
  };

  const renderTabButtons = () => {
    const { tabList } = props;

    return tabList.map((tabData, index) => {
      return (
        <Fragment key={index}>
          {index !== 0 && <div className={styles["tabs-separator"]} />}
          <div
            className={classnames(styles["tab-button"], {
              [styles["selected"]]: index === tabIndex,
            })}
            onClick={() => handleClickTab(index)}
          >
            {tabData.title}
            <div
              className={classnames(styles["underline"], {
                [styles["selected"]]: index === tabIndex,
              })}
            ></div>
          </div>
        </Fragment>
      );
    });
  };

  const renderContent = () => {
    const { tabList } = props;

    return tabList[tabIndex].component;
  };

  return (
    <div>
      <div className={styles["tab-buttons-container"]}>
        {renderTabButtons()}
      </div>
      <div>{renderContent()} </div>
    </div>
  );
}
