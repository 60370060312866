import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { ISensorsSort, SensorDataStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import FilterSensors from "./../filter-sensors";
import SearchSensors from "./../search-sensors";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import { AuthStore, FeatureStore, TransitionStore } from "../../../../store";
import webResource from "./../../web";
import { GlobalDialogData } from "../../../../store/transition-store";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { SensorListCallOut } from "../../store/typesOut";
import AddButton from "../../../../components/buttons/AddButton";
import { runInAction } from "mobx";
import { ExpandedState } from "../../../../components/table/table";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";

export interface HeaderProps {
  sensorDataStore?: SensorDataStore;
  authStore?: AuthStore;
  transitionStore?: TransitionStore;
  featureStore?: FeatureStore;
}

function Header(props: HeaderProps) {
  const { t } = useTranslation("sensor-grid");
  const sensorDataStore = props.sensorDataStore!;
  const transitionStore = props.transitionStore!;
  const authStore = props.authStore!;
  const featureStore = props.featureStore!;

  const render = () => {
    return (
      <div className={styles["manage-sensors-page-header"]}>
        <div className={styles["manage-sensors-tabs"]}>
          <TabPanel activeKey={"manage-sensors"}>
            <TabItem itemKey={"manage-sensors"} label={t("Manage Sensors")} />
          </TabPanel>
        </div>
        <div className={styles["manage-sensors-filter-inputs"]}>
          <div className={styles["manage-sensors-input-container-filter"]}>
            <FilterSensors onFilterChange={filterChange} />
          </div>
          <div className={styles["manage-sensors-input-container-filter"]}>
            <SearchSensors onSearchChange={searchChange} />
          </div>
          {addButton()}
        </div>
      </div>
    );
  };

  const searchChange = () => {
    setTimeout(() => {
      selectSensorOnPage();
    });
  };

  const filterChange = () => {
    const sensorSorting = sensorDataStore.sensorsSort;

    readAllSensorData(sensorSorting);
  };

  const addButton = () => {
    return (
      authStore.isAdmin() && (
        <div className={styles["add-button-container"]}>
          <AddButton
            disabled={
              transitionStore.isLoading ||
              sensorDataStore.expandedSensorIndex !== ExpandedState.COLLAPSED
            }
            onClick={() => {
              sensorDataStore.setNewSensor();
              setLocationForNewSensor();
              ofeliaOverrideSensorsType();
            }}
          >
            {t("Add Sensor")}
          </AddButton>
        </div>
      )
    );
  };

  const ofeliaOverrideSensorsType = () => {
    if (featureStore.features.ofeliaIntegrationEnabled) {
      const { selectedSensor } = sensorDataStore;
      runInAction(() => {
        selectedSensor.sensorType = "VirtualSensor";
      });
    }
  };

  const setLocationForNewSensor = () => {
    if (sensorDataStore.filteringLocationId) {
      const data = sensorDataStore.rooms.find(
        (r) => r.locationId === sensorDataStore.filteringLocationId
      );

      if (data) {
        const { selectedSensor } = sensorDataStore;
        runInAction(() => {
          selectedSensor.locationId = data.locationId;
          selectedSensor.locationName = data.locationName;
          selectedSensor.areaName = data.areaName;
        });
      }
    }
  };

  const selectSensorOnPage = () => {
    const selectedElement = document.querySelector("#selected-sensor");
    if (selectedElement) {
      scrollIntoViewAndAlign(selectedElement);
    }
  };

  const readAllSensorData = (sorted: ISensorsSort[]) => {
    sensorDataStore.setIsDataLoading(true);

    const sensorData: SensorListCallOut = {
      SortOptions: sorted,
      LocationId: sensorDataStore.filteringLocationId || null,
    };
    return webResource
      .readAll(sensorData)
      .then((ajaxResponse: any) => {
        const response = ajaxResponse.data;
        sensorDataStore.setSensors(response.data);
        sensorDataStore.setAvailableRooms(response.availableRooms);
        sensorDataStore.setSensorTypes(response.sensorTypes);
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);
        transitionStore?.showGlobalDialog(dialog);
      })
      .finally(() => {
        sensorDataStore.setIsDataLoading(false);
      });
  };

  return render();
}

export default inject(
  "sensorDataStore",
  "authStore",
  "transitionStore",
  "featureStore"
)(observer(Header));
