import { observable, action, makeObservable, computed } from "mobx";

export default class AuthStore {
  constructor() {
    makeObservable(this, {
      authentication: observable,
      isAuthenticating: observable,

      authenticate: action,
      clearAuthentication: action,
      setIsAuthenticating: action,
      reloadToken: action,
      setToken: action,

      getToken: computed,
      isAuthenticated: computed,
    });
  }

  public authentication: {
    username: string;
    role: string;
    token: string;

    firstname: string;
    lastname: string;
  } = {
    username: "",
    role: "",
    token: "",
    firstname: "",
    lastname: "",
  };

  private readonly localStorageTokenKey = "BearerToken";
  public isAuthenticating: boolean = true;

  public get isAuthenticated() {
    const auth = this.authentication;
    
    return !!auth.token;
  }

  public hasAdminRights(): Boolean {
    return (
      this.authentication.role === "Admin" ||
      this.authentication.role === "NurseAdmin"
    );
  }
  public isAdmin(): Boolean {
    return this.authentication.role === "Admin";
  }
  public isNurseAdmin(): Boolean {
    return this.authentication.role === "NurseAdmin";
  }
  public isNurse(): Boolean {
    return this.authentication.role === "Nurse";
  }

  public UserName(): string {
    return this.authentication.username;
  }

  public Firstname(): string{
    return this.authentication.firstname;
  }

  public Lastname(): string{
    return this.authentication.lastname;
  }

  public authenticate = (
    username: string,
    role: string,
    firstName: string,
    lastName: string,
  ) => {
    const auth = this.authentication;

    auth.username = username;
    auth.role = role;

    auth.firstname = firstName;
    auth.lastname = lastName;
  };

  public reloadToken() {
    const storageToken = localStorage.getItem(this.localStorageTokenKey);
    const { token } = this.authentication;

    if (storageToken !== token) this.authentication.token = storageToken ?? "";
  }

  public get getToken(): string {
    return this.authentication.token;
  }

  public setToken = (token: string) => {
    this.authentication.token = token;

    localStorage.setItem(this.localStorageTokenKey, token);
  };

  public clearAuthentication = () => {
    this.isAuthenticating = false;
    const auth = this.authentication;

    auth.username = "";
    auth.role = "";
    auth.token = "";

    localStorage.removeItem(this.localStorageTokenKey);
  };

  public setIsAuthenticating(value: boolean) {
    this.isAuthenticating = value;
  }
}
