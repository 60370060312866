import { inject, observer } from "mobx-react";
import { SubstatusDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface AlternateTextInputProps {
  substatusDataStore?: SubstatusDataStore;
}

function AlternateTextInput(props: AlternateTextInputProps) {
  const { t } = useTranslation(["substatus-grid"]);
  const substatusDataStore = props.substatusDataStore!;
  const alternateText = substatusDataStore!.selectedSubstatus.alternateText;

  return (
    <TextFieldInput
      value={alternateText}
      label={t("AlternateText").toUpperCase()}
      inputProps={{ pattern: "^[A-Za-z0-9_ ]{0,100}$" }}
      placeholder={t("AlternateText")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;
        substatusDataStore.setSelectedSubstatusAlternateText(
          event.target.value
        );
        substatusDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        substatusDataStore.validateSelectedSubstatus("alternateText");
        substatusDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("substatusDataStore")(observer(AlternateTextInput));
