import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import ToggleButtonsComponent from "../../../../components/toggle-button/ToggleButtonGroup";

import { ReactComponent as CardIcon } from "../../svg/card-view.svg";
import { ReactComponent as GridIcon } from "../../svg/grid-view.svg";
import { useTranslation } from "react-i18next";

export interface ViewTogggleProps {
  clientOverviewStore?: ClientOverviewStore;
}

function ViewTogggle(props: ViewTogggleProps) {
  const clientOverviewStore = props.clientOverviewStore!;
  const { viewType } = clientOverviewStore!;

  const { t } = useTranslation("client-grid");

  const buttons = [
    {
      id: "grid",
      icon: <GridIcon width={16} />,
      tooltip: t("GridToggleButton"),
    },
    {
      id: "card",
      icon: <CardIcon width={16} />,
      tooltip: t("CardToggleButton"),
    },
  ];

  const render = () => {
    return (
      <ToggleButtonsComponent
        buttons={buttons}
        selectedID={viewType}
        onChange={(ID: string) => {
          if (ID) clientOverviewStore.viewType = ID;
          if (ID !== null) clientOverviewStore.viewType = ID;
        }}
      />
    );
  };
  return render();
}

export default inject("clientOverviewStore")(observer(ViewTogggle));
