import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { SwitchInput } from "../../../../../components/form-inputs";
import { ChangeEvent } from "react";

export interface UsedToggleProps {
  peripheralDataStore?: PeripheralDataStore;

  lockedToggle?: boolean;
}

function UsedToggle(props: UsedToggleProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const isUsed = peripheralDataStore.selectedPeripheral.isUsed;
  const { lockedToggle } = props;

  return (
    <SwitchInput
      checked={isUsed}
      disabled={lockedToggle}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        peripheralDataStore.setSelectedPeripheralUsed(event.target.checked);

        peripheralDataStore.validateSelectedPeripheral();
      }}
    ></SwitchInput>
  );
}

export default inject("peripheralDataStore")(observer(UsedToggle));
