import styles from "./header.module.css";

import { PageHeader } from "../../../../components/page";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClientDashboardStore } from "../../store/store";
import { inject, observer } from "mobx-react";
import BackButton from "../../../../components/buttons/BackButton";

export interface HeaderProps {
  clientDashboardStore?: ClientDashboardStore;
}

function Header(props: HeaderProps) {
  const { t } = useTranslation("dashboard");
  const history = useNavigate();
  const clientDetails = props.clientDashboardStore!.clientDetails!;

  const render = () => {
    return (
      <PageHeader>
        <div className={styles["title"]}>
          {t("Client Dashboard")}
        </div>

        <div className={styles["client-name"]}>{clientDetails.clientName}</div>
        <div className={styles["client-area"]}>
          <span>{clientDetails.areaName}</span>
          <span> - </span>
          <span className={styles["client-room"]}>
            {clientDetails.locationName}
          </span>
        </div>

        <div className={styles["header-button"]}>
          <BackButton sx={{ height: 40 }} onClick={() => history("/residents-overview")}>{t("Back")}</BackButton>
        </div>

        <div className={styles["dashboard-timestamp"]}>
          <span className={styles["label"]}>{t("Timestamp")}:</span>
          <span className={styles["value"]}>
            {moment(clientDetails.timestamp)
              .locale(navigator.language.substring(0, 2))
              .format("D MMM HH:mm")}
          </span>
        </div>
      </PageHeader>
    );
  };

  return render();
}

export default inject("clientDashboardStore")(observer(Header));
