import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  SelectInput,
  TextFieldInput,
} from "../../../../../components/form-inputs";
import { AuthStore, FeatureStore } from "../../../../../store";

import { SensorDataStore } from "../../../store/store";

export interface TypeDropDownProps {
  sensorDataStore?: SensorDataStore;
  authStore?: AuthStore;
  featureStore?: FeatureStore;
}

function TypeDropDown(props: TypeDropDownProps) {
  const sensorDataStore = props.sensorDataStore!;
  const authStore = props.authStore!;
  const featureStore = props.featureStore!;

  const { t } = useTranslation("sensor-grid");
  const { errors } = sensorDataStore;
  const sensorType = sensorDataStore!.selectedSensor.sensorType;

  const buildSensorTypeDropdownData = () => {
    const { availableSensorTypes } = sensorDataStore.selectedSensor;

    const dropdownData = availableSensorTypes
      .map((value) => ({
        id: value,
        value: t(value),
      }))
      .sort((a, b) => {
        return a.value.localeCompare(b.value, undefined, {
          numeric: true,
        });
      });

    if (dropdownData.length > 0 && dropdownData[0].id === "VirtualSensor") {
      dropdownData[0] = { id: sensorType, value: sensorType };
    }

    return dropdownData;
  };

  let typeValues = buildSensorTypeDropdownData();

  if (featureStore.features.ofeliaIntegrationEnabled) {
    typeValues = typeValues.filter((value) => value.id === "VirtualSensor");

    if (typeValues.length === 0)
      typeValues.push({
        id: sensorType,
        value: t(sensorType),
      });
  }

  const selectedRoom =
    typeValues.find((value: any) => value.id === sensorType) || typeValues[0];

  return (
    <>
      {authStore.isAdmin() ? (
        <SelectInput
          mandatory
          options={typeValues}
          label={t("SensorType").toUpperCase()}
          value={selectedRoom}
          disabled={typeValues.length === 1}
          onChange={(_event: any, value: any) => {
            sensorDataStore.setSelectedSensorType(value.id);
            sensorDataStore.setSelectedSensorHasChanges(true);
            sensorDataStore.validateSelectedSensor("sensorId");
          }}
          errorMessage={
            (errors && errors["sensorType"] && t("Required information")) || ""
          }
        ></SelectInput>
      ) : (
        <TextFieldInput
          mandatory
          value={t(sensorType)}
          disabled={true}
          label={t("SensorType").toUpperCase()}
        />
      )}
    </>
  );
}

export default inject(
  "sensorDataStore",
  "authStore",
  "featureStore"
)(observer(TypeDropDown));
