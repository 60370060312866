import { Route } from "react-router-dom";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nb";
import "moment/locale/nl";

import AuthorizedRoute from "../../components/authorized-route";

import ClientDashboard from "./client-dashboard.page";

const ProtectedClientDashboard = (props: any) => (
  <AuthorizedRoute path="/" component={ClientDashboard} {...props} />
);
const clientDashboardRoute = (
  <Route
    path="/client-dashboard/:id"
    element={<ProtectedClientDashboard />}
  />
);

export default clientDashboardRoute;
