import searchStyles from "../search-substatus.module.css";

import { ReactComponent as InBed } from "../../../clients-overview/svg/client-in-bed.svg";
import { ReactComponent as InRoom } from "../../../clients-overview/svg/client-in-room.svg";
import { ReactComponent as InBathroom } from "../../../clients-overview/svg/client-in-bathroom.svg";
import { ReactComponent as Inactive } from "../../../clients-overview/svg/client-inactive.svg";
import { ReactComponent as NotAvailable } from "../../../clients-overview/svg/client-not-available.svg";
import { ReactComponent as OutOfBed } from "../../../clients-overview/svg/client-out-of-bed.svg";
import { ReactComponent as OutOfRoom } from "../../../clients-overview/svg/client-out-of-room.svg";
import { ReactComponent as StatusOff } from "../../../clients-overview/svg/client-status-off.svg";
import { ReactComponent as InChair } from "../../../clients-overview/svg/client-in-chair.svg";
import { ReactComponent as VisitorDetected } from "../../../clients-overview/svg/client-visitor-detected.svg";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { SubstatusDataStore, SubstatusSort } from "../../store/store";
import {
  AlertData,
  IListSubstatuses,
  ISubstatusData,
  SubstatusData,
} from "../../store/types";
import { TransitionStore } from "../../../../store";
import webResource from "../../web";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { GlobalDialogData } from "../../../../store/transition-store";
import { useEffect } from "react";
import {
  SortHeader,
  SORT_DIRECTION,
} from "../../../../components/table/headers/SortHeader";
import { BaseTable, ColumnData } from "../../../../components/table/table";
import { TextCell } from "../../../../components/table/cells/TextCell";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../../components/table/headers/BasicHeader";
import { SensorDataConverter } from "../../store/convertor";
import { SubstatusDataIn } from "../../store/typesIn";
import AlertIcon from "../cells/icon";

interface IDefaultStatusGridProps {
  substatusDataStore?: SubstatusDataStore;
  transitionStore?: TransitionStore;
}

function chooseStatusIcon(statusId?: number): any {
  switch (statusId) {
    case 1:
      return <Inactive width="40px" height="40px" />;
    case 2:
      return <InBed width="40px" height="40px" />;
    case 3:
      return <VisitorDetected width="40px" height="40px" />;
    case 4:
      return <InRoom width="40px" height="40px" />;
    case 5:
      return <OutOfRoom width="40px" height="40px" />;
    case 6:
      return <InBathroom width="40px" height="40px" />;
    case 8:
      return <StatusOff width="40px" height="40px" />;
    case 9:
      return <OutOfBed width="40px" height="40px" />;
    case 10:
      return <InChair width="40px" height="40px" />;
    default:
      return <NotAvailable width="40px" height="40px" />;
  }
}

function DefaultStatusesGrid(props: IDefaultStatusGridProps) {
  const { t } = useTranslation(["substatus-grid"]);

  const parentStatusNameAccessor = "parentStatusName";
  const textAccessor = "text";
  const statusNameAccessor = "statusName";
  const alertNameAccessor = "alertName";

  const transitionStore = props.transitionStore!;
  const substatusDataStore = props.substatusDataStore!;
  const { statusSort } = substatusDataStore;

  useEffect(() => {
    substatusDataStore.setStatusSort([
      new SubstatusSort(statusNameAccessor, false),
    ]);
    readAllSubstatusData();

    return function unmount() {
      substatusDataStore.setHilightSubstatus(null);
      substatusDataStore.errors = null;
      substatusDataStore.searchValue = "";
    };
  }, []);

  const readAllSubstatusData = () => {
    substatusDataStore.setIsDataLoading(true);

    const params: IListSubstatuses = {
      SortOptions: substatusDataStore.statusSort,
    };

    const noStatuses: SubstatusData[] = [];
    const noAlerts: AlertData[] = [];

    return webResource
      .readAll(params)
      .then((ajaxResponse: any) => {
        const response = ajaxResponse.data;

        const storeDefaultStatusList = response.data.map(
          (substatus: SubstatusDataIn) => {
            return SensorDataConverter.toStore(substatus);
          }
        );

        substatusDataStore.setAlerts(response.alertsData);

        if (substatusDataStore.statusSort[0].id === "text") {
          const substatuses = storeDefaultStatusList;
          const val = substatusDataStore.statusSort[0].desc ? -1 : 1;

          substatuses.sort(function (a: any, b: any) {
            return t(a.statusName).localeCompare(t(b.statusName)) * val;
          });

          substatusDataStore.setSubstatuses(substatuses);
        } else {
          substatusDataStore.setSubstatuses(storeDefaultStatusList);
        }
      })
      .catch((ajaxResponse: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);
        transitionStore.showGlobalDialog(dialog);
        substatusDataStore.setAlerts(noAlerts);
        substatusDataStore.setSubstatuses(noStatuses);
      })
      .finally(() => {
        substatusDataStore.setIsDataLoading(false);
      });
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== statusSort[0].id) return SORT_DIRECTION.NONE;

    if (statusSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const columnData: ColumnData[] = [
    {
      header: t("StatusName"),
      width: "20%",
      HeaderComponent: (
        <SortHeader
          text={t("StatusName")}
          selected={statusSort[0].id === statusNameAccessor}
          sortDirection={getSortDirection(statusNameAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(statusNameAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => {
        return <TextCell text={value.statusName} />;
      },
    },
    {
      header: t("Text"),
      width: "15%",
      HeaderComponent: (
        <SortHeader
          text={t("Text")}
          selected={statusSort[0].id === textAccessor}
          sortDirection={getSortDirection(textAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(textAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => {
        return <TextCell text={t(value.statusName)} />;
      },
    },
    {
      header: t("ParentStatusName"),
      width: "20%",
      HeaderComponent: (
        <SortHeader
          text={t("ParentStatusName")}
          selected={statusSort[0].id === parentStatusNameAccessor}
          sortDirection={getSortDirection(parentStatusNameAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(parentStatusNameAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => (
        <TextCell text={t(value.parentStatusName)} />
      ),
    },
    {
      header: t("alertName"),
      width: "20%",
      HeaderComponent: (
        <SortHeader
          text={t("alertName")}
          selected={statusSort[0].id === alertNameAccessor}
          sortDirection={getSortDirection(alertNameAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(alertNameAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => {
        return (
          <TextCell text={substatusDataStore.getAlertName(value.alertId)} />
        );
      },
    },
    {
      header: t("StatusIcon"),
      HeaderComponent: (
        <BasicHeader text={t("StatusIcon")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: ISubstatusData) => {
        if (value.statusId && value.statusId <= 10) {
          return <AlertIcon icon={chooseStatusIcon(value.statusId)} />;
        }
        return <AlertIcon data={value.statusIcon} />;
      },
    },
  ];

  const updateSorting = (name: string) => {
    if (statusSort[0].id === name) {
      substatusDataStore.setStatusSort([
        new SubstatusSort(name, !statusSort[0].desc),
      ]);
    } else {
      substatusDataStore.setStatusSort([new SubstatusSort(name, false)]);
    }
    readAllSubstatusData();
  };

  const highlightedItem = substatusDataStore.highlightSubstatus;
  const gridSubstatusesData = substatusDataStore.substatuses;

  const defaultStatusList = gridSubstatusesData.filter(
    (substatus: SubstatusData) => {
      if (substatus.statusId && substatus.statusId <= 10) return true;
      return false;
    }
  );

  return (
    <BaseTable
      minWidth={1286}
      data={defaultStatusList}
      columns={columnData}
      customRowId={(_index, rowData: SubstatusData) => {
        if (rowData.statusId === highlightedItem?.statusId)
          return "selected-substatus";
        return "";
      }}
      customRowClass={(_index, rowData: SubstatusData) => {
        if (rowData.statusId === highlightedItem?.statusId)
          return searchStyles["selected-substatus"];
        return "";
      }}
    />
  );
}

export default inject(
  "substatusDataStore",
  "transitionStore"
)(observer(DefaultStatusesGrid));
