import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import styles from "./toggle-button-group.module.css";
import { forwardRef } from "react";

interface ToggleButton {
  id: string;
  icon?: any;
  text?: string;
  tooltip?: string;
}
interface ToggleButtonsComponentProps {
  buttons: ToggleButton[];
  selectedID: string;
  onChange?(ID: string): void;
  tooltip?: string;
}

interface TooltipToggleButtonProps {
  title?: string;
  value: string;
  children?: any;
}

const TooltipToggleButton = forwardRef<any, TooltipToggleButtonProps>(
  ({ title, ...props }, ref) => {
    return (
      <Tooltip title={title}>
        <ToggleButton ref={ref} {...props} />
      </Tooltip>
    );
  }
);

function ToggleButtonsComponent(props: ToggleButtonsComponentProps) {
  const { buttons, selectedID, onChange } = props;

  const render = () => {
    return (
      <div className={styles["toggle-button-group-container"]}>
        <ToggleButtonGroup
          value={selectedID}
          exclusive
          onChange={(_event: React.MouseEvent<HTMLElement>, value: string) => {
            if (onChange) onChange(value);
          }}
        >
          {buttons.map((buttonData: ToggleButton) => {
            const { text, icon, id, tooltip } = buttonData;
            return (
              <TooltipToggleButton value={id} title={tooltip}>
                <div className={styles["button-container"]}>
                  {text && <div className={styles["text"]}>{text}</div>}
                  <div className={styles["icon"]}>{icon}</div>
                </div>
              </TooltipToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
    );
  };

  return render();
}

export default ToggleButtonsComponent;
