import styles from "./last-days.module.css";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { Component } from "react";

const barProps = {
  stackId: "same-bar",
  barSize: 13,
};

export interface IDayData {
  day: string;
  hoursInBed: number;
  hoursOutOfRoom: number;
  hoursInRoom: number;
  hoursInBathroom: number;
}

export interface ITimeData {
  day: string;
  timesOutOfRoom: number;
  timesVisitedBathroom: number;
  timesOutOfBed: number;
}

export class LastDaysPanel extends Component<
  {
    hoursData: IDayData[];
    timesData: ITimeData[];
    isSevenDaysView: boolean;
    t?: any;
  },
  {}
> {
  public render() {
    const { t } = this.props;
    this.setBarSize();
    return (
      <div className={styles["last-days"]}>
        <div className={styles["column"]}>
          <span className={styles["chart-title"]}>{t("Hours")}</span>
          {this.hours()}
        </div>
        <div className={styles["column"]}>
          <span className={styles["chart-title"]}>{t("Times")}</span>
          {this.times()}
        </div>
      </div>
    );
  }

  private setBarSize() {
    if (this.props.isSevenDaysView) {
      barProps.barSize = 26;
    } else if (window.innerWidth > 1366) {
      barProps.barSize = 13;
    } else {
      barProps.barSize = 8;
    }
  }

  private hours() {
    const { hoursData, t } = this.props;
    let itemsData: any[] = hoursData;

    if (itemsData.length === 0) {
      itemsData = [];

      for (let i = 7; i >= 1; i--) {
        const today = moment().seconds(0).minutes(0).hours(0);
        itemsData.push({ day: today.subtract(i, "d").format("D") });
      }
    }

    return (
      <ResponsiveContainer height={255} width="99%">
        <BarChart
          data={itemsData}
          margin={{ top: 20, right: 26, left: -24, bottom: 5 }}
          barCategoryGap={2}
        >
          <CartesianGrid stroke="#b7b7b7" vertical={false} />
          <XAxis dataKey="day" interval={0} tickCount={1} />
          <YAxis
            type="number"
            domain={[0, 24]}
            tickLine={false}
            axisLine={false}
            tickFormatter={(val: number) => Math.round(val).toString()}
          />
          <Bar
            dataKey="hoursInBed"
            name={t("Hours in bed")}
            fill="#0b2d46"
            stroke="#fff"
            {...barProps}
          />
          <Bar
            dataKey="hoursInRoom"
            name={t("Hours in room")}
            fill="#613373"
            stroke="#fff"
            {...barProps}
          />
          <Bar
            dataKey="hoursInBathroom"
            name={t("Hours in bathroom")}
            fill="#1f87d2"
            stroke="#fff"
            {...barProps}
          />
          <Bar
            dataKey="hoursOutOfRoom"
            name={t("Hours out of room")}
            fill="#28c018"
            stroke="#fff"
            {...barProps}
          />
          <Legend layout="vertical" content={this.legendContent} />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  private times() {
    const { timesData, t } = this.props;

    let itemsData: any[] = timesData;

    if (itemsData.length === 0) {
      itemsData = [];

      for (let i = 7; i >= 1; i--) {
        const today = moment().seconds(0).minutes(0).hours(0);
        itemsData.push({ day: today.subtract(i, "d").format("D") });
      }
    }
    return (
      <ResponsiveContainer height={241} width="99%">
        <BarChart
          data={itemsData}
          margin={{ top: 20, right: 26, left: -24, bottom: 5 }}
        >
          <CartesianGrid stroke="#b7b7b7" vertical={false} />
          <XAxis dataKey="day" interval={barProps.barSize === 26 ? 0 : 1} />
          <YAxis type="number" tickLine={false} axisLine={false} />
          <Bar
            dataKey="timesInBed"
            name={t("Times out of bed")}
            fill="#0b2d46"
            stroke="#fff"
            {...barProps}
          />
          <Bar
            dataKey="timesInBathroom"
            name={t("Times visited bathroom")}
            fill="#1f87d2"
            stroke="#fff"
            {...barProps}
          />
          <Bar
            dataKey="timesOutOfRoom"
            name={t("Times out of room")}
            fill="#28c018"
            stroke="#fff"
            {...barProps}
          />
          <Legend layout="vertical" content={this.legendContent} />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  private legendContent = (props: any) => {
    const { payload } = props;
    return (
      <ul className={styles["recharts-default-legend"]}>
        {payload.reverse().map(this.legendItem)}
      </ul>
    );
  };

  private legendItem = (item: any, index: any) => (
    <li key={`legend-item-${index}`} className={styles["recharts-legend-item"]}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 32 32"
        version="1.1"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginRight: "4px",
        }}
      >
        <path stroke="none" fill={item.color} d="M0,4h32v24h-32z"></path>
      </svg>
      <span
        style={{
          color: item.color,
        }}
      >
        {item.value}
      </span>
    </li>
  );
}
