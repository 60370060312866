import styles from "./login-button.module.css";

import { SimpleButton } from "../../../../components/simple-button";
import { useTranslation } from "react-i18next";

export interface LoginButtonProps {
  clickEvent?(event: MouseEvent): void;
  disabled?: boolean;
}

export function LoginButton(props: LoginButtonProps) {
  const { t } = useTranslation("login");
  const { disabled, clickEvent } = props;

  return (
    <div className={styles["login-button"]}>
      <SimpleButton
        disabled={disabled}
        text={t("login")}
        clickEvent={clickEvent}
      ></SimpleButton>
    </div>
  );
}
