import { Provider } from "mobx-react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Routes from "./routes";
import stores from "./store";
import { ErrorBoundary } from "./components/error-boundary";
import { createRoot } from "react-dom/client";

function SmartSenseApp() {
  const render = () => {
    return (
      <ErrorBoundary>
        <Provider {...stores}>
          <I18nextProvider i18n={i18n}>
            <Routes />
          </I18nextProvider>
        </Provider>
      </ErrorBoundary>
    );
  };

  return render();
}

const container = document.getElementById("react-app");
const root = createRoot(container!);

function renderApp() {
  root.render(<SmartSenseApp />);
}
renderApp();
