import styles from "./client-profile-alert-settings.module.css";

import { ClientProfileStore } from "../../store/store";
import ClientProfileAlertSettingsContent from "./client-profile-alert-settings-content";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ClientProfileAlertSettingsHeader from "./client-profile-alert-settings-header";
// import { Prompt } from "react-router";
import { Navigate, useLocation } from "react-router-dom";
import { TransitionStore } from "../../../../store";
import webResource from "../../web";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { GlobalDialogData } from "../../../../store/transition-store";
import { useEffect } from "react";
import { ReadAllClientProfileAlertSettingsCallOut } from "../../store/typesOut";
import { AlertCallIn } from "../../store/typesIn";

interface ClientProfileAlertSettingsPageProps {
  clientProfileStore: ClientProfileStore;
  saveAlertsSettings(): void;
  cancelAlertChanges(): void;
  transitionStore?: TransitionStore;
}

function ClientProfileAlertSettingsPage(
  props: ClientProfileAlertSettingsPageProps
) {
  const { t } = useTranslation("client-profile");
  const {
    clientProfileStore,
    saveAlertsSettings,
    cancelAlertChanges,
    transitionStore,
  } = props;
  const { clientProfileAlerts } = clientProfileStore;
  const { state } = useLocation();

  useEffect(() => {
    fetchData();
    return () => {
      clientProfileAlerts.clientId = NaN;
      clientProfileAlerts.intervalId = NaN;
      clientProfileAlerts.intervalType = NaN;
      clientProfileAlerts.alerts = [];
    };
  }, []);

  const fetchData = () => {
    const clientId = clientProfileStore.clientProfileDetails.clientId;
    if (
      !clientProfileStore.clientProfileDetails.clientName &&
      !clientProfileStore.clientProfileDetails.areaName
    ) {
      return;
    }
    const { editingPeriodId } = state;
    const clientSettings = clientProfileStore.clientProfileSettings;
    const isDaytimeInterval =
      clientSettings.getInterval(editingPeriodId).isDaytimeInterval;

    transitionStore?.setLoading(true);

    const params: ReadAllClientProfileAlertSettingsCallOut = {
      clientId: clientId! | 0,
      intervalType: isDaytimeInterval ? 1 : 2,
    };

    return webResource
      .readAllClientProfileAlertSettings(params)
      .then((ajaxResponse) => {
        const response: AlertCallIn = ajaxResponse.data!;

        clientProfileAlerts.clientId = response.clientId;
        clientProfileAlerts.intervalType = response.intervalType;
        clientProfileAlerts.alerts = response.alerts;

        transitionStore?.setLoading(false);
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore?.showGlobalDialog(dialog);
        transitionStore?.setLoading(false);
      });
  };

  const render = () => {
    const clientDetails = clientProfileStore.clientProfileDetails;
    const clientSettings = clientProfileStore.clientProfileSettings;

    if (!clientDetails.clientName && !clientDetails.areaName) {
      const linkArr = window.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Navigate to={link} />;
    }

    const { editingPeriodId } = state;
    const isDaytimeInterval =
      clientSettings.getInterval(editingPeriodId).isDaytimeInterval;
    const prefix = isDaytimeInterval ? "Day" : "Night";
    const { acousticSettingsValid } = clientProfileStore.dataValidationStatus;

    return (
      <div>
        <ClientProfileAlertSettingsHeader
          title={t(prefix + " time alerts")}
          clientDetails={clientDetails}
          saveAlertsSettings={saveAlertsSettings}
          cancelAlertChanges={cancelAlertChanges}
        />
        <div className={styles["client-profile-content"]}>
          <ClientProfileAlertSettingsContent
            isDaytimeInterval={isDaytimeInterval}
            clientId={clientDetails.clientId! | 0}
            clientSettings={clientSettings}
            clientProfileAlerts={clientProfileStore.clientProfileAlerts}
            canUpdateVoiceAlerts={acousticSettingsValid}
          />
        </div>

        {/* <Prompt when={clientSettings.hasChanges} message="" /> */}
      </div>
    );
  };

  return render();
}
export default observer(ClientProfileAlertSettingsPage);
