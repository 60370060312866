import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

import Substatuses from "./page.highorder";

const ProtectedSubstatuses = (props: any) => (
  <AuthorizedRoute
    requiredRoles={[ROLES.ADMIN]}
    path="/"
    component={Substatuses}
    {...props}
  />
);
const substatusesRoute = (
  <Route path="/manage-statuses" element={<ProtectedSubstatuses />} />
);

export default substatusesRoute;
