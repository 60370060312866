import * as joi from "joi";

export type { ValidationResult } from "joi";

export const sensorDataSchema = joi.object().keys({
  sensorId: joi.string().required(),
  locationId: joi.number().required(),
  sensorType: joi.string().min(1).required(),
  storage: joi.bool().required(),
});
