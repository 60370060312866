import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { SubstatusDataStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import { TransitionStore } from "../../../../store";
import SearchSubstatus from "./../../components/search-substatus";
import AddButton from "../../../../components/buttons/AddButton";

export interface IHeaderProps {
  substatusDataStore?: SubstatusDataStore;
  transitionStore?: TransitionStore;
}

function Header(props: IHeaderProps) {
  const { t } = useTranslation("substatus-grid");
  const substatusDataStore = props.substatusDataStore!;
  const transitionStore = props.transitionStore!;

  const render = () => {
    return (
      <div className={styles["manage-substatuses-page-header"]}>
        <div className={styles["manage-substatuses-tabs"]}>
          <TabPanel
            onItemChange={(key: string) => {
              substatusDataStore.setSelectedTab(key);
            }}
            activeKey={substatusDataStore.selectedTab}
          >
            <TabItem
              itemKey={"manage-substatuses"}
              label={t("ManageSubstatuses")}
            />
            <TabItem
              itemKey={"default-substatuses"}
              label={t("DefaultStatuses")}
            />
          </TabPanel>
        </div>
        <div className={styles["manage-substatuses-filter-inputs"]}>
          <div className={styles["manage-substatuses-input-container"]}>
            <SearchSubstatus onSearchChange={searchChange} />
          </div>
          {substatusDataStore.selectedTab === "manage-substatuses" && (
            <div className={styles["manage-substatuses-add-button"]}>
              <AddButton
                disabled={
                  transitionStore?.isLoading ||
                  substatusDataStore?.hasSelectedSubstatus
                }
                onClick={() => {
                  substatusDataStore?.setNewSubstatus();
                }}
              >
                {t("AddSubstatus")}
              </AddButton>
            </div>
          )}
        </div>
      </div>
    );
  };

  const searchChange = () => {
    setTimeout(() => {
      selectSubstatusOnPage();
    });
  };

  const selectSubstatusOnPage = () => {
    const selectedElement = document.querySelector("#selected-substatus");
    if (selectedElement) {
      selectedElement.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  return render();
}

export default inject(
  "substatusDataStore",
  "transitionStore"
)(observer(Header));
