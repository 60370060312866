import styles from "./header.module.css";

import SearchClients from "../search-clients";
import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import { TabItem, TabPanel } from "../../../../components/tab-panel";

export interface HeaderProps {
  clientOverviewStore?: ClientOverviewStore;
}

function Header(props: HeaderProps) {
  const { t } = useTranslation("client-grid");

  const render = () => {
    const { clientOverviewStore } = props;
    return (
      <div className={styles["clients-overview-header"]}>
        <div className={styles["clients-overview-tabs"]}>
          <TabPanel
            activeKey={clientOverviewStore!.selectedTab}
            onItemChange={(key: string) => {
              clientOverviewStore!.setSelectedTab(key);
            }}
          >
            <TabItem itemKey={"client-status"} label={t("ClientStatus")} />
            <TabItem itemKey={"alarm-settings"} label={t("AlarmOverview")} />
          </TabPanel>
        </div>

        <div className={styles["clients-overview-filter-inputs"]}>
          <div className={styles["clients-overview-search"]}>
            <SearchClients onSearchChange={onSearchChange} />
          </div>
        </div>
      </div>
    );
  };

  const onSearchChange = () => {
    const { clientOverviewStore } = props;

    clientOverviewStore!.executeOnReload = () => {
      selectClientOnPage();
      clientOverviewStore!.executeOnReload = () => {};
    };

    setTimeout(() => {
      selectClientOnPage();
    });
  };

  const selectClientOnPage = () => {
    const selectedClientElement = document.querySelector("#selected-client");
    if (selectedClientElement) {
      selectedClientElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  return render();
}

export default inject("clientOverviewStore")(observer(Header));
