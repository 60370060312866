import styles from "./alert-icons.module.css";

import AlarmSettingsCell from "../../../alert-overview-grid/cells/alert-settings-cell";
import { ClientOverviewStore } from "../../../../store/store";
import { inject, observer } from "mobx-react";

interface CardAlertIconsProps {
  iconData: any;
  clientOverviewStore?: ClientOverviewStore;
}

function CardAlertIcons(props: CardAlertIconsProps) {
  const { iconData } = props;
  const clientOverviewStore = props.clientOverviewStore!;

  const night = clientOverviewStore.nightTimeAlerts;

  const render = () => {
    return <AlarmSettingsCell nightTime={night} data={iconData} showTooltip />;
  };

  return render();
}

export default inject("clientOverviewStore")(observer(CardAlertIcons));
