import { FilterField } from "../../../components/form/filter-field";
import { observer, inject } from "mobx-react";
import { CameraDataStore, EmptyCamera } from "../store/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Filter } from "../../../components/svg";
import { CameraData, ILocationData } from "../store/types";

export interface FilterCameraProps {
  onFilterChange(): void;
  cameraDataStore?: CameraDataStore;
}

function FilterCamera(props: FilterCameraProps) {
  const [filterValue, setFilterValue] = useState("");
  const [isFilterItemSelected, setIsFilterItemSelected] = useState(false);
  const { cameraDataStore, onFilterChange } = props;
  const { t } = useTranslation("filter-camera");

  const filterInputChange = (value: any) => {
    setIsFilterItemSelected(false);
    setFilterValue(value);

    if (value === "") {
      clearFilterSelection();
    }
  };

  const clearFilterSelection = () => {
    const { cameraDataStore } = props;

    if (!cameraDataStore) return;
    if (!cameraDataStore.hasSelectedFilterId) return;

    cameraDataStore.setFilteringData(undefined);
    cameraDataStore.setSelectedCamera(EmptyCamera);
    onFilterChange();
  };

  const getFilterValues = () => {
    if (!filterValue || !cameraDataStore) return [];

    const usedLocations: ILocationData[] = [];
    const addedLocationsMap: any = {};

    cameraDataStore.cameras.forEach((cameraData: CameraData) => {
      cameraData.locations.forEach((location: ILocationData) => {
        if (!addedLocationsMap[location.locationId]) {
          addedLocationsMap[location.locationId] = 1;
          usedLocations.push(location);
        }
      });
    });

    return usedLocations
      .filter((value: ILocationData) => {
        return value.locationName
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      })
      .sort((a: ILocationData, b: ILocationData) => {
        return a.locationName.localeCompare(b.locationName, undefined, {
          numeric: true,
        });
      })
      .map((val, index) => {
        return { key: index, object: val, displayValue: val.locationName };
      });
  };

  const setFilterSelection = (item: any) => {
    cameraDataStore!.setFilteringData(item.locationId);
    cameraDataStore!.setSelectedCamera(EmptyCamera);

    setFilterValue(item.locationName);
    setIsFilterItemSelected(true);
    onFilterChange();
  };

  const filterValues = getFilterValues();

  return (
    <FilterField
      icon={Filter}
      placeholder={t("Filter by room")}
      inputValue={filterValue}
      values={filterValues}
      onValueChange={(value) => filterInputChange(value)}
      onSelect={setFilterSelection}
      isOpen={!isFilterItemSelected && filterValues.length !== 0}
      isDisabled={cameraDataStore!.hasSelectedCamera}
    />
  );
}

export default inject("cameraDataStore")(observer(FilterCamera));
