import "./footer.css";
import { Ellipse, FooterLogo } from "./svg";
import { TransitionStore } from "../store";
import { inject, observer } from "mobx-react";
import { AboutDialog } from "./about-dialog-content";
import { GlobalDialogData } from "../store/transition-store";
import { NoConnectionModal } from "../utils/NoConnectionDialog";
import { useTranslation } from "react-i18next";
import { readRequest } from "../web";
import { useEffect, useState } from "react";

export interface FooterProps {
  transitionStore?: TransitionStore;
}

function Footer(props: FooterProps) {
  const { t } = useTranslation("info");
  const { transitionStore } = props;
  const [version, setVersion] = useState("1.0");
  const [buildDate, setBuildDate] = useState("1.0");
  const { globalWarningMessage } = transitionStore!;

  const handleAboutClick = () => {
    var content = <AboutDialog version={version} t={t} buildDate={buildDate} />;

    const aboutDialogData = {
      actionButtonData: [],
      title: t("AboutSmartSense"),
      message: content,
      canBeClosed: true,
    };

    transitionStore!.showGlobalDialog(aboutDialogData);
  };

  useEffect(() => {
    readVersion();
    readBuildDate();
  }, []);

  const readVersion = () => {
    readRequest("/api/info")
      .then((data: any) => {
        setVersion(data.data);
      })
      .catch((ajaxError: any) => {
        const { transitionStore } = props;
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore?.showGlobalDialog(dialog);
      });
  };

  const readBuildDate = () => {
    readRequest("/api/info/build-date")
        .then((data: any) => {
            setBuildDate(data.data);
        })
        .catch((ajaxError: any) => {
            const { transitionStore } = props;
            const dialog: GlobalDialogData = NoConnectionModal(t);

            transitionStore?.showGlobalDialog(dialog);
        });
    };

  return (
    <div className="layout-footer">
      <div className="footer-logo">
        <FooterLogo />
      </div>
      {globalWarningMessage && (
        <div className="layout-footer-warning-content">
          <Ellipse />
          <div className="layout-footer-warning-message">
            {globalWarningMessage}
          </div>
        </div>
      )}

      <div className="about-button" onClick={handleAboutClick}>
        {t("FooterAbout")}
      </div>
    </div>
  );
}

const injectedComponent = inject("transitionStore")(observer(Footer));
export { injectedComponent as Footer };
