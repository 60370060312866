import "./dropdown-grid.css";
import * as React from "react";
import classnames from "classnames";
import { ArrowDown } from "./svg";

interface IDropdownGridProps {
  defaultValue: any;
  data: any[];
  onChange: (value: any, index: number) => void;
}

export default class DropdownGrid extends React.Component<IDropdownGridProps> {
  private dropdown: HTMLElement;
  private arrow: HTMLDivElement;
  private menu: HTMLDivElement;
  private selectedValue: HTMLDivElement;
  private items: HTMLDivElement[] = [];
  private selectedIndex = 0;
  private isOpen = false;

  public render() {
    const { defaultValue } = this.props;

    const touchEvent = "ontouchstart" in window ? "touchstart" : "click";
    document.addEventListener(touchEvent, this.handleOutsideClick);

    return (
      <div
        className="dropdown-grid"
        ref={(e) => (this.dropdown = e as HTMLElement)}
      >
        <div className="clickable-area" onClick={this.dropdownClick}>
          <div
            className="selected-value"
            ref={(e) => (this.selectedValue = e as HTMLDivElement)}
          >
            {defaultValue}
          </div>
          <div
            className="arrow"
            ref={(e) => (this.arrow = e as HTMLDivElement)}
          >
            <ArrowDown />
          </div>
        </div>
        <div className="menu" ref={(e) => (this.menu = e as HTMLDivElement)}>
          {this.drawColumn(0)}
          {this.drawColumn(1)}
          {this.drawColumn(2)}
        </div>
      </div>
    );
  }

  private drawColumn = (index: number) => {
    const rowIndexStart = 4 * index;

    return (
      <div className={`menu-col`}>
        {this.drawItem(rowIndexStart)}
        {this.drawItem(rowIndexStart + 1)}
        {this.drawItem(rowIndexStart + 2)}
        {this.drawItem(rowIndexStart + 3)}
      </div>
    );
  };

  private drawItem = (index: number) => {
    const { defaultValue, data } = this.props;
    const selected = data[index] === defaultValue;
    if (selected) {
      this.selectedIndex = index;
    }

    return (
      <div
        className={classnames(`menu-item`, {
          selected: selected,
        })}
        ref={(e) => (this.items[index] = e as HTMLDivElement)}
        onClick={() => this.clickItem(index)}
      >
        {data[index]}
      </div>
    );
  };

  private clickItem = (index: number) => {
    this.closeDropdown();

    const { data, onChange } = this.props;
    this.items[this.selectedIndex].classList.remove("selected");
    this.items[index].classList.add("selected");
    this.selectedValue.innerText = data[index];
    this.selectedIndex = index;

    onChange(data[index], index);
  };

  private dropdownClick = () => {
    if (this.isOpen) {
      this.closeDropdown();
      return;
    }
    this.openDropdown();
  };

  private openDropdown = () => {
    if (this.areReferencesNotSet()) {
      return;
    }

    this.arrow.classList.remove("initial");
    this.arrow.classList.add("rotate");
    this.menu.classList.remove("hide");
    this.menu.classList.add("show");

    this.isOpen = true;
    this.menu.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  private closeDropdown = () => {
    if (this.areReferencesNotSet()) {
      return;
    }

    this.arrow.classList.remove("rotate");
    this.arrow.classList.add("initial");
    this.menu.classList.remove("show");
    this.menu.classList.add("hide");

    this.isOpen = false;
  };

  private areReferencesNotSet = () => {
    return !this.arrow || !this.menu || !this.selectedValue || !this.dropdown;
  };

  private handleOutsideClick = (event: any) => {
    if (!this.isOpen) {
      return;
    }
    if (!this.dropdown.contains(event.target)) {
      this.closeDropdown();
    }
  };
}
