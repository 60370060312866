import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { CameraDataStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import FilterCamera from "./../../components/filter-camera";
import SearchCamera from "./../../components/search-camera";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import { AuthStore, TransitionStore } from "../../../../store";
import webResource from "./../../web";
import { GlobalDialogData } from "../../../../store/transition-store";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import AddButton from "../../../../components/buttons/AddButton";
import { ExpandedState } from "../../../../components/table/table";

export interface HeaderProps {
  cameraDataStore?: CameraDataStore;
  authStore?: AuthStore;
  transitionStore?: TransitionStore;
}

function Header(props: HeaderProps) {
  const { t } = useTranslation("camera-grid");
  const cameraDataStore = props.cameraDataStore!;
  const transitionStore = props.transitionStore!;
  const authStore = props.authStore!;

  const render = () => {
    return (
      <div className={styles["manage-cameras-page-header"]}>
        <div className={styles["manage-cameras-tabs"]}>
          <TabPanel activeKey={"manage-cameras"}>
            <TabItem itemKey={"manage-cameras"} label={t("Manage Cameras")} />
          </TabPanel>
        </div>
        <div className={styles["manage-cameras-filter-inputs"]}>
          <div className={styles["manage-cameras-input-container"]}>
            <FilterCamera onFilterChange={filterChange} />
          </div>
          <div className={styles["manage-cameras-input-container"]}>
            <SearchCamera onSearchChange={searchChange} />
          </div>
          {addButton()}
        </div>
      </div>
    );
  };

  const addButton = () => {
    return (
      authStore.isAdmin() && (
        <div className={styles["manage-cameras-add-button"]}>
          <AddButton
            disabled={
              transitionStore.isLoading ||
              cameraDataStore.expandedCamera !== ExpandedState.COLLAPSED
            }
            onClick={() => {
              cameraDataStore.setNewCamera();
              setLocationForNewCamera();
            }}
          >
            {t("Add Camera")}
          </AddButton>
        </div>
      )
    );
  };

  const searchChange = () => {
    setTimeout(() => {
      selectCameraOnPage();
    });
  };

  const filterChange = () => {
    const cameraSorting = cameraDataStore.sorting;

    readAllCameraData(cameraSorting);
  };

  const setLocationForNewCamera = () => {
    if (cameraDataStore.filteringLocationId) {
      const data = cameraDataStore.locations.find(
        (r) => r.locationId === cameraDataStore.filteringLocationId
      );

      if (data) {
        cameraDataStore.selectedCamera.locations!.push(data);
      }
    }
  };

  const selectCameraOnPage = () => {
    const selectedElement = document.querySelector("#selected-search-camera");
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  const readAllCameraData = (sorted: any[]) => {
    cameraDataStore.setIsDataLoading(true);
    const cameraData = {
      SortOptions: sorted,
      LocationId: cameraDataStore.filteringLocationId,
    };

    return webResource
      .readAll(cameraData)
      .then((ajaxResponse: any) => {
        const response = ajaxResponse.data;
        cameraDataStore.setCameras(response.data);
        cameraDataStore.setLocations(response.availableLocations);
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore.showGlobalDialog(dialog);
      })
      .finally(() => {
        cameraDataStore.setIsDataLoading(false);
      });
  };

  return render();
}

export default inject(
  "cameraDataStore",
  "authStore",
  "transitionStore"
)(observer(Header));
