import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface AddressInputProps {
  peripheralDataStore?: PeripheralDataStore;
}

function AddressInput(props: AddressInputProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const { t } = useTranslation("peripheral-grid");
  const address = peripheralDataStore.selectedPeripheral.address;

  return (
    <TextFieldInput
      value={address}
      label={t("Address").toUpperCase()}
      inputProps={{ pattern: "^[-0-9A-Fa-f]{0,10}" }}
      placeholder={t("Address")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;

        peripheralDataStore.setSelectedPeripheralAdress(event.target.value);
        peripheralDataStore.validateSelectedPeripheral();
      }}
    />
  );
}

export default inject("peripheralDataStore")(observer(AddressInput));
