import styles from "./edit-camera.module.css";

import { inject, observer } from "mobx-react";
import { ILocationData } from "../../store/types";
import { CameraDataStore } from "../../store/store";
import CameraNameInput from "./components/camera-name-input";
import RTSPInput from "./components/rtsp-input";
import GenericToggle from "./components/generic-toggle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DistanceInput from "./components/distance-input";
import EditCameraLocation from "./components/camera-location";
import CancelButton from "../../../../components/buttons/CancelButton";
import SaveButton from "../../../../components/buttons/SaveButton";
import AddRoomButton from "./buttons/AddRoomButton";
import { scrollIntoViewAndAlign } from "../../../../utils/scroll";
import { runInAction } from "mobx";

interface EditCameraProps {
  cameraDataStore?: CameraDataStore;
  saveLocked?: boolean;
  onSaveClick(): void;
  onCancelClick(): void;
}

function EditCamera(props: EditCameraProps) {
  let editComponentElement: HTMLDivElement;
  const cameraDataStore = props.cameraDataStore!;
  const { saveLocked, onSaveClick, onCancelClick } = props;
  const emptyLocation: ILocationData = {
    locationId: 0,
    locationName: "emptyLocation",
    locationPath: "",
    distance: 0,
  };
  const { t } = useTranslation(["camera-grid"]);
  const { t: commonT } = useTranslation("common");

  useEffect(() => {
    scrollIntoViewAndAlign(editComponentElement);

    return function unmount() {
      runInAction(() => {
        cameraDataStore.errors = null;
        cameraDataStore.errorMessages = null;
        cameraDataStore.setValidated(true);
      });
    };
  }, []);

  const render = () => {
    return (
      <tr
        className={styles["edit-camera"]}
        ref={(control: HTMLTableRowElement) => {
          editComponentElement = control;
        }}
      >
        <td>
          <div
            className={`${styles["edit-column-content"]} ${styles["first-column"]}`}
          >
            <CameraNameInput />
          </div>
        </td>

        <RTSPInput />

        <td>
          <div className={styles["edit-column-content"]}>
            <EditCameraLocation />
            {AddButton()}
          </div>
        </td>

        <td>
          <div className={styles["edit-column-content"]}>
            <DistanceInput />
          </div>
        </td>

        <GenericToggle />

        <td className={styles["action-column"]}>
          <div className={styles["action-buttons-container"]}>
            <div className={styles["action-buttons"]}>
              <CancelButton
                onClick={() => {
                  onCancelClick();
                }}
              >
                {commonT("Cancel")}
              </CancelButton>

              <SaveButton
                onClick={() => {
                  onSaveClick();
                }}
                disabled={saveLocked}
              >
                {commonT("Save")}
              </SaveButton>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const addNewEmptyLocationCell = () => {
    const componentId = cameraDataStore.getUniqueListId();
    const locationList = cameraDataStore.selectedCamera.locations;

    locationList.push({
      ...emptyLocation,
      listUniqueId: componentId,
    });
  };

  const isAddButtonDisabled = () => {
    return cameraDataStore.selectedCamera.locations.some(
      (location: ILocationData) =>
        location === undefined || location.locationId === 0
    );
  };

  const AddButton = () => {
    return (
      cameraDataStore.filteringLocationId == null && (
        <div className={styles["add-location-button-container"]}>
          <AddRoomButton
            disabled={isAddButtonDisabled()}
            onClick={() => {
              addNewEmptyLocationCell();
              cameraDataStore.validateSelectedCamera();
            }}
          >
            {t("Add new room")}
          </AddRoomButton>
        </div>
      )
    );
  };

  return render();
}

export default inject("cameraDataStore")(observer(EditCamera));
