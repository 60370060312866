import { ValidationErrorItem, ValidationResult } from "joi";

export interface FlatErrorValidationResult extends Record<string, string[]> {}

export function flatErrorMap(
  errorResult: ValidationResult
): FlatErrorValidationResult | null {
  const mappedErrors: FlatErrorValidationResult = {};

  if (errorResult.error) {
    errorResult.error.details.forEach((error: ValidationErrorItem) => {
      const errorId: string = error.path.join("_");

      if (!mappedErrors[errorId]) mappedErrors[errorId] = [];
      mappedErrors[errorId].push(error.type);
    });

    return mappedErrors;
  }

  return {};
}
