import styles from "./client-profile-access-settings.module.css";

import { ClientProfileStore } from "../../store/store";
import ClientProfileAccessSettingsContent from "./client-profile-access-settings-content";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ClientProfileAccessSettingsHeader from "./client-profile-access-settings-header";
// import { Prompt } from "react-router";
import { Navigate, useLocation } from "react-router-dom";

interface ClientProfileAccessSettingsPageProps {
  clientProfileStore: ClientProfileStore;
  saveAccessSettings(): void;
  cancelAccessSettings(): void;
}

export function ClientProfileAccessSettingsPage(
  props: ClientProfileAccessSettingsPageProps
) {
  const { t } = useTranslation("client-profile");
  const { state } = useLocation();

  const render = () => {
    const {
      clientProfileStore,
      saveAccessSettings,
      cancelAccessSettings,
    } = props;
    const clientDetails = clientProfileStore.clientProfileDetails;
    const clientAccess = clientProfileStore.clientProfileAccessDetails;
    const { isEditingDay } = state;
    const prefix = isEditingDay ? "Day" : "Night";

    if (!clientDetails.clientName && !clientDetails.areaName) {
      const linkArr =window.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Navigate to={link} />;
    }

    return (
      <>
        <ClientProfileAccessSettingsHeader
          title={t(prefix + " time access")}
          clientDetails={clientDetails}
          saveAccessSettings={saveAccessSettings}
          cancelAccessSettings={cancelAccessSettings}
        />
        <div className={styles["client-profile-content"]}>
          <ClientProfileAccessSettingsContent
            clientAccess={clientAccess}
          />
        </div>
      </>
    );
  };

  return render();
}
export default observer(ClientProfileAccessSettingsPage);
