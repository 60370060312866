import stores from "./store";

export interface ResponseStructure<Payload> {
  response: Response;
  data?: Payload;
}

const includeCredentials = {
  withCredentials: true,
};

const httpMethods = {
  get: { method: "GET" },
  post: { method: "POST" },
  put: { method: "PUT" },
  delete: { method: "DELETE" },
};

function uploadFileRequest<PayloadIn = any>(
  url: string,
  payload: FormData
): Promise<ResponseStructure<PayloadIn>> {
  const headers = new Headers();
  headers.append("Authorization", stores.authStore.authentication.token);

  const req: RequestInit = {
    body: payload,
    ...httpMethods.post,
    ...includeCredentials,
    headers,
  };

  return fetch(url, req).then((response: Response) => {
    return response
      .json()
      .then((jsonData: PayloadIn) => {
        return { response, data: jsonData };
      })
      .catch(() => {
        return { response, data: undefined };
      })
      .then((data: ResponseStructure<PayloadIn>) => {
        if (response.ok) {
          return data;
        }
        throw data;
      });
  });
}

function readRequest<PayloadIn = any>(
  url: string
): Promise<ResponseStructure<PayloadIn>> {
  const headers = new Headers();
  headers.append("Authorization", stores.authStore.authentication.token);

  const req: RequestInit = {
    ...httpMethods.get,
    ...includeCredentials,
    headers,
  };

  return fetch(url, req).then((response: Response) => {
    return response
      .json()
      .then((jsonData: PayloadIn) => {
        return { response, data: jsonData };
      })
      .catch(() => {
        return { response, data: undefined };
      })
      .then((data: ResponseStructure<PayloadIn>) => {
        if (response.ok) {
          return data;
        }
        throw data;
      });
  });
}

function createRequest<PayloadOut = any, PayloadIn = any>(
  url: string,
  payload?: PayloadOut
): Promise<ResponseStructure<PayloadIn>> {
  const headers = new Headers();
  headers.append("Authorization", stores.authStore.authentication.token);
  headers.append("Content-Type", "text/json");

  const req: RequestInit = {
    body: JSON.stringify(payload),
    headers,
    ...httpMethods.post,
    ...includeCredentials,
  };

  return fetch(url, req).then((response: Response) => {
    return response
      .json()
      .then((jsonData: PayloadIn) => {
        return { response, data: jsonData };
      })
      .catch(() => {
        return { response, data: undefined };
      })
      .then((data: ResponseStructure<PayloadIn>) => {
        if (response.ok) {
          return data;
        }
        throw data;
      });
  });
}

function updateRequest<PayloadOut = any, PayloadIn = any>(
  url: string,
  payload: PayloadOut
): Promise<ResponseStructure<PayloadIn>> {
  const headers = new Headers();
  headers.append("Authorization", stores.authStore.authentication.token);
  headers.append("Content-Type", "text/json");

  const req: RequestInit = {
    body: JSON.stringify(payload),
    headers,
    ...httpMethods.put,
    ...includeCredentials,
  };

  return fetch(url, req).then((response: Response) => {
    return response
      .json()
      .then((jsonData: PayloadIn) => {
        return { response, data: jsonData };
      })
      .catch(() => {
        return { response, data: undefined };
      })
      .then((data: ResponseStructure<PayloadIn>) => {
        if (response.ok) {
          return data;
        }
        throw data;
      });
  });
}

function deleteRequest<PayloadOut = any, PayloadIn = any>(
  url: string,
  payload: PayloadOut
): Promise<ResponseStructure<PayloadIn>> {
  const headers = new Headers();
  headers.append("Authorization", stores.authStore.authentication.token);
  headers.append("Content-Type", "text/json");

  const req: RequestInit = {
    body: JSON.stringify(payload),
    headers,
    ...httpMethods.delete,
    ...includeCredentials,
  };

  return fetch(url, req).then((response: Response) => {
    return response
      .json()
      .then((jsonData: PayloadIn) => {
        return { response, data: jsonData };
      })
      .catch(() => {
        return { response, data: undefined };
      })
      .then((data: ResponseStructure<PayloadIn>) => {
        if (response.ok) {
          return data;
        }
        throw data;
      });
  });
}

const postRequest = createRequest;

export {
  readRequest,
  createRequest,
  postRequest,
  updateRequest,
  deleteRequest,
  uploadFileRequest,
};
