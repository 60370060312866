import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import "./side-menu.css";
import SideMenuButton from "./components/side-menu-button/side-menu-button";
import { matchPath, useNavigate, useLocation } from "react-router-dom";

import {
  ClientOverview,
  ClientSettings,
  SensorSettings,
  CameraSettings,
  MariSettings,
  PeripheralSettings,
  HeaderProfileIcon,
  AlertSettings,
  SideMenuExpand,
  SideMenuCollapse,
} from "../svg/index";

import { AuthStore, FeatureStore, TransitionStore } from "../../store";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { CreateConfirmationDialog } from "../../utils/ConfirmationDialog";

export interface SideMenuProps {
  authStore?: AuthStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;
}

const SIDE_MENU_EXPANDED_STORAGE_KEY = "SideMenuExpanded";

function SideMenu(props: SideMenuProps) {
  const { t } = useTranslation();
  const [sideMenuExpanded, setSideMenuExpanded] = useState(true);
  const { transitionStore } = props;
  const { navigateWithPrompt } = transitionStore!;
  const OverviewPath: string = "/residents-overview";
  const ManageClientsPath: string = "/manage-residents";
  const ManageSensorsPath: string = "/manage-sensors";
  const ManageCamerasPath: string = "/manage-cameras";
  const ManageMarisPath: string = "/manage-maris";
  const ManagePeripheralsPath: string = "/manage-peripherals";
  const ManageSubstatusesPath: string = "/manage-statuses";
  const ManageAlertsPath: string = "/manage-alerts";

  const location = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem(SIDE_MENU_EXPANDED_STORAGE_KEY) === null ||
      localStorage.getItem(SIDE_MENU_EXPANDED_STORAGE_KEY) === undefined
    ) {
      localStorage.setItem(SIDE_MENU_EXPANDED_STORAGE_KEY, "true");
    }
    setSideMenuExpanded(
      localStorage.getItem(SIDE_MENU_EXPANDED_STORAGE_KEY) === "true"
    );
  }, []);

  const render = () => {
    return (
      <div className="sidemenu-container">
        <div
          className={classnames("nav-container", {
            expanded: !!sideMenuExpanded,
          })}
        >
          {clientOverviewButton()}
          {clientSettingsButton()}
          {sensorSettingsButton()}
          {cameraSettingsButton()}
          {mariSettingsButton()}
          {peripheralSettingsButton()}
          {substatusSettingsButton()}
          {alertSettingsButton()}
          {expandButton()}
        </div>
      </div>
    );
  };

  const clientOverviewButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      (authStore!.isNurse() || authStore!.isNurseAdmin()) && (
        <SideMenuButton
          buttonId={"client-overview"}
          text={t("SidemenuClientsOverview")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(OverviewPath);
            });
          }}
          isActive={isOnPath(OverviewPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <ClientOverview />
        </SideMenuButton>
      )
    );
  };

  const clientSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      authStore!.isNurseAdmin() &&
      featureStore!.features.manageClients && (
        <SideMenuButton
          buttonId={"manage-clients"}
          text={t("SidemenuManageClients")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManageClientsPath);
            });
          }}
          isActive={isOnPath(ManageClientsPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <ClientSettings />
        </SideMenuButton>
      )
    );
  };

  const sensorSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      (authStore!.isNurseAdmin() || authStore!.isAdmin()) && (
        <SideMenuButton
          buttonId={"manage-sensors"}
          text={t("SidemenuManageSensors")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManageSensorsPath);
            });
          }}
          isActive={isOnPath(ManageSensorsPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <SensorSettings />
        </SideMenuButton>
      )
    );
  };

  const cameraSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      (authStore!.isNurseAdmin() || authStore!.isAdmin()) && (
        <SideMenuButton
          buttonId={"manage-cameras"}
          text={t("SidemenuManageCameras")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManageCamerasPath);
            });
          }}
          isActive={isOnPath(ManageCamerasPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <CameraSettings />
        </SideMenuButton>
      )
    );
  };

  const mariSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;
    return (
      featureStore!.features.manageMaris &&
      authStore!.isAdmin() && (
        <SideMenuButton
          buttonId={"manage-maris"}
          text={t("SidemenuManageMaris")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManageMarisPath);
            });
          }}
          isActive={isOnPath(ManageMarisPath)}
          collapsed={!sideMenuExpanded}
        >
          <MariSettings />
        </SideMenuButton>
      )
    );
  };

  const peripheralSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      featureStore!.features.managePeripherals &&
      authStore!.isAdmin() && (
        <SideMenuButton
          buttonId={"manage-peripherals"}
          text={t("SidemenuManagePeripherals")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManagePeripheralsPath);
            });
          }}
          isActive={isOnPath(ManagePeripheralsPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <PeripheralSettings />
        </SideMenuButton>
      )
    );
  };

  const substatusSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      authStore!.isAdmin() && (
        <SideMenuButton
          buttonId={"manage-statuses"}
          text={t("SidemenuManageSubstatuses")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManageSubstatusesPath);
            });
          }}
          isActive={isOnPath(ManageSubstatusesPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <HeaderProfileIcon />
        </SideMenuButton>
      )
    );
  };

  const alertSettingsButton = () => {
    const { authStore } = props;
    const { featureStore } = props;

    return (
      authStore!.isAdmin() && (
        <SideMenuButton
          buttonId={"manage-alerts"}
          text={t("SidemenuManageAlerts")}
          onButtonClick={() => {
            navigateWithPrompt(t, () => {
              history(ManageAlertsPath);
            });
          }}
          isActive={isOnPath(ManageAlertsPath)}
          collapsed={!sideMenuExpanded}
          isDisabled={!featureStore!.features.initialConfigCompleted}
        >
          <AlertSettings />
        </SideMenuButton>
      )
    );
  };

  const expandButton = () => {
    return (
      <div className="sidemenu-expand-button">
        <IconButton
          onClick={() => {
            localStorage.setItem(
              SIDE_MENU_EXPANDED_STORAGE_KEY,
              !sideMenuExpanded + ""
            );
            setSideMenuExpanded(!sideMenuExpanded);
          }}
        >
          {sideMenuExpanded === false ? (
            <SideMenuExpand />
          ) : (
            <SideMenuCollapse />
          )}
        </IconButton>
      </div>
    );
  };

  const isOnPath = (pathToMatch: string) => {
    var currentPath = location!.pathname;
    var isMatch = matchPath(
      {
        path: pathToMatch,
        caseSensitive: true,
        end: true,
      },
      currentPath
    );
    return isMatch !== null;
  };

  return render();
}

export default inject(
  "authStore",
  "transitionStore",
  "featureStore"
)(observer(SideMenu));
