export enum StatusIdentifier {
  Unknown = 0,
  InBed,
  InBedWithSecondPersonInRoom,
  InRoom,
  InBathroom,
  OutOfRoom,
  Inactivity,
  OutOfBed,
  InChair,
  Off,
  ExternalStatus,
}

export interface ISubstatusData {
  statusId?: number | null;
  parentStatusId?: number;
  parentStatusName: string;
  statusName: string;
  alternateText: string | null;
  statusIdentifier: StatusIdentifier;
  statusIcon: string;
  statusIconChanged: boolean;
  newStatusIcon: FormData | null;
  alertId: number | null;
}

export class SubstatusData implements ISubstatusData {
  statusId?: number | null;
  alertId: number | null;
  parentStatusId?: number;
  parentStatusName: string;
  statusName: string;
  alternateText: string | null;
  statusIdentifier: StatusIdentifier;
  statusIcon: string;

  statusIconChanged: boolean;
  newStatusIcon: FormData | null;

  public isNewSubstatus?: boolean;
}

export interface IAlertData {
  alertId: number;
  alertName: string;
}

export class AlertData implements IAlertData {
  alertId: number;
  alertName: string;
}

export interface IUpdateSubstatusData {
  statusId: number;
  statusName: string;
  parentStatusId?: number;
  statusIcon: FormData | null;
  statusIconChanged: boolean;
}

export interface IListSubstatuses {
  SortOptions: any;
}
