import "./simple-button.css";

import classNames from "classnames";

export interface SimpleButtonProps {
  text?: string;
  clickEvent?(event: MouseEvent): void;
  disabled?: boolean;
}

export function SimpleButton(props: SimpleButtonProps) {
  return (
    <div
      onClick={(event: any) => {
        if (props.clickEvent && !props.disabled) props.clickEvent(event);
      }}
      className={classNames(
        "simple-button",
        {
          "disabled": props.disabled,
          "active": !props.disabled,
        }
      )}
    >
      <div className={"button-text"}>{props.text}</div>
    </div>
  );
}
