import styles from "../../page.module.css";

import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import { ViewItem, ViewSwitch } from "../../../../components/view-switch";
import CardViewGrid from "../card-view-grid/CardViewGrid";
import ResidentStatusGrid from "../resident-status-grid/ResidentStatusGrid";
import { CARD_TYPES } from "../card-view-grid/card-item/card-item";
import webResource from "../../web";
import { ClientDataConverter } from "../../store/convertor";
import { runInAction } from "mobx";
import { ClientDataIn } from "../../store/typesIn";
import { useEffect } from "react";
import { FeatureStore, TransitionStore } from "../../../../store";
import { useTranslation } from "react-i18next";
import { GlobalDialogData } from "../../../../store/transition-store";
import {
  NO_CONNECTION_MODAL_ID,
  NoConnectionModal,
} from "../../../../utils/NoConnectionDialog";

const GRID_REFRESH_RATE_MS = 7500;

export interface ResidentStatusTabProps {
  clientOverviewStore?: ClientOverviewStore;
  transitionStore?: TransitionStore;
  featureStore?: FeatureStore;
}

function ResidentStatusTab(props: ResidentStatusTabProps) {
  const clientOverviewStore = props.clientOverviewStore!;
  const transitionStore = props.transitionStore!;
  const featureStore = props.featureStore!;

  let gridRefreshTimer: any = undefined;
  const { t } = useTranslation("client-grid");

  useEffect(() => {
    resetRefreshTimeout();

    return () => {
      if (gridRefreshTimer) clearTimeout(gridRefreshTimer!);
    };
  }, []);

  useEffect(() => {
    clientOverviewStore.setIsDataLoading(true);
    fetchData()
      .then(() => {
        if (transitionStore.id === NO_CONNECTION_MODAL_ID) {
          transitionStore.hideGlobalDialog();
        }
        clientOverviewStore.executeOnReload();
        scrollIntoViewSelectedClient();
      })
      .catch((ajaxError: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);

        transitionStore?.showGlobalDialog(dialog);
      })
      .finally(() => {
        clientOverviewStore.setIsDataLoading(false);
      });
  }, [clientOverviewStore.assignedClients.sortOptions]);

  const scrollIntoViewSelectedClient = () => {
    setTimeout(() => {
      const selectedClientElement = document.querySelector("#selected-client");
      if (selectedClientElement) {
        selectedClientElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    });
  };

  const validateStaleData = () => {
    if (!clientOverviewStore.isDataStale) {
      transitionStore.setGlobalWarningMessage("");
      return;
    }

    if (!featureStore.features.ofeliaIntegrationEnabled)
      transitionStore.setGlobalWarningMessage(t("StaleDataMessage"));
  };

  const silentFetchData = () => {
    const resultPromise = fetchData();
    resultPromise.then(() => {
      resetRefreshTimeout();
    });
  };

  const resetRefreshTimeout = () => {
    if (gridRefreshTimer) clearTimeout(gridRefreshTimer);

    const newGridRefreshTimer = setTimeout(
      silentFetchData,
      GRID_REFRESH_RATE_MS
    );
    gridRefreshTimer = newGridRefreshTimer;
  };

  const fetchData = () => {
    const { sortOptions } = clientOverviewStore.assignedClients;

    return webResource.readMyClients(sortOptions).then((ajaxResponse) => {
      const response = ajaxResponse.data!;
      const { assignedClientsForNurse, isDataStale } = response;

      const clientList = assignedClientsForNurse.map((client: ClientDataIn) => {
        return ClientDataConverter.toStore(client);
      });

      runInAction(() => {
        clientOverviewStore.clients = clientList;
        clientOverviewStore.isDataStale = isDataStale;
      });
      validateStaleData();
    });
  };

  const render = () => {
    return (
      <ViewSwitch activeKey={clientOverviewStore.viewType}>
        <ViewItem itemKey="grid">
          <div className={styles["overflow-content"]}>
            <ResidentStatusGrid />
          </div>
        </ViewItem>
        <ViewItem itemKey="card">
          <div className={styles["overflow-content"]}>
            <CardViewGrid cardType={CARD_TYPES.Status} />
          </div>
        </ViewItem>
      </ViewSwitch>
    );
  };

  return render();
}

export default inject(
  "clientOverviewStore",
  "transitionStore",
  "featureStore"
)(observer(ResidentStatusTab));
