import styles from "./historical.module.css";

import moment from "moment";
import { observer, inject } from "mobx-react";
import { LastDaysPanel } from "./last-days.panel";
import web from "../../web";
import { IClientStatus } from "../../store/types";
import { TabContainer } from "../../components/tabs/tab-container";
import { useEffect } from "react";
import { ClientStatusesConverter } from "../../store/convertor";

export interface IHistoricalViewProps {
  clientId: number;
  clientDashboardStore?: any;
  t(value: string): string;
}

function HistoricalViewComponent(props: IHistoricalViewProps) {
  useEffect(() => {
    const { clientDashboardStore } = props;

    web
      .readStatusSummary(props.clientId)
      .then((result: any) => {
        const clientStatuses = result.data.clientStatuses as IClientStatus[];

        const clientStatusesList = clientStatuses.map((clientStatus) => {
          return ClientStatusesConverter.toStore(clientStatus);
        });

        clientDashboardStore.clientStatuses.replace(clientStatusesList);
      })
      .catch(() => {});
  }, []);

  const render = () => {
    const { clientDashboardStore, t } = props;
    const { clientStatuses } = clientDashboardStore;
    const inTheLastSevenDaysValue = inTheLastSevenDays();

    const thirtyDaysHoursData = clientStatuses.map(mapHours);
    const thirtyDaysTimesData = clientStatuses.map(mapTimes);

    const sevenDaysHoursData = clientStatuses
      .filter(inTheLastSevenDaysValue)
      .map(mapHours);
    const sevenDaysTimesData = clientStatuses
      .filter(inTheLastSevenDaysValue)
      .map(mapTimes);

    const lastStatus = clientDashboardStore.statusesAndAlerts.find(
      (el: any) => {
        return el.type === 1;
      }
    );

    if (lastStatus) {
      if (lastStatus.typeId === 2) {
        if (sevenDaysTimesData.length > 0) {
          sevenDaysTimesData[sevenDaysTimesData.length - 1].timesInBed -= 1;
          thirtyDaysTimesData[thirtyDaysTimesData.length - 1].timesInBed -= 1;
        }
      }
    }

    return (
      <div className={styles["historical-view"]}>
        <TabContainer
          defaultTab={0}
          tabList={[
            {
              component: (
                <LastDaysPanel
                  isSevenDaysView
                  hoursData={sevenDaysHoursData}
                  timesData={sevenDaysTimesData}
                  t={t}
                />
              ),
              title: t("Last 7 days"),
            },
            {
              component: (
                <LastDaysPanel
                  isSevenDaysView={false}
                  hoursData={thirtyDaysHoursData}
                  timesData={thirtyDaysTimesData}
                  t={t}
                />
              ),
              title: t("Last 30 days"),
            },
          ]}
        />
      </div>
    );
  };

  const mapHours = (c: IClientStatus) => {
    return {
      day: moment(c.timeStamp).format("D"),
      hoursInBathroom: c.hoursInBathroom,
      hoursInBed: c.hoursInBed,
      hoursInRoom: c.hoursInRoom,
      hoursOutOfRoom: c.hoursOutOfRoom,
    };
  };

  const mapTimes = (c: IClientStatus) => {
    return {
      day: moment(c.timeStamp).format("D"),
      timesInBed: c.timesInBed,
      timesInBathroom: c.timesInBathroom,
      timesInRoom: c.timesInRoom,
      timesOutOfRoom: c.timesOutOfRoom,
    };
  };

  const inTheLastSevenDays = () => {
    const today = moment().seconds(0).minutes(0).hours(0);
    return (c: IClientStatus) => {
      const entryDate = moment(c.timeStamp);
      const diffInDays = moment.duration(today.diff(entryDate)).asDays();

      return diffInDays < 8;
    };
  };

  return render();
}

export const HistoricalView = inject("clientDashboardStore")(
  observer(HistoricalViewComponent)
);
