import {
  updateRequest,
  postRequest,
  deleteRequest,
  uploadFileRequest,
} from "../../web";
import { IListMaris } from "./store/types";
import { MariListCallIn } from "./store/typesIn";
import {
  MariDataOut,
  MariListCallOut,
  MariUpdateCallOut,
} from "./store/typesOut";

const resourceUrl = "/api/inventory/manage-maris";

const webCalls = {
  readAll(mariData: IListMaris) {
    return postRequest<MariListCallOut, MariListCallIn>(
      `${resourceUrl}/list`,
      mariData
    );
  },

  update(mariData: MariDataOut) {
    return updateRequest<MariUpdateCallOut, null>(resourceUrl, mariData);
  },

  remove(mariId: number) {
    return deleteRequest<{ mariId: number }, null>(resourceUrl, { mariId });
  },

  uploadFile(data: FormData) {
    return uploadFileRequest<null>(`${resourceUrl}/uploadfile`, data);
  },
};

export default webCalls;
