import styles from "./search-mari.module.css";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MariDataStore } from "../store/store";
import { FilterField, ValueData } from "../../../components/form/filter-field";
import { Search } from "../../../components/svg";
import { inject, observer } from "mobx-react";
import { MariData } from "../store/types";

export interface SearchMariProps {
  onSearchChange(): void;
  mariDataStore?: MariDataStore;
}

function SearchMari(props: SearchMariProps) {
  const [searchValue, setSearchValue] = useState("");
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(false);
  const { mariDataStore, onSearchChange } = props;
  const { t } = useTranslation("search-mari");

  const searchInputChange = (value: any) => {
    setIsSearchItemSelected(false);
    setSearchValue(value);

    if (value === "") {
      clearSearchSelection();
    }
  };

  const clearSearchSelection = () => {
    if (!mariDataStore) return;

    mariDataStore.setHilightMari(null);
    onSearchChange();
  };

  const getSearchValues = () => {
    if (!searchValue || !mariDataStore) return [];

    return mariDataStore.maris
      .filter((value: MariData) => {
        return value.mariName.toLowerCase().includes(searchValue.toLowerCase());
      })
      .sort((a: MariData, b: MariData) => {
        return a.mariName.localeCompare(b.mariName, undefined, {
          numeric: true,
        });
      })
      .map((val, index) => {
        return {
          key: index,
          object: val,
          displayValue: val.mariName,
        };
      });
  };

  const setSearchSelection = (item: any) => {
    mariDataStore!.setHilightMari(item);

    setSearchValue(item.mariName);
    setIsSearchItemSelected(true);
    onSearchChange();
  };

  const searchContentRender = (value: ValueData) => {
    return (
      <div>
        <div className={styles["mari-name"]}>{value.object.mariName}</div>
      </div>
    );
  };

  const searchValues = getSearchValues();
  return (
    <FilterField
      icon={Search}
      contentRenderer={searchContentRender}
      placeholder={t("SearchMari")}
      inputValue={searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChange(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    />
  );
}

export default inject("mariDataStore")(observer(SearchMari));
