import OverflowLabel from "../../OverflowLabel";
import "./textcell.css";

export function TextCell(props: any) {
  return (
    <div className="text-cell">
      <div className="text-cell-container">
        <OverflowLabel text={props.text}></OverflowLabel>
      </div>
    </div>
  );
}
