import styles from "./current-location.module.css";

import { inject, observer } from "mobx-react";
import { ClientOverviewStore, IClientData } from "../../../../store/store";
import webResource from "../../../../web";
import { ILastLocation } from "../../../../store/types";
import { ReactComponent as LocationIcon } from "../../../../svg/location-button.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import classNames from "classnames";
import { FeatureStore } from "../../../../../../store";
import OverflowLabel from "../../../../../../components/OverflowLabel";

export interface CurrentLocationProps {
  clientID: number;
  clientOverviewStore?: ClientOverviewStore;
  featureStore?: FeatureStore;
}

function CurrentLocation(props: CurrentLocationProps) {
  const { clientID } = props;
  const clientOverviewStore = props.clientOverviewStore!;
  const featureStore = props.featureStore!;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("client-grid");

  const hasLocation = (original: IClientData) => {
    return (
      [5, 9].includes(original.statusId!) ||
      [5, 9].includes(original.parentStatusId!)
    );
  };

  const readClientLocation = () => {
    if (isLoading) return;

    setIsLoading(true);
    webResource
      .showLastLocation(clientID)
      .then((ajaxResponse) => {
        clientOverviewStore.lastLocations[clientID] =
          ajaxResponse.data as ILastLocation;

        const timeout = setTimeout(() => {
          clientOverviewStore.lastLocations[clientID] = undefined;
        }, 15000);
        clientOverviewStore.lastLocatonsTimers.push(timeout);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const textContent = () => {
    if (isLoading === true) return <div className={styles["loader"]} />;
    if (clientOverviewStore.lastLocations[clientID] !== undefined)
      return clientOverviewStore.lastLocations[clientID].name;
    return null;
  };

  const render = () => {
    const clientData = clientOverviewStore.clients.find((value) => {
      return value.id === clientID;
    });

    const canShowLastLocation =
      featureStore?.features.showLastLocation === true &&
      hasLocation(clientData!);

    return (
      <div className={styles["content-location"]}>
        {canShowLastLocation === true && (
          <>
            <div className={styles["location-text"]}>
              <OverflowLabel text={textContent()} />
            </div>

            <div
              className={styles["location-button"]}
              onClick={() => {
                readClientLocation();
              }}
            >
              <Tooltip title={t("CurrentLocationToolTip")}>
                <div
                  className={classNames(styles["icon"], {
                    [styles["locked"]]: isLoading,
                  })}
                >
                  <LocationIcon />
                </div>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    );
  };

  return render();
}

export default inject(
  "clientOverviewStore",
  "featureStore"
)(observer(CurrentLocation));
