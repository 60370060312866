import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { substatusDataSchema } from "../validation";
import { AlertData, StatusIdentifier, SubstatusData } from "./types";
import { ExpandedState } from "../../../components/table/table";
import { DEFAULT_BLACK_ICON } from "../../manage-alerts/store/store";

export const EmptySubstatus: SubstatusData = observable({
  statusId: undefined,
  alertId: null,
  parentStatusId: undefined,
  parentStatusName: "",
  statusName: "",
  alternateText: "",
  statusIdentifier: StatusIdentifier.Unknown,
  statusIcon: "",
  statusIconChanged: false,
  newStatusIcon: null,
});

export interface ISubstatusSort {
  id: string;
  desc: boolean;
}

export class SubstatusSort implements ISubstatusSort {
  public id: string;
  public desc: boolean;

  constructor(id: string, desc: boolean) {
    this.id = id;
    this.desc = desc;
  }
}

export class SubstatusDataStore {
  constructor() {
    makeObservable(this, {
      validationErrors: observable,
      substatuses: observable,
      alerts: observable,
      selectedSubstatusHasChanges: observable,
      selectedSubstatus: observable,
      highlightSubstatus: observable,
      statusSort: observable,
      expandedSubstatusIndex: observable,
      errors: observable,
      selectedTab: observable,
      searchValue: observable,
      errorMessages: observable,
      validated: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      hasSelectedSubstatus: computed,

      setStatusSort: action,
      setNewSubstatus: action,
      setSubstatuses: action,
      setAlerts: action,
      resetSelectedData: action,
      setSelectedSubstatus: action,
      setSelectedSubstatusParentData: action,
      setSelectedSubstatusName: action,
      setSelectedSubstatusHasChanges: action,
      setSelectedSubstatusIsNewStatus: action,
      setHilightSubstatus: action,
      closeExpanded: action,
      setExpandedSubstatusIndex: action,
      validateSelectedSubstatus: action,
      setValidated: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
    });
  }

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;
  public validated: boolean = true;
  public errorMessages: FlatErrorValidationResult | null = null;
  public validationErrors: ObservableMap<{}> = observable.map();
  public errors: FlatErrorValidationResult | null = null;
  public substatuses: SubstatusData[] = [];
  public alerts: AlertData[] = [];
  public statusSort: ISubstatusSort[] = [
    new SubstatusSort("statusName", false),
  ];
  public searchValue: string = "";
  public selectedSubstatusHasChanges: boolean = false;
  public expandedSubstatusIndex: number = ExpandedState.COLLAPSED;
  public selectedSubstatus: SubstatusData = {
    statusId: undefined,
    alertId: null,
    parentStatusId: undefined,
    parentStatusName: "",
    statusName: "",
    alternateText: "",
    statusIdentifier: StatusIdentifier.Unknown,
    statusIcon: "",
    statusIconChanged: false,
    newStatusIcon: null,
  };
  public oldSubstatusData: any = {
    statusId: undefined,
    alertId: null,
    parentStatusId: undefined,
    parentStatusName: "",
    statusName: "",
    statusIdentifier: StatusIdentifier.Unknown,
    statusIcon: "",
    statusIconChanged: false,
    newStatusIcon: null,
  };
  public highlightSubstatus: SubstatusData | null = {
    statusId: undefined,
    alertId: null,
    parentStatusId: undefined,
    parentStatusName: "",
    statusName: "",
    alternateText: "",
    statusIdentifier: StatusIdentifier.Unknown,
    statusIcon: "",
    statusIconChanged: false,
    newStatusIcon: null,
  };
  public selectedTab: string = "manage-substatuses";

  public setNewSubstatus() {
    const svg = new Blob([DEFAULT_BLACK_ICON], { type: "image/svg+xml" });
    const dataForm = new FormData();
    dataForm.append("statusIcon", svg);

    const data = {
      statusId: null,
      alertId: null,
      parentStatusId: undefined,
      parentStatusName: "",
      statusName: "",
      alternateText: "",
      statusIdentifier: StatusIdentifier.ExternalStatus,
      statusIcon: "",
      isNewSubstatus: true,
      statusIconChanged: false,
      newStatusIcon: dataForm,
    };

    this.selectedSubstatus = { ...data };
    this.oldSubstatusData = { ...data };
    this.selectedSubstatusHasChanges = false;
    this.expandedSubstatusIndex = ExpandedState.TOP;
  }

  public setSubstatuses(value: SubstatusData[]) {
    this.substatuses = value;
  }

  public setAlerts(value: AlertData[]) {
    this.alerts = value;
  }

  public setSelectedTab(tab: string) {
    this.selectedTab = tab;
  }

  public resetSelectedData() {
    this.selectedSubstatus = EmptySubstatus;
    const { statusIconChanged, parentStatusName, ...rest } = EmptySubstatus;
    this.oldSubstatusData = { ...rest };
    this.highlightSubstatus = null;
  }

  public setSelectedSubstatus(value: SubstatusData) {
    this.selectedSubstatus = { ...value };
    const { statusIconChanged, parentStatusName, ...rest } =
      this.selectedSubstatus;
    this.oldSubstatusData = { ...rest };
  }

  public setSelectedSubstatusParentData(
    parentStatusId: number | undefined,
    parentStatusName: string
  ) {
    this.selectedSubstatus = { ...this.selectedSubstatus };
    this.selectedSubstatus.parentStatusId = parentStatusId;
    this.selectedSubstatus.parentStatusName = parentStatusName;

    this.selectedSubstatusHasChanges = true;
  }

  public setSelectedSubstatusName(value: string) {
    this.selectedSubstatus = { ...this.selectedSubstatus };
    this.selectedSubstatus.statusName = value;
    this.selectedSubstatusHasChanges = true;
  }
  
  public setSelectedSubstatusAlternateText(value: string) {
    this.selectedSubstatus = { ...this.selectedSubstatus };
    this.selectedSubstatus.alternateText = value;
    this.selectedSubstatusHasChanges = true;
  }

  public setSelectedSubstatusHasChanges(value: boolean) {
    this.selectedSubstatusHasChanges = value;
  }

  public setSelectedSubstatusIsNewStatus(value: boolean) {
    this.selectedSubstatus = { ...this.selectedSubstatus };
    this.selectedSubstatus.isNewSubstatus = value;
  }

  public setSelectedSubstatusAlertId(value: number | null) {
    this.selectedSubstatus = { ...this.selectedSubstatus };
    this.selectedSubstatus.alertId = value;
    this.selectedSubstatusHasChanges = true;
  }

  public setSelectedSubstatusIcon(value: FormData | null) {
    this.selectedSubstatus = { ...this.selectedSubstatus };
    this.selectedSubstatus.newStatusIcon = value;
    this.selectedSubstatus.statusIconChanged = true;
    this.selectedSubstatusHasChanges = true;
  }

  public setHilightSubstatus(value: SubstatusData | null) {
    this.highlightSubstatus = value;
  }

  public setStatusSort(value: ISubstatusSort[]) {
    this.statusSort = value;
  }

  public getAlertName(alertId: number | null): string {
    if (alertId === null) return "";

    return this.alerts.find((a) => a.alertId === alertId)?.alertName ?? "";
  }

  public get hasSelectedSubstatus() {
    return this.expandedSubstatusIndex !== ExpandedState.COLLAPSED;
  }

  public closeExpanded() {
    this.expandedSubstatusIndex = ExpandedState.COLLAPSED;
  }

  public setExpandedSubstatusIndex(index: number) {
    this.expandedSubstatusIndex = index;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedSubstatus(value: string = "") {
    const validationResult = validateData<SubstatusData>(
      this.selectedSubstatus,
      substatusDataSchema
    );

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = null;

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}