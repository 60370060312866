import { KeyboardEvent } from "react";
import "./input-field.css";
import classnames from "classnames";
import { HidePassword, ShowPassword } from "../svg";
import { useState } from "react";

export interface InputFieldProps {
  placeholder?: string;
  value: string;
  passwordField?: boolean;
  onChange?(value: string, target?: any): void;
  onKeyUpEvent?(event: KeyboardEvent<HTMLInputElement>): void;
  error?: string;
  autoFocused?: boolean;
  disabled?: boolean;
  pattern?: string;
}

export const InputField = (props: InputFieldProps) => {
  const {
    error,
    placeholder,
    value,
    onChange,
    disabled,
    pattern,
    autoFocused,
  } = props;

  const [textHidden, setTextHidden] = useState(true);
  const type = textHidden && props.passwordField ? "password" : "text";

  const renderIcon = () => {
    if (!props.passwordField) return null;
    if (textHidden) return <ShowPassword />;
    return <HidePassword />;
  };

  const handleIconClick = () => {
    setTextHidden(!textHidden);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!!props.onKeyUpEvent) props.onKeyUpEvent!(e);
  };

  return (
    <>
      <div className="input-field-body">
        <input
          autoFocus={autoFocused}
          disabled={disabled}
          type={type}
          className={classnames(`input-field`, {
            disabled: disabled,
          })}
          placeholder={placeholder ? placeholder : ""}
          onChange={(val) => {
            if (onChange) onChange(val.target.value, val.target);
          }}
          value={value}
          pattern={pattern}
          onKeyUp={handleKeyUp}
        />
        {props.passwordField && (
          <div className="eye-icon" onClick={handleIconClick}>
            {renderIcon()}
          </div>
        )}
      </div>
      {error && error !== "" && <div>{error}</div>}
    </>
  );
};
