import styles from "./search-clients.module.css";

import { useState } from "react";
import { FilterField, ValueData } from "../../../components/form/filter-field";
import { observer, inject } from "mobx-react";
import {
  ClientOverviewStore,
  EmptyClientData,
  IClientData,
} from "../store/store";
import { useTranslation } from "react-i18next";
import { Search } from "../../../components/svg";

export interface SearchCameraProps {
  onSearchChange(): void;
  clientOverviewStore?: ClientOverviewStore;
}

function SearchSensor(props: SearchCameraProps) {
  const [searchValue, setSearchValue] = useState("");
  const [isSearchItemSelected, setIsSearchItemSelected] = useState(false);
  const { clientOverviewStore, onSearchChange } = props;
  const { t } = useTranslation("search-client");

  const searchInputChange = (value: any) => {
    setIsSearchItemSelected(false);
    setSearchValue(value);

    if (value === "") {
      clearSearchSelection();
    }
  };

  const clearSearchSelection = () => {
    const { clientOverviewStore } = props;

    if (!clientOverviewStore) return;

    clientOverviewStore.setSelectedClient(EmptyClientData);
    onSearchChange();
  };

  const getSearchValues = () => {
    if (!searchValue || !clientOverviewStore) return [];

    return clientOverviewStore!.clients
      .filter((value: IClientData) => {
        return value.name
          ? value.name.toLowerCase().includes(searchValue.toLowerCase())
          : false;
      })
      .sort((a: IClientData, b: IClientData) => {
        return a.name!.localeCompare(b.name!, undefined, {
          numeric: true,
        });
      })
      .map((val: any, index: any) => {
        return {
          key: index,
          object: val,
          displayValue: val.name ? val.name : "",
        };
      });
  };

  const setSearchSelection = (item: any) => {
    clientOverviewStore!.setSelectedClient(item);

    setSearchValue(item.name);
    setIsSearchItemSelected(true);
    onSearchChange();
  };

  const searchContentRender = (value: ValueData) => {
    return (
      <div className={styles["search-clients-input"]}>
        <div className={styles["search-client-name"]}>{value.object.name}</div>
        <div className={styles["search-client-room"]}>
          <span className={styles["search-area"]}>{value.object.areaName}</span>
          <span className={styles["search-room"]}>
            {value.object.locationName}
          </span>
        </div>
      </div>
    );
  };

  const searchValues = getSearchValues();

  return (
    <FilterField
      icon={Search}
      contentRenderer={searchContentRender}
      placeholder={t("Search client")}
      inputValue={searchValue}
      values={searchValues}
      onValueChange={(value) => searchInputChange(value)}
      onSelect={setSearchSelection}
      isOpen={!isSearchItemSelected && searchValues.length !== 0}
    ></FilterField>
  );
}

export default inject("clientOverviewStore")(observer(SearchSensor));
