import styles from "./client-profile-content.module.css";

import {
  ClientProfileDetails,
  ClientProfileSettings,
  ClientProfileAccessSettings,
  ClientProfileCameraSettings,
  DataValidationStatus,
} from "../../store/types";
import ClientProfileAccessTab from "./client-profile-access-tab";
import ClientProfileAlertsTab from "./client-profile-alerts-tab";
import ClientProfileClientStatusTab from "./client-profile-client-status-tab";
import ClientProfileCameraTab from "./client-profile-camera-tab";
import ClientProfileSensorIssuesTab from "./client-profile-sensor-issues-tab";
import { AuthStore, FeatureStore, TransitionStore } from "../../../../store";
import { inject, observer } from "mobx-react";

interface ClientContentProps {
  clientDetails: ClientProfileDetails;
  clientSettings: ClientProfileSettings;
  clientAccess: ClientProfileAccessSettings;
  clientCameraSettings: ClientProfileCameraSettings;
  dataValidationStatus: DataValidationStatus;
  authStore?: AuthStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;
  toggleClientStatus(): void;
  toggleAlertStatus(): void;
  toggleAccessStatus(): void;
  toggleCameraStatus(): void;
  switchAlertTimeStatus(): void;
  switchAccessTimeStatus(): void;
  infoLoaded: boolean;
}

function ClientProfileContent(props: ClientContentProps) {
  const {
    clientDetails,
    clientSettings,
    clientAccess,
    clientCameraSettings,
    authStore,
    featureStore,
    transitionStore,
    toggleClientStatus,
    toggleAccessStatus,
    switchAccessTimeStatus,
    toggleAlertStatus,
    switchAlertTimeStatus,
    toggleCameraStatus,
    infoLoaded,
    dataValidationStatus,
  } = props;

  const render = () => {
    if (!infoLoaded) return null;

    const { manageAccessSettings } = featureStore!.features;

    return (
      <div>
        <ul className={styles["flex-container"]}>
          <ClientProfileSensorIssuesTab
            sensorIssues={clientDetails.sensorIssues}
          />
          <ClientProfileClientStatusTab
            clientDetails={clientDetails}
            toggleClientStatus={toggleClientStatus}
            authStore={authStore!}
            transitionStore={transitionStore!}
          />
          <ClientProfileAlertsTab
            clientDetails={clientDetails}
            clientSettings={clientSettings}
            switchAlertTimeStatus={switchAlertTimeStatus}
            toggleAlertStatus={toggleAlertStatus}
            authStore={authStore!}
            transitionStore={transitionStore!}
          />
          {manageAccessSettings === true && (
            <ClientProfileAccessTab
              clientAccess={clientAccess}
              clientDetails={clientDetails}
              toggleAccessStatus={toggleAccessStatus}
              switchAccessTimeStatus={switchAccessTimeStatus}
              authStore={authStore!}
              transitionStore={transitionStore!}
              isAccessInformationValid={
                dataValidationStatus.buildingAccessValid
              }
            />
          )}
          <ClientProfileCameraTab
            clientCameraSettings={clientCameraSettings}
            clientDetails={clientDetails}
            toggleCameraStatus={toggleCameraStatus}
            authStore={authStore!}
            featuresStore={featureStore!}
            transitionStore={transitionStore!}
          />
        </ul>
      </div>
    );
  };

  return render();
}

export default inject(
  "authStore",
  "featureStore",
  "transitionStore"
)(observer(ClientProfileContent));
