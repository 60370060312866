import styles from "./alert-delay.module.css";

import { observer } from "mobx-react";
import classnames from "classnames";
import Dropdown from "../../../components/dropdown-grid";
import { useTranslation } from "react-i18next";
import { IAlertInfo } from "./client-settings-alert-status";
import { DelayType } from "../store/types";

interface IDelayProps {
  alert: IAlertInfo;
  onChange?(): void;
}

function Delay(props: IDelayProps) {
  const hourValues = [0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const minuteValues = [0, 1, 2, 3, 4, 5, 6, 7, 10, 15, 20, 30];
  const secondValues = [0.1, 0.2, 0.5, 0.7, 1, 1.2, 1.5, 1.7, 2, 2.2, 2.5, 3];

  const { t } = useTranslation("client-profile");
  const { alert, onChange } = props;

  const render = () => {
    return (
      <div
        className={classnames(styles["delay-col"], {
          [styles["invisible"]]:
            (alert.settings.delay === null ||
              alert.settings.delay === undefined) &&
            alert.settings.delayType === 0,
        })}
      >
        <div className={styles["label"]}>
          <div>{t("Delay")}</div>
          <div className={styles["disabled"]}>({getUnits(alert)})</div>
        </div>
        {alert.enabled ? (
          <Dropdown
            defaultValue={getDefaultValue(alert)}
            data={getDelayValues(alert)}
            onChange={(value) => {
              alert.settings.delay = value;
              if (onChange) {
                onChange();
              }
            }}
          />
        ) : (
          <div className={styles["alert-readonly-settings-value"]}>
            {alert.settings.delay}
          </div>
        )}
      </div>
    );
  };

  const getDefaultValue = (alert: IAlertInfo): number | undefined => {
    if (alert.settings.delayType === DelayType.None) return;

    if (!alert.settings.delay || isNaN(alert.settings.delay))
      return getDelayValues(alert)[0];

    return alert.settings.delay;
  };

  const getUnits = (alert: IAlertInfo): string => {
    switch (alert.settings.delayType) {
      case DelayType.Seconds:
        return "sec";
      case DelayType.Minutes:
        return "min";
      case DelayType.Hours:
        return "h";
      case DelayType.None:
        return "";

      default:
        return "min";
    }
  };

  const getDelayValues = (alert: IAlertInfo): number[] => {
    switch (alert.settings.delayType) {
      case DelayType.Seconds:
        return secondValues;
      case DelayType.Minutes:
        return minuteValues;
      case DelayType.Hours:
        return hourValues;
      case DelayType.None:
        return [];

      default:
        return minuteValues;
    }
  };

  return render();
}

export default observer(Delay);
