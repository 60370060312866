import { inject, observer } from "mobx-react";
import { CameraDataStore } from "../../../store/store";
import { AuthStore } from "../../../../../store";
import styles from "./../edit-camera.module.css";
import { SwitchInput } from "../../../../../components/form-inputs";
import { ChangeEvent } from "react";

export interface GenericToggleProps {
  cameraDataStore?: CameraDataStore;
  authStore?: AuthStore;
}

function GenericToggle(props: GenericToggleProps) {
  const cameraDataStore = props.cameraDataStore!;
  const authStore = props.authStore!;

  const isGeneric = cameraDataStore.selectedCamera.generic;

  return (
    <>
      {authStore.isAdmin() && (
        <td>
          <div className={styles["edit-column-content"]}>
            <SwitchInput
              checked={isGeneric}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                cameraDataStore.setSelectedCameraGeneric(event.target.checked);
                cameraDataStore.validateSelectedCamera();
              }}
            ></SwitchInput>
          </div>
        </td>
      )}
    </>
  );
}

export default inject("cameraDataStore", "authStore")(observer(GenericToggle));
