import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../store/store";
import { FeatureStore, TransitionStore } from "../../../../store";
import { useTranslation } from "react-i18next";
import SearchMari from "../search-mari";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import AddButton from "../../../../components/buttons/AddButton";

export interface IHeaderProps {
  mariDataStore?: MariDataStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;
}

function Header(props: IHeaderProps) {
  const { t } = useTranslation("mari-grid");
  const mariDataStore = props.mariDataStore!;
  const transitionStore = props.transitionStore!;
  const { uniteIntegrationEnabled } = props.featureStore!.features;

  const render = () => {
    return (
      <div className={styles["manage-maris-page-header"]}>
        <div className={styles["manage-maris-tabs"]}>
          <TabPanel activeKey={"manage-maris"}>
            <TabItem itemKey={"manage-maris"} label={t("ManageMaris")} />
          </TabPanel>
        </div>
        <div className={styles["manage-maris-filter-inputs"]}>
          <div className={styles["manage-maris-input-container"]}>
            <SearchMari onSearchChange={searchChange} />
          </div>

          {uniteIntegrationEnabled && addButton()}
        </div>
      </div>
    );
  };

  const addButton = () => {
    return (
      <div className={styles["manage-maris-add-button"]}>
        <AddButton
          disabled={transitionStore.isLoading || mariDataStore.hasSelectedMari}
          onClick={() => {
            if (transitionStore.isLoading) return;
            mariDataStore.setNewMari();
          }}
        >
          {t("AddMari")}
        </AddButton>
      </div>
    );
  };

  const searchChange = () => {
    setTimeout(() => {
      selectMariOnPage();
    });
  };

  const selectMariOnPage = () => {
    const selectedElement = document.querySelector("#selected-search-mari");
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  };

  return render();
}

export default inject(
  "mariDataStore",
  "featureStore",
  "transitionStore"
)(observer(Header));
