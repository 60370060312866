import styles from "./dashboard-button.module.css";

import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DashboardIcon } from "../../../../svg/dashboard-button.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export interface DasboardButtonProps {
  id: number;
}

function DasboardButton(props: DasboardButtonProps) {
  const { t } = useTranslation("client-grid");
  const { id } = props;
  const history = useNavigate();

  const accessClientDashboard = (clientId: number) => {
    history(`/client-dashboard/${clientId}`);
  };

  const render = () => {
    return (
      <Button
        onClick={() => {
          accessClientDashboard(id);
        }}
        startIcon={<DashboardIcon />}
      >
        <div className={styles["text"]}>{t("Dashboard")}</div>
      </Button>
    );
  };

  return render();
}

export default inject("clientOverviewStore")(observer(DasboardButton));
