import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface BusInputProps {
  peripheralDataStore?: PeripheralDataStore;
}

function BusInput(props: BusInputProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const { t } = useTranslation("peripheral-grid");
  const bus = peripheralDataStore.selectedPeripheral.bus?.toString() || "";

  return (
    <TextFieldInput
      autoFocus
      value={bus}
      label={t("Bus").toUpperCase()}
      inputProps={{ pattern: "^[0-9]{0,3}" }}
      placeholder={t("Bus")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;
        const baseAddress = parseInt(event.target.value);

        if (isNaN(baseAddress))
          peripheralDataStore.setSelectedPeripheralBus(undefined);
        else peripheralDataStore.setSelectedPeripheralBus(baseAddress);
        peripheralDataStore.validateSelectedPeripheral();
      }}
    />
  );
}

export default inject("peripheralDataStore")(observer(BusInput));
