import styles from "./page.module.css";

import { observer, inject } from "mobx-react";
import { CameraDataStore } from "./store/store";
import CameraGrid from "./components/ManageCamerasGrid";
import Header from "./components/header/header";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TransitionStore } from "../../store";
interface ICameraProps {
  cameraDataStore: CameraDataStore;
  transitionStore: TransitionStore;
}

function Cameras(props: ICameraProps) {
  const { ready: readyCameraGrid } = useTranslation("camera-grid");
  const { ready: readyFilter } = useTranslation("filter-camera");
  const { ready: readySearch } = useTranslation("search-camera");
  const { ready: readyCommon } = useTranslation("common");

  const { cameraDataStore, transitionStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = cameraDataStore;

  const languagesLoaded =
    readyCameraGrid && readyFilter && readySearch && readyCommon;

  useEffect(() => {
    return () => {
      cameraDataStore.resetSelectedData();
      transitionStore.isPromptShown = false;
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      cameraDataStore.setIsTranslationLoading(true);
    } else {
      cameraDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  useEffect(() => {
    transitionStore.isPromptShown = cameraDataStore.hasSelectedCamera;
  }, [cameraDataStore.hasSelectedCamera]);

  const render = () => {
    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div
              id="grid-container"
              className={styles["manage-camera-grid-container"]}
            >
              <div className={styles["overflow-content"]}>
                <CameraGrid />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return render();
}

export default inject("cameraDataStore", "transitionStore")(observer(Cameras));
