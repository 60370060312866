import { MariData } from "./types";
import { MariDataIn } from "./typesIn";
import { MariDataOut } from "./typesOut";

export interface MariDataConverter {
  toStore(inData: MariDataIn): MariData;
  toOut(storeData: MariData): MariDataOut;
}

export const MariDataConverter: MariDataConverter = {
  toStore(inData: MariDataIn): MariData {
    return {
      baseAddress: inData.baseAddress,
      mariName: inData.mariName,
      password: inData.password,
      uniteId: inData.uniteId,
      username: inData.username,
      mariId: inData.mariId,
      nismIp: inData.nismIp,
      virtualDevices: inData.virtualDevices,
    };
  },
  toOut(storeData: MariData): MariDataOut {
    let formatedAdress = storeData.baseAddress;

    if (storeData.baseAddress.slice(-1) === "/") {
      formatedAdress = storeData.baseAddress.slice(0, -1);
    }

    return {
      baseAddress: formatedAdress,
      mariId: storeData.mariId || 0,
      mariName: storeData.mariName,
      password: storeData.password,
      uniteId: storeData.uniteId,
      username: storeData.username,
      nismIp: storeData.nismIp,
      virtualDevices: storeData.virtualDevices,
    };
  },
};
