import styles from "../search-clients.module.css";

import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientOverviewStore } from "../..";
import { BaseTable, SortHeader } from "../../../../components/table";
import { LocationCell } from "../../../../components/table/cells/LocationCell";
import { BasicHeader } from "../../../../components/table/headers/BasicHeader";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import TransitionStore from "../../../../store/transition-store";
import ClientStatus from "./cells/client-status.cell";
import ClientProfileCell from "./cells/client-profile.cell";
import { ILastLocation } from "../../store/types";
import webResource from "../../web";
import { ResidentLink } from "../cells/ResidentLink";

interface IResidentStatusGridProps {
  clientOverviewStore?: ClientOverviewStore;
  transitionStore?: TransitionStore;
}

function ResidentStatusGrid(props: IResidentStatusGridProps) {
  const clientOverviewStore = props.clientOverviewStore!;
  const transitionStore = props.transitionStore!;
  const { t } = useTranslation("client-grid");
  const { t: statusesTranslation } = useTranslation("statuses");

  const history = useNavigate();
  const { assignedClients } = clientOverviewStore;

  useEffect(() => {
    return () => {
      transitionStore.setDisableLoadingFeedback(false);
      clientOverviewStore.lastLocations = {};
      clientOverviewStore.lastLocatonsTimers.forEach((timeout) => {
        clearTimeout(timeout);
      });
      clientOverviewStore.lastLocatonsTimers = [];
    };
  }, []);

  const getSortDirection = (id: string) => {
    if (assignedClients.sortOptions[0].id === id) {
      if (assignedClients.sortOptions[0].desc) {
        return SORT_DIRECTION.DESCENDING;
      } else return SORT_DIRECTION.ASCENDING;
    }
    return SORT_DIRECTION.NONE;
  };

  const columnData: any[] = [
    {
      header: t("Client"),
      cellComponent: (value: any) => (
        <ResidentLink
          value={value.name}
          alertData={{
            alertId: value.alertId,
            alertDelayed: value.alertDelayed,
          }}
          click={() => {
            accessClientDashboard(value.id);
          }}
        />
      ),
      width: "25%",
      HeaderComponent: (
        <SortHeader
          text={t("Client")}
          selected={assignedClients.sortOptions[0].id === "name"}
          sortDirection={getSortDirection("name")}
          locked={false}
          onClick={() => {
            if (assignedClients.sortOptions[0].id === "name") {
              clientOverviewStore.setAssignedClientsSortOptions([
                {
                  id: "name",
                  desc: !assignedClients.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAssignedClientsSortOptions([
                { id: "name", desc: false },
              ]);
            }
          }}
        />
      ),
    },
    {
      header: t("Room"),
      cellComponent: LocationCell,
      width: "35%",
      HeaderComponent: (
        <SortHeader
          text={t("Room")}
          selected={assignedClients.sortOptions[0].id === "room"}
          sortDirection={getSortDirection("room")}
          locked={false}
          onClick={() => {
            if (assignedClients.sortOptions[0].id === "room") {
              clientOverviewStore.setAssignedClientsSortOptions([
                {
                  id: "room",
                  desc: !assignedClients.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAssignedClientsSortOptions([
                { id: "room", desc: false },
              ]);
            }
          }}
        />
      ),
    },
    {
      header: t("Status"),
      cellComponent: (props: any) => (
        <ClientStatus
          {...props}
          original={props}
          t={t}
          statusesTranslation={statusesTranslation}
          lastLocations={clientOverviewStore.lastLocations}
          showLastLocation={readClientLocation}
        />
      ),
      HeaderComponent: (
        <SortHeader
          text={t("Status")}
          selected={assignedClients.sortOptions[0].id === "status"}
          sortDirection={getSortDirection("status")}
          locked={false}
          onClick={() => {
            if (assignedClients.sortOptions[0].id === "status") {
              clientOverviewStore.setAssignedClientsSortOptions([
                {
                  id: "status",
                  desc: !assignedClients.sortOptions[0].desc,
                },
              ]);
            } else {
              clientOverviewStore.setAssignedClientsSortOptions([
                { id: "status", desc: false },
              ]);
            }
          }}
        />
      ),
    },
    {
      header: t("Client Profile"),
      width: "150px",
      cellComponent: (props: any) => {
        return ClientProfileCell({
          original: props,
          t,
          accessClientProfile: accessClientProfile,
        });
      },
      HeaderComponent: <BasicHeader text={t("Client Profile")} />,
    },
  ];

  const accessClientProfile = (clientId: number) => {
    history(`/client-profile/${clientId}`);
  };

  const accessClientDashboard = (clientId: number) => {
    history(`/client-dashboard/${clientId}`);
  };

  const readClientLocation = (clientId: number) => {
    webResource.showLastLocation(clientId).then((ajaxResponse) => {
      clientOverviewStore.lastLocations[clientId] =
        ajaxResponse.data as ILastLocation;

      const timeout = setTimeout(() => {
        clientOverviewStore.lastLocations[clientId] = undefined;
      }, 15 * 1000 /*15 seconds*/);
      clientOverviewStore.lastLocatonsTimers.push(timeout);
    });
  };

  const render = () => {
    const { selectedClient } = clientOverviewStore;
    return (
      <BaseTable
        minWidth={1286}
        data={clientOverviewStore.clients.slice()}
        columns={columnData}
        customRowId={(_index, rowData) => {
          if (rowData.id === selectedClient?.id) return "selected-client";
          return "";
        }}
        customRowClass={(_index, rowData) => {
          if (rowData.id === selectedClient?.id)
            return styles["selected-client"];
          return "";
        }}
        expandedRowComponent={<div />}
      ></BaseTable>
    );
  };

  return render();
}
export default inject(
  "clientOverviewStore",
  "transitionStore"
)(observer(ResidentStatusGrid));
