import { inject, observer } from "mobx-react";
import { Component } from "react";

@inject("transitionStore")
@observer
export default class LoadingSpinner extends Component<any, any> {
  public render() {
    const { transitionStore } = this.props;
    const isLoading = transitionStore.isLoading;
    const isDisabled = transitionStore.disableLoadingFeedback;

    return !isDisabled && isLoading && <div className="loader" />;
  }
}
