import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface MacAddressInputProps {
  peripheralDataStore?: PeripheralDataStore;
}

function MacAddressInput(props: MacAddressInputProps) {
  const peripheralDataStore = props.peripheralDataStore!;
  const mac = peripheralDataStore.selectedPeripheral.controllerMAC;
  const { t } = useTranslation("peripheral-grid");
  const { errorMessages } = peripheralDataStore;

  return (
    <TextFieldInput
      value={mac}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["controllerMAC"] &&
          t("RequiredInformation")) ||
        ""
      }
      label={t("ControllerMAC").toUpperCase()}
      inputProps={{ pattern: "^[0-9A-Fa-f]{0,12}" }}
      placeholder={t("ControllerMAC")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;

        peripheralDataStore.setSelectedPeripheralControllerMac(
          event.target.value
        );
        peripheralDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        peripheralDataStore.validateSelectedPeripheral("controllerMAC");
        peripheralDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("peripheralDataStore")(observer(MacAddressInput));
