export const scrollIntoViewAndAlign = (element: Element) => {
  if (!element) {
    return;
  }
  element.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "start",
  });
};

export const scrollIntoViewAndAlignInstant = (element: Element) => {
  if (!element) {
    return;
  }
  element.scrollIntoView({
    behavior: "instant" as ScrollBehavior,
    block: "nearest",
    inline: "start",
  });
};
