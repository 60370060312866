import styles from "./client-profile-access-settings-content.module.css";

import { TimePicker } from "../../../../components/time-picker";
import {
  ClientProfileAccessSettings,
  AccessLocations,
} from "../../store/types";
import { observer } from "mobx-react";
import { ChangeEvent } from "react";
import { SelectInput, SwitchInput } from "../../../../components/form-inputs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface ClientProfileSettingsContentProps {
  clientAccess: ClientProfileAccessSettings;
}

function ClientProfileAccessSettingsContent(
  props: ClientProfileSettingsContentProps
) {
  const { t } = useTranslation("client-profile");
  const { clientAccess } = props;

  const { state } = useLocation();

  const render = () => {
    return (
      <div>
        <ul className={styles["flex-container"]}>
          {renderTimeIntervalTitle()}
          {renderTimeIntervalSettings()}

          {renderAccessLocationsTitle()}
          {renderAccessLocationSettings()}
        </ul>
      </div>
    );
  };

  const isEditingDayPeriod = () => {
    const { isEditingDay } = state;
    return isEditingDay;
  };

  const renderTimeIntervalTitle = () => {
    const prefix = isEditingDayPeriod() ? "Day " : "Night ";

    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(prefix + "time interval")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Start hour")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("End hour")}
        </div>
      </li>
    );
  };

  const renderTimeIntervalSettings = () => {
    const daytimeInterval = clientAccess.getDaytimeInterval();
    const nighttimeInterval = clientAccess.getNighttimeInterval();

    const currentAccessArea = getCurrentEditingInterval();

    const endHour = isEditingDayPeriod()
      ? nighttimeInterval.accessAreaStartTime
      : daytimeInterval.accessAreaStartTime;
    const formattedEndHour =
      endHour.length > 5 ? endHour.substring(0, 5) : endHour;

    const endHourAccessType = isEditingDayPeriod()
      ? t("Night time access start")
      : t("Day time access start");

    return (
      <li
        className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
      >
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-1"]}`}
        >
          {t("Active interval")}
        </div>
        <div className={styles["settings-col-2"]}>
          <TimePicker
            value={currentAccessArea.accessAreaStartTime}
            limitAt={endHour}
            onChange={(value) => {
              currentAccessArea.accessAreaStartTime = value;
            }}
          />
        </div>
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-3"]}`}
        >
          {formattedEndHour + " - " + t(endHourAccessType)}
        </div>
      </li>
    );
  };

  const renderAccessLocationsTitle = () => {
    const timeAccess = isEditingDayPeriod()
      ? "Day time access"
      : "Night time access";
    return (
      <li
        className={`${styles["item"]} ${styles["odd-tab"]} ${styles["settings-odd-tab"]}`}
      >
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-1"]}`}
        >
          {t(timeAccess)}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-2"]}`}
        >
          {t("Active")}
        </div>
        <div
          className={`${styles["settings-title"]} ${styles["settings-col-3"]}`}
        >
          {t("Access locations")}
        </div>
      </li>
    );
  };

  const renderAccessLocationSettings = () => {
    const currentAccessArea = getCurrentEditingInterval();

    const dropdownData = convertToDropdownValues(
      clientAccess.clientProfileAccessLocations
    );

    const currentDropdownValue =
      currentAccessArea.accessLocationId === 0
        ? dropdownData[0]
        : dropdownData.find(
            (x: any) => x.id === currentAccessArea.accessLocationId
          );

    return (
      <li
        className={`${styles["item"]} ${styles["even-tab"]} ${styles["settings-even-tab"]}`}
      >
        <div
          className={`${styles["settings-text"]} ${styles["settings-col-1"]}`}
        ></div>
        <div className={styles["settings-col-2"]}>
          <SwitchInput
            checked={currentAccessArea.accessLocationId > 0}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (!event.target.checked) {
                currentAccessArea.accessLocationId = 0;
              }
              if (event.target.checked) {
                currentAccessArea.accessLocationId = dropdownData[0].id;
              }
            }}
          />
        </div>
        <div className={styles["settings-col-3"]}>
          <SelectInput
            options={dropdownData}
            value={currentDropdownValue}
            onChange={(_event: any, value: any) => {
              const currentAccessArea = getCurrentEditingInterval();
              currentAccessArea.accessLocationId = value.id;
            }}
            data={dropdownData}
          ></SelectInput>
        </div>
      </li>
    );
  };

  const onChangeAccessLocation = (event: any) => {
    const currentAccessArea = getCurrentEditingInterval();
    currentAccessArea.accessLocationId = event.id;
  };

  const convertToDropdownValues = (object: AccessLocations[]) => {
    if (object.length === 0) {
      return [{ id: 0, value: t("No access available") }];
    }

    const dropdownValue: any = [];
    object.forEach((location) => {
      dropdownValue.push({
        value: location.name,
        id: location.wireless_access_id,
      });
    });
    return dropdownValue;
  };

  const getCurrentEditingInterval = () => {
    const daytimeInterval = clientAccess.getDaytimeInterval();
    const nighttimeInterval = clientAccess.getNighttimeInterval();

    const interval = isEditingDayPeriod() ? daytimeInterval : nighttimeInterval;

    return interval;
  };

  return render();
}

export default observer(ClientProfileAccessSettingsContent);
