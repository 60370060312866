import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { ClientDataStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import SearchInactiveClient from "../search-inactive-clients";
import AddButton from "../../../../components/buttons/AddButton";
import { TransitionStore } from "../../../../store";
export interface HeaderProps {
  clientDataStore?: ClientDataStore;
  transitionStore?: TransitionStore;
}

function Header(props: HeaderProps) {
  const { t } = useTranslation("client-grid");
  const clientDataStore = props.clientDataStore!;
  const transitionStore = props.transitionStore!;

  const render = () => {
    return (
      <div className={styles["clients-overview-header"]}>
        <div className={styles["clients-overview-tabs"]}>
          <TabPanel
            activeKey={clientDataStore.selectedTab}
            onItemChange={(key: string) => {
              clientDataStore.setSelectedTab(key);
            }}
          >
            <TabItem itemKey={"manage-clients"} label={t("Manage Clients")} />
            <TabItem
              itemKey={"inactive-clients"}
              label={t("Inactive Clients")}
            />
          </TabPanel>
        </div>
        <div className={styles["manage-clients-filter-inputs"]}>
          {clientDataStore.selectedTab === "inactive-clients" && (
            <div className={styles["inactive-clients-search"]}>
              <SearchInactiveClient
                onSearchChange={(item) => {
                  clientDataStore.setHighlightInactiveClient(item);
                  selectClientOnPage();
                }}
              />
            </div>
          )}
          {clientDataStore.selectedTab === "manage-clients" &&
            AddButtonComponent()}
        </div>
      </div>
    );
  };

  const selectClientOnPage = () => {
    setTimeout(() => {
      const selectedClientElement = document.querySelector("#selected-client");
      if (selectedClientElement) {
        selectedClientElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }, 0);
  };

  const AddButtonComponent = () => {
    return (
      <div className={styles["add-button-container"]}>
        <AddButton
          disabled={
            transitionStore.isLoading || clientDataStore.hasSelectedClient
          }
          onClick={() => {
            if (clientDataStore.loading) {
              return;
            }

            clientDataStore.addNewEmptyClient();
          }}
        >
          {t("Add Client")}
        </AddButton>
      </div>
    );
  };

  return render();
}

export default inject("clientDataStore", "transitionStore")(observer(Header));
