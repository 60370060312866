import { useEffect } from "react";
import { inject } from "mobx-react";
import webResource from "../../modules/login/web";
import { AuthStore, FeatureStore, TransitionStore } from "../../store";
import { LoginResponse } from "../../modules/login/types";
import { runInAction } from "mobx";

const FEATURE_TIMER_MS = 7000;

interface AuthenticationLayerProps {
  children?: React.ReactNode;
  authStore?: AuthStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;
}

function AuthenticationLayer(props: AuthenticationLayerProps) {
  useEffect(() => {
    let featureTimer: any = null;
    const authentication = props.authStore!;
    const { featureStore } = props;
    authentication.setIsAuthenticating(true);
    authentication.reloadToken();

    webResource
      .requestCurrent()
      .then((callData: any) => {
        const data = callData.data as LoginResponse;
        if (data) {
          authentication.authenticate(
            data.username,
            data.role,
            data.firstName,
            data.lastName
          );
        }
      })
      .catch((_reason: any) => {
        authentication.clearAuthentication();
      })
      .finally(() => {
        readFeatureList().finally(() => {
          authentication.setIsAuthenticating(false);
          if (
            authentication.isAuthenticated &&
            featureStore!.features.initialConfigCompleted === false
          ) {
            if (featureTimer === null) {
              featureTimer = setInterval(readFeatureList, FEATURE_TIMER_MS);
            }
          } else {
            if (featureTimer) {
              clearInterval(featureTimer);
              featureTimer = null;
            }
          }
        });
      });

    return function cleanup() {
      clearInterval(featureTimer);
      featureTimer = null;
    };
  }, [props]);

  const readFeatureList = () => {
    const { featureStore, transitionStore } = props;

    transitionStore!.disableLoadingFeedback = true;
    return webResource
      .readFeatureList()
      .then((callData) => {
        const { data } = callData;

        runInAction(() => {
          featureStore!.features = data.featureList;
        });
      })
      .finally(() => {
        transitionStore!.disableLoadingFeedback = false;
      });
  };

  return <>{props.children}</>;
}

export default inject(
  "authStore",
  "featureStore",
  "transitionStore"
)(AuthenticationLayer);
