import styles from "./profile-icon.module.css";

import { ClientProfile } from "../../../../../components/svg";
import { ClientProfileWarning } from "../../../../../components/svg";
import { ClientProfileCritical } from "../../../../../components/svg";

interface ProfileIconProps {
  sensorIssues: any;
  onClick(): void;
}

export default function ProfileIcon(props: ProfileIconProps): any {
  const ProfileIcon = getProfileIcon(props.sensorIssues);

  return (
    <span className={styles["profile-icon"]} onClick={props.onClick}>
      <ProfileIcon width="40px" height="40px" />
    </span>
  );
}

function getProfileIcon(sensorIssues: number) {
  switch (sensorIssues) {
    case 2:
      return ClientProfileCritical;
    case 1:
      return ClientProfileWarning;
    default:
      return ClientProfile;
  }
}
