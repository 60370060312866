import styles from "./time-alerts-radio.module.css";

import classNames from "classnames";
interface IRadioProps {
  checked?: any;

  onChange?: any;
  title: string;
  startTime: string;
  endTime: string;
}
export default function TimeAlertRadio(props: IRadioProps) {
  const { title } = props;

  return (
    <div className={styles["time-alerts"]}>
      <input
        onChange={props.onChange}
        checked={props.checked}
        type={"radio"}
        className={styles["input-radio"]}
      ></input>
      <div onClick={props.onChange} className={styles["label-container"]} >
        <div
          className={classNames({
            [styles["selected-interval-title"]]: props.checked,
            [styles["time-interval-title"]]: !props.checked,
          })}
        >
          {title}
        </div>
        <div onClick={props.onChange} className={styles["time-interval-hours"]}>
          ({props.startTime} - {props.endTime})
        </div>
      </div>
    </div>
  );
}
