import { readRequest, postRequest, updateRequest } from "../../web";
import { AlertCallIn, ReadClientProfileCallIn } from "./store/typesIn";
import {
  ChangeActiveAccessTimeStatusCallOut,
  ChangeActiveAlertTimeStatusCallOut,
  ChangeClientProfileAccessSettingCallOut,
  ChangeClientProfileCameraStatusCallOut,
  ReadAllClientProfileAlertSettingsCallOut,
  ToggleClientProfileAccessStatusCallOut,
  ToggleClientProfileClientStatusCallOut,
  ToggleClientProfileRoomAlertsCallOut,
  UpdateClientProfileAlertSettingsCallOut,
} from "./store/typesOut";

const resourceUrl = "/api/careplan/client-profile";

const webCalls = {
  readClientProfile(clientId: number) {
    return readRequest<ReadClientProfileCallIn>(
      `${resourceUrl}?clientId=${clientId}`
    );
  },
  toggleClientProfileClientStatus(
    toggleData: ToggleClientProfileClientStatusCallOut
  ) {
    return postRequest<ToggleClientProfileClientStatusCallOut, null>(
      `${resourceUrl}/toggle-clientstatus`,
      toggleData
    );
  },
  toggleClientProfileRoomAlerts(
    toggleData: ToggleClientProfileRoomAlertsCallOut
  ) {
    return postRequest<ToggleClientProfileRoomAlertsCallOut, null>(
      `${resourceUrl}/toggle-roomalerts`,
      toggleData
    );
  },
  toggleClientProfileAccessStatus(
    toggleData: ToggleClientProfileAccessStatusCallOut
  ) {
    return postRequest<ToggleClientProfileAccessStatusCallOut, null>(
      `${resourceUrl}/toggle-areaaccess`,
      toggleData
    );
  },
  changeActiveAlertTimeStatus(toggleData: ChangeActiveAlertTimeStatusCallOut) {
    return postRequest<ChangeActiveAlertTimeStatusCallOut, null>(
      `${resourceUrl}/change-active-interval`,
      toggleData
    );
  },
  changeActiveAccessTimeStatus(
    toggleData: ChangeActiveAccessTimeStatusCallOut
  ) {
    return postRequest<ChangeActiveAccessTimeStatusCallOut, null>(
      `${resourceUrl}/change-active-accessgroup`,
      toggleData
    );
  },
  changeClientProfileCameraStatus(
    data: ChangeClientProfileCameraStatusCallOut
  ) {
    return postRequest<ChangeClientProfileCameraStatusCallOut, null>(
      `${resourceUrl}/change-cameraviews`,
      data
    );
  },
  changeClientProfileAccessSetting(
    accessSeting: ChangeClientProfileAccessSettingCallOut
  ) {
    return updateRequest<ChangeClientProfileAccessSettingCallOut, null>(
      `${resourceUrl}/change-access-group`,
      accessSeting
    );
  },
  readAllClientProfileAlertSettings(
    data: ReadAllClientProfileAlertSettingsCallOut
  ) {
    return postRequest<ReadAllClientProfileAlertSettingsCallOut, AlertCallIn>(
      `${resourceUrl}/alerts`,
      data
    );
  },
  updateClientProfileAlertSettings(
    data: UpdateClientProfileAlertSettingsCallOut
  ) {
    return updateRequest<UpdateClientProfileAlertSettingsCallOut, null>(
      `${resourceUrl}/alerts`,
      data
    );
  },
};

export default webCalls;
