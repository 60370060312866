import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface NismIpInputProps {
  mariDataStore?: MariDataStore;
}

function NismIpInput(props: NismIpInputProps) {
  const mariDataStore = props.mariDataStore!;
  const { t } = useTranslation("mari-grid");
  const nismIp = mariDataStore.selectedMari.nismIp;

  return (
    <TextFieldInput
      value={nismIp}
      disabled={mariDataStore.isValidationInProgress}
      label={t("NismIp").toUpperCase()}
      placeholder={t("NismIp")}
      onChange={(event: any) => {
        const nismIpValue = event.target.value;

        mariDataStore.setSelectedMariNismIp(nismIpValue);
        mariDataStore.validateSelectedMari();
      }}
    />
  );
}

export default inject("mariDataStore")(observer(NismIpInput));
