import styles from "../search-alert.module.css";

import { ReactComponent as InBed } from "../../../clients-overview/svg/client-in-bed.svg";
import { ReactComponent as InBathroom } from "../../../clients-overview/svg/client-in-bathroom.svg";
import { ReactComponent as Inactive } from "../../../clients-overview/svg/client-inactive.svg";
import { ReactComponent as OutOfBed } from "../../../clients-overview/svg/client-out-of-bed.svg";
import { ReactComponent as OutOfRoom } from "../../../clients-overview/svg/client-out-of-room.svg";
import { ReactComponent as VisitorDetected } from "../../../clients-overview/svg/client-visitor-detected.svg";
import { ReactComponent as ClientAlarm } from "../../../clients-overview/svg/client-alarm.svg";
import { ReactComponent as VoiceAlarm } from "../../../clients-overview/svg/client-voice-alarm.svg";
import { ReactComponent as Dash } from "../../../manage-peripherals/svg/dash.svg";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { BaseTable, SortHeader } from "../../../../components/table";
import { SORT_DIRECTION } from "../../../../components/table/headers/SortHeader";
import TransitionStore, {
  GlobalDialogData,
} from "../../../../store/transition-store";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { AlertDataStore, AlertsSort } from "../../store/store";
import webResource from "../../web";
import { TextCell } from "../../../../components/table/cells/TextCell";
import { AlertData, getDelayName } from "../../store/types";
import { useEffect } from "react";
import { ColumnData } from "../../../../components/table/table";
import { useTranslation } from "react-i18next";
import AlertIcon from "../cells/icon";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../../components/table/headers/BasicHeader";
import { AlertDataConverter, DelayTypeConverter } from "../../store/convertor";
import { FeatureStore } from "../../../../store";

interface IDefaultAlertsGridProps {
  alertDataStore?: AlertDataStore;
  transitionStore?: TransitionStore;
  featureStore?: FeatureStore;
}

function chooseAlertIcon(alertId: number, delay: boolean): any {
  let color = "";
  const dashIcon = <Dash width="40px" height="40px" />;

  if (delay === true) color = "#f4ae22";
  else color = "#f73b0f";

  if (alertId === 5 && delay === true) {
    return dashIcon;
  }
  if (alertId === 7 && delay !== true) {
    return dashIcon;
  }
  if (alertId === 8 && delay === false) {
    return dashIcon;
  }

  switch (alertId) {
    case 1:
      return <OutOfBed width="40px" height="40px" fill={color} />;
    case 2:
      return <InBathroom width="40px" height="40px" fill={color} />;
    case 3:
      return <OutOfRoom width="40px" height="40px" fill={color} />;
    case 4:
      return <Inactive width="40px" height="40px" fill={color} />;
    case 5:
      return <VisitorDetected width="40px" height="40px" fill={color} />;
    case 6:
      return <ClientAlarm width="40px" height="40px" fill={color} />;
    case 7:
      return <VoiceAlarm width="40px" height="40px" fill={color} />;
    case 8:
      return <InBed width="40px" height="40px" fill={color} />;
    default:
      return <ClientAlarm width="40px" height="40px" fill={color} />;
  }
}

function DefaultAlertsGrid(props: IDefaultAlertsGridProps) {
  const alertDataStore = props.alertDataStore!;
  const transitionStore = props.transitionStore!;
  const featureStore = props.featureStore!;
  const { t } = useTranslation("alert-grid");

  const { alertsSort } = alertDataStore;

  useEffect(() => {
    alertDataStore.setAlertsSort([new AlertsSort("alertName", false)]);
    readAllAlerts();

    return function unmount() {
      alertDataStore.setHilightAlert(null);
      alertDataStore.errors = null;
      alertDataStore.closeExpanded();
      alertDataStore.searchValue = "";
    };
  }, []);

  const readAllAlerts = async () => {
    alertDataStore.setIsDataLoading(true);

    const params = {
      SortOptions: alertDataStore.alertsSort,
    };

    const noAlerts: AlertData[] = [];

    try {
      const ajaxResponse = await webResource.readAll(params);
      const response = ajaxResponse.data!;

      const alertList = response.alerts.map((alertData) => {
        return AlertDataConverter.toStore(alertData);
      });

      const delayTypeList = response.delayTypes.map((delayType) => {
        return DelayTypeConverter.toStore(delayType);
      });

      if (alertDataStore.alertsSort[0].id === "text") {
        const alerts = alertList;
        const val = alertDataStore.alertsSort[0].desc ? -1 : 1;

        alerts.sort(function (a: any, b: any) {
          return t(a.alertName).localeCompare(t(b.alertName)) * val;
        });

        alertDataStore.setAlerts(alerts);
      } else {
        alertDataStore.setAlerts(alertList);
      }

      alertDataStore!.setDelayTypes(delayTypeList);
      alertDataStore.setIsDataLoading(false);
    } catch (ajaxError) {
      const dialog: GlobalDialogData = NoConnectionModal(t);

      alertDataStore!.setAlerts(noAlerts);
      transitionStore.showGlobalDialog(dialog);
      alertDataStore.setIsDataLoading(false);
    }
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== alertsSort[0].id) return SORT_DIRECTION.NONE;

    if (alertsSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const columnData: ColumnData[] = [
    {
      header: t("alertName"),
      accesor: "alertName",
      HeaderComponent: (
        <SortHeader
          text={t("alertName")}
          selected={alertsSort[0].id === "alertName"}
          sortDirection={getSortDirection("alertName")}
          onClick={() => {
            if (alertsSort[0].id === "alertName") {
              alertDataStore.setAlertsSort([
                new AlertsSort("alertName", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([
                new AlertsSort("alertName", false),
              ]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => {
        return <TextCell text={value.alertName} />;
      },
    },
    {
      header: t("Text"),
      accesor: "text",
      width: "15%",
      HeaderComponent: (
        <SortHeader
          text={t("Text")}
          selected={alertsSort[0].id === "text"}
          sortDirection={getSortDirection("text")}
          onClick={() => {
            if (alertsSort[0].id === "text") {
              alertDataStore.setAlertsSort([
                new AlertsSort("text", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([new AlertsSort("text", false)]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => {
        return <TextCell text={t(value.alertName)} />;
      },
    },
    {
      header: t("triggerPort"),
      accesor: "triggerPort",
      width: "13%",
      HeaderComponent: (
        <SortHeader
          text={t("triggerPort")}
          selected={alertsSort[0].id === "triggerPort"}
          sortDirection={getSortDirection("triggerPort")}
          onClick={() => {
            if (alertsSort[0].id === "triggerPort") {
              alertDataStore.setAlertsSort([
                new AlertsSort("triggerPort", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([
                new AlertsSort("triggerPort", false),
              ]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => (
        <TextCell text={isNaN(value.triggerPort!) ? "" : value.triggerPort} />
      ),
    },
    {
      header: t("delayType"),
      width: "13%",
      accesor: "delayType",
      HeaderComponent: (
        <SortHeader
          text={t("delayType")}
          selected={alertsSort[0].id === "delayType"}
          sortDirection={getSortDirection("delayType")}
          onClick={() => {
            if (alertsSort[0].id === "delayType") {
              alertDataStore.setAlertsSort([
                new AlertsSort("delayType", !alertsSort[0].desc),
              ]);
            } else {
              alertDataStore.setAlertsSort([
                new AlertsSort("delayType", false),
              ]);
            }
            readAllAlerts();
          }}
        />
      ),
      cellComponent: (value: AlertData) => (
        <TextCell
          text={t(getDelayName(alertDataStore.delayTypes, value.delayType))}
        />
      ),
    },
    {
      header: t("icon"),
      width: "18%",
      accesor: "icon",
      HeaderComponent: (
        <BasicHeader text={t("icon")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: AlertData) => {
        return <AlertIcon icon={chooseAlertIcon(value.alertId!, false)} />;
      },
    },
    {
      header: t("delayIcon"),
      width: "18%",
      accesor: "delayIcon",
      HeaderComponent: (
        <BasicHeader text={t("delayIcon")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: AlertData) => {
        return <AlertIcon icon={chooseAlertIcon(value.alertId!, true)} />;
      },
    },
  ];

  if (featureStore.features.showTriggerPort === false) {
    const triggerPortColumnIndex = columnData.findIndex((column) => {
      if (column.accesor === "triggerPort") return true;
      return false;
    });

    if (triggerPortColumnIndex >= 0) {
      columnData.splice(triggerPortColumnIndex, 1);
    }
  }

  const highlightedItem = alertDataStore.highlightAlert;

  let defaultAlertsList = alertDataStore.alerts.filter((alert: AlertData) => {
    if (alert.alertId && alert.alertId <= 8) return true;
    return false;
  });

  if (featureStore.features.ofeliaIntegrationEnabled) {
    defaultAlertsList = defaultAlertsList.map((value) => {
      const item = { ...value };
      const notSupportedText = t("NotSupported");
      if (item.alertId === 7) {
        item.alertName += `(${notSupportedText})`;
        item.alternateText += `(${notSupportedText})`;
      }
      return item;
    });
  }

  return (
    <BaseTable
      minWidth={1286}
      data={defaultAlertsList}
      columns={columnData}
      customRowId={(_index, rowData: AlertData) => {
        if (rowData.alertId === highlightedItem?.alertId)
          return "selected-alert";
        return "";
      }}
      customRowClass={(_index, rowData) => {
        if (rowData.alertId === highlightedItem?.alertId)
          return styles["selected-alert"];
        return "";
      }}
    />
  );
}

export default inject(
  "alertDataStore",
  "transitionStore",
  "featureStore"
)(observer(DefaultAlertsGrid));
