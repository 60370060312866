import { ILocationData } from "../store/types";

export function CameraLocation(
  locations: ILocationData[],
  t: any,
  filteringLocationId?: number
) {
  if (locations === null || locations === undefined || locations.length === 0)
    return "";

  const data = locations.filter(
    (loc) =>
      filteringLocationId === null ||
      filteringLocationId === undefined ||
      loc.locationId === filteringLocationId
  );

  if (data.length === 0) return "";

  if (data.length === 1) return data[0].locationName;

  if (data.length > 0) return t("Multiple locations");
}

export function LocationPath(locations: any, filteringLocationId?: number) {
  if (locations === null || locations === undefined || locations.length === 0)
    return "";

  const data = locations.filter(
    (loc: any) =>
      filteringLocationId === null ||
      filteringLocationId === undefined ||
      loc.locationId === filteringLocationId
  );

  if (data.length === 0) return "";

  if (data.length === 1) return data[0].locationPath;
}

export function CameraDistance(
  locations: ILocationData[],
  t: any,
  filteringLocationId?: number
) {
  if (locations === null || locations === undefined || locations.length === 0)
    return "";

  const data = locations.filter(
    (loc) =>
      filteringLocationId === null ||
      filteringLocationId === undefined ||
      loc.locationId === filteringLocationId
  );

  if (data.length === 0) return "";

  if (data.length === 1) {
    return data[0].distance;
  }

  const distance = data[0].distance;
  if (data.every((l) => l.distance === distance)) return distance;

  return t("Multiple");
}
