import { Navigate, RouteProps } from "react-router-dom";
import { inject } from "mobx-react";
import AuthStore from "../store/auth-store";
import { FeatureStore, TransitionStore } from "../store";
import { ElementType, ReactNode } from "react";

export enum ROLES {
  ADMIN,
  NURSEADMIN,
  NURSE,
  UNKNOWN,
}

export const ROLE_DEFAULT_PATH: any = {
  ADMIN: "/manage-sensors",
  NURSEADMIN: "/residents-overview",
  NURSE: "/residents-overview",
};

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  authStore?: AuthStore;
  featureStore?: FeatureStore;
  transitionStore?: TransitionStore;
  component: ElementType;
  children?: ReactNode;
  requiredRoles?: ROLES[];
  shouldRedirect?: boolean;
}

function AuthorizedRoute(props: PrivateRouteProps) {
  const {
    authStore,
    featureStore,
    component: Component,
    shouldRedirect,
  } = props;
  const { requiredRoles } = props;

  const render = () => {
    return routeRenderer();
  };

  const routeRenderer = () => {
    const { isAuthenticated } = authStore!;

    if (!isAuthenticated) {
      return <Navigate to={{ pathname: "/login" }} />;
    }

    if (
      !featureStore!.features.initialConfigCompleted &&
      authStore?.isAdmin() &&
      window.location.pathname !== "/manage-maris"
    ) {
      return <Navigate to={{ pathname: "/manage-maris" }} />;
    }

    if (
      !featureStore!.features.managePeripherals &&
      authStore?.isAdmin() &&
      window.location.pathname == "/manage-peripherals"
    ) {
      return <Navigate to={{ pathname: ROLE_DEFAULT_PATH.ADMIN }} />;
    }

    if (isRoleAllowed() && !shouldRedirect) {
      return <Component {...props} />;
    } else {
      return redirectBasedOnRole();
    }
  };

  const redirectBasedOnRole = () => {
    const role = getUserRole();

    if (role === ROLES.UNKNOWN) return null;

    const roleToString = ROLES[role];
    const path = ROLE_DEFAULT_PATH[roleToString];

    return <Navigate to={{ pathname: path }} />;
  };

  const getUserRole = (): number => {
    if (authStore?.isAdmin()) {
      return ROLES.ADMIN;
    }
    if (authStore?.isNurseAdmin()) {
      return ROLES.NURSEADMIN;
    }
    if (authStore?.isNurse()) {
      return ROLES.NURSE;
    }
    return ROLES.UNKNOWN;
  };

  const isRoleAllowed = (): boolean => {
    const role = getUserRole();

    if (!requiredRoles) return true;

    if (requiredRoles.includes(role)) return true;
    return false;
  };

  return render();
}

export default inject(
  "authStore",
  "featureStore",
  "transitionStore"
)(AuthorizedRoute);
