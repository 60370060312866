import ProfileIcon from "./profile-icon";

import styles from "./client-profile.module.css";

export default function ClientProfileCell(props: any) {
  const { original, accessClientProfile, t } = props;

  return (
    <div className={styles["client-profile"]}>
      <div className={styles["client-profile-icon"]}>
        <ProfileIcon
          sensorIssues={original.sensorIssues}
          onClick={() => {
            accessClientProfile(original.id);
          }}
        />
      </div>

      {original.hasTemporaryProfileChange && (
        <div className={styles["profile-text"]}>
          <span>{t("Temporarily changed")}</span>
        </div>
      )}
    </div>
  );
}
