import styles from "./page.module.css";

import { observer, inject } from "mobx-react";
import { ClientOverviewStore, EmptyClientData } from "./store/store";
import Header from "./components/header";
import { ViewSwitch, ViewItem } from "../../components/view-switch";
import { useEffect } from "react";
import { TransitionStore } from "../../store";
import { useTranslation } from "react-i18next";
import ViewTogggle from "./components/view-toggle/view-toggle";
import ResidentStatusToggle from "./components/sort-toggle/ResidentStatusToggle";
import TimeToggle from "./components/time-toggle/time-toggle";
import ResidentStatusTab from "./components/tabs/ResidentStatusTab";
import AlertOverviewTab from "./components/tabs/AlertOverviewTab";
import AlertOverviewToggle from "./components/sort-toggle/AlertOverviewToggle";

interface HomeProps {
  clientOverviewStore?: ClientOverviewStore;
  transitionStore?: TransitionStore;
}

export function Home(props: HomeProps) {
  const { ready: readyClientGrid } = useTranslation("client-grid");
  const { ready: readySearch } = useTranslation("search-client");
  const clientOverviewStore = props.clientOverviewStore!;
  const { isDataLoading, isTranslationLoading } = clientOverviewStore;
  const transitionStore = props.transitionStore!;
  const languagesLoaded = readyClientGrid && readySearch;

  useEffect(() => {
    return () => {
      clientOverviewStore!.setSelectedClient(EmptyClientData);
      transitionStore!.setGlobalWarningMessage("");
    };
  }, []);

  useEffect(() => {
    if (!languagesLoaded) {
      clientOverviewStore.setIsTranslationLoading(true);
    } else {
      clientOverviewStore.setIsTranslationLoading(false);
    }

    if (isDataLoading || isTranslationLoading) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  const render = () => {
    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div className={styles["clients-overview-grid-container"]}>
              <div
                className={styles["clients-overview-grid-buttons-container"]}
              >
                <ViewSwitch activeKey={clientOverviewStore.viewType}>
                  <ViewItem itemKey="card">
                    <ViewSwitch activeKey={clientOverviewStore.selectedTab}>
                      <ViewItem itemKey="client-status">
                        <ResidentStatusToggle />
                      </ViewItem>
                      <ViewItem itemKey="alarm-settings">
                        <AlertOverviewToggle />
                      </ViewItem>
                    </ViewSwitch>
                  </ViewItem>
                </ViewSwitch>

                {clientOverviewStore.selectedTab === "alarm-settings" &&
                  clientOverviewStore.viewType === "card" && (
                    <div className={styles["time-toggle"]}>
                      <TimeToggle />
                    </div>
                  )}
                <ViewTogggle />
              </div>
              <div className={styles["clients-overview-content-container"]}>
                <ViewSwitch activeKey={clientOverviewStore.selectedTab}>
                  <ViewItem itemKey="client-status">
                    <ResidentStatusTab />
                  </ViewItem>
                  <ViewItem itemKey="alarm-settings">
                    <AlertOverviewTab />
                  </ViewItem>
                </ViewSwitch>
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return render();
}
export default inject("clientOverviewStore", "transitionStore")(observer(Home));
