import styles from "./daily.module.css";

import moment, { Moment } from "moment";
import { IDailyChart, IActivity } from "./daily-chart.panel.data";
import { inject, observer } from "mobx-react";
import web from "../../web";
import { useTranslation } from "react-i18next";
import { PortraitMode, LandscapeMode } from "../../../../media-queries";
import { CanvasChart } from "./canvas-daily-chart/canvas-chart";
import * as TimeConversion from "./utils/time-conversions";
import { useEffect, useState } from "react";
import { ArrowButton, GetArrow } from "./dashboard-components/arrow-button";
import { TodayButton } from "./dashboard-components/today-button";
import { Category, GetCategories } from "./dashboard-components/categories";
import { ClientActivityDataIn } from "../../store/typesIn";
import PickerWithButtonField from "./calendar-picker/CalendarButton";
import { ClientDashboardStore } from "../../store/store";

export interface IDailyView {
  clientId: number;
  clientDashboardStore?: ClientDashboardStore;
}

function DailyView(props: IDailyView) {
  const { clientId } = props;
  const [chartData, setChartData] = useState<IDailyChart>({
    data: [],
    hasBathroom: true,
    coveredHours: 0,
  });
  const [timestamp, setTimestamp] = useState<Moment | null>(null);
  const clientDashboardStore = props.clientDashboardStore!;

  const daysShiftLimit = 31;

  const currentDate = (): Moment => {
    return moment().locale(navigator.language.substring(0, 2));
  };

  const timezoneOffsetInMinutes = (): number => {
    return -new Date().getTimezoneOffset();
  };

  const [displayedDate, setDisplayedDate] = useState<Moment>(currentDate());
  const [categories, setCategories] = useState<Category[]>([]);
  const [noData, setNoData] = useState<boolean>(false);

  const { t } = useTranslation("dashboard");

  const IN_ROOM_INDEX = 2;

  useEffect(() => {
    setCategories(GetCategories({ t }));
    setTimestamp(moment());
    onTimestampChange(moment());
  }, []);

  const render = () => {
    return (
      <div className={styles["daily-view-container"]}>
        <div className={styles["chart-info"]}>
          <div className={styles["daily-view-title"]}>{t("Daily View")}</div>
          {LeftArrow()}
          {DateComponent()}
          {ArrowRight()}
          {Today()}
        </div>
        <div className={styles["daily-chart"]}>
          <LandscapeMode>
            <CanvasChart
              canvasId="normal-chart"
              parts={1440}
              barData={getMappedData()}
              categories={getCategories()}
              yLabels={getYLabels()}
              drawBarConnectionLines={true}
            ></CanvasChart>
          </LandscapeMode>
          <PortraitMode>
            <CanvasChart
              canvasId="small-chart"
              parts={1440}
              barData={getSmallChartMappedData()}
              categories={getCategories()}
              yLabels={getSmallChartYLabels()}
            ></CanvasChart>
          </PortraitMode>
        </div>
      </div>
    );
  };

  const LeftArrow = () => {
    let enabled = false;
    let isDateSameAsLastValidDate = false;

    if (timestamp)
      isDateSameAsLastValidDate = timestamp.isSame(lastValidDate(), "days");

    enabled =
      (moment().diff(timestamp, "days") < daysShiftLimit && !noData) &&
      !isDateSameAsLastValidDate;

    return ArrowButton({
      isEnabled: enabled,
      onArrowClick: onClickLeftArrow,
      styleName: "arrow-left",
      data: GetArrow(),
    });
  };

  const lastValidDate = () => {
    let lastValidDate = moment();

    const list = clientDashboardStore.statusesAndAlerts;
    const statusesList = list.filter((item) => {
      return item.type === 1;
    });
    if (statusesList.length > 0) {
      lastValidDate = moment(statusesList[statusesList.length - 1].timestamp);
    }

    return lastValidDate;
  };

  const disableDays = (date: Moment) => {
    const diff = moment().diff(date, "days");
    const isDateAfterLastValidDate = date.isBefore(lastValidDate(), "days");
    return diff > daysShiftLimit || date.isAfter() || isDateAfterLastValidDate;
  };

  const DateComponent = () => {
    return (
      <PickerWithButtonField
        value={timestamp}
        shouldDisableDate={disableDays}
        label={getLabel()}
        onChange={(value) => {
          if (!value) return;
          let offsetDate = value?.startOf("day");

          if (offsetDate.isSameOrAfter(moment(), "day")) {
            offsetDate = moment();
          }

          onTimestampChange(offsetDate, true);
        }}
      />
    );
  };

  const getLabel = () => {
    let day = "";

    if (timestamp?.isSame(moment(), "day")) {
      day =
        moment().subtract(1, "days").format("DD") + "-" + moment().format("DD");
    } else {
      if (timestamp) day = timestamp!.format("DD");
    }

    const label =
      timestamp == null
        ? ""
        : timestamp
            .locale(navigator.language.substring(0, 2))
            .format("MMM YYYY");
    return day + " " + label;
  };

  const ArrowRight = () => {
    if (!timestamp) return;

    let enabled = false;

    if (moment().diff(timestamp, "days") > 0) {
      enabled = true;
    }

    return ArrowButton({
      isEnabled: enabled,
      onArrowClick: onClickRightArrow,
      styleName: "arrow-right",
      data: GetArrow(),
    });
  };

  const Today = () => {
    if (!timestamp) return;

    const enabled = !timestamp?.isSame(moment(), "day");

    return TodayButton({
      isEnabled: enabled,
      onButtonClick: onClickToday,
      t: t,
    });
  };

  const getSmallChartYLabels = () => {
    const labels = new Map<number, string>();

    for (let hour = 0; hour <= 24; hour += 2) {
      labels.set(TimeConversion.hourToMinutes(hour), hour + "h");
    }

    return labels;
  };

  const getSmallChartMappedData = () => {
    const itemList: any[] = [];

    cleanCategoryCounts();
    if (chartData && chartData.data)
      chartData.data.forEach((data: any) => {
        const start = data.timeStart;
        const end = data.timeEnd;

        categories[data.type].count += 1;
        categories[data.type].total += Math.floor(
          TimeConversion.millisecondsToMinutes(end - start)
        );
      });

    categories.forEach((category, index) => {
      let item = { category: 0, start: 0, end: 0, label: "" };

      item.start = 0;
      item.end = category.total;
      item.category = index;

      itemList.push(item);
    });

    return itemList;
  };

  const getItemLabel = (start: number, end: number) => {
    let label = "";

    const minutesLabel = Math.floor(
      TimeConversion.millisecondsToMinutes(end - start)
    );
    const hours = Math.floor(TimeConversion.minutesToHour(minutesLabel));
    if (hours > 0) {
      label = `${hours}h`;
    }

    const hoursInMinutes = TimeConversion.hourToMinutes(hours);
    if (minutesLabel - hoursInMinutes > 0) {
      label = label + `${minutesLabel - hoursInMinutes}m`;
    }

    return label;
  };

  const getMappedData = () => {
    const upperLimit = displayedDate.valueOf();
    const lowerLimit = upperLimit - TimeConversion.MILLISECONDS_IN_DAY;
    const itemList: any[] = [];

    cleanCategoryCounts();
    if (!chartData || !chartData.data) return itemList;

    chartData.data.forEach((data: IActivity) => {
      let item = { category: 0, start: 0, end: 0, label: "" };

      const start = data.timeStart;
      const end = data.timeEnd;

      if (start <= lowerLimit) {
        item.start = 0;
      } else {
        item.start = Math.floor(
          TimeConversion.millisecondsToMinutes(start - lowerLimit)
        );
      }

      item.end = Math.floor(
        TimeConversion.millisecondsToMinutes(end - lowerLimit)
      );

      item.label = getItemLabel(start, end);

      if (end - start > 0) {
        categories[data.type].count += 1;
        categories[data.type].total += Math.floor(
          TimeConversion.millisecondsToMinutes(end - start)
        );
      }

      item.category = data.type;
      itemList.push(item);
    });

    return itemList;
  };

  const cleanCategoryCounts = () => {
    categories.forEach((category) => {
      category.count = 0;
      category.total = 0;
    });
  };

  const getCategories = () => {
    return categories.map((category, index) => {
      let label = [];

      if (index === IN_ROOM_INDEX) {
        label = [t(category.name)];
      } else {
        const minutes = getMinutesToString(category.total);
        if (minutes === "") {
          label = [t(category.name)];
        } else {
          const totalCount = getCountToString(category.count);
          label = [`${minutes} ${totalCount}`, t(category.name)];
        }
      }

      return {
        color: category.color,
        icon: category.imageSrc,
        labels: label,
      };
    });
  };

  const getCountToString = (count: number) => {
    if (count) return `(${count}x)`;
    return "";
  };

  const getMinutesToString = (minutes: number) => {
    let label = "";

    const hours = Math.floor(TimeConversion.minutesToHour(minutes));
    if (hours > 0) {
      label = `${hours}h`;
    }
    if (minutes - TimeConversion.hourToMinutes(hours) > 0) {
      label = label + `${minutes - TimeConversion.hourToMinutes(hours)}m`;
    }
    return label;
  };

  const getHoursToString = (hour: number, lowerHour: number) => {
    let shownHour = hour + lowerHour;
    if (shownHour > 24) shownHour = shownHour - 24;

    if (shownHour === 24) return "00:00";
    if (shownHour < 10) return `0${shownHour}:00`;
    return `${shownHour}:00`;
  };

  const getYLabels = () => {
    const labels = new Map<number, string>();
    let lowerHour = 0;
    if (timestamp) lowerHour = timestamp.hour();

    for (let hour = 0; hour <= 24; hour += 2) {
      labels.set(
        TimeConversion.hourToMinutes(hour),
        getHoursToString(hour, lowerHour)
      );
    }

    return labels;
  };

  const onTimestampChange = (
    timestamp: Moment,
    isFromDateComponent: boolean = false
  ) => {
    requestClientActivity(timestamp).then(
      (data: ClientActivityDataIn | null) => {
        if (data === null) {
          if (!isFromDateComponent) setNoData(true);
          return;
        }

        setNoData(false);

        setChartData({
          data: data.clientActivities,
          hasBathroom: data.hasBathroom,
          coveredHours: data.coveredHours,
        });

        if (data.clientActivities.length === 0) {
          return;
        }

        setTimestamp(timestamp);
      }
    );
  };

  const requestClientActivity = (timestamp: Moment) => {
    const year = timestamp.year();
    const month = timestamp.month();
    const day = timestamp.date();

    const formatedTimestamp = formatRequestDate(
      timestamp
        .clone()
        .subtract(timezoneOffsetInMinutes(), "minutes")
        .date(day)
        .month(month)
        .year(year)
    );

    const request = {
      clientId,
      timestamp: formatedTimestamp,
      coveredHours: 24,
      coveredDays: 31,
    };

    return web
      .readClientActivity(request)
      .then((result) => {
        const data = result.data!.clientActivityData as ClientActivityDataIn;
        if (data.clientActivities.length > 0) {
          const lastTimestamp = moment(
            data.clientActivities[data.clientActivities.length - 1].timeEnd
          );
          setDisplayedDate(lastTimestamp);
          return data;
        }

        return null;
      })
      .catch(() => {
        return null;
      });
  };

  const onClickLeftArrow = () => {
    if (!timestamp) {
      return;
    }

    const date = timestamp.clone().subtract(1, "days").startOf("day");

    onTimestampChange(date);
  };

  const onClickRightArrow = () => {
    if (!timestamp) {
      return;
    }

    let date = timestamp.clone().add(1, "day").startOf("day");

    if (date.isSameOrAfter(moment(), "day")) {
      date = moment();
    }

    onTimestampChange(date);
  };

  const onClickToday = () => {
    if (!timestamp) {
      return;
    }

    const date = moment();
    onTimestampChange(date);
  };

  return render();
}

const formatRequestDate = (dateTime: moment.Moment) => {
  return dateTime.format("YYYY-MM-DDTHH:mm:ss");
};

export default inject("clientDashboardStore")(observer(DailyView));
