import { postRequest, deleteRequest, uploadFileRequest } from "../../web";
import { IListSubstatuses } from "./store/types";
import { SubstatusDataListCallIn } from "./store/typesIn";
import { SubstatusDataListCallOut } from "./store/typesOut";

const resourceUrl = "/api/careplan/client-substatus";

const webCalls = {
  readAll(substatusData: IListSubstatuses) {
    return postRequest<SubstatusDataListCallOut, SubstatusDataListCallIn>(
      `${resourceUrl}/list`,
      substatusData
    );
  },

  update(substatusData: FormData) {
    return uploadFileRequest<null>(resourceUrl, substatusData);
  },

  remove(substatusId: number) {
    return deleteRequest<{ substatusId: number }, null>(resourceUrl, {
      substatusId,
    });
  },
};

export default webCalls;
