import styles from "./status-item.module.css";

import moment from "moment";
import { formatStatusText } from "../../../clients-overview/components/resident-status-grid/cells/client-status.cell";
import {
  IconElement,
  SelectIcon,
  StatusIcon,
} from "../../../../components/select-icon";
import { IStatusAndAlert } from "../../store/types";
import { useTranslation } from "react-i18next";
import OverflowLabel from "../../../../components/OverflowLabel";

export interface StatusItemProps {
  original: IStatusAndAlert;
}

enum AlertOrStatusType {
  None = 0,
  Status = 1,
  Alert = 2,
  ExternalStatus = 3,
}

export function StatusItem(props: StatusItemProps) {
  const { original } = props;

  const { t: statusesTranslation } = useTranslation("statuses");

  const item =
    original.type === AlertOrStatusType.Alert
      ? {
          alertId: original.typeId,
          alertDelayed: original.wasDelayed,
          alertIcon: original.iconData,
        }
      : {
          statusId: original.typeId,
          statusIcon: original.iconData,
          parentStatusId: original.parentTypeId,
        };

  const SelectedIcon = SelectIcon(item);

  return (
    <div className={styles["status-item"]}>
      <div className={styles["icon"]}>
        <StatusIcon>
          <IconElement
            data={SelectedIcon.element}
            isCustom={SelectedIcon.isCustomIcon}
            className={SelectedIcon.className}
            width="30px"
            height="30px"
          />
        </StatusIcon>
      </div>
      <div className={styles["info-container"]}>
        <div className={styles["name"]}>
          <OverflowLabel
            text={formatLabels(original, item, statusesTranslation)}
          />
        </div>
        <div className={styles["timestamp"]}>
          {formatTime(original.timestamp)}
        </div>
      </div>
    </div>
  );
}

function formatLabels(original: IStatusAndAlert, item: any, t: any) {
  if (original.type === AlertOrStatusType.ExternalStatus) {
    return original.name;
  }
  const alertText = formatAlertText(item, t);
  if (!!alertText) return alertText;

  return original.name;
}

function formatAlertText(item: any, t: any) {
  if (!!item.alertId && item.alertId === 5) {
    return t("Visitor");
  }
  return formatStatusText(item, t);
}

function formatTime(timestamp: any) {
  const result = moment(timestamp)
    .locale(navigator.language.substring(0, 2))
    .format("DD MMM HH:mm");

  return result;
}
