import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface MariNameInputProps {
  mariDataStore?: MariDataStore;
}

const InputStringMaximumLength = 99;

function MariNameInput(props: MariNameInputProps) {
  const mariDataStore = props.mariDataStore!;
  const { t } = useTranslation("mari-grid");
  const mariName = mariDataStore.selectedMari.mariName;
  const { errorMessages } = mariDataStore;

  return (
    <TextFieldInput
      autoFocus
      value={mariName}
      disabled={mariDataStore.isValidationInProgress}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["mariName"] &&
          t("RequiredInformation")) ||
        ""
      }
      label={t("MariName").toUpperCase()}
      placeholder={t("MariName")}
      onChange={(event: any) => {
        const mariNameValue = event.target.value;
        if (mariNameValue.length > InputStringMaximumLength) return;

        mariDataStore.setSelectedMariName(mariNameValue);
        mariDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        mariDataStore.validateSelectedMari("mariName");
        mariDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("mariDataStore")(observer(MariNameInput));
