import styles from "./client-profile-header.module.css";

import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ClientProfileDetails } from "../../store/types";
import { PageHeader } from "../../../../components/page";
import BackButton from "../../../../components/buttons/BackButton";
import { useNavigate } from "react-router-dom";

interface ClientHeaderProps {
  clientDetails: ClientProfileDetails;
}

export function ClientProfileHeader(props: ClientHeaderProps) {
  const { t } = useTranslation("client-profile");
  const { clientDetails } = props;

  const history = useNavigate();

  const render = () => {
    return (
      <PageHeader>
        <div className={styles["title"]}>{t("Client Profile")}</div>
        <div className={styles["client-name"]}>{clientDetails.clientName}</div>
        <div className={styles["client-area"]}>
          <span>{clientDetails.areaName}</span>
          <span> - </span>
          <span className={styles["client-room"]}>
            {clientDetails.locationName}
          </span>
        </div>
        <div className={styles["header-button"]}>
          <BackButton sx={{ height: 40 }} onClick={() => history("/residents-overview")}>
            {t("Back")}
          </BackButton>
        </div>
      </PageHeader>
    );
  };
  
  return render();
}
export default observer(ClientProfileHeader);
