import { observable, makeObservable, action } from "mobx";
import { IStatusAndAlert, IClientStatus } from "./types";

export interface ClientDetails {
  clientName: string;
  locationName: string;
  areaName: string;

  timestamp: string;
}

export class ClientDashboardStore {
  constructor() {
    makeObservable(this, {
      clientDetails: observable,
      statusesAndAlerts: observable,
      clientStatuses: observable,
      isDataLoading: observable,
      isTranslationLoading: observable,

      setStatusesAndAlers: action,
      setIsTranslationLoading: action,
    });
  }

  public clientDetails: ClientDetails = {
    clientName: "",
    locationName: "",
    areaName: "",

    timestamp: "",
  };

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public statusesAndAlerts: IStatusAndAlert[] = [];
  public clientStatuses: IClientStatus[] = [];

  public setStatusesAndAlers(value: IStatusAndAlert[]) {
    this.statusesAndAlerts = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }
}
