import "./header.css";

import { HeaderProfileIcon, SmartSenseLogo } from "./svg";
import { inject, observer } from "mobx-react";
import { AuthStore, TransitionStore } from "../store";
import webResource from "../modules/login/web";
import { useNavigate } from "react-router-dom";
import { CreateConfirmationDialog } from "../utils/ConfirmationDialog";
import OverflowLabel from "./OverflowLabel";

export interface HeaderProps {
  authStore?: AuthStore;
  transitionStore?: TransitionStore;
  t(value: string): string;
}

function Header(props: HeaderProps) {
  const { t, authStore, transitionStore } = props;
  const firstname = authStore?.Firstname();
  const lastname = authStore?.Lastname();
  const history = useNavigate();

  const render = () => {
    return (
      <div className="layout-header">
        <div className="title-container">
          <SmartSenseLogo onClick={handleLogoClick} />
          <div className="title">{t("HeaderTitle")}</div>
        </div>
        <div className="profile-container">
          <div className="profile-icon">
            <HeaderProfileIcon />
          </div>
          <div className="profile-name">
            <OverflowLabel text={firstname + " " + lastname} />
          </div>
          <div className="separator" />
          <div onClick={handleLogoutClick} className="logout-button">
            {t("HeaderLogout")}
          </div>
        </div>
      </div>
    );
  };

  const handleLogoClick = () => {
    history("/residents-overview");
  };

  const handleLogoutClick = () => {
    const confirmationDialogData = CreateConfirmationDialog(
      t("HeaderLogout"),
      t("LogOutContent"),
      t("Yes"),
      t("No"),
      false,
      handleLogout
    );

    transitionStore!.showGlobalDialog(confirmationDialogData);
  };

  const handleLogout = () => {
    webResource
      .requestLogout()
      .then(() => {
        window.location.href = "/";
      })
      .finally(() => {
        authStore?.clearAuthentication();
      });
  };

  return render();
}

const injectedComponent = inject(
  "authStore",
  "transitionStore"
)(observer(Header));

export { injectedComponent as Header };
