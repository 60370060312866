import { action, makeObservable, observable, ObservableMap } from "mobx";

export default class LoginStore {
  constructor() {
    makeObservable(this, {
      credentials: observable,

      setUsername: action,
      setPassword: action,
    });
  }

  public credentials: {
    username: string;
    password: string;
  } = {
    username: "",
    password: "",
  };

  setUsername(username: string) {
    this.credentials.username = username;
  }

  setPassword(password: string) {
    this.credentials.password = password;
  }

  public validationErrors: ObservableMap<any> = observable.map();
}
