import gridStyles from "./manage-substatuses-grid.module.css";
import searchStyles from "../search-substatus.module.css";

import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { NotificationDialog } from "../../../../utils/NotificationDialog";
import {
  EmptySubstatus,
  SubstatusDataStore,
  SubstatusSort,
} from "../../store/store";
import {
  AlertData,
  IListSubstatuses,
  ISubstatusData,
  SubstatusData,
} from "../../store/types";
import { TransitionStore } from "../../../../store";
import Web from "../../web";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import EditSubstatus from "../edit-substatus/edit-substatus";
import webResource from "../../web";
import { NoConnectionModal } from "../../../../utils/NoConnectionDialog";
import { GlobalDialogData } from "../../../../store/transition-store";
import { useEffect } from "react";
import {
  SortHeader,
  SORT_DIRECTION,
} from "../../../../components/table/headers/SortHeader";
import {
  ActionsCell,
  ButtonAvailability,
  ButtonVisibility,
  BUTTON_TYPE,
} from "../../../../components/table/cells/ActionsCell";
import {
  BaseTable,
  ColumnData,
  ExpandedState,
} from "../../../../components/table/table";
import { TextCell } from "../../../../components/table/cells/TextCell";
import {
  BasicHeader,
  ColumnAlign,
} from "../../../../components/table/headers/BasicHeader";
import { SensorDataConverter } from "../../store/convertor";
import { SubstatusDataIn } from "../../store/typesIn";
import AlertIcon from "../cells/icon";

interface ISubstatusGridProps {
  substatusDataStore?: SubstatusDataStore;
  transitionStore?: TransitionStore;
}

function ManageSubstatusesGrid(props: ISubstatusGridProps) {
  const { t } = useTranslation(["substatus-grid"]);
  const { t: commonT } = useTranslation(["common"]);

  const transitionStore = props.transitionStore!;
  const substatusDataStore = props.substatusDataStore!;
  const { errors } = substatusDataStore;
  const { statusSort, selectedSubstatus, oldSubstatusData, validated } =
    substatusDataStore;
  const { isLoading } = transitionStore;

  useEffect(() => {
    substatusDataStore.setStatusSort([
      new SubstatusSort(statusNameAccessor, false),
    ]);
    readAllSubstatusData();

    return function unmount() {
      substatusDataStore.setHilightSubstatus(null);
      substatusDataStore.errors = null;
      substatusDataStore.closeExpanded();
      substatusDataStore.searchValue = "";
    };
  }, []);

  const readAllSubstatusData = () => {
    substatusDataStore.setIsDataLoading(true);

    const params: IListSubstatuses = {
      SortOptions: substatusDataStore.statusSort,
    };

    const noStatuses: SubstatusData[] = [];
    const noAlerts: AlertData[] = [];

    return webResource
      .readAll(params)
      .then((ajaxResponse: any) => {
        const response = ajaxResponse.data;

        const storeStatusList = response.data.map(
          (substatus: SubstatusDataIn) => {
            return SensorDataConverter.toStore(substatus);
          }
        );

        substatusDataStore.setAlerts(response.alertsData);
        substatusDataStore.setSubstatuses(storeStatusList);
      })
      .catch((ajaxResponse: any) => {
        const dialog: GlobalDialogData = NoConnectionModal(t);
        transitionStore.showGlobalDialog(dialog);
        substatusDataStore.setAlerts(noAlerts);
        substatusDataStore.setSubstatuses(noStatuses);
      })
      .finally(() => {
        substatusDataStore.setIsDataLoading(false);
      });
  };

  const getSortDirection = (accessor: string) => {
    if (accessor !== statusSort[0].id) return SORT_DIRECTION.NONE;

    if (statusSort[0].desc) return SORT_DIRECTION.DESCENDING;
    else return SORT_DIRECTION.ASCENDING;
  };

  const actionButtonsAvailability = (
    index: number,
    selectedIndex: number
  ): ButtonAvailability => {
    let editAvailability: boolean = false;
    let deleteAvailability: boolean = false;

    if (selectedIndex === ExpandedState.COLLAPSED && !isLoading) {
      editAvailability = true;
      deleteAvailability = true;
    }

    return {
      edit: editAvailability,
      delete: deleteAvailability,
    };
  };

  const saveDisabled = () => {
    let saveAvailability = false;
    const { parentStatusName, statusIconChanged, ...selectedSubstatusValues } =
      selectedSubstatus;
    const {
      parentStatusName: unusedValue1,
      statusIconChanged: unusedValue2,
      ...oldSubstatusValues
    } = oldSubstatusData;

    if (substatusDataStore.isSaveLoading) return true;

    if (
      JSON.stringify(selectedSubstatusValues) ===
      JSON.stringify(oldSubstatusValues)
    ) {
      saveAvailability = true;
    }

    if (!validated) {
      saveAvailability = true;
    }

    if (errors && Object.keys(errors).length !== 0) {
      saveAvailability = true;
    }

    return saveAvailability;
  };

  const actionButtonsVisibility = (
    index: number,
    selectedIndex: number
  ): ButtonVisibility => {
    let editVisibility: boolean = false;
    let deleteVisibility: boolean = false;

    if (index !== selectedIndex) {
      editVisibility = true;
      deleteVisibility = true;
    }

    return {
      edit: editVisibility,
      delete: deleteVisibility,
    };
  };

  const save = () => {
    const substatusData = substatusDataStore.selectedSubstatus;

    update(substatusData);
  };

  const update = (substatusData: SubstatusData) => {
    let data: FormData = new FormData();

    if (
      substatusData.newStatusIcon !== null &&
      substatusData.newStatusIcon !== undefined
    ) {
      data.append(
        "statusIcon",
        Object.fromEntries(substatusData.newStatusIcon)["statusIcon"]
      );
    }

    if (!!substatusData.statusId) {
      data.append("statusId", substatusData.statusId!.toString());
    } else {
      data.append("statusId", "0");
    }

    if (substatusData.parentStatusId) {
      data.append("parentStatusId", substatusData.parentStatusId!.toString());
    }

    if (!!substatusData.alertId)
      data.append("alertId", `${substatusData.alertId}`);

    data.append("statusIconChanged", `${!!substatusData.statusIconChanged}`);
    data.append("statusName", substatusData.statusName);
    data.append("alternateText", substatusData.alternateText!);

    substatusDataStore.setIsSaveLoading(true);
    Web.update(data)
      .then(
        () => {
          substatusDataStore.setSelectedSubstatusIsNewStatus(false);
          substatusDataStore.setSelectedSubstatus(EmptySubstatus);
          substatusDataStore.closeExpanded();

          readAllSubstatusData();
        },
        (err: any) => {
          err.message = t("Could not save or update substatus.");
          resolveAjaxError(err.data);
        }
      )
      .finally(() => {
        substatusDataStore.setIsSaveLoading(false);
      });
  };

  const resolveAjaxError = (error: any) => {
    const manageStatusErrors = error.ManageStatus;

    if (manageStatusErrors === undefined || manageStatusErrors.length === 0) {
      reportAjaxError(error.message);
      return;
    }

    const errorData = manageStatusErrors[0];
    reportAjaxError(errorData);
  };
  const reportAjaxError = (errorMessage: string) => {
    const dialog: GlobalDialogData = NotificationDialog(
      t("ErrorTitleMessage"),
      t(errorMessage),
      commonT("Ok")
    );

    transitionStore?.showGlobalDialog(dialog);
  };

  const parentStatusNameAccessor = "parentStatusName";
  const statusNameAccessor = "statusName";
  const alternateTextAccessor = "alternateText";
  const alertNameAccessor = "alertName";

  const columnData: ColumnData[] = [
    {
      header: t("StatusName"),
      width: "18%",
      HeaderComponent: (
        <SortHeader
          text={t("StatusName")}
          selected={statusSort[0].id === statusNameAccessor}
          sortDirection={getSortDirection(statusNameAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(statusNameAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => {
        return <TextCell text={value.statusName} />;
      },
    },
    {
      header: t("AlternateText"),
      width: "18%",
      HeaderComponent: (
        <SortHeader
          text={t("AlternateText")}
          selected={statusSort[0].id === alternateTextAccessor}
          sortDirection={getSortDirection(alternateTextAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(alternateTextAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => {
        return <TextCell text={value.alternateText} />;
      },
    },
    {
      header: t("ParentStatusName"),
      width: "18%",
      HeaderComponent: (
        <SortHeader
          text={t("ParentStatusName")}
          selected={statusSort[0].id === parentStatusNameAccessor}
          sortDirection={getSortDirection(parentStatusNameAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(parentStatusNameAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => (
        <TextCell text={t(value.parentStatusName)} />
      ),
    },
    {
      header: t("alertName"),
      width: "18%",
      HeaderComponent: (
        <SortHeader
          text={t("alertName")}
          selected={statusSort[0].id === alertNameAccessor}
          sortDirection={getSortDirection(alertNameAccessor)}
          locked={substatusDataStore.hasSelectedSubstatus}
          onClick={() => updateSorting(alertNameAccessor)}
        />
      ),
      cellComponent: (value: ISubstatusData) => {
        return (
          <TextCell text={substatusDataStore.getAlertName(value.alertId)} />
        );
      },
    },
    {
      header: t("StatusIcon"),
      HeaderComponent: (
        <BasicHeader text={t("StatusIcon")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: ISubstatusData) => {
        return <AlertIcon data={value.statusIcon} />;
      },
    },
    {
      header: t("Actions"),
      width: "140px",
      HeaderComponent: (
        <BasicHeader text={t("Actions")} align={ColumnAlign.Center} />
      ),
      cellComponent: (value: ISubstatusData, index: number) => {
        if (value.statusId && value.statusId <= 10) return <span></span>;
        return (
          <ActionsCell
            buttonVisibility={actionButtonsVisibility(
              index,
              substatusDataStore.expandedSubstatusIndex
            )}
            buttonAvailability={actionButtonsAvailability(
              index,
              substatusDataStore.expandedSubstatusIndex
            )}
            onButtonClick={(type) => onActionButtonClicked(value, index, type)}
          />
        );
      },
    },
  ];

  const onActionButtonClicked = (
    substatusData: ISubstatusData,
    index: number,
    buttonType: BUTTON_TYPE
  ) => {
    switch (buttonType) {
      case BUTTON_TYPE.EDIT: {
        const data: ISubstatusData = {
          statusId: substatusData.statusId,
          alertId: substatusData.alertId,
          parentStatusId: substatusData.parentStatusId,
          parentStatusName: substatusData.parentStatusName,
          statusName: substatusData.statusName,
          alternateText:
            substatusData.alternateText === null
              ? ""
              : substatusData.alternateText,
          statusIdentifier: substatusData.statusIdentifier,
          statusIcon: substatusData.statusIcon,
          statusIconChanged: substatusData.statusIconChanged,
          newStatusIcon: substatusData.newStatusIcon,
        };
        substatusDataStore.setSelectedSubstatus(data);
        substatusDataStore.setExpandedSubstatusIndex(index);
        break;
      }

      case BUTTON_TYPE.DELETE: {
        onDeleteSubstatus(substatusData);
        break;
      }
    }
  };

  const onDeleteSubstatus = (substatus: ISubstatusData) => {
    const content = (
      <div>
        <span>
          {t("DeleteSubstatusDialogQuestion1")}
          <span
            className={gridStyles["manage-substatuses-grid-popup-client-name"]}
          >
            {substatus.statusName}
          </span>
          {t("DeleteSubstatusDialogQuestion2")}
        </span>
      </div>
    );

    const data = CreateConfirmationDialog(
      t("RemoveSubstatus"),
      content,
      t("Remove"),
      t("Cancel"),
      false,
      () => {
        substatusDataStore.setIsDeleteLoading(true);
        Web.remove(substatus.statusId!)
          .then(() => {
            readAllSubstatusData();
          })
          .catch(() => {
            const dialog: GlobalDialogData = NotificationDialog(
              t("Delete error"),
              t("Used status delete"),
              commonT("Ok")
            );

            transitionStore?.showGlobalDialog(dialog);
          })
          .finally(() => {
            substatusDataStore.setIsDeleteLoading(false);
          });
      }
    );

    transitionStore?.showGlobalDialog(data);
  };

  const updateSorting = (name: string) => {
    if (statusSort[0].id === name) {
      substatusDataStore.setStatusSort([
        new SubstatusSort(name, !statusSort[0].desc),
      ]);
    } else {
      substatusDataStore.setStatusSort([new SubstatusSort(name, false)]);
    }
    readAllSubstatusData();
  };

  const highlightedItem = substatusDataStore.highlightSubstatus;
  const gridSubstatusesData = substatusDataStore.substatuses;

  const statusList = gridSubstatusesData.filter((substatus: SubstatusData) => {
    if (substatus.statusId && substatus.statusId <= 10) return false;
    return true;
  });

  return (
    <BaseTable
      minWidth={1286}
      expandedRows={substatusDataStore.expandedSubstatusIndex}
      data={statusList}
      columns={columnData}
      customRowId={(_index, rowData: SubstatusData) => {
        if (rowData.statusId === highlightedItem?.statusId)
          return "selected-substatus";
        return "";
      }}
      customRowClass={(_index, rowData: SubstatusData) => {
        if (rowData.statusId === highlightedItem?.statusId)
          return searchStyles["selected-substatus"];
        return "";
      }}
      expandedRowComponent={
        <EditSubstatus
          saveLocked={saveDisabled()}
          onSaveClick={() => {
            save();
          }}
          onCancelClick={() => {
            substatusDataStore.closeExpanded();
          }}
        />
      }
    />
  );
}

export default inject(
  "substatusDataStore",
  "transitionStore"
)(observer(ManageSubstatusesGrid));
