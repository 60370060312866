import * as React from "react";
import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";

import Sensors from "./page.highorder";

const ProtectedSensors = (props: any) => (
  <AuthorizedRoute
    requiredRoles={[ROLES.ADMIN, ROLES.NURSEADMIN]}
    exact
    path="/"
    component={Sensors}
    {...props}
  />
);
const sensorsRoute = (
  <Route path="/manage-sensors" element={<ProtectedSensors />} />
);

export default sensorsRoute;
