import "./page-header.css";

import classnames from "classnames";

export interface PageHeaderProps {
  children: any;
  className?: string;
}

export default function PageHeader(props: PageHeaderProps) {
  const { className, children } = props;

  const render = () => {
    return (
      <div
        className={classnames("amstel-page-header", className, {
          "mari-height": window.parent !== window.self,
        })}
      >
        {children}
      </div>
    );
  };

  return render();
}
