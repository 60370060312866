import styles from "./icon.module.css";
import { ReactNode } from "react";

export interface IAlertIconProps {
  data?: string;
  icon?: ReactNode;
}

export default function AlertIcon(props: IAlertIconProps) {
  const { data, icon } = props;
  if (!data && !icon) return <div />;

  return (
    <div className={styles["alert-icon"]}>
      {data && <img src={data} alt="" />}
      {icon && icon}
    </div>
  );
}
