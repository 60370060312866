export const MILLISECONDS_IN_DAY = 86400000;
export const MILLISECONDS_IN_HOUR = 3600000;
export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;

export const millisecondsToMinutes = (ms: number) => {
  return ms / MILLISECONDS_IN_SECOND / SECONDS_IN_MINUTE;
};

export const minutesToHour = (minutes: number) => {
  return minutes / MINUTES_IN_HOUR;
};

export const hourToMinutes = (hour: number) => {
  return hour * MINUTES_IN_HOUR;
};

export const localTimeToUtc = (localMs: number) => {
  return (
    localMs +
    new Date().getTimezoneOffset() * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND
  );
};

export const localTimeOffsetInMilliseconds = (): number => {
  return (
    new Date().getTimezoneOffset() * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND
  );
};
