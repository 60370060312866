import { Button, ButtonProps } from "@mui/material";
import { Plus } from "../../../../../components/svg";

interface AddRoomButtonProps {
    children?: React.ReactNode;
}

function AddRoomButton(props: AddRoomButtonProps | ButtonProps) {
    const { children, ...rest } = props;

    const render = () => {
        return (
            <Button
                sx={{ height: "36px", color: "#333333" }}
                fullWidth
                startIcon={<Plus />}
                {...rest}
            >
                {children}
            </Button>
        );
    };

    return render();
}

export default AddRoomButton;