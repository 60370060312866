import "./tab-item.css";
import * as React from "react";
import { useEffect } from "react";
import classnames from "classnames";

export interface TabItemProps {
  itemKey: string;
  label?: string;
  onClick?(key: string): void;
  isInactive?: boolean;
  children?: React.ReactNode[];
}

export function TabItem(props: TabItemProps) {
  useEffect(() => {}, []);

  const handleClick = () => {
    const { itemKey, onClick } = props;
    if (onClick) onClick(itemKey);
  };

  const children = () => {
    const { children } = props;

    if (!children) {
      return defaultButton();
    }
    return children;
  };

  const defaultButton = () => {
    const { isInactive, label } = props;

    return (
      <div
        className={classnames("default-tab-button", {
          inactive: isInactive,
        })}
      >
        <div className="tab-text">{label}</div>
      </div>
    );
  };

  const render = () => {
    return <div className="tab-button-container" onClick={handleClick}>{children()}</div>;
  };

  return render();
}
