import { readRequest, postRequest } from "../../web";
import {
  ReadClientActivityCallIn,
  ReadClientOverviewCallIn,
  ReadStatusesAndAlertsCallIn,
  ReadStatusSummaryCallIn,
} from "./store/typesIn";
import { ReadClientActivityCallOut } from "./store/typesOut";

const resourceUrl = "/api/careplan/client-dashboard";

const webCalls = {
  readStatusesAndAlerts(clientId: number, periodInDays: number) {
    return readRequest<ReadStatusesAndAlertsCallIn>(
      `${resourceUrl}/last-period-statuses-and-alerts?clientId=${clientId}&periodInDays=${periodInDays}`
    );
  },
  readStatusSummary(clientId: number) {
    return readRequest<ReadStatusSummaryCallIn>(
      `${resourceUrl}/status-summary?clientId=${clientId}`
    );
  },
  readClientActivity(data: ReadClientActivityCallOut) {
    return postRequest<ReadClientActivityCallOut, ReadClientActivityCallIn>(
      `${resourceUrl}/client-activity`,
      data
    );
  },
  readClientOverview(clientId: number) {
    return readRequest<ReadClientOverviewCallIn>(
      `${resourceUrl}/overview?clientId=${clientId}`
    );
  },
};

export default webCalls;
