import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { CameraDataStore } from "../../../store/store";
import { ILocationData } from "../../../store/types";
import { CrossX } from "../../../../../components/svg";
import styles from "../edit-camera.module.css";
import fieldsStyles from "../../../../../components/form/edit-fields/dropdown-field/dropdown-field.module.css";
import { TextFieldInput } from "../../../../../components/form-inputs";
import SimpleIconButton from "../../../../../components/buttons/SimpleIconButton";
import { Fragment } from "react";
import { runInAction } from "mobx";
export interface DistanceInputProps {
  cameraDataStore?: CameraDataStore;
}

function DistanceInput(props: DistanceInputProps) {
  const { t } = useTranslation("camera-grid");

  const cameraDataStore = props.cameraDataStore!;
  const locationList = cameraDataStore.selectedCamera.locations;
  const { errors } = cameraDataStore;

  const emptyLocation: ILocationData = {
    locationId: 0,
    locationName: "emptyLocation",
    locationPath: "",
    distance: 0,
  };

  const onDeleteLocation = (componentId: number) => {
    const elementIndex = cameraDataStore.selectedCamera.locations.findIndex(
      (locDist) => locDist.listUniqueId === componentId
    );

    cameraDataStore.selectedCamera.locations.splice(elementIndex, 1);

    cameraDataStore.setSelectedCameraHasChanges(true);
    cameraDataStore.validateSelectedCamera();

    if (cameraDataStore.selectedCamera.locations.length === 0) {
      cameraDataStore.selectedCamera.locations.push({
        ...emptyLocation,
        listUniqueId: cameraDataStore.getUniqueListId(),
      });
    }
  };

  return (
    <>
      {locationList.map((element, index) => {
        return (
          <Fragment key={index}>
            <div className={styles["distance-field"]}>
              <TextFieldInput
                value={element.distance.toString()}
                inputProps={{ pattern: "^[0-9]{1,3}" }}
                disabled={element.locationId === emptyLocation.locationId}
                placeholder={t("Distance")}
                onChange={(event: any) => {
                  if (event.target.validity.valid === false) return;

                  if (isNaN(event.target.value)) return;

                  const elementIndex =
                    cameraDataStore.selectedCamera.locations.findIndex(
                      (locDist) =>
                        locDist.listUniqueId === element.listUniqueId!
                    );
                  runInAction(() => {
                    cameraDataStore.selectedCamera.locations[
                      elementIndex
                    ].distance = Number(event.target.value);
                  });

                  cameraDataStore.setSelectedCameraHasChanges(true);
                  cameraDataStore.validateSelectedCamera();
                }}
              />
              <SimpleIconButton
                className={styles["clear-location-button"]}
                disabled={element.locationId === emptyLocation.locationId}
                onClick={() => {
                  onDeleteLocation(element.listUniqueId!);
                }}
              >
                <CrossX fill={"#8c9396"} width={24} height={24} />
              </SimpleIconButton>
            </div>
            {errors && errors[`locations_${index}_distance`] && (
              <div className={fieldsStyles["intent-danger"]}>
                {t("Required information")}
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

export default inject("cameraDataStore")(observer(DistanceInput));
