import { Route } from "react-router";
import AuthorizedRoute from "../../components/authorized-route";
import ClientProfile from "./client-profile.highorder";
import { ClientProfileEditAlerts } from "./client-profile-edit-alerts.highorder";
import { ClientProfileEditAccess } from "./client-profile-edit-access.highorder";
import ClientProfileEditCamera from "./client-profile-edit-camera.highorder";


const ProtectedClientProfile = (props: any) => {
  return <AuthorizedRoute path="/" component={ClientProfile} {...props} />;
};

const ProtectedClientProfileEditAlerts = (props: any) => {
  return (
    <AuthorizedRoute path="/" component={ClientProfileEditAlerts} {...props} />
  );
};

const ProtectedClientProfileEditAccess = (props: any) => {
  return (
    <AuthorizedRoute path="/" component={ClientProfileEditAccess} {...props} />
  );
};

const ProtectedClientProfileEditCamera = (props: any) => {
  return (
    <AuthorizedRoute path="/" component={ClientProfileEditCamera} {...props} />
  );
};

const clientProfileRoute = (
  <Route path="/client-profile">
    <Route
      path="alert-settings/:id"
      element={<ProtectedClientProfileEditAlerts />}
    />
    <Route
      path="access-settings/:id"
      element={<ProtectedClientProfileEditAccess />}
    />
    <Route path=":id" element={<ProtectedClientProfile />} />
    <Route
      path="camera-settings/:id"
      element={<ProtectedClientProfileEditCamera />}
    />
  </Route>
);

export default clientProfileRoute;
