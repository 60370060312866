import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";
import { AlertDataStore } from "../../../store/store";
import { alertDataSchema } from "../../../validation";

export interface AlternateTextInputInputProps {
  alertDataStore?: AlertDataStore;
}

function AlternateTextInput(props: AlternateTextInputInputProps) {
  const alertDataStore = props.alertDataStore!;

  const { t } = useTranslation("alert-grid");
  const alternateText = alertDataStore.selectedAlert.alternateText;
  const AlternateTextMaximumLength = 50;
  const { errorMessages } = alertDataStore;

  return (
    <TextFieldInput
      value={alternateText}
      errorMessage={
        (errorMessages &&
          errorMessages["alternateText"] &&
          t("Required information")) ||
        ""
      }
      inputProps={{
        pattern: `^[A-Za-z0-9_ ]{0,${AlternateTextMaximumLength}}$`,
      }}
      label={t("AlternateText").toUpperCase()}
      placeholder={t("AlternateText")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;

        alertDataStore.setSelectedAlternateText(event.target.value);

        alertDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        let schema = alertDataSchema;

        alertDataStore.validateSelectedAlert(schema, "alternateText");
        alertDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("alertDataStore")(observer(AlternateTextInput));
