import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { AlertDataStore } from "../../../store/store";
import { alertDataSchema } from "../../../validation";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface ITriggerPortInputProps {
  alertDataStore?: AlertDataStore;
}

function TriggerPortInput(props: ITriggerPortInputProps) {
  const alertDataStore = props.alertDataStore!;

  const { t } = useTranslation("alert-grid");
  const triggerPort =
    alertDataStore.selectedAlert.triggerPort?.toString() || "";

  return (
    <TextFieldInput
      value={triggerPort}
      inputProps={{ pattern: "^[0-9]{1,9}" }}
      label={t("triggerPort").toUpperCase()}
      placeholder={t("triggerPort")}
      onChange={(event: any) => {
        if (!event.target.validity.valid) return;
        const port = parseInt(event.target.value);

        if (isNaN(port)) alertDataStore.setSelectedTriggerPort(undefined);
        else alertDataStore.setSelectedTriggerPort(port);

        alertDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        let schema = alertDataSchema;

        alertDataStore.validateSelectedAlert(schema, "triggerPort");
        alertDataStore.setValidated(true);
      }}
    />
  );
}

export default inject(
  "alertDataStore"
)(observer(TriggerPortInput));
