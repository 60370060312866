import styles from "./status-icon.module.css";

import { ReactComponent as InBed } from "../../modules/clients-overview/svg/client-in-bed.svg";
import { ReactComponent as InRoom } from "../../modules/clients-overview/svg/client-in-room.svg";
import { ReactComponent as InBathroom } from "../../modules/clients-overview/svg/client-in-bathroom.svg";
import { ReactComponent as Inactive } from "../../modules/clients-overview/svg/client-inactive.svg";
import { ReactComponent as NotAvailable } from "../../modules/clients-overview/svg/client-not-available.svg";
import { ReactComponent as OutOfBed } from "../../modules/clients-overview/svg/client-out-of-bed.svg";
import { ReactComponent as OutOfRoom } from "../../modules/clients-overview/svg/client-out-of-room.svg";
import { ReactComponent as StatusOff } from "../../modules/clients-overview/svg/client-status-off.svg";
import { ReactComponent as InChair } from "../../modules/clients-overview/svg/client-in-chair.svg";
import { ReactComponent as VisitorDetected } from "../../modules/clients-overview/svg/client-visitor-detected.svg";
import { ReactComponent as ClientAlarm } from "../../modules/clients-overview/svg/client-alarm.svg";
import { ReactComponent as VoiceAlarm } from "../../modules/clients-overview/svg/client-voice-alarm.svg";
import { ReactComponent as NursePresent } from "../../modules/clients-overview/svg/client-nurse-present.svg";
import classNames from "classnames";

export interface StatusIconProps {
  alertId?: number;
  alertIcon?: string;
  statusId?: number;
  statusIcon?: string;
  parentStatusId?: number;
  alertDelayed?: boolean;
  alertActive?: boolean;
  alertDisabled?: boolean;
  isNursePresent?: boolean;
}

export function StatusIcon(props: StatusIconProps) {
  const {
    isNursePresent,
    statusId,
    alertId,
    alertIcon,
    statusIcon,
    parentStatusId,

    alertActive,
    alertDelayed,
    alertDisabled,
  } = props;

  const getIcon = () => {
    if (isNursePresent) {
      return <NursePresent />;
    }
    if (alertId) {
      if (alertId >= 1 && alertId < 9) return getAlertIcon();
      else return customIcon(alertIcon);
    } else if (statusId) {
      if (statusIcon) return customIcon(statusIcon);
      if (statusId >= 1 && statusId <= 10) return getStatusIcon();
      else return getStatusIcon(true);
    }

    return <NotAvailable />;
  };

  const getAlertIcon = () => {
    switch (alertId) {
      case 1:
        return <OutOfBed />;
      case 2:
        return <InBathroom />;
      case 3:
        return <OutOfRoom />;
      case 4:
        return <Inactive />;
      case 5:
        return <VisitorDetected />;
      case 6:
        return <ClientAlarm />;
      case 7:
        return <VoiceAlarm />;
      case 8:
        return <InBed />;
      default:
        return <ClientAlarm />;
    }
  };

  const getStatusIcon = (useParentId: boolean = false): any => {
    let id = statusId;
    if (useParentId) id = parentStatusId;

    switch (id) {
      case 1:
        return getStatusIcon(true);
      case 2:
        return <InBed />;
      case 3:
        return <VisitorDetected />;
      case 4:
        return <InRoom />;
      case 5:
        return <OutOfRoom />;
      case 6:
        return <InBathroom />;
      case 8:
        return <StatusOff />;
      case 9:
        return <OutOfBed />;
      case 10:
        return <InChair />;
      default:
        return <NotAvailable />;
    }
  };

  const customIcon = (iconBase64: string | undefined) => {
    if (iconBase64 && iconBase64.length > 0) {
      return <img className={styles["custom-icon"]} src={iconBase64} alt="" />;
    }
    return <NotAvailable />;
  };

  const getColor = () => {
    const alertOverride = alertId === 6;
    if (typeof alertActive !== "undefined" && !alertActive) {
      return styles["inactive"];
    } else if (alertOverride || (alertId && !alertDelayed)) {
      return styles["instant"];
    } else if (!alertOverride && alertId && alertDelayed) {
      return styles["delayed"];
    }

    return styles["default"];
  };

  const render = () => {
    return (
      <div
        className={classNames("status-icon", getColor(), {
          [styles["disabled"]]: alertDisabled,
        })}
      >
        {getIcon()}
      </div>
    );
  };

  return render();
}
