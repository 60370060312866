import { inject, observer } from "mobx-react";
import { MariDataStore } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { TextFieldInput } from "../../../../../components/form-inputs";

export interface PasswordInputProps {
  mariDataStore?: MariDataStore;
}

const InputStringMaximumLength = 50;

function PasswordInput(props: PasswordInputProps) {
  const mariDataStore = props.mariDataStore!;
  const { t } = useTranslation("mari-grid");
  const password = mariDataStore.selectedMari.password;
  const { errorMessages } = mariDataStore;

  return (
    <TextFieldInput
      value={password}
      disabled={mariDataStore.isValidationInProgress}
      mandatory
      errorMessage={
        (errorMessages &&
          errorMessages["password"] &&
          t("RequiredInformation")) ||
        ""
      }
      label={t("Password").toUpperCase()}
      placeholder={t("Password")}
      onChange={(event: any) => {
        const passwordValue = event.target.value;
        if (passwordValue.length > InputStringMaximumLength) return;

        mariDataStore.setSelectedMarePassword(passwordValue);
        mariDataStore.setValidated(false);
      }}
      onValueChangeDebounced={() => {
        mariDataStore.validateSelectedMari("password");
        mariDataStore.setValidated(true);
      }}
    />
  );
}

export default inject("mariDataStore")(observer(PasswordInput));
