import styles from "./resident-status-toggle.module.css";
import { inject, observer } from "mobx-react";
import { ClientOverviewStore } from "../../store/store";
import ToggleButtonsComponent from "../../../../components/toggle-button/ToggleButtonGroup";
import { ReactComponent as ArrowUp } from "../../svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../svg/arrow-down.svg";
import { useTranslation } from "react-i18next";

export interface ResidentStatusToggleProps {
  clientOverviewStore?: ClientOverviewStore;
}

function ResidentStatusToggle(props: ResidentStatusToggleProps) {
  const clientOverviewStore = props.clientOverviewStore!;
  const { assignedClients } = clientOverviewStore!;
  const { t } = useTranslation("client-grid");

  const getIcon = (id: string) => {
    if (assignedClients.sortOptions[0].id === id) {
      if (assignedClients.sortOptions[0].desc) {
        return <ArrowDown />;
      } else {
        return <ArrowUp />;
      }
    }

    return (
      <div className={styles["inactive"]}>
        <ArrowUp /> <ArrowDown />
      </div>
    );
  };

  const buttons = [
    {
      id: "name",
      icon: getIcon("name"),
      text: t("Client"),
    },
    { id: "room", icon: getIcon("room"), text: t("Room") },
    { id: "status", icon: getIcon("status"), text: t("Status") },
  ];

  const render = () => {
    return (
      <ToggleButtonsComponent
        buttons={buttons}
        selectedID={assignedClients.sortOptions[0].id}
        onChange={(ID: string) => {
          if (ID === null) {
            clientOverviewStore.setAssignedClientsSortOptions([
              {
                id: assignedClients.sortOptions[0].id,
                desc: !assignedClients.sortOptions[0].desc,
              },
            ]);
          } else {
            clientOverviewStore.setAssignedClientsSortOptions([
              {
                id: ID,
                desc: false,
              },
            ]);
          }
        }}
      />
    );
  };

  return render();
}

export default inject("clientOverviewStore")(observer(ResidentStatusToggle));
