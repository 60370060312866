import styles from "./resident-link.module.css";
import classnames from "classnames";

export { ReactComponent as ClientAlarm } from "../../svg/client-alarm.svg";
export { ReactComponent as ClientAlert } from "../../svg/client-alert.svg";

export function RoomIcon(props: any) {
  return (
    <div className={classnames(styles["client-cell-icon"], styles[props.modifier])}>
      {props.children}
    </div>
  );
}
