export const CHART_HEIGHT = "240px";
export const CHART_WIDTH = "100%";
export const RENDER_INTERVAL = 200;

export const CATEGORY = {
  WIDTH: 200,
  HEIGHT: 200,
  ICON: {
    PADDING_LEFT: 10,
    PADDING_RIGHT: 10,
    WIDTH: 48,
    HEIGHT: 48,
  },
  TEXT: {
    FONT: "Open Sans",
    FONT_SIZE: "11px",
    ALIGN: "left",
    BASE_LINE: "middle",
  },
  SEPARATION_LINE: {
    COLOR: "grey",
    SIZE: 0.07,
  },
  SIDE_BAR: {
    SIZE: 5,
  },
};

export const BARS = {
  HEIGHT: 200,
  BAR_HEIGHT_PERCENTAGE: 0.4,
  TEXT: {
    COLOR: "white",
    FONT: "Open Sans",
    FONT_SIZE: "13px",
    ALIGN: "center",
    PADDING_LEFT: 5,
    PADDING_RIGHT: 5,
    BASE_LINE: "middle",
  },
  BACKGROUND_COLOR: "rgb(255, 255, 255)",
  NO_ACTIVITY_ZONE_COLOR: "#eaeaea",
  LEFT_PADDING: 30,
  CONNECTION_LINE: {
    SIZE: 0.5,
    COLOR: "grey",
  },
};

export const BOTTOM_LABEL = {
  TEXT: {
    COLOR: "grey",
    FONT: "Open Sans",
    FONT_SIZE: "12px",
    BOTTOM_PADDING: 12,
    ALIGN: "center",
    BASE_LINE: "middle",
  },
};
