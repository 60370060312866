import styles from "./client-profile-access-tab.module.css";

import classNames from "classnames";
import {
  ClientProfileAccessSettings,
  ClientProfileDetails,
} from "../../store/types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TimeAlertRadio from "../../cells/time-alerts-radio";
import { Edit } from "../../../../components/svg";
import { AuthStore, TransitionStore } from "../../../../store";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { ReactComponent as WarningIcon } from "../../svg/warning.svg";
import { ChangeEvent } from "react";
import { SwitchInput } from "../../../../components/form-inputs";
import { useNavigate } from "react-router-dom";

interface ClientProfileAccessTabProps {
  clientAccess: ClientProfileAccessSettings;
  clientDetails: ClientProfileDetails;
  authStore: AuthStore;
  transitionStore: TransitionStore;
  toggleAccessStatus(): void;
  switchAccessTimeStatus(): void;
  isAccessInformationValid: boolean;
}

function ClientProfileAccessTab(props: ClientProfileAccessTabProps) {
  const { t } = useTranslation("client-profile");
  const {
    clientAccess,
    clientDetails,
    isAccessInformationValid,
    authStore,
    transitionStore,
    toggleAccessStatus,
    switchAccessTimeStatus,
  } = props;

  const history = useNavigate();

  const render = () => {
    if (!isAccessInformationValid) return showCannotRetrieveData();

    if (clientAccess.clientProfileAssignmentAreas.length < 1) {
      return showNotAvailableAccessTab();
    }
    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {t("Access inside / outside of the building")}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          {renderAccessToggleButton()}
          {renderTimeIntervalSwitches()}
          {renderAccessAreasInformation()}
          {renderEditAccessSettingsButtons()}
        </li>
      </div>
    );
  };

  const renderAccessToggleButton = () => {
    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      prepareToggleDialogInformation();

    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        clientDetails.isAccessProfileEnabled =
          !clientDetails.isAccessProfileEnabled;
        toggleAccessStatus();
      }
    );

    return (
      <div className={`${styles["toggle-button"]} ${styles["col-1"]}`}>
        <div className={styles["row-1"]}>
          <div className={styles["switch-container"]}>
            <SwitchInput
              checked={clientDetails.isAccessProfileEnabled}
              disabled={!authStore.hasAdminRights()}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                transitionStore.showGlobalDialog(confirmationDialogData);
              }}
            />
          </div>
        </div>
        <div className={styles["row-2"]} />
      </div>
    );
  };

  const prepareToggleDialogInformation = () => {
    const toggleStatus = !clientDetails.isAccessProfileEnabled
      ? t("common:On")
      : t("common:Off");
    const action = !clientDetails.isAccessProfileEnabled
      ? t("common:Start")
      : t("common:Stop");

    const dialogTitle = t("AccessToggleDialogTitle", {
      toggleStatus: toggleStatus,
    });
    const okButtonText = t("AccessToggleDialogConfirmation", {
      toggleStatus: toggleStatus,
    });
    const cancelButtonText = t("common:Cancel");
    const dialogContent = `${t("AccessToggleDialogAction", { action: action })}
        ${t("AccessToggleDialogQuestion", {
          toggleStatus: toggleStatus,
          clientName: clientDetails.clientName,
        })}`;
    return { dialogTitle, okButtonText, cancelButtonText, dialogContent };
  };

  const renderTimeIntervalSwitches = () => {
    const dayAccess = clientAccess.getDaytimeInterval();
    const nightAccess = clientAccess.getNighttimeInterval();
    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      prepareSwitchDialogInformation();
    const confirmationDialogDataDay = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        changeAccessStatus("Day");
      }
    );

    const confirmationDialogDataNight = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        changeAccessStatus("Night");
      }
    );

    return (
      <div className={`${styles["text"]} ${styles["col-2"]}`}>
        <div
          className={classNames({
            [styles["row-1"]]: clientDetails.isAccessProfileEnabled,
            [`${styles["row-1"]} ${styles["disabled"]}`]:
              !clientDetails.isAccessProfileEnabled,
          })}
        >
          <TimeAlertRadio
            title={t("Day time access")}
            checked={dayAccess.isActive}
            onChange={(event: { target: { value: any } }) => {
              transitionStore.showGlobalDialog(confirmationDialogDataDay);
            }}
            startTime={formatHour(dayAccess.accessAreaStartTime)}
            endTime={formatHour(nightAccess.accessAreaStartTime)}
          />
        </div>
        <div
          className={classNames({
            [styles["row-2"]]: clientDetails.isAccessProfileEnabled,
            [`${styles["row-2"]} ${styles["disabled"]}`]:
              !clientDetails.isAccessProfileEnabled,
          })}
        >
          <TimeAlertRadio
            title={t("Night time access")}
            checked={nightAccess.isActive}
            onChange={(event: { target: { value: any } }) => {
              transitionStore.showGlobalDialog(confirmationDialogDataNight);
            }}
            startTime={formatHour(nightAccess.accessAreaStartTime)}
            endTime={formatHour(dayAccess.accessAreaStartTime)}
          />
        </div>
      </div>
    );
  };

  const prepareSwitchDialogInformation = () => {
    const dayAccess = clientAccess.getDaytimeInterval();
    const nightAccess = clientAccess.getNighttimeInterval();
    const selectedPeriod = !dayAccess.isActive ? t("Day") : t("Night");
    const endingPeriod = !dayAccess.isActive ? t("Night") : t("Day");
    const endHour = !dayAccess.isActive
      ? dayAccess.accessAreaStartTime
      : nightAccess.accessAreaStartTime;
    const clientName = clientDetails.clientName;

    const dialogTitle = t("client-profile:AccessSwitchDialogTitle", {
      selectedPeriod: selectedPeriod,
    });

    const okButtonText = t("client-profile:AccessSwitchDialogConfirmation", {
      selectedPeriod: selectedPeriod,
    });
    const cancelButtonText = t("common:Cancel");

    const dialogContent = `${t("client-profile:AccessSwitchDialogAction", {
      switchStatus: selectedPeriod,
      endHour: formatHour(endHour),
      endingPeriod: endingPeriod,
    })} 
                            ${t("client-profile:AccessSwitchDialogQuestion", {
                              switchStatus: selectedPeriod,
                              clientName: clientName,
                            })}`;

    return { dialogTitle, dialogContent, okButtonText, cancelButtonText };
  };

  const renderAccessAreasInformation = () => {
    const dayArea = getAreaName(
      clientAccess.getDaytimeInterval().accessLocationId
    );
    const nightArea = getAreaName(
      clientAccess.getNighttimeInterval().accessLocationId
    );

    return (
      <div
        className={classNames({
          [styles["col-3"]]: clientDetails.isAccessProfileEnabled,
          [`${styles["col-3"]} ${styles["disabled"]}`]:
            !clientDetails.isAccessProfileEnabled,
        })}
      >
        <div className={`${styles["row-1"]} ${styles["text"]}`}>
          {t(dayArea)}
        </div>
        <div className={`${styles["row-2"]} ${styles["text"]}`}>
          {t(nightArea)}
        </div>
      </div>
    );
  };

  const renderEditAccessSettingsButtons = () => {
    return (
      <div className={styles["col-4"]}>
        {authStore.hasAdminRights() && (
          <>
            <div className={`${styles["row-1"]} ${styles["edit-text"]}`}>
              <Edit className={styles["edit-day-time-icon"]} />
              <div
                className={styles["edit-day-time-text"]}
                onClick={() => {
                  history(
                    "/client-profile/access-settings/" + clientDetails.clientId,
                    { state: { isEditingDay: true } }
                  );
                }}
              >
                {t("Edit day time access")}
              </div>
            </div>
            <div className={`${styles["row-2"]} ${styles["edit-text"]}`}>
              <Edit className={styles["edit-day-time-icon"]} />
              <div
                className={styles["edit-day-time-text"]}
                onClick={() => {
                  history(
                    "/client-profile/access-settings/" + clientDetails.clientId,
                    { state: { isEditingDay: false } }
                  );
                }}
              >
                {t("Edit night time access")}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const getAreaName = (areaId: number) => {
    if (areaId === 0) {
      return "Disabled";
    }
    const result = clientAccess.clientProfileAccessLocations.find(
      (l) => l.wireless_access_id === areaId
    );

    if (result === undefined) return "";

    return result!.name;
  };

  const showNotAvailableAccessTab = () => {
    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {t("Access inside / outside of the building")}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          <div className={styles["text"]}>{t("Not available")}</div>
        </li>
      </div>
    );
  };

  const showCannotRetrieveData = () => {
    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {t("Access inside / outside of the building")}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          <div className={`${styles["col-1"]} ${styles["critical"]}`}>
            <WarningIcon width="40px" height="40px" />
          </div>
          <div className={`${styles["col-234"]} ${styles["text"]}`}>
            {t("BuildingAccessInformationNotAvailable")}
          </div>
          <div className={styles["col-4"]} />
        </li>
      </div>
    );
  };

  const changeAccessStatus = (value: string) => {
    clientDetails.activeAccessPeriodId =
      value === "Day"
        ? clientAccess.getDaytimeInterval().accessGroupId
        : clientAccess.getNighttimeInterval().accessGroupId;
    switchAccessTimeStatus();
  };

  const formatHour = (hour: string) => {
    return hour.length > 5 ? hour.substring(0, 5) : hour;
  };

  return render();
}

export default observer(ClientProfileAccessTab);
