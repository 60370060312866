import {
  action,
  computed,
  makeObservable,
  observable,
  ObservableMap,
} from "mobx";
import {
  flatErrorMap,
  FlatErrorValidationResult,
} from "../../../utils/validation/flatErrorMap";
import { validateData } from "../../../utils/validation/validate";
import { peripheralDataSchema } from "../validation";
import { LocationData, PeripheralData } from "./types";
import { ExpandedState } from "../../../components/table/table";

export const EmptyPeripheral: PeripheralData = observable({
  peripheralId: undefined,
  mariName: "",
  locationId: undefined,
  locationName: "",
  areaName: "",
  bus: undefined,
  address: "",
  subAddress: undefined,
  controllerMAC: "",
  service: "",
  ipAddress: "",
  isUsed: true,
});

export interface PeripheralSort {
  id: string;
  desc: boolean;
}

export class PeripheralDataStore {
  constructor() {
    makeObservable(this, {
      validationErrors: observable,
      peripherals: observable,
      availableLocations: observable,
      selectedPeripheralHasChanges: observable,
      selectedPeripheral: observable,
      oldPeripheralData: observable,
      highlightPeripheral: observable,
      expandedRow: observable,
      sortOptions: observable,
      errors: observable,
      errorMessages: observable,
      validated: observable,
      isTranslationLoading: observable,
      isDataLoading: observable,
      isSaveLoading: observable,
      isDeleteLoading: observable,

      hasSelectedPeripheral: computed,

      setNewPeripheral: action,
      setPeripherals: action,
      resetSelectedData: action,
      setSelectedPeripheral: action,
      setSelectedPeripheralLocationData: action,
      setSelectedPeripheralBus: action,
      setSelectedPeripheralAdress: action,
      setSelectedPeripheralControllerMac: action,
      setSelectedPeripheralIpAddress: action,
      setSelectedPeripheralSubAdress: action,
      setExpandedRow: action,
      closeExpanded: action,
      setSorting: action,
      setSelectedPeripheralHasChanges: action,
      setSelectedPeripheralUsed: action,
      validateSelectedPeripheral: action,
      setValidated: action,
      setIsTranslationLoading: action,
      setIsDataLoading: action,
      setIsSaveLoading: action,
      setIsDeleteLoading: action,
    });
  }

  public isTranslationLoading: boolean = false;
  public isDataLoading: boolean = false;
  public isSaveLoading: boolean = false;
  public isDeleteLoading: boolean = false;
  public validated: boolean = true;
  public errorMessages: FlatErrorValidationResult | null = null;
  public validationErrors: ObservableMap<{}> = observable.map();
  public expandedRow: number = ExpandedState.COLLAPSED;
  public peripherals: PeripheralData[] = [];
  public availableLocations: LocationData[] = [];
  public sortOptions: PeripheralSort[] = [
    {
      id: "mariName",
      desc: false,
    },
  ];
  public errors: FlatErrorValidationResult | null = null;

  public selectedPeripheralHasChanges: boolean = false;
  public selectedPeripheral: PeripheralData = {
    peripheralId: undefined,
    mariName: "",
    locationId: undefined,
    locationName: "",
    areaName: "",
    bus: undefined,
    address: "",
    subAddress: undefined,
    controllerMAC: "",
    service: "",
    ipAddress: "",
    isUsed: true,
  };
  public oldPeripheralData: PeripheralData = {
    peripheralId: undefined,
    mariName: "",
    locationId: undefined,
    locationName: "",
    areaName: "",
    bus: undefined,
    address: "",
    subAddress: undefined,
    controllerMAC: "",
    service: "",
    ipAddress: "",
    isUsed: true,
  };

  public highlightPeripheral: PeripheralData | null = {
    peripheralId: undefined,
    mariName: "",
    locationId: undefined,
    locationName: "",
    areaName: "",
    bus: undefined,
    address: "",
    subAddress: undefined,
    controllerMAC: "",
    service: "",
    ipAddress: "",
    isUsed: true,
  };

  public get hasSelectedPeripheral() {
    return this.selectedPeripheral.peripheralId !== undefined;
  }

  public setNewPeripheral() {
    const data = {
      peripheralId: 0,
      mariName: "",
      locationId: undefined,
      locationName: "",
      areaName: "",
      bus: undefined,
      address: "",
      subAddress: undefined,
      controllerMAC: "",
      service: "",
      ipAddress: "",
      isNewPeripheral: true,
      isUsed: true,
    };
    this.selectedPeripheral = { ...data };
    this.oldPeripheralData = { ...data };

    this.selectedPeripheralHasChanges = false;
    this.expandedRow = ExpandedState.TOP;
  }

  public setPeripherals(value: PeripheralData[]) {
    this.peripherals = value;
  }

  public resetSelectedData() {
    this.selectedPeripheral = EmptyPeripheral;
    this.oldPeripheralData = EmptyPeripheral;
    this.highlightPeripheral = null;
  }

  public setSelectedPeripheral(value: PeripheralData) {
    this.selectedPeripheral = { ...value };
    this.oldPeripheralData = { ...value };
  }

  public setSelectedPeripheralLocationData(
    locationId: number,
    locationName: string,
    areaName: string
  ) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.locationId = locationId;
    this.selectedPeripheral.locationName = locationName;
    this.selectedPeripheral.areaName = areaName;
    this.selectedPeripheralHasChanges = true;
  }

  public setSelectedPeripheralBus(value: number | undefined) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.bus = value;
    this.selectedPeripheralHasChanges = true;
  }

  public setSelectedPeripheralAdress(value: string) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.address = value;
    this.selectedPeripheralHasChanges = true;
  }

  public setSelectedPeripheralSubAdress(value: number | undefined) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.subAddress = value;
    this.selectedPeripheralHasChanges = true;
  }

  public setSelectedPeripheralControllerMac(value: string) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.controllerMAC = value;
    this.selectedPeripheralHasChanges = true;
  }

  public setSelectedPeripheralIpAddress(value: string) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.ipAddress = value;
    this.selectedPeripheralHasChanges = true;
  }

  public setSelectedPeripheralHasChanges(value: boolean) {
    this.selectedPeripheralHasChanges = value;
  }

  public setSelectedPeripheralUsed(value: boolean) {
    this.selectedPeripheral = { ...this.selectedPeripheral };
    this.selectedPeripheral.isUsed = value;
    this.selectedPeripheralHasChanges = true;
  }

  public setExpandedRow(value: number) {
    this.expandedRow = value;
  }

  public setSorting(value: PeripheralSort[]) {
    this.sortOptions = value;
  }

  public closeExpanded() {
    this.expandedRow = ExpandedState.COLLAPSED;
  }

  public setValidated(value: boolean) {
    this.validated = value;
  }

  public setIsTranslationLoading(value: boolean) {
    this.isTranslationLoading = value;
  }

  public setIsDataLoading(value: boolean) {
    this.isDataLoading = value;
  }

  public setIsSaveLoading(value: boolean) {
    this.isSaveLoading = value;
  }

  public setIsDeleteLoading(value: boolean) {
    this.isDeleteLoading = value;
  }

  public validateSelectedPeripheral(value: string = "") {
    const validationResult = validateData<PeripheralData>(
      this.selectedPeripheral,
      peripheralDataSchema
    );

    if (validationResult) this.errors = flatErrorMap(validationResult);
    else this.errors = null;

    if (value !== "") {
      if (this.errorMessages === null) this.errorMessages = {};
      this.errorMessages![value] = this.errors![value];
    }
  }
}
