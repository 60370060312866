import styles from "./client-profile-camera-settings.module.css";

import { ClientProfileStore } from "../../store/store";
import ClientProfileCameraSettingsHeader from "./client-profile-camera-settings-header";
import ClientProfileCameraSettingsContent from "./client-profile-camera-settings-content";
import { observer } from "mobx-react";
// import { Prompt } from "react-router";
import { Navigate } from "react-router-dom";

interface ClientProfileCameraSettingsPageProps {
  clientProfileStore: ClientProfileStore;
  saveCameraSettings(): void;
  cancelCameraSettings(): void;
  t?: any;
}

function ClientProfileCameraSettingsPageComponent(
  props: ClientProfileCameraSettingsPageProps
) {
  const {
    clientProfileStore,
    saveCameraSettings,
    cancelCameraSettings,
  } = props;
  
  const render = () => {
    const { clientProfileDetails, clientProfileCameraSettings } =
      clientProfileStore;
    if (!clientProfileDetails.clientName && !clientProfileDetails.areaName) {
      const linkArr = window.location.pathname.split("/");

      const link = `/client-profile/${linkArr[linkArr.length - 1]}`;
      return <Navigate to={link} />;
    }
    return (
      <div>
        <ClientProfileCameraSettingsHeader
          clientDetails={clientProfileDetails}
          title={"Video camera views"}
          saveCameraSettings={saveCameraSettings}
          cancelCameraSettings={cancelCameraSettings}
        />
        <div className={styles["client-profile-content"]}>
          <ClientProfileCameraSettingsContent
            cameraSettings={clientProfileCameraSettings}
          />
        </div>
        {/* <Prompt when={clientProfileCameraSettings.hasChanges} message="" /> */}
      </div>
    );
  };

  return render();
}

export const ClientProfileCameraSettingsPage = observer(
  ClientProfileCameraSettingsPageComponent
);
