import { inject, observer } from "mobx-react";
import * as React from "react";
import { Route } from "react-router-dom";
import AuthorizedRoute, { ROLES } from "../../components/authorized-route";
import { FeatureStore } from "../../store";

import Maris from "./page.highorder";

export interface ProtectedMarisProps {
  featureStore?: FeatureStore;
}

const ProtectedMarisComponent = (props: ProtectedMarisProps) => {
  const { manageMaris } = props.featureStore!.features;

  return (
    <AuthorizedRoute
      requiredRoles={[ROLES.ADMIN]}
      path="/"
      component={Maris}
      shouldRedirect={manageMaris === false}
      {...props}
    />
  );
};

const AuthorizedMaris = inject("featureStore")(
  observer(ProtectedMarisComponent)
);

const marisRoute = (
  <Route path="/manage-maris" element={<AuthorizedMaris />} />
);

export default marisRoute;
