import styles from "./client-profile-alerts-tab.module.css";

import classNames from "classnames";
import { ClientProfileSettings, ClientProfileDetails } from "../../store/types";
import { observer } from "mobx-react";
import TimeAlertRadio from "../../cells/time-alerts-radio";
import ClientAlertStatusIcons from "../../cells/client-status-group";
import { Edit } from "../../../../components/svg";
import { AuthStore, TransitionStore } from "../../../../store";
import { useTranslation } from "react-i18next";
import { CreateConfirmationDialog } from "../../../../utils/ConfirmationDialog";
import { ChangeEvent } from "react";
import { SwitchInput } from "../../../../components/form-inputs";
import { useNavigate } from "react-router-dom";

interface IClientProfileAlertsTabProps {
  clientDetails: ClientProfileDetails;
  clientSettings: ClientProfileSettings;
  transitionStore: TransitionStore;
  authStore: AuthStore;
  toggleAlertStatus(): void;
  switchAlertTimeStatus(): void;
}

function ClientProfileAlertsTab(props: IClientProfileAlertsTabProps) {
  const { t } = useTranslation("client-profile");
  const {
    clientSettings,
    clientDetails,
    toggleAlertStatus,
    authStore,
    transitionStore,
    switchAlertTimeStatus,
  } = props;
  const { isAlarmProfileEnabled, activeAlertPeriodId } = clientDetails;

  const history = useNavigate();

  const render = () => {
    return (
      <div className={styles["tabs-container"]}>
        <li className={`${styles["item"]} ${styles["odd-tab"]}`}>
          <div className={styles["title"]}>
            {t("Alerts triggered from the room")}
          </div>
        </li>
        <li className={`${styles["item"]} ${styles["even-tab"]}`}>
          {renderAlertsToggleButton()}
          {renderTimeIntervalSwitches()}
          {renderAlertsStatusIcons()}
          {renderEditAlertSettingsButtons()}
        </li>
      </div>
    );
  };

  const renderAlertsToggleButton = () => {
    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      prepareToggleDialogInformation();

    const confirmationDialogData = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        clientDetails.isAlarmProfileEnabled =
          !clientDetails.isAlarmProfileEnabled;
        toggleAlertStatus();
      }
    );

    return (
      <div className={`${styles["toggle-button"]} ${styles["col-1"]}`}>
        <div className={styles["row-1"]}>
          <div className={styles["switch-container"]}>
            <SwitchInput
              checked={clientDetails.isAlarmProfileEnabled}
              disabled={!authStore.hasAdminRights()}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                transitionStore.showGlobalDialog(confirmationDialogData);
              }}
            />
          </div>
        </div>
        <div className={styles["row-2"]} />
      </div>
    );
  };

  const prepareToggleDialogInformation = () => {
    const toggleStatus = !clientDetails.isAlarmProfileEnabled
      ? t("common:On")
      : t("common:Off");
    const action = !clientDetails.isAlarmProfileEnabled
      ? t("common:Start")
      : t("common:Stop");

    const dialogTitle = t("AlertsToggleDialogTitle", {
      toggleStatus: toggleStatus,
    });
    const okButtonText = t("AlertsToggleDialogConfirmation", {
      toggleStatus: toggleStatus,
    });
    const cancelButtonText = t("common:Cancel");

    const dialogContent = `${t("AlertsToggleDialogAction", { action: action })}
    ${t("AlertsToggleDialogQuestion", {
      toggleStatus: toggleStatus,
      clientName: clientDetails.clientName,
    })}`;

    return { dialogTitle, dialogContent, okButtonText, cancelButtonText };
  };

  const renderTimeIntervalSwitches = () => {
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();

    const { dialogTitle, dialogContent, okButtonText, cancelButtonText } =
      prepareSwitchDialogInformation();
    const confirmationDialogDataDay = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        changeAlertStatus("Day");
      }
    );

    const confirmationDialogDataNight = CreateConfirmationDialog(
      dialogTitle,
      dialogContent,
      okButtonText,
      cancelButtonText,
      false,
      () => {
        changeAlertStatus("Night");
      }
    );

    return (
      <div className={`${styles["text"]} ${styles["col-2"]}`}>
        <div
          className={classNames({
            [styles["row-1"]]: isAlertTabEnabled(),
            [`${styles["row-1"]} ${styles["disabled"]}`]: !isAlertTabEnabled(),
          })}
        >
          <TimeAlertRadio
            title={t("Day time alerts")}
            checked={isPeriodActive(dayInterval.intervalId)}
            onChange={(event: { target: { value: any } }) => {
              transitionStore.showGlobalDialog(confirmationDialogDataDay);
            }}
            startTime={formatHour(dayInterval.startTime)}
            endTime={formatHour(nightInterval.startTime)}
          />
        </div>
        <div
          className={classNames({
            [styles["row-2"]]: isAlertTabEnabled(),
            [`${styles["row-2"]} ${styles["disabled"]}`]: !isAlertTabEnabled(),
          })}
        >
          <TimeAlertRadio
            title={t("Night time alerts")}
            checked={isPeriodActive(nightInterval.intervalId)}
            onChange={(event: { target: { value: any } }) => {
              transitionStore.showGlobalDialog(confirmationDialogDataNight);
            }}
            startTime={formatHour(nightInterval.startTime)}
            endTime={formatHour(dayInterval.startTime)}
          />
        </div>
      </div>
    );
  };

  const prepareSwitchDialogInformation = () => {
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();
    const selectedPeriod = isPeriodActive(dayInterval.intervalId)
      ? t("Night")
      : t("Day");
    const endHour = isPeriodActive(dayInterval.intervalId)
      ? dayInterval.startTime
      : nightInterval.startTime;
    const nextPeriod = isPeriodActive(dayInterval.intervalId)
      ? t("Day")
      : t("Night");

    const dialogContent = `${t("AlertsSwitchDialogAction", {
      selectedPeriod: selectedPeriod,
      endHour: formatHour(endHour),
      nextPeriod: nextPeriod,
    })} 
    ${t("AlertsSwitchDialogQuestion", {
      selectedPeriod: selectedPeriod,
      clientName: clientDetails.clientName,
    })}`;

    const dialogTitle = t("AlertsSwitchDialogTitle", {
      selectedPeriod: selectedPeriod,
    });
    const okButtonText = t("AlertsSwitchDialogConfirmation", {
      selectedPeriod: selectedPeriod,
    });
    const cancelButtonText = t("common:Cancel");

    return { dialogTitle, dialogContent, okButtonText, cancelButtonText };
  };

  const renderAlertsStatusIcons = () => {
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();

    return (
      <div className={styles["col-3"]}>
        <div className={styles["row-1"]}>
          <ClientAlertStatusIcons
            key={"DayStatusIcons"}
            clientSettings={clientSettings}
            disabled={
              isPeriodActive(nightInterval.intervalId) ||
              !clientDetails.isAlarmProfileEnabled
            }
            alertPeriodId={dayInterval.intervalId}
          />
        </div>
        <div className={styles["row-2"]}>
          <ClientAlertStatusIcons
            key={"NightStatusIcons"}
            clientSettings={clientSettings}
            disabled={
              isPeriodActive(dayInterval.intervalId) ||
              !clientDetails.isAlarmProfileEnabled
            }
            alertPeriodId={nightInterval.intervalId}
          />
        </div>
      </div>
    );
  };

  const renderEditAlertSettingsButtons = () => {
    const dayInterval = clientSettings.getDaytimeInterval();
    const nightInterval = clientSettings.getNighttimeInterval();

    return (
      <div className={styles["col-4"]}>
        {authStore.hasAdminRights() && (
          <>
            <div className={`${styles["row-1"]} ${styles["edit-text"]}`}>
              <Edit className={styles["edit-day-time-icon"]} />
              <div
                className={styles["edit-day-time-text"]}
                onClick={() => {
                  history(
                    "/client-profile/alert-settings/" + clientDetails.clientId,
                    { state: { editingPeriodId: dayInterval!.intervalId } }
                  );
                }}
              >
                {t("Edit day time alerts")}
              </div>
            </div>
            <div className={`${styles["row-2"]} ${styles["edit-text"]}`}>
              <Edit className={styles["edit-day-time-icon"]} />
              <div
                className={styles["edit-day-time-text"]}
                onClick={() => {
                  history(
                    "/client-profile/alert-settings/" + clientDetails.clientId,
                    { state: { editingPeriodId: nightInterval.intervalId } }
                  );
                }}
              >
                {t("Edit night time alerts")}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const changeAlertStatus = (value: string) => {
    clientDetails.activeAlertPeriodId =
      value === "Day"
        ? clientSettings.getDaytimeInterval().intervalId
        : clientSettings.getNighttimeInterval().intervalId;
    switchAlertTimeStatus();
  };

  const isAlertTabEnabled = () => {
    return isAlarmProfileEnabled;
  };

  const isPeriodActive = (periodId: number) => {
    return activeAlertPeriodId === periodId;
  };

  const formatHour = (hour: string) => {
    return hour.length > 5 ? hour.substring(0, 5) : hour;
  };

  return render();
}

export default observer(ClientProfileAlertsTab);
