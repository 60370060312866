import styles from "./card-view-grid.module.css";

import { inject, observer } from "mobx-react";
import { ClientOverviewStore, IClientData } from "../..";
import { useEffect, useRef, useState } from "react";
import CardItem, { CARD_TYPES } from "./card-item/card-item";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SensorIssues } from "../../store/store";
import {
  getStatusText,
  getTypeText,
  getWarningType,
} from "../../../../components/sensor-issues-mapper";

interface CardViewGridProps {
  cardType: CARD_TYPES;
  clientOverviewStore?: ClientOverviewStore;
}

export enum SensorState {
  Undefined = 0xff,
  Active = 0x0001,
  Inactive = 0x0002,
  Storage = 0x0004,
  Disconnected = 0x0008,
  BatteryLow = 0x0010,
  NotAvailable = 0x0020,
  SetForTooLong = 0x0040,
}

function CardViewGrid(props: CardViewGridProps) {
  const containerRef = useRef(null);
  const { t } = useTranslation("client-grid");
  const { t: statusesTranslation } = useTranslation("statuses");
  const { t: profileTranslation } = useTranslation("client-profile");

  const [ghostCardsNumber, setGhostCardsNumber] = useState(0);
  const clientOverviewStore = props.clientOverviewStore!;
  const { cardType } = props;

  const mapInitialsFromName = (name: string) => {
    const stringArray = name.split(" ");

    let firstInitial = "";
    let secondInitial = "";

    if (stringArray.length > 0) {
      firstInitial = stringArray[0].charAt(0).toUpperCase();
    }

    if (stringArray.length > 1)
      for (let index = stringArray.length - 1; index > 0; index--) {
        if (stringArray[index] !== "") {
          secondInitial = stringArray[index].charAt(0).toUpperCase();
          break;
        }
      }

    return firstInitial + secondInitial;
  };

  const mapAlertsToCards = () => {
    return clientOverviewStore.alertSettingsList.map((client) => {
      return (
        <CardItem
          initialsText={mapInitialsFromName(client.clientName!)}
          name={client.clientName!}
          location={client.locationName!}
          id={client.id!}
          type={cardType}
          alertIconData={client.alertSettings}
          highlight={client.id === clientOverviewStore.selectedClient?.id}
        />
      );
    });
  };

  const mapClientsToCards = () => {
    return clientOverviewStore.clients.map((client) => {
      let mainTimestamp = client.statusTimestamp;
      let secondaryTimestamp = "";

      if (client.nursePresent) {
        mainTimestamp = client.statusTimestamp;
      } else if (hasVisitor(client)) {
        if (client.alertId !== 0) {
          mainTimestamp = client.alertTimestamp;
          if (hasStatusChanged(client, t))
            secondaryTimestamp = client.statusTimestamp!;
        } else {
          mainTimestamp = client.extraTimestamp;
          secondaryTimestamp = client.statusTimestamp!;
        }
      } else if (client.alertId !== 0) {
        mainTimestamp = client.alertTimestamp;
        if (hasStatusChanged(client, t))
          secondaryTimestamp = client.statusTimestamp!;
      } else {
        mainTimestamp = client.statusTimestamp;
      }

      const hasExtraTimestamp = secondaryTimestamp !== "";

      return (
        <CardItem
          initialsText={mapInitialsFromName(client.name!)}
          name={client.name!}
          location={client.locationName!}
          status={formatStatusText(client, statusesTranslation)}
          timestamp={formatStatusSince(mainTimestamp)}
          secondStatus={
            hasExtraTimestamp && GetSecondaryStatus(client, statusesTranslation)
          }
          secondTimestamp={formatStatusSince(secondaryTimestamp)}
          id={client.id!}
          StatusProps={{
            statusId: client.statusId,
            alertId: client.alertId,
            alertIcon: client.alertIcon,
            statusIcon: client.statusIcon,
            parentStatusId: client.parentStatusId,
            alertDelayed: client.alertDelayed,
          }}
          nurseIsPresent={client.nursePresent}
          alert={client.alertId !== 0}
          delayed={client.alertDelayed}
          warning={client.sensorIssues !== null}
          warningTooltip={mapIssuesText(client.sensorIssues)}
          warningType={getWarningType(client.sensorIssues)}
          type={cardType}
          highlight={client.id === clientOverviewStore.selectedClient?.id}
        />
      );
    });
  };

  const mapIssuesText = (issues: SensorIssues[] | null) => {
    if (issues && issues.length > 0) {
      let extraIssues = "";

      if (issues.length > 1) {
        extraIssues = t("AndMoreErrors", { number: issues.length - 1 });
      }

      return (
        profileTranslation(
          getTypeText(issues[0].type, issues[0].id, issues[0].template)
        ) +
        "-" +
        profileTranslation(getStatusText(issues[0].status)) +
        " " +
        extraIssues
      );
    }
    return "";
  };

  function GetSecondaryStatus(original: IClientData, t: any) {
    if (original.alertId !== 0) {
      return GetCurrentStatus(
        t,
        original.statusId!,
        original.parentStatusId!,
        original.status
      );
    } else {
      const statusId = original.statusId === 3 ? 2 : original.statusId!;
      return GetCurrentStatus(
        t,
        statusId,
        original.parentStatusId!,
        original.status
      );
    }
  }

  const formatStatusText = (original: IClientData, t: any) => {
    if (!!original.alertId) return MapAlert(original, t);
    if (hasVisitor(original)) return t("Visitor detected");

    return GetCurrentStatus(
      t,
      original.statusId!,
      original.parentStatusId!,
      original.status
    );
  };

  function hasVisitor(original: IClientData) {
    return original.statusId === 3 || original.alertId === 5;
  }

  function hasStatusChanged(original: any, t: any) {
    const alert = MapAlert(original, t);
    const status = GetCurrentStatus(
      t,
      original.statusId!,
      original.parentStatusId!,
      original.status
    );

    return alert !== status;
  }

  function MapAlert(clientData: IClientData, t: any) {
    switch (clientData.alertId) {
      case 1:
        return t("Out of bed");
      case 2:
        return t("In bathroom");
      case 3:
        return t("Out of room");
      case 4:
        return t("Inactivity");
      case 5:
        return t("Visitor detected");
      case 8:
        return t("In bed");
      case 6:
        return t("Assistance");
      case 7:
        return t("Voice alert");
      default:
        return clientData.alert;
    }
  }

  function GetCurrentStatus(
    t: any,
    statusId: number,
    parentStatusId: number,
    defaultStatus?: string
  ) {
    const hasParentStatus =
      parentStatusId !== null && parentStatusId !== undefined;

    if (hasParentStatus && statusId < 10) {
      if (statusId === 1) return MapStatus(parentStatusId, t);
      return MapStatus(statusId, t);
    } else if (!!statusId) {
      return MapStatus(statusId, t, defaultStatus);
    }
    return t("Unknown");
  }

  function MapStatus(
    statusId: number,
    t: (translateKey: string) => string,
    defaultText?: string
  ) {
    switch (statusId) {
      case 3:
        return t("Visitor detected");
      case 2:
        return t("In bed");
      case 1:
      case 4:
        return t("In room");
      case 5:
        return t("Out of room");
      case 6:
        return t("In bathroom");
      case 7:
        return t("Unknown");
      case 8:
        return t("Status OFF");
      case 9:
        return t("Out of bed");
      case 10:
        return t("In chair");
      default:
        return defaultText;
    }
  }

  const formatStatusSince = (statusSince: any) => {
    const timeSpan = moment.duration(statusSince);

    if (timeSpan.asMinutes() < 1) {
      return "";
    }

    if (timeSpan.asHours() < 1) {
      return `${timeSpan.minutes()}m`;
    }

    if (timeSpan.asDays() < 1) {
      return `${timeSpan.hours()}h ${timeSpan.minutes()}m`;
    }

    return `${Math.trunc(timeSpan.asDays())}d ${timeSpan.hours()}h`;
  };

  const calculateGhostItems = () => {
    if (containerRef && containerRef.current) {
      const cardPerRow = Math.floor(
        (containerRef.current as HTMLDivElement).offsetWidth / (280 + 10 + 10)
      );

      const lastRowCardNumber = clientOverviewStore.clients.length % cardPerRow;
      let ghostCardsNumberValue = 0;
      if (lastRowCardNumber === 0) {
        ghostCardsNumberValue = 0;
      } else {
        ghostCardsNumberValue = cardPerRow - lastRowCardNumber;
      }

      if (ghostCardsNumber !== ghostCardsNumberValue) {
        setGhostCardsNumber(ghostCardsNumberValue);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      calculateGhostItems();
    }, 20);

    return () => {
      clearInterval(timer);
    };
  }, [ghostCardsNumber]);

  const ghostCards = () => {
    const items = [];
    for (let i = 0; i < ghostCardsNumber; i++) {
      items.push(<div className={styles["card-child-ghost"]} />);
    }
    return items;
  };

  useEffect(() => {
    calculateGhostItems();
  });

  const render = () => {
    return (
      <div ref={containerRef} className={styles["card-container"]}>
        {cardType === CARD_TYPES.Status && mapClientsToCards()}
        {cardType === CARD_TYPES.Alert && mapAlertsToCards()}
        {ghostCards()}
      </div>
    );
  };

  return render();
}
export default inject("clientOverviewStore")(observer(CardViewGrid));
