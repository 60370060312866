import "./layout.css";
import "./react-table.grid.css";
import "./spinner.css";

import UnexpectedErrorStore from "../store/unexpected-error-store";
import AuthStore from "../store/auth-store";
import LoadingSpinner from "./spinner";
import SideMenu from "./menu/side-menu";
import { observer, inject } from "mobx-react";
import { Navigate } from "react-router";
import { Header } from "./header";
import { Footer } from "./footer";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export interface LayoutProps {
  children?: React.ReactNode;
  unexpectedErrorStore?: UnexpectedErrorStore;
  authStore?: AuthStore;
}
export interface ILayoutState {
  authenticating: boolean;
}

function Layout(props: LayoutProps) {
  const { authStore } = props;
  const { t } = useTranslation("common");

  const render = () => {
    const { isAuthenticated, isAuthenticating } = authStore!;
    const showSideMenu = window.self === window.top;

    if (isAuthenticating) {
      return <LoadingSpinner />;
    }
    if (isAuthenticated === false) {
      return <Navigate to="/login" />;
    }

    return (
      <div className={"layout"}>
        <Header t={t} />
        <div className="layout-content-container">
          {showSideMenu && <SideMenu />}

          <div id="main" className={"main"}>
            <Outlet />
          </div>
        </div>
        <Footer />

        <LoadingSpinner />
      </div>
    );
  };

  return render();
}

export default inject("authStore")(observer(Layout));
