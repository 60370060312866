import { inject, observer } from "mobx-react";
import { IconUploadField } from "../../../../../components/form/edit-fields/icon-upload-field/IconUploadField";
import { AlertDataStore, DEFAULT_INSTANT_ICON } from "../../../store/store";
import { alertDataSchema } from "../../../validation";

export interface IBrowseForIconProps {
  alertDataStore?: AlertDataStore;
}

function BrowseForIcon(props: IBrowseForIconProps) {
  const alertDataStore = props.alertDataStore!;

  const validate = () => {
    let schema = alertDataSchema;

    alertDataStore.validateSelectedAlert(schema);
  };

  const onIconChanged = (data: FormData): void => {
    alertDataStore.setSelectedIcon(data);
    validate();
  };

  const svgIcon = new Blob([DEFAULT_INSTANT_ICON], { type: "image/svg+xml" });

  return (
    <IconUploadField
      currentIcon={alertDataStore.selectedAlert.icon}
      iconMemberName="icon"
      onIconChanged={onIconChanged}
      defaultIcon={svgIcon}
    />
  );
}

export default inject("alertDataStore")(observer(BrowseForIcon));