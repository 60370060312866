import { makeObservable, observable } from "mobx";

export class ClientProfileDetails {
  constructor() {
    makeObservable(this);
  }
  @observable public clientId?: number;
  @observable public locationName: string;
  @observable public clientName: string;
  @observable public areaName: string;
  @observable public activeAlertPeriodId: number;
  @observable public activeAccessPeriodId: number;
  @observable public activeSensors: string[] = [];
  @observable public availableStatuses: Dictionary<boolean>;
  @observable public isAccessProfileEnabled: boolean;
  @observable public isStatusEnabled: boolean;
  @observable public isAlarmProfileEnabled: boolean;
  @observable public sensorIssues: SensorIssuesInfo[];
}

export class SensorIssuesInfo {
  constructor() {
    makeObservable(this);
  }
  @observable public id: string;
  @observable public type: number;
  @observable public status: number;
  @observable public template: string;
}

export class ClientProfileCameraSettings {
  constructor() {
    makeObservable(this);
  }
  @observable public hasChanges: boolean;
  @observable public isActive: boolean;
  @observable public optionTypeId: number;
}

export class ClientProfileSettings {
  constructor() {
    makeObservable(this);
  }
  @observable public alerts: Dictionary<Alert[]>;
  @observable public intervals: Interval[] = [];
  @observable public hasChanges?: boolean = false;

  public getInterval = (intervalId: number): Interval => {
    const result = this.intervals.find(
      (interval) => interval.intervalId === intervalId
    );

    if (result === undefined) return new Interval();

    return result!;
  };

  public getDaytimeInterval = (): Interval => {
    const interval = this.intervals.find((i) => i.isDaytimeInterval);
    if (interval === undefined) return new Interval();

    return interval;
  };

  public getNighttimeInterval = (): Interval => {
    const interval = this.intervals.find((i) => i.isDaytimeInterval === false);
    if (interval === undefined) return new Interval();

    return interval;
  };
}

export class ClientProfileAccessSettings {
  constructor() {
    makeObservable(this);
  }
  @observable public clientProfileAccessLocations: AccessLocations[] = [];
  @observable public clientProfileAssignmentAreas: AccessArea[] = [];
  @observable public hasChanges: boolean = false;

  public getDaytimeInterval = (): AccessArea => {
    const accessArea = this.clientProfileAssignmentAreas.find(
      (cpaa) => cpaa.isDaytimeInterval
    );
    if (accessArea === undefined) return new AccessArea();

    return accessArea;
  };

  public getNighttimeInterval = (): AccessArea => {
    const accessArea = this.clientProfileAssignmentAreas.find(
      (cpaa) => cpaa.isDaytimeInterval === false
    );
    if (accessArea === undefined) return new AccessArea();

    return accessArea;
  };
}
export class AccessLocations {
  constructor() {
    makeObservable(this);
  }
  @observable public wireless_access_id: number;
  @observable public name: string;
}

export class AccessArea {
  constructor() {
    makeObservable(this);
  }
  @observable public accessGroupId: number;
  @observable public accessLocationId: number;
  @observable public isActive: boolean;
  @observable public accessAreaStartTime: string;
  @observable public isDaytimeInterval: boolean;
}

export interface Dictionary<TVal> {
  [key: string]: TVal;
}

export class Alert {
  constructor() {
    makeObservable(this);
  }
  @observable public alertId: number;
  @observable public isActive: boolean;
  @observable public hasSensors: boolean;
  @observable public areSensorsActive: boolean;
  @observable public delay?: number;
  @observable public sound?: number;
  @observable public name: string;
  @observable public typeId: number;
}

export class Interval {
  constructor() {
    makeObservable(this);
  }
  @observable public intervalId: number;
  @observable public startTime: string;
  @observable public isDaytimeInterval: boolean;
}

export class DataValidationStatus {
  constructor() {
    makeObservable(this);
  }

  @observable public buildingAccessValid: boolean;
  @observable public acousticSettingsValid: boolean;
}

export class ClientProfileAlertsData {
  constructor() {
    makeObservable(this);
  }

  @observable public clientId: number;
  @observable public intervalType: number;
  @observable public intervalId: number;
  @observable public alerts: AlertSettings[];
}

export enum AlertType {
  Internal = 0,
  External = 1,
}

export enum DelayType {
  None = 0,
  Seconds,
  Minutes,
  Hours,
}

export class AlertSettings {
  alertId: number;
  type: AlertType;
  name: string;
  delay?: number;
  delayType: DelayType;
  sound?: number;
  isActive: boolean;
  hasSensors: boolean;
  areSensorsActive: boolean;
}

export class AlertData {
  alertId: number;
  delay?: number;
  sound?: number;
  isActive: boolean;
}
