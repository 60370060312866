import styles from "./page.module.css";

import { observer, inject } from "mobx-react";
import { ClientDataStore, EmptyClientData } from "./store/store";
import Header from "./components/header/header";
import { ViewItem, ViewSwitch } from "../../components/view-switch";
import InactiveClientGrid from "./components/inactive-residents-grid/InactiveClientsGrid";
import ManageClientsGrid from "./components/residents-grid/ManageClientsGrid";
import { useEffect } from "react";
import { TransitionStore } from "../../store";
import { useTranslation } from "react-i18next";

interface IClientProps {
  clientDataStore: ClientDataStore;
  transitionStore: TransitionStore;
}

function Clients(props: IClientProps) {
  const { ready: readyCameraGrid } = useTranslation("client-grid");
  const { ready: readySearch } = useTranslation("search-client");
  const { ready: readyCommon } = useTranslation("common");
  const { clientDataStore, transitionStore } = props;
  const {
    isDataLoading,
    isTranslationLoading,
    isSaveLoading,
    isDeleteLoading,
  } = clientDataStore;

  const languagesLoaded = readyCameraGrid && readySearch && readyCommon;

  useEffect(() => {
    if (!languagesLoaded) {
      clientDataStore.setIsTranslationLoading(true);
    } else {
      clientDataStore.setIsTranslationLoading(false);
    }

    if (
      isDataLoading ||
      isTranslationLoading ||
      isSaveLoading ||
      isDeleteLoading
    ) {
      transitionStore.setLoading(true);
    } else {
      transitionStore.setLoading(false);
    }
  });

  useEffect(() => {
    return () => {
      const { clientDataStore } = props;

      clientDataStore!.setSelectedClient(EmptyClientData);
      clientDataStore!.closeExpanded();
      transitionStore.isPromptShown = false;
    };
  }, []);

  useEffect(() => {
    transitionStore.isPromptShown = clientDataStore.hasSelectedClient;
  }, [clientDataStore.hasSelectedClient]);

  const render = () => {
    const clientDataStore = props.clientDataStore as ClientDataStore;

    return (
      <>
        {languagesLoaded && (
          <>
            <Header />
            <div
              id="grid-container"
              className={styles["manage-clients-grid-container"]}
            >
              <ViewSwitch activeKey={clientDataStore.selectedTab}>
                <ViewItem itemKey="manage-clients">
                  <div className={styles["overflow-content"]}>
                    <ManageClientsGrid />
                  </div>
                </ViewItem>
                <ViewItem itemKey="inactive-clients">
                  <div className={styles["overflow-content"]}>
                    <InactiveClientGrid />
                  </div>
                </ViewItem>
              </ViewSwitch>
            </div>
          </>
        )}
      </>
    );
  };

  return render();
}

export default inject("clientDataStore", "transitionStore")(observer(Clients));
