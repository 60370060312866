import styles from "./header.module.css";

import { inject, observer } from "mobx-react";
import { PeripheralDataStore } from "../../store/store";
import { TransitionStore } from "../../../../store";
import { useTranslation } from "react-i18next";
import { TabItem, TabPanel } from "../../../../components/tab-panel";
import AddButton from "../../../../components/buttons/AddButton";

export interface IHeaderProps {
  peripheralDataStore?: PeripheralDataStore;
  transitionStore?: TransitionStore;
}

function Header(props: IHeaderProps) {
  const { t } = useTranslation("peripheral-grid");
  const transitionStore = props.transitionStore!;
  const peripheralDataStore = props.peripheralDataStore!;

  const render = () => {
    return (
      <div className={styles["manage-peripherals-page-header"]}>
        <div className={styles["manage-peripherals-tabs"]}>
          <TabPanel activeKey={"manage-peripherals"}>
            <TabItem
              itemKey={"manage-peripherals"}
              label={t("ManagePeripherals")}
            />
          </TabPanel>
        </div>
        <div className={styles["manage-peripherals-filter-inputs"]}>
          <div className={styles["manage-peripherals-add-button"]}>
            <AddButton
              disabled={
                transitionStore?.isLoading ||
                peripheralDataStore.hasSelectedPeripheral
              }
              onClick={() => {
                peripheralDataStore.setNewPeripheral();
              }}
            >
              {t("AddPeripheral")}
            </AddButton>
          </div>
        </div>
      </div>
    );
  };

  return render();
}

export default inject(
  "peripheralDataStore",
  "transitionStore"
)(observer(Header));
