import { inject, observer } from "mobx-react";
import { IconUploadField } from "../../../../../components/form/edit-fields/icon-upload-field/IconUploadField";
import { SubstatusDataStore } from "../../../store/store";
import { DEFAULT_BLACK_ICON } from "../../../../manage-alerts/store/store";

export interface BrowseForFileProps {
  substatusDataStore?: SubstatusDataStore;
}

function BrowseForFile(props: BrowseForFileProps) {
  const substatusDataStore = props.substatusDataStore!;

  const onIconChanged = (data: FormData): void => {
    substatusDataStore.setSelectedSubstatusIcon(data);
    substatusDataStore.validateSelectedSubstatus();
  };

  const svgIcon = new Blob([DEFAULT_BLACK_ICON], { type: "image/svg+xml" });

  return (
    <IconUploadField
      currentIcon={substatusDataStore.selectedSubstatus.statusIcon}
      iconMemberName="statusIcon"
      onIconChanged={onIconChanged}
      defaultIcon={svgIcon}
    />
  );
}

export default inject("substatusDataStore")(observer(BrowseForFile));